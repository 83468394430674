html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, legend, table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, audio, canvas, details, figcaption, figure, footer, header, hgroup,
mark, menu, meter, nav, output, progress, section, summary, time, video {
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  margin: 0;
  padding: 0;
  font-family: "Roboto",sans-serif;
}

article, aside, dialog, figure, footer, header, hgroup, nav, section, blockquote {
  display: block;
}

nav ul {
  list-style: none;
}

ol {
  list-style: decimal;
}

ul {
  list-style: disc;
}

ul ul {
  list-style: circle;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

ins {
  text-decoration: underline;
}

del {
  text-decoration: line-through;
}

mark {
  background: none;
}

abbr[title], dfn[title], acronym[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input[type=submit], input[type=button], button {
  margin: 0;
  padding: 0;
}

input, select, a img {
  vertical-align: middle;
}

b, strong {
  font-weight: bold;
}

i, em {
  font-style: italic;
}

html {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  -moz-box-sizing: inherit;
  -webkit-box-sizing: inherit;
}

/* generic class */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
  word-break: break-word;
}

p {
  margin-bottom: 20px;
}

ul, ol {
  margin-left: 35px;
  margin-bottom: 20px;
}

dl {
  margin-bottom: 20px;
}

dl dt {
  font-weight: 700;
  margin-top: 10px;
}

dl dt:first-child {
  margin-top: 0;
}

dl dd {
  margin-left: 20px;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
  margin-bottom: 20px;
}

figure {
  max-width: 100%;
}

.clear {
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
  zoom: 1;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: " ";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

/* wordpress core */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter, div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

figcaption.wp-caption-text {
  margin-top: 20px;
}

.navigation {
  text-align: right;
  margin-bottom: 30px;
}

.navigation .screen-reader-text {
  display: none;
}

.navigation a, .navigation span {
  margin: 0 5px;
}

.post-password-form input[type="password"] {
  padding: 14px 20px;
  margin-left: 5px;
  margin-right: 6px;
}

/* layout */
.realfactory-body-outer-wrapper {
  position: relative;
  overflow: hidden;
}

.realfactory-body-wrapper.realfactory-with-frame {
  position: relative;
}

.realfactory-page-wrapper {
  clear: both;
}

body.realfactory-boxed .realfactory-body-wrapper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

body.realfactory-boxed .realfactory-body-wrapper.realfactory-left {
  margin-left: 0;
}

body.realfactory-boxed .realfactory-body-wrapper.realfactory-right {
  margin-right: 0;
}

body.realfactory-boxed.realfactory-boxed-border .realfactory-body-wrapper {
  box-shadow: 0 -5px 9px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 -5px 9px rgba(0, 0, 0, 0.25);
}

body.realfactory-boxed.realfactory-background-pattern {
  background-position: center;
  background-repeat: repeat;
}

body.realfactory-boxed.realfactory-background-image .realfactory-body-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: cover;
}

.realfactory-container {
  margin-left: auto;
  margin-right: auto;
}

.realfactory-column-10 {
  float: left;
  width: 16.6666%;
}

.realfactory-column-12 {
  float: left;
  width: 20%;
}

.realfactory-column-15 {
  float: left;
  width: 25%;
}

.realfactory-column-20 {
  float: left;
  width: 33.3333%;
}

.realfactory-column-24 {
  float: left;
  width: 40%;
}

.realfactory-column-30 {
  float: left;
  width: 50%;
}

.realfactory-column-36 {
  float: left;
  width: 60%;
}

.realfactory-column-40 {
  float: left;
  width: 66.6666%;
}

.realfactory-column-45 {
  float: left;
  width: 75%;
}

.realfactory-column-48 {
  float: left;
  width: 80%;
}

.realfactory-column-50 {
  float: left;
  width: 83.3333%;
}

.realfactory-column-60 {
  float: left;
  width: 100%;
}

.realfactory-sidebar-right {
  float: right;
}

.realfactory-sidebar-center {
  float: none;
  display: inline-block;
}

.realfactory-content-area {
  padding-top: 60px;
  padding-bottom: 40px;
}

.realfactory-sidebar-area {
  padding-top: 60px;
}

.realfactory-line-height-0 {
  line-height: 0;
}

a, button {
  text-decoration: none;
  transition: background 300ms, color 300ms, border-color 300ms;
  -moz-transition: background 300ms, color 300ms, border-color 300ms;
  -o-transition: background 300ms, color 300ms, border-color 300ms;
  -webkit-transition: background 300ms, color 300ms, border-color 300ms;
}

img {
  max-width: 100%;
  height: auto;
}

blockquote {
  quotes: none;
  padding: 30px 30px 15px;
  font-size: 17px;
  margin-bottom: 35px;
  font-style: italic;
  margin-top: 35px;
  border-left-width: 2px;
  border-left-style: solid;
}

q {
  margin-right: 15px;
  display: inline-block;
  padding: 10px 20px;
  vertical-align: middle;
  border-left-width: 2px;
  border-left-style: solid;
}

.realfactory-media-image {
  line-height: 0;
  position: relative;
}

.realfactory-left-align {
  text-align: left;
}

.realfactory-center-align {
  text-align: center;
}

.realfactory-right-align {
  text-align: right;
}

.realfactory-body-wrapper {
  overflow: hidden;
  z-index: 1;
}

/* input */
input, textarea {
  border-width: 1px 1px 2px 1px;
  border-style: solid;
}

input:focus, textarea:focus {
  outline: none;
}

/* button */
.realfactory-button, input[type="button"], input[type="submit"], input[type="reset"] {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  padding: 15px 33px;
  display: inline-block;
  -webkit-appearance: none;
}

input[type="button"], input[type="submit"], input[type="reset"] {
  border-width: 0;
  border-style: solid;
  cursor: pointer;
  transition: background 300ms, color 300ms, border-color 300ms;
  -moz-transition: background 300ms, color 300ms, border-color 300ms;
  -o-transition: background 300ms, color 300ms, border-color 300ms;
  -webkit-transition: background 300ms, color 300ms, border-color 300ms;
}

input[type="button"]:focus, input[type="submit"]:focus, input[type="reset"]:focus {
  outline: none;
}

/**
 * 2.0 - Header
 */
/* preload */
.realfactory-page-preload {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  background-image: url("../images/preload.gif");
  background-position: center;
  background-repeat: no-repeat;
}

.realfactory-page-preload.realfactory-out {
  background-image: none;
}

/* top bar */
.realfactory-top-bar {
  position: relative;
  border-bottom-style: solid;
}

.realfactory-top-bar-background {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.realfactory-top-bar-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.realfactory-top-bar-left {
  float: left;
}

.realfactory-top-bar-right {
  float: right;
}

.realfactory-top-bar-right-text {
  display: inline;
}

.realfactory-top-bar-right-social {
  display: inline;
  margin-left: 10px;
}

.realfactory-top-bar-right-social a {
  margin-left: 25px;
}

/* mobile menu */
.realfactory-mm-menu-wrap {
  display: none;
}

.admin-bar .mm-menu {
  margin-top: 32px;
}

.realfactory-mobile-button-hamburger-with-border {
  font-size: 16px;
  padding: 6px;
  line-height: 0;
  width: 32px;
  height: 32px;
  text-align: center;
  display: inline-block;
  border-width: 2px;
  border-style: solid;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.realfactory-right-menu-button {
  display: inline-block;
  margin-left: 27px;
}

.realfactory-mobile-menu {
  float: left;
  margin-top: -8px;
  margin-left: 30px;
}

.realfactory-mobile-menu-right {
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -9px;
}

/* hamburger */
.realfactory-mobile-button-hamburger {
  box-sizing: border-box;
  display: block;
  width: 32px;
  height: 32px;
  position: relative;
}

.realfactory-mobile-button-hamburger:before,
.realfactory-mobile-button-hamburger:after,
.realfactory-mobile-button-hamburger span {
  content: '';
  display: block;
  width: 22px;
  height: 2px;
  position: absolute;
  left: 5px;
}

.realfactory-mobile-button-hamburger:before {
  top: 8px;
}

.realfactory-mobile-button-hamburger span {
  top: 15px;
}

.realfactory-mobile-button-hamburger:after {
  top: 22px;
}

.realfactory-mobile-button-hamburger:before {
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.realfactory-mobile-button-hamburger span {
  transition: opacity 0.15s 0.4s ease;
}

.realfactory-mobile-button-hamburger:after {
  transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.realfactory-mobile-button-hamburger.realfactory-active:before {
  -webkit-transform: translate3d(0, 7px, 0) rotate(135deg);
  transform: translate3d(0, 7px, 0) rotate(135deg);
  transition-delay: 0.1s;
}

.realfactory-mobile-button-hamburger.realfactory-active span {
  transition-delay: 0s;
  opacity: 0;
}

.realfactory-mobile-button-hamburger.realfactory-active:after {
  transition-delay: 0.1s;
  -webkit-transform: translate3d(0, -7px, 0) rotate(-135deg);
  transform: translate3d(0, -7px, 0) rotate(-135deg);
}

/* navigation menu */
.realfactory-navigation .sf-menu {
  display: inline-block;
  line-height: 1.7;
  text-align: left;
  margin-bottom: 0;
}

.realfactory-navigation .sf-menu > li:first-child {
  padding-left: 0;
}

.realfactory-navigation .sf-menu > li:last-child {
  padding-right: 0;
}

.realfactory-navigation .sf-menu > li > a {
  letter-spacing: 0.5px;
}

.realfactory-navigation-submenu-indicator .sf-menu > li > a.sf-with-ul-pre:after {
  display: inline-block;
  margin-left: 13px;
  content: "\f107";
  font-family: "fontAwesome",sans-serif;
}

.realfactory-navigation .sf-menu > .realfactory-normal-menu li {
  padding: 1px 11px;
}

.realfactory-navigation .sf-menu > .realfactory-normal-menu li:first-child {
  padding-top: 12px;
}

.realfactory-navigation .sf-menu > .realfactory-normal-menu li:last-child {
  padding-bottom: 12px;
}

.realfactory-navigation .sf-menu > .realfactory-normal-menu li li {
  /* padding-left: 0; */
}

.realfactory-navigation .sf-menu > .realfactory-normal-menu li > a {
  display: block;
  padding: 6px 12px;
}

.realfactory-navigation .sf-menu > .realfactory-normal-menu li > a.sf-with-ul-pre:after {
  float: right;
  margin-left: 18px;
  content: "\f105";
  font-family: fontAwesome;
}

.realfactory-navigation .sf-menu > .realfactory-normal-menu .sub-menu {
  font-size: 14px;
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega-full {
  left: 0;
  right: 0;
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega-section-wrap {
  overflow: hidden;
  border-top-width: 1px;
  border-top-style: solid;
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega-section-wrap:first-child {
  border-top: none;
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega-section {
  padding-bottom: 999px;
  margin-bottom: -999px;
  border-left-width: 1px;
  border-left-style: solid;
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega-section:first-child {
  border-left: none;
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega-section-inner {
  padding: 31px 28px;
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega-section-inner > a {
  font-size: 15px;
  display: block;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 0 12px;
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega-section-inner .sub-menu a {
  font-size: 14px;
  padding: 5px 12px;
  display: block;
}

.realfactory-navigation .realfactory-navigation-slide-bar {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  position: absolute;
  display: none;
  margin-top: -4px;
  overflow: hidden;
}

.realfactory-navigation .sf-vertical > li {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.realfactory-navigation .sf-vertical > li:first-child {
  padding-top: 0;
}

.realfactory-navigation .sf-vertical > li > a {
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: block;
}

.realfactory-navigation .sf-vertical ul.sub-menu {
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

.realfactory-navigation .sf-vertical ul.sub-menu li {
  padding: 2px 11px;
}

.realfactory-navigation .sf-vertical ul.sub-menu li:first-child {
  padding-top: 20px;
}

.realfactory-navigation .sf-vertical ul.sub-menu li:last-child {
  padding-bottom: 20px;
}

.realfactory-navigation .sf-vertical ul.sub-menu li > a {
  display: block;
  padding: 4px 12px;
}

.realfactory-navigation .sf-vertical ul.sub-menu li > a.sf-with-ul:after,
.realfactory-navigation-submenu-indicator .sf-vertical > li > a.sf-with-ul:after {
  float: right;
  margin-left: 18px;
  content: "\f105";
  font-family: fontAwesome;
}

/* bullet anchor */
.realfactory-bullet-anchor {
  position: fixed;
  top: 50%;
  right: 30px;
  margin-top: -50px;
  z-index: 10;
  opacity: 0;
  transition: opacity 200ms;
  -moz-transition: opacity 200ms;
  -o-transition: opacity 200ms;
  -webkit-transition: opacity 200ms;
}

.realfactory-bullet-anchor.realfactory-init {
  opacity: 1;
}

.realfactory-bullet-anchor a {
  display: block;
  width: 17px;
  height: 17px;
  margin-bottom: 5px;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.realfactory-bullet-anchor a:before {
  content: " ";
  width: 9px;
  height: 9px;
  margin: 3px;
  display: block;
  overflow: hidden;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  transition: background-color 200ms;
  -moz-transition: background-color 200ms;
  -o-transition: background-color 200ms;
  -webkit-transition: background-color 200ms;
}

/* breadcrumbs */
.realfactory-breadcrumbs a {
  color: #c0c0c0;
}

.realfactory-breadcrumbs,
.realfactory-breadcrumbs a:hover {
  color: #777;
}

.realfactory-breadcrumbs {
  font-size: 15px;
  padding: 21px 0 19px;
}

.realfactory-breadcrumbs span[property="itemListElement"] {
  margin: 0 15px;
}

.realfactory-breadcrumbs span[property="itemListElement"]:first-child {
  margin-left: 0;
}

.realfactory-breadcrumbs a span {
  transition: color 200ms;
  -moz-transition: color 200ms;
  -o-transition: color 200ms;
  -webkit-transition: color 200ms;
}

/* top search */
.realfactory-top-search-wrap input.search-field {
  border-bottom-color: #303030;
  color: #fff;
}

.realfactory-top-search-wrap .realfactory-top-search-submit,
.realfactory-top-search-wrap .realfactory-top-search-close {
  color: #fff;
}

.realfactory-top-search-wrap input::-webkit-input-placeholder {
  color: #fff;
}

.realfactory-top-search-wrap input::-moz-placeholder {
  color: #fff;
}

.realfactory-top-search-wrap input:-ms-input-placeholder {
  color: #fff;
}

.realfactory-top-search-wrap input:-moz-placeholder {
  color: #fff;
}

.realfactory-top-search-row {
  display: table;
  width: 100%;
  height: 100%;
}

.realfactory-top-search-cell {
  display: table-cell;
  vertical-align: middle;
  padding-left: 30px;
  padding-right: 30px;
}

.realfactory-top-search-wrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: auto;
  display: none;
}

.realfactory-top-search-wrap form {
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.realfactory-top-search-wrap input.search-field {
  background: transparent;
  width: 100%;
  border-width: 0 0 3px;
  font-size: 40px;
  padding: 0 120px 25px 0;
  line-height: 48px;
  letter-spacing: 0.5px;
}

.realfactory-top-search-wrap input.search-submit {
  position: absolute;
  top: 0;
  right: 52px;
  width: 48px;
  height: 48px;
  padding: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.realfactory-top-search-wrap .realfactory-top-search-submit {
  font-size: 30px;
  position: absolute;
  right: 52px;
  top: 0;
  line-height: 48px;
  width: 48px;
  text-align: center;
}

.realfactory-top-search-wrap .realfactory-top-search-close {
  font-size: 48px;
  position: absolute;
  right: 0;
  top: 0;
  line-height: 48px;
  width: 48px;
  text-align: center;
  cursor: pointer;
}

/* overlay menu */
.realfactory-overlay-menu-row {
  display: table;
  width: 100%;
  height: 100%;
}

.realfactory-overlay-menu-cell {
  display: table-cell;
  vertical-align: middle;
}

.realfactory-overlay-menu-content {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: auto;
  display: none;
}

.realfactory-overlay-menu-content ul.menu {
  list-style: none;
  text-align: center;
  padding-top: 140px;
  padding-bottom: 180px;
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
}

.realfactory-overlay-menu-content ul.menu > li {
  font-size: 23px;
  font-weight: bold;
  text-transform: uppercase;
  opacity: 0;
  margin-bottom: 17px;
  padding-bottom: 17px;
  letter-spacing: 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  -webkit-transform: translate3d(0, -30px, 0);
  transform: translate3d(0, -30px, 0);
  transition: opacity 300ms, transform 300ms;
  -moz-transition: opacity 300ms, -moz-transform 300ms;
  -o-transition: opacity 300ms, -o-transform 300ms;
  -webkit-transition: opacity 300ms, -webkit-transform 300ms;
}

.realfactory-overlay-menu-content.realfactory-active ul.menu > li {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: none;
  transform: none;
}

.realfactory-overlay-menu-content ul.sub-menu {
  display: none;
  padding-top: 25px;
  padding-bottom: 10px;
  list-style: none;
  margin-left: 0;
}

.realfactory-overlay-menu-content ul.sub-menu > li {
  font-size: 14px;
  text-transform: none;
  font-weight: normal;
  margin-bottom: 15px;
}

.realfactory-overlay-menu-content ul.sub-menu ul.sub-menu {
  max-width: 380px;
  margin: 25px auto 25px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
}

.realfactory-overlay-menu-content .realfactory-overlay-menu-close {
  line-height: 1;
  font-size: 48px;
  position: absolute;
  left: 50%;
  top: 60px;
  margin-left: -24px;
  cursor: pointer;
}

.realfactory-overlay-menu-content .realfactory-overlay-menu-close:after {
  content: "\4d";
  font-family: elegantIcons;
}

.realfactory-overlay-menu-content ul li.menu-item-has-children > a:after {
  content: "\f107";
  font-family: "fontAwesome";
  margin-left: 15px;
}

body.admin-bar .realfactory-overlay-menu-content {
  margin-top: 32px;
}

/* menu right area */
.realfactory-main-menu-right-button {
  float: left;
  padding: 8px 15px;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: -8px;
  margin-left: 30px;
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
}

.realfactory-main-menu-right {
  float: left;
  margin-top: -5px;
}

.realfactory-main-menu-search {
  float: left;
  margin-top: -1px;
  margin-left: 25px;
}

.realfactory-main-menu-search i {
  font-size: 14px;
  margin-top: -1px;
  cursor: pointer;
}

.realfactory-main-menu-cart {
  float: left;
  margin-top: -0px;
  padding-left: 20px;
  margin-left: 5px;
  position: relative;
}

.realfactory-main-menu-cart i {
  font-size: 16px;
  margin-top: -1px;
  cursor: pointer;
}

.realfactory-main-menu-cart > .realfactory-top-cart-count {
  font-size: 10px;
  font-weight: 700;
  vertical-align: super;
  width: 16px;
  height: 16px;
  line-height: 15px;
  position: absolute;
  top: -5px;
  right: -8px;
  text-align: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.realfactory-top-cart-content-wrap {
  position: absolute;
  width: 210px;
  right: 0;
  top: 100%;
  padding-top: 24px;
  z-index: 99;
}

.realfactory-top-cart-content-wrap .realfactory-top-cart-content {
  padding: 25px;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
}

.realfactory-top-cart-content-wrap .woocommerce-Price-amount.amount {
  float: none;
}

.realfactory-top-cart-content-wrap .realfactory-top-cart-count-wrap {
  margin-bottom: 8px;
}

.realfactory-top-cart-content-wrap .realfactory-top-cart-amount-wrap {
  margin-bottom: 23px;
}

.realfactory-top-cart-content-wrap .realfactory-top-cart-button {
  display: block;
  margin-bottom: 5px;
}

.realfactory-top-cart-content-wrap .realfactory-top-cart-checkout-button {
  display: block;
}

.realfactory-main-menu-cart .realfactory-top-cart-hover-area {
  position: absolute;
  top: -20px;
  right: 0;
  bottom: -20px;
  left: 0;
}

.realfactory-top-cart-hover-area + .realfactory-top-cart-content-wrap {
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, 15px, 0);
  -webkit-transform: translate3d(0, 15px, 0);
  transition: opacity 400ms, transform 400ms;
  -moz-transition: opacity 400ms, -moz-transform 400ms;
  -o-transition: opacity 400ms, -o-transform 400ms;
  -webkit-transition: opacity 400ms, -webkit-transform 400ms;
}

.realfactory-main-menu-cart:hover .realfactory-top-cart-content-wrap,
.realfactory-top-cart-hover-area:hover + .realfactory-top-cart-content-wrap {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

/* header */
.realfactory-logo {
  position: relative;
}

.realfactory-header-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.realfactory-header-container-inner {
  position: relative;
}

/* header right */
.realfactory-logo-right-block {
  text-align: left;
  display: inline-block;
  padding: 2px 0 2px 20px;
  margin-left: 20px;
  border-left-width: 1px;
  border-left-style: solid;
  vertical-align: middle;
}

.realfactory-logo-right-block:first-child {
  padding-left: 0;
  border: none;
  margin-left: 0;
}

.realfactory-logo-right-block .realfactory-logo-right-block-icon {
  font-size: 15px;
  float: left;
  margin-right: 12px;
}

.realfactory-logo-right-block .realfactory-logo-right-block-content {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
}

.realfactory-logo-right-block .realfactory-logo-right-block-title {
  font-size: 16px;
  font-weight: 600;
}

.realfactory-logo-right-block .realfactory-logo-right-block-caption {
  font-size: 13px;
  text-transform: uppercase;
  margin-top: 8px;
}

.realfactory-header-right-button {
  display: inline-block;
  padding: 12px 25px;
  font-size: 13px;
  font-weight: bold;
  margin-top: -8px;
  margin-left: 50px;
  border-radius: 3px;
  vertical-align: middle;
  border-width: 2px;
  border-style: solid;
}

.realfactory-header-right-button:first-child {
  margin-left: 0;
}

/* header plain */
.realfactory-header-style-plain {
  position: relative;
  border-bottom-style: solid;
}

.realfactory-header-style-plain .realfactory-navigation {
  line-height: 0;
}

.realfactory-header-style-plain .realfactory-header-background {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.realfactory-header-background-transparent {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.realfactory-header-style-plain.realfactory-style-menu-right .realfactory-logo {
  float: left;
}

.realfactory-header-style-plain.realfactory-style-menu-right .realfactory-navigation {
  float: right;
}

.realfactory-header-style-plain.realfactory-style-menu-right .realfactory-main-menu {
  display: inline-block;
  vertical-align: top;
}

.realfactory-header-style-plain.realfactory-style-menu-right .realfactory-main-menu-right-wrap {
  display: inline-block;
  line-height: 1.7;
  margin-left: 10px;
  vertical-align: top;
}

.realfactory-header-style-plain.realfactory-style-center-logo .realfactory-logo-inner {
  margin-left: auto;
  margin-right: auto;
}

.realfactory-header-style-plain.realfactory-style-center-logo .realfactory-navigation {
  text-align: center;
  position: relative;
}

.realfactory-header-style-plain.realfactory-style-center-logo .realfactory-main-menu {
  display: inline-block;
  vertical-align: top;
}

.realfactory-header-style-plain.realfactory-style-center-logo .realfactory-main-menu-right-wrap {
  position: absolute;
  right: 0;
  line-height: 1.7;
}

.realfactory-header-style-plain.realfactory-style-center-menu .realfactory-logo {
  position: absolute;
}

.realfactory-header-style-plain.realfactory-style-center-menu .realfactory-navigation {
  text-align: center;
}

.realfactory-header-style-plain.realfactory-style-center-menu .realfactory-main-menu {
  display: inline-block;
  vertical-align: top;
}

.realfactory-header-style-plain.realfactory-style-center-menu .realfactory-main-menu-right-wrap {
  position: absolute;
  right: 0;
  line-height: 1.7;
}

.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-logo {
  padding-top: 0;
  padding-bottom: 0;
}

.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-navigation {
  text-align: center;
}

.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-main-menu {
  display: inline-block;
  vertical-align: top;
}

.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-main-menu-right-wrap {
  position: absolute;
  right: 0;
  line-height: 1.7;
}

.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-main-menu-left-wrap {
  position: absolute;
  left: 0;
  line-height: 1.7;
}

.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-right-menu-button {
  margin-left: 0;
}

/* header box */
.realfactory-header-boxed-wrap {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.realfactory-header-style-boxed .realfactory-header-container-item {
  position: relative;
}

.realfactory-header-style-boxed .realfactory-navigation {
  line-height: 0;
}

.realfactory-header-style-boxed .realfactory-header-background {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.realfactory-header-style-boxed.realfactory-style-menu-right .realfactory-logo {
  float: left;
}

.realfactory-header-style-boxed.realfactory-style-menu-right .realfactory-navigation {
  float: right;
}

.realfactory-header-style-boxed.realfactory-style-menu-right .realfactory-main-menu {
  display: inline-block;
  vertical-align: top;
}

.realfactory-header-style-boxed.realfactory-style-menu-right .realfactory-main-menu-right-wrap {
  display: inline-block;
  line-height: 1.7;
  margin-left: 10px;
  vertical-align: top;
}

.realfactory-header-style-boxed.realfactory-style-center-menu .realfactory-logo {
  position: absolute;
}

.realfactory-header-style-boxed.realfactory-style-center-menu .realfactory-navigation {
  text-align: center;
}

.realfactory-header-style-boxed.realfactory-style-center-menu .realfactory-main-menu {
  display: inline-block;
  vertical-align: top;
}

.realfactory-header-style-boxed.realfactory-style-center-menu .realfactory-main-menu-right-wrap {
  position: absolute;
  line-height: 1.7;
}

.realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-logo {
  padding-top: 0;
  padding-bottom: 0;
}

.realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-navigation {
  text-align: center;
}

.realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-main-menu {
  display: inline-block;
  vertical-align: top;
}

.realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-main-menu-right-wrap {
  position: absolute;
  line-height: 1.7;
}

.realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-main-menu-left-wrap {
  position: absolute;
  line-height: 1.7;
}

.realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-right-menu-button {
  margin-left: 0;
}

/* header bar */
.realfactory-header-style-bar .realfactory-logo {
  float: left;
}

.realfactory-header-style-bar .realfactory-logo-right-text {
  float: right;
  text-align: right;
  font-size: 13px;
}

.realfactory-header-style-bar .realfactory-header-container-inner {
  position: relative;
}

.realfactory-navigation-bar-wrap {
  position: relative;
}

.realfactory-navigation-bar-wrap.realfactory-style-transparent {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.realfactory-navigation-bar-wrap .realfactory-navigation {
  line-height: 0;
  position: relative;
}

.realfactory-navigation-bar-wrap .realfactory-navigation-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.realfactory-navigation-bar-wrap .realfactory-navigation-container {
  position: relative;
}

.realfactory-navigation-bar-wrap .realfactory-main-menu {
  display: inline-block;
  vertical-align: top;
}

.realfactory-navigation-bar-wrap.realfactory-style-left .realfactory-main-menu-right-wrap {
  float: right;
  line-height: 1.7;
  margin-left: 10px;
}

.realfactory-navigation-bar-wrap.realfactory-style-center {
  text-align: center;
}

.realfactory-navigation-bar-wrap.realfactory-style-center .realfactory-main-menu-right-wrap {
  position: absolute;
  right: 0;
  line-height: 1.7;
}

.realfactory-header-style-bar.realfactory-style-center .realfactory-logo {
  float: none;
}

.realfactory-header-style-bar.realfactory-style-center .realfactory-logo-inner {
  margin-left: auto;
  margin-right: auto;
}

.realfactory-header-style-bar.realfactory-style-center .realfactory-logo-right-text {
  position: absolute;
  top: 0;
  right: 0;
}

/* header side */
.realfactory-header-side-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 99;
}

.realfactory-header-side-nav.realfactory-allow-slide {
  position: absolute;
  bottom: auto;
}

.realfactory-header-side-nav.realfactory-fix-bottom {
  position: fixed;
  top: auto;
  bottom: 0;
}

.realfactory-header-side-nav.realfactory-fix-top {
  position: fixed;
}

.realfactory-header-side-nav.realfactory-style-left {
  left: 0;
}

.realfactory-header-side-nav.realfactory-style-right {
  right: 0;
}

.realfactory-header-side-nav .realfactory-pos-bottom,
.realfactory-header-side-nav.realfactory-style-middle .realfactory-pos-middle {
  opacity: 0;
  filter: alpha(opacity=0);
  transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
}

.realfactory-header-side-nav .realfactory-pos-bottom.realfactory-active,
.realfactory-header-side-nav.realfactory-style-middle .realfactory-pos-middle.realfactory-active {
  opacity: 1;
  filter: alpha(opacity=100);
}

.realfactory-header-side-nav.realfactory-style-side .realfactory-header-social {
  padding-top: 20px;
  padding-bottom: 40px;
}

.realfactory-header-side-nav.realfactory-style-side .realfactory-header-social a {
  margin-left: 25px;
}

.realfactory-header-side-nav.realfactory-style-side .realfactory-header-social a:first-child {
  margin-left: 0;
}

.realfactory-header-side-nav.realfactory-style-side .realfactory-main-menu-right-wrap {
  padding-top: 22px;
}

.realfactory-header-side-nav.realfactory-style-side .realfactory-main-menu-search {
  float: none;
  display: inline-block;
  margin-top: 0;
  margin-left: 20px;
}

.realfactory-header-side-nav.realfactory-style-side .realfactory-main-menu-cart {
  float: none;
  display: inline-block;
  margin-top: 0;
  margin-left: 0;
}

.realfactory-header-side-nav.realfactory-style-side .realfactory-main-menu-right-wrap > div:first-child {
  margin-left: 0;
}

.realfactory-header-side-nav.realfactory-style-left .sf-vertical ul.sub-menu {
  text-align: left;
  margin-left: 0;
}

.realfactory-header-side-nav.realfactory-style-right .sf-vertical ul.sub-menu {
  left: auto;
  right: 100%;
  text-align: right;
}

.realfactory-header-side-nav.realfactory-style-right .sf-vertical ul.sub-menu li > a.sf-with-ul:after {
  content: "\f104";
  float: left;
  margin-left: 0;
  margin-right: 18px;
}

.realfactory-header-style-side.realfactory-center-align .realfactory-logo-inner {
  margin-right: auto;
  margin-left: auto;
}

.realfactory-header-style-side.realfactory-right-align .realfactory-logo-inner {
  margin-right: 0;
  margin-left: auto;
}

.realfactory-header-side-nav.realfactory-style-side-toggle {
  text-align: center;
}

.realfactory-header-side-nav.realfactory-style-side-toggle .realfactory-logo-inner {
  margin-left: auto;
  margin-right: auto;
}

.realfactory-header-side-nav.realfactory-style-side-toggle .realfactory-main-menu-search,
.realfactory-header-side-nav.realfactory-style-side-toggle .realfactory-main-menu-cart {
  float: none;
  margin-bottom: 8px;
  padding-left: 0;
  margin-left: 0;
  margin-top: 0;
}

.realfactory-header-side-nav.realfactory-style-side-toggle .realfactory-header-icon {
  padding-bottom: 20px;
  padding-top: 30px;
}

body.admin-bar .realfactory-header-side-nav {
  padding-top: 32px;
}

html.mm-opened body.admin-bar .realfactory-header-side-nav {
  padding-top: 0;
}

/* fixed nav bar */
.realfactory-sticky-navigation.realfactory-style-fixed .realfactory-logo,
.realfactory-sticky-navigation.realfactory-style-fixed .realfactory-navigation,
.realfactory-sticky-navigation.realfactory-style-fixed .realfactory-navigation .sf-menu > li > a {
  transition: background 300ms, color 300ms, border-color 300ms, padding 200ms;
  -moz-transition: background 300ms, color 300ms, border-color 300ms, padding 200ms;
  -o-transition: background 300ms, color 300ms, border-color 300ms, padding 200ms;
  -webkit-transition: background 300ms, color 300ms, border-color 300ms, padding 200ms;
}

.realfactory-sticky-navigation.realfactory-style-fixed .realfactory-navigation-top {
  transition: top 200ms;
  -moz-transition: top 200ms;
  -o-transition: top 200ms;
  -webkit-transition: top 200ms;
}

.realfactory-sticky-navigation.realfactory-style-fixed.realfactory-animate-fixed-navigation .realfactory-logo-inner img {
  transition: height 200ms;
  -moz-transition: height 200ms;
  -o-transition: height 200ms;
  -webkit-transition: height 200ms;
}

.realfactory-fixed-navigation.realfactory-style-fixed,
.realfactory-fixed-navigation.realfactory-style-slide {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.realfactory-fixed-navigation.realfactory-style-slide .realfactory-logo-inner img {
  max-height: 35px !important;
  width: auto;
}

body.realfactory-sticky-navigation-no-logo .realfactory-fixed-navigation .realfactory-logo {
  display: none;
}

body.realfactory-sticky-navigation-no-logo .realfactory-mobile-header.realfactory-fixed-navigation .realfactory-logo {
  display: block;
}

body.admin-bar .realfactory-fixed-navigation.realfactory-style-fixed,
body.admin-bar .realfactory-fixed-navigation.realfactory-style-slide {
  margin-top: 32px;
}

@media screen and (max-width: 782px) {
  body.admin-bar .realfactory-fixed-navigation.realfactory-style-slide {
    margin-top: 0;
  }
}

.realfactory-animate-fixed-navigation.realfactory-header-style-plain .realfactory-logo {
  padding-top: 20px;
  padding-bottom: 20px;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-plain .realfactory-navigation {
  padding-top: 30px;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-plain .realfactory-navigation .sf-menu > li > a {
  padding-bottom: 25px;
  line-height: 20px;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-plain .realfactory-navigation-top {
  top: 30px;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-plain.realfactory-animate-logo-height .realfactory-logo-inner img {
  height: 35px !important;
  width: auto;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-logo {
  margin-top: -10px;
  padding-top: 0;
  padding-bottom: 0;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-navigation .sf-menu > li > a {
  padding-top: 0;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-navigation-top {
  top: 22px;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-plain.realfactory-style-center-logo .realfactory-logo {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-boxed .realfactory-logo {
  padding-top: 20px;
  padding-bottom: 20px;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-boxed .realfactory-navigation {
  padding-top: 30px;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-boxed .realfactory-navigation .sf-menu > li > a {
  padding-bottom: 25px;
  line-height: 20px;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-boxed.realfactory-animate-logo-height .realfactory-logo-inner img {
  height: 35px !important;
  width: auto;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-logo {
  margin-top: -10px;
  padding-top: 0;
  padding-bottom: 0;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-navigation .sf-menu > li > a {
  padding-top: 0;
}

/* page title */
.realfactory-page-title-wrap {
  background-image: url("../images/page-title-background.jpg");
  background-position: center;
  background-size: cover;
  position: relative;
}

.realfactory-page-title-wrap .realfactory-page-title-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.realfactory-page-title-wrap .realfactory-page-title-container {
  position: relative;
}

.realfactory-page-title-wrap .realfactory-page-title {
  text-transform: uppercase;
}

.realfactory-page-title-wrap.realfactory-style-small .realfactory-page-title {
  font-size: 37px;
  margin-bottom: 0;
}

.realfactory-page-title-wrap.realfactory-style-small .realfactory-page-caption {
  font-size: 16px;
  margin-top: 13px;
}

.realfactory-page-title-wrap.realfactory-style-small .realfactory-page-title-content {
  padding-top: 93px;
  padding-bottom: 87px;
}

.realfactory-page-title-wrap.realfactory-style-medium .realfactory-page-title {
  font-size: 54px;
  margin-bottom: 0;
}

.realfactory-page-title-wrap.realfactory-style-medium .realfactory-page-caption {
  font-size: 19px;
  margin-top: 13px;
}

.realfactory-page-title-wrap.realfactory-style-medium .realfactory-page-title-content {
  padding-top: 126px;
  padding-bottom: 116px;
}

.realfactory-page-title-wrap.realfactory-style-large .realfactory-page-title {
  font-size: 75px;
  margin-bottom: 0;
}

.realfactory-page-title-wrap.realfactory-style-large .realfactory-page-caption {
  font-size: 25px;
  margin-top: 13px;
}

.realfactory-page-title-wrap.realfactory-style-large .realfactory-page-title-content {
  padding-top: 184px;
  padding-bottom: 168px;
}

.realfactory-page-title-wrap.realfactory-style-custom .realfactory-page-title {
  font-size: 37px;
  margin-bottom: 0;
}

.realfactory-page-title-wrap.realfactory-style-custom .realfactory-page-caption {
  font-size: 16px;
}

/**
 * 3.0 - Footer
 */
/* footer */
.realfactory-footer-wrapper .realfactory-footer-container {
  overflow: hidden;
}

.realfactory-footer-wrapper .realfactory-footer-column {
  padding-bottom: 1029px;
  margin-bottom: -1020px;
  border-left: 1px;
  border-style: solid;
  border-color: inherit;
  padding-left: 30px;
  padding-right: 30px;
}

.realfactory-footer-wrapper .realfactory-footer-column:first-child {
  border: none;
}

.realfactory-footer-wrapper .realfactory-widget-title {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 35px;
}

.realfactory-footer-wrapper .realfactory-widget-title .gdlr-core-flexslider-nav {
  float: right;
  margin-left: 20px;
}

.realfactory-footer-wrapper .widget {
  margin-bottom: 60px;
}

.realfactory-footer-wrapper .widget:last-child {
  margin-bottom: 0;
}

.realfactory-fixed-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

body.realfactory-boxed .realfactory-fixed-footer .realfactory-footer-wrapper,
body.realfactory-boxed .realfactory-fixed-footer .realfactory-copyright-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.realfactory-footer-back-to-top-button {
  position: fixed;
  z-index: 9;
  right: 30px;
  bottom: 30px;
  font-size: 18px;
  line-height: 20px;
  width: 45px;
  height: 45px;
  text-align: center;
  padding: 12px;
  cursor: pointer;
  opacity: 0;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  pointer-events: none;
  transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
}

.realfactory-footer-back-to-top-button.realfactory-scrolled {
  opacity: 1;
  pointer-events: auto;
}

/* copyright */
.realfactory-footer-wrapper {
  padding-top: 70px;
  padding-bottom: 50px;
}

.realfactory-copyright-text {
  text-align: center;
  padding-top: 38px;
  padding-bottom: 38px;
  font-size: 14px;
  letter-spacing: 1px;
}

/**
 * 4.0 - Blog
 */
/* single */
.realfactory-single-article-date-wrapper {
  float: left;
  width: 63px;
  border-right-width: 3px;
  border-right-style: solid;
  line-height: 1;
  margin-top: 5px;
  margin-right: 30px;
  padding-right: 25px;
  padding-bottom: 25px;
  text-align: center;
  white-space: nowrap;
}

.realfactory-single-article-date-wrapper .realfactory-single-article-date-day {
  font-size: 27px;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 0;
  letter-spacing: 3px;
}

.realfactory-single-article-date-wrapper .realfactory-single-article-date-month {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.realfactory-single-article {
  padding-bottom: 35px;
}

.realfactory-single-article .realfactory-single-article-content ul,
.realfactory-single-article .realfactory-single-article-content ol {
  line-height: 2;
}

.realfactory-single-article .realfactory-single-article-thumbnail {
  margin-bottom: 42px;
}

.realfactory-single-article .realfactory-single-article-head {
  margin-bottom: 32px;
}

.realfactory-single-article .realfactory-single-article-title {
  font-size: 37px;
  font-weight: 700;
  margin-bottom: 16px;
  letter-spacing: 0.5px;
}

.realfactory-single-article .realfactory-single-article-head-right {
  overflow: hidden;
}

.realfactory-blog-info-wrapper .realfactory-head {
  margin-right: 13px;
  vertical-align: middle;
}

.realfactory-blog-info-wrapper .realfactory-head i {
  font-size: 15px;
}

.realfactory-blog-info-wrapper .realfactory-blog-info {
  display: inline-block;
  margin-right: 25px;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.realfactory-blog-info-wrapper .realfactory-blog-info:last-child {
  margin-right: 0;
}

.realfactory-sticky-banner {
  line-height: 1;
  display: inline-block;
  letter-spacing: 0.5px;
  padding: 18px 20px 17px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 22px;
}

.realfactory-sticky-banner i {
  font-size: 12px;
  margin-right: 10px;
}

.realfactory-single-article-thumbnail .realfactory-sticky-banner {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 0;
}

body.single-post .realfactory-sidebar-style-none {
  margin-left: auto;
  margin-right: auto;
}

body.blog .realfactory-sidebar-style-none {
  margin-left: auto;
  margin-right: auto;
}

body.blog .realfactory-single-article {
  padding-bottom: 60px;
}

.realfactory-archive-taxonomy-description {
  margin-bottom: 60px;
}

/* single post format */
.realfactory-blog-aside-format .realfactory-single-article-content {
  font-size: 18px;
  font-style: italic;
  padding: 50px 60px 40px;
}

.realfactory-blog-aside-format .realfactory-excerpt-read-more {
  display: none;
}

.realfactory-blog-quote-format .realfactory-single-article-content {
  padding: 50px 45px 40px;
  position: relative;
}

.realfactory-blog-quote-format .realfactory-blog-quote-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  filter: alpha(opacity=30);
}

.realfactory-blog-quote-format .realfactory-blog-quote {
  float: left;
  font-size: 120px;
  margin-top: -10px;
  line-height: 1;
  margin-right: 35px;
  position: relative;
}

.realfactory-blog-quote-format .realfactory-blog-content-wrap {
  overflow: hidden;
  position: relative;
}

.realfactory-blog-quote-format .realfactory-blog-quote-content {
  font-size: 18px;
  font-style: italic;
}

.realfactory-blog-quote-format .realfactory-blog-quote-author {
  font-size: 16px;
  font-style: italic;
  margin-bottom: 20px;
  font-weight: bold;
}

.realfactory-blog-quote-format .realfactory-blog-quote-author:before {
  content: "-";
  margin-right: 2px;
}

.realfactory-blog-quote-format .realfactory-excerpt-read-more {
  display: none;
}

.realfactory-blog-quote-format blockquote,
.realfactory-blog-quote-format q {
  background: transparent;
  border: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
}

.realfactory-blog-link-format .realfactory-blog-icon-link {
  font-size: 23px;
  float: left;
  margin-right: 30px;
  margin-top: 5px;
}

.realfactory-blog-link-format .realfactory-blog-content-wrap {
  overflow: hidden;
}

.realfactory-blog-link-format .realfactory-blog-title {
  font-size: 20px;
  text-transform: none;
  letter-spacing: 0;
  margin-bottom: 9px;
}

.realfactory-blog-link-format .realfactory-blog-content {
  font-size: 14px;
  margin-bottom: 0;
}

.realfactory-blog-link-format .realfactory-excerpt-read-more {
  display: none;
}

/* single social share */
.realfactory-single-social-share {
  padding-top: 10px;
  padding-bottom: 48px;
}

/* single author */
.realfactory-single-author {
  margin-bottom: 55px;
  padding-top: 55px;
  border-top-width: 1px;
  border-top-style: solid;
}

.realfactory-single-author .realfactory-single-author-avartar {
  max-width: 80px;
  overflow: hidden;
  float: left;
  margin-right: 30px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  margin-bottom: 25px;
}

.realfactory-single-author .realfactory-single-author-head-wrap {
  overflow: hidden;
  padding-top: 5px;
}

.realfactory-single-author .realfactory-single-author-caption {
  font-size: 14px;
  font-style: italic;
  margin-bottom: 9px;
  letter-spacing: 0.5px;
}

.realfactory-single-author .realfactory-single-author-title {
  font-size: 26px;
  margin-bottom: 22px;
}

.realfactory-single-author .realfactory-single-author-description {
  clear: left;
}

/* single navigation */
.realfactory-single-nav-area {
  border-top: 1px;
  border-bottom: 1px;
  border-style: solid;
  padding: 33px 0;
  margin-bottom: 55px;
}

.realfactory-single-nav i {
  font-size: 25px;
}

.realfactory-single-nav .realfactory-text {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.5px;
}

.realfactory-single-nav-left {
  float: left;
}

.realfactory-single-nav-left i {
  margin-right: 15px;
  vertical-align: middle;
}

.realfactory-single-nav-right {
  float: right;
}

.realfactory-single-nav-right i {
  margin-left: 15px;
  vertical-align: middle;
}

/* comment area */
.realfactory-comments-area .realfactory-comments-title {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 55px;
}

.realfactory-comments-area ol.comment-list {
  list-style: none;
  margin-bottom: 55px;
  margin-left: 0;
}

.realfactory-comments-area ol.comment-list ol {
  margin-left: 50px;
}

.realfactory-comments-area .comment-article {
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.realfactory-comments-area .comment-avatar {
  max-width: 80px;
  margin-right: 35px;
  float: left;
  overflow: hidden;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.realfactory-comments-area .comment-avatar img {
  display: block;
}

.realfactory-comments-area .comment-meta {
  position: relative;
  margin-bottom: 20px;
}

.realfactory-comments-area .comment-author {
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 5px;
}

.realfactory-comments-area .comment-body {
  overflow: hidden;
}

.realfactory-comments-area .comment-time {
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.8px;
}

.realfactory-comments-area .comment-reply {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.5px;
  position: absolute;
  right: 0;
  top: 4px;
}

.realfactory-comments-area .comment-respond {
  margin-bottom: 85px;
}

.realfactory-comments-area ol.comment-list .comment-respond {
  margin-top: 55px;
}

.realfactory-comments-area .comment-reply-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
}

.realfactory-comments-area .comment-reply-title small {
  margin-left: 10px;
}

.realfactory-comments-area textarea {
  width: 100%;
  padding: 18px 22px;
  font-size: 14px;
  display: block;
  border-width: 1px 1px 2px 1px;
  border-style: solid;
  margin: 0;
  margin-bottom: 14px;
}

.realfactory-comments-area input[type="text"] {
  width: 100%;
  display: block;
  font-size: 14px;
  border-width: 1px 1px 2px 1px;
  border-style: solid;
  padding: 16px 22px;
  margin-bottom: 14px;
}

.realfactory-comments-area input[type="text"]:focus,
.realfactory-comments-area textarea:focus {
  outline: none;
}

.realfactory-comments-area .realfactory-comment-form-author {
  width: 50%;
  padding-right: 7px;
  float: left;
}

.realfactory-comments-area .realfactory-comment-form-email {
  width: 50%;
  padding-left: 7px;
  float: left;
}

.realfactory-comments-area .form-submit {
  padding-top: 18px;
}

/* blog title */
.realfactory-blog-title-wrap {
  background-image: url("../images/page-title-background.jpg");
  background-position: center;
  position: relative;
  background-size: cover;
}

.realfactory-blog-title-wrap .realfactory-blog-title-container {
  position: relative;
}

.realfactory-blog-title-wrap .realfactory-blog-title-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.realfactory-blog-title-wrap.realfactory-feature-image .realfactory-blog-title-overlay {
  opacity: 1;
  background: url("../images/blog-title-gradient.png") center bottom repeat-x;
}

.realfactory-blog-title-wrap.realfactory-style-small .realfactory-blog-title-content {
  padding-top: 93px;
  padding-bottom: 87px;
}

.realfactory-blog-title-wrap.realfactory-style-large .realfactory-blog-title-content {
  padding-top: 184px;
  padding-bottom: 168px;
}

.realfactory-blog-title-wrap .realfactory-single-article-title {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 11px;
  letter-spacing: 0.5px;
}

.realfactory-blog-title-wrap .realfactory-single-article-head-right {
  overflow: hidden;
}

.realfactory-blog-title-wrap .realfactory-single-article-date-wrapper {
  margin-top: 11px;
}

/* 404 */
.realfactory-not-found-wrap {
  text-align: center;
  position: relative;
}

.realfactory-not-found-wrap .realfactory-not-found-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.27;
  filter: alpha(opacity=27);
  background-position: center;
  background-size: cover;
}

.realfactory-not-found-wrap .realfactory-not-found-content {
  position: relative;
  padding: 60px 0 65px;
}

.realfactory-not-found-wrap .realfactory-not-found-head {
  font-size: 150px;
  line-height: 1;
  margin-bottom: 40px;
}

.realfactory-not-found-wrap .realfactory-not-found-title {
  font-size: 35px;
  line-height: 1;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 14px;
}

.realfactory-not-found-wrap .realfactory-not-found-caption {
  font-size: 18px;
}

.realfactory-not-found-wrap .realfactory-not-found-back-to-home a {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.realfactory-not-found-wrap form.search-form {
  max-width: 430px;
  margin: 58px auto 0;
  position: relative;
  margin-bottom: 13px;
}

.realfactory-not-found-wrap form.search-form input.search-field {
  font-size: 15px;
  width: 100%;
  border: none;
  padding: 22px 60px 22px 25px;
  height: 64px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.realfactory-not-found-wrap form.search-form input.search-submit {
  position: absolute;
  right: 0;
  top: 0;
  width: 64px;
  height: 64px;
  opacity: 0;
  filter: alpha(opacity=0);
}

.realfactory-not-found-wrap form.search-form .realfactory-top-search-submit {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -15px;
  font-size: 18px;
}

body.search .realfactory-not-found-wrap .realfactory-not-found-head {
  font-size: 100px;
  text-transform: uppercase;
  margin-bottom: 18px;
}

body.search .realfactory-not-found-wrap form.search-form {
  margin-top: 50px;
}

/* Not Found */
body.search .realfactory-not-found-wrap {
  background-color: #bd584e;
}

body.search .realfactory-not-found-wrap .realfactory-not-found-caption {
  color: #ffe6e4;
}

/**
 * 5.0 - Widget
 */
/* sidebar */
.realfactory-sidebar-area .realfactory-widget {
  margin-bottom: 55px;
}

.realfactory-sidebar-area .realfactory-widget-title {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  position: relative;
  margin-bottom: 28px;
  overflow: hidden;
}

.realfactory-sidebar-area .realfactory-widget-title .gdlr-core-flexslider-nav {
  float: right;
  margin-left: 20px;
}

.realfactory-sidebar-area .realfactory-widget-head-text {
  display: block;
  float: left;
  margin-right: 28px;
}

.realfactory-sidebar-left .realfactory-sidebar-area {
  overflow: hidden;
  border-right-width: 1px;
  border-right-style: solid;
}

.realfactory-sidebar-right .realfactory-sidebar-area {
  overflow: hidden;
  border-left-width: 1px;
  border-left-style: solid;
}

/* list */
.widget_rss .rss-widget-icon {
  margin-right: 10px;
  margin-top: -3px;
}

.widget_product_categories ul,
.widget_archive ul, .widget_categories ul, .widget_nav_menu ul,
.widget_meta ul, .widget_pages ul, .widget_recent_comments ul,
.widget_recent_entries ul, .widget_rss ul {
  position: relative;
  list-style: none;
  margin-left: 0;
}

.widget_product_categories ul li,
.widget_archive ul li, .widget_categories ul li, .widget_nav_menu ul li,
.widget_meta ul li, .widget_pages ul li, .widget_recent_comments ul li,
.widget_recent_entries ul li, .widget_rss ul li {
  font-size: 15px;
  margin-bottom: 15px;
  line-height: 18px;
}

.widget_product_categories ul ul,
.widget_archive ul ul, .widget_categories ul ul, .widget_nav_menu ul ul,
.widget_meta ul ul, .widget_pages ul ul, .widget_recent_comments ul ul,
.widget_recent_entries ul ul, .widget_rss ul ul {
  margin-left: 20px;
}

.widget_product_categories ul ul li,
.widget_archive ul ul li, .widget_categories ul ul li, .widget_nav_menu ul ul li,
.widget_meta ul ul li, .widget_pages ul ul li:last-child, .widget_recent_comments ul ul li,
.widget_recent_entries ul ul li, .widget_rss ul ul li {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
  margin-top: 15px;
}

/* search widget */
.widget_search form {
  display: block;
  position: relative;
}

.widget_search form:after {
  content: "\f002";
  font-family: "fontAwesome";
  font-size: 14px;
  line-height: 14px;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -7px;
}

.widget_search label .screen-reader-text {
  display: none;
}

.widget_search .search-form input.search-field {
  font-size: 13px;
  line-height: 17px;
  height: 51px;
  border-width: 1px;
  border-style: solid;
  width: 100%;
  padding: 16px 15px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.widget_search input.search-field:focus {
  outline: none;
}

.widget_search input.search-submit {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 51px;
  padding: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 1;
}

/* tag cloud */
.tagcloud {
  zoom: 1;
}

.tagcloud:after {
  content: " ";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.tagcloud a {
  font-size: 11px !important;
  text-transform: uppercase;
  font-weight: 600;
  float: left;
  padding: 8px 16px 7px;
  border-width: 1px;
  border-style: solid;
  margin-right: 10px;
  margin-bottom: 10px;
}

/* calendar */
.widget_calendar table {
  width: 100%;
  margin-bottom: 0;
}

.widget_calendar table thead th {
  font-size: 13px;
  font-weight: bold;
  padding: 10px 0;
}

.widget_calendar table tbody td {
  text-align: center;
  padding: 10px 0;
}

.widget_calendar table tfoot td {
  text-align: center;
  padding: 10px 0;
}

.widget_calendar caption {
  font-size: 14px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}

/* table */
table {
  width: 100%;
  text-align: center;
  border-spacing: 2px;
  border-collapse: separate;
  margin-bottom: 20px;
}

table tr td {
  padding: 15px;
}

table tr th {
  padding: 15px;
  font-size: 16px;
  font-weight: normal;
}

/* for goodlayer core plugin */
body.realfactory-boxed .gdlr-core-column-extend-left .gdlr-core-pbf-background-wrap {
  margin-left: -50px;
}

body.realfactory-boxed .gdlr-core-column-extend-right .gdlr-core-pbf-background-wrap {
  margin-right: -50px;
}

/**
 * 6.0 - Responsive
 */
.realfactory-mobile-header-wrap {
  display: none;
}

.realfactory-mobile-header {
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 9999;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.realfactory-mobile-header.realfactory-fixed-navigation {
  display: none;
}

.realfactory-mobile-header .realfactory-mobile-header-container {
  position: relative;
}

.realfactory-mobile-header .realfactory-logo {
  padding: 0 15px;
}

.realfactory-mobile-header .realfactory-logo-inner {
  line-height: 45px;
  margin-right: 110px;
}

.realfactory-mobile-header .realfactory-logo-inner img {
  vertical-align: middle;
  width: auto;
}

.realfactory-mobile-header .realfactory-mobile-menu {
  margin-left: 15px;
}

.realfactory-mobile-header .realfactory-main-menu-cart {
  margin-left: 0;
  padding-left: 15px;
}

/*--- tablet ---*/
@media only screen and (max-width: 1140px) {
  html .widget_product_search input[type="search"]#woocommerce-product-search-field {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 999px) {
  body {
    width: 100%;
    overflow-x: hidden;
  }
  body.realfactory-boxed div.realfactory-body-wrapper {
    margin-top: 0;
    margin-bottom: 0;
  }
  .realfactory-bullet-anchor {
    display: none;
  }
  /* remove header */
  .realfactory-header-wrap.realfactory-header-style-plain,
  .realfactory-header-wrap.realfactory-header-style-bar, .realfactory-navigation-bar-wrap,
  .realfactory-header-background-transparent, .realfactory-header-boxed-wrap, .realfactory-header-side-nav,
  .realfactory-header-transparent-substitute, .realfactory-sticky-navigation {
    display: none !important;
  }
  .realfactory-mobile-header-wrap {
    display: block;
  }
  .realfactory-mobile-header-wrap .realfactory-top-bar {
    display: block;
  }
  .realfactory-mobile-header-wrap .realfactory-top-bar {
    font-size: 13px;
  }
  .realfactory-mobile-header-wrap .realfactory-top-bar-left,
  .realfactory-mobile-header-wrap .realfactory-top-bar-right {
    padding-top: 0;
    padding-bottom: 0;
  }
  .realfactory-mobile-header.realfactory-fixed-navigation {
    display: block;
  }
  .realfactory-sticky-menu-placeholder {
    display: none !important;
  }
  body .realfactory-header-side-content.realfactory-style-left {
    margin-left: 0;
  }
  body .realfactory-header-side-content.realfactory-style-right {
    margin-right: 0;
  }
  body.single-product.woocommerce div.product form.cart {
    float: none;
    margin-bottom: 30px;
    width: auto;
    margin-right: 0;
  }
  body.single-product.woocommerce div.product .product_meta {
    margin-top: 0;
  }
}

/*--- mobile landscape style ---*/
@media only screen and (max-width: 767px) {
  body.realfactory-body-front .realfactory-container {
    max-width: 500px;
    padding-left: 15px;
    padding-right: 15px;
  }
  body.realfactory-body-front .gdlr-core-container {
    max-width: 500px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .realfactory-column-10, .realfactory-column-12, .realfactory-column-15, .realfactory-column-20,
  .realfactory-column-24, .realfactory-column-30, .realfactory-column-36, .realfactory-column-40,
  .realfactory-column-45, .realfactory-column-48, .realfactory-column-50, .realfactory-column-60 {
    width: 100%;
  }
  /* header */
  .realfactory-mobile-header-wrap .realfactory-top-bar-left,
  .realfactory-mobile-header-wrap .realfactory-top-bar-right {
    float: none;
    text-align: center;
  }
  .realfactory-page-title-wrap .realfactory-page-title-content {
    padding-top: 60px !important;
    padding-bottom: 50px !important;
  }
  .realfactory-blog-title-wrap .realfactory-blog-title-content {
    padding-top: 60px !important;
    padding-bottom: 50px !important;
  }
  .realfactory-single-article-date-wrapper {
    display: none;
  }
  .realfactory-overlay-menu-content ul.menu > li {
    font-size: 18px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .realfactory-overlay-menu-content ul.menu {
    padding-top: 120px;
    padding-bottom: 100px;
    max-width: 80%;
  }
  .realfactory-overlay-menu-content .realfactory-overlay-menu-close {
    top: 35px;
  }
  body.admin-bar .realfactory-overlay-menu-content {
    margin-top: 0;
  }
  /* single*/
  .realfactory-comments-area .comment-respond {
    padding: 45px 30px;
  }
  .realfactory-comments-area .comment-respond {
    padding: 45px 30px;
  }
  .realfactory-comments-area .realfactory-comment-form-author {
    width: 100%;
    padding-right: 0;
  }
  .realfactory-comments-area .realfactory-comment-form-email {
    width: 100%;
    padding-left: 0;
  }
  /* footer */
  .realfactory-footer-wrapper .realfactory-footer-column {
    padding: 0 15px;
    margin-bottom: 60px;
    border: none;
  }
  .realfactory-footer-wrapper .realfactory-footer-column:last-child {
    margin-bottom: 20px;
  }
  .realfactory-fixed-footer-placeholder {
    display: none;
  }
  .realfactory-fixed-footer {
    position: static;
  }
  body .woocommerce .col2-set, .woocommerce-page .col2-set {
    width: 100%;
    float: none;
    margin-right: 0%;
  }
  body div#order_review {
    width: 100%;
  }
}

/*--- mobile portrait style ---*/
@media only screen and (max-width: 419px) {
  body {
    min-width: 320px;
  }
  .realfactory-top-search-wrap input.search-field {
    font-size: 30px;
    padding-right: 90px;
  }
  .realfactory-top-search-wrap .realfactory-top-search-submit {
    right: 48px;
    font-size: 24px;
    margin-top: -2px;
  }
  .realfactory-top-search-wrap input.search-submit {
    right: 48px;
  }
  .realfactory-top-search-wrap .realfactory-top-search-close {
    font-size: 40px;
  }
  .realfactory-top-search-wrap input.search-field {
    padding-bottom: 10px;
  }
}

/**
 * 7.0 - Plugins
 */
/*-------------------*/
/*--- wpml        ---*/
/*-------------------*/
ul.sf-menu > .menu-item-language img.iclflag {
  vertical-align: baseline;
  margin-right: 10px;
}

ul.sf-menu > .menu-item-language ul.submenu-languages {
  display: none;
  position: absolute;
  z-index: 99;
  list-style: none;
  margin: 0 0 0 -15px;
}

ul.sf-menu > .menu-item-language li {
  padding: 1px 11px;
}

ul.sf-menu > .menu-item-language li a {
  padding: 6px 12px;
  display: block;
}

ul.sf-menu > .menu-item-language li:first-child {
  padding-top: 12px;
}

ul.sf-menu > .menu-item-language li:last-child {
  padding-bottom: 12px;
}

.realfactory-custom-wpml-flag {
  margin-right: 26px;
}

.realfactory-custom-wpml-flag .realfactory-custom-wpml-flag-item {
  margin-left: 20px;
}

.realfactory-custom-wpml-flag .realfactory-custom-wpml-flag-item:first-child {
  margin-left: 0;
}

/*-------------------*/
/*--- wpcf7       ---*/
/*-------------------*/
.realfactory-body span.wpcf7-not-valid-tip {
  font-size: 13px;
  margin-bottom: 15px;
}

.realfactory-body div.wpcf7-response-output {
  font-size: 13px;
  padding: 6px 20px;
  clear: both;
  margin: 0;
}

.wpcf7 input {
  font-size: 15px;
  padding: 15px 22px;
  width: 100%;
  display: block;
}

.wpcf7 input[type="button"], .wpcf7 input[type="submit"], input[type="reset"] {
  width: auto;
}

.wpcf7 .wpcf7-form-control .wpcf7-list-item {
  display: block;
  margin-bottom: 5px;
}

.wpcf7 textarea {
  font-size: 15px;
  padding: 18px 22px;
  height: 120px;
  width: 100%;
}

/*-------------------*/
/*--- woocommerce ---*/
/*-------------------*/
/* style */
figure.woocommerce-product-gallery__wrapper {
  max-width: none;
}

body.woocommerce .woocommerce-error, body.woocommerce .woocommerce-info, body.woocommerce .woocommerce-message {
  padding: 0.7em 2em 1em 3.5em !important;
}

.woocommerce .woocommerce-error, .woocommerce .woocommerce-info, .woocommerce .woocommerce-message {
  padding: 0.7em 2em 1em 3.5em !important;
}

.woocommerce .woocommerce-error:before, .woocommerce .woocommerce-info:before, .woocommerce .woocommerce-message:before {
  top: 0.7em;
}

.woocommerce .woocommerce-error .button, .woocommerce .woocommerce-info .button, .woocommerce .woocommerce-message .button {
  margin-top: 5px;
  padding: 0;
  background-color: transparent;
}

.woocommerce .woocommerce-error .button:hover, .woocommerce .woocommerce-info .button:hover, .woocommerce .woocommerce-message .button:hover {
  background-color: transparent;
}

.woocommerce div.product form.cart .variations td.label {
  padding-right: 1.3em;
  padding-left: 1.3em;
}

.woocommerce div.product form.cart .variations td.value, .woocommerce div.product form.cart .variations th.value {
  min-width: 210px;
}

.woocommerce div.product form.cart .variations select {
  float: left;
  margin-top: 0.6em;
  margin-left: 2em;
}

a.reset_variations {
  margin-right: 15px;
  margin-left: 7px;
}

.woocommerce div.product form.cart .variations select {
  min-width: 59%;
}

.related.products h2 {
  font-size: 23px;
  margin-bottom: 42px;
}

.woocommerce table.shop_attributes th {
  background: transparent;
  padding-top: 16px;
  padding-bottom: 16px;
}

.woocommerce table.shop_attributes td, .woocommerce table.shop_attributes th {
  border-bottom: 0;
}

.woocommerce table.shop_attributes {
  border-top: 0;
}

.single-product.woocommerce #review_form #respond p.comment-form-comment {
  margin-bottom: 25px;
}

.woocommerce table.shop_table {
  border: 0;
}

.woocommerce table.shop_table.shop_table_responsive.cart {
  border-spacing: 0;
}

.woocommerce table.shop_table th {
  padding: 17px 12px;
  background: none;
}

.woocommerce table.shop_table.shop_table_responsive.cart tr:nth-child(odd), .woocommerce table.shop_table.shop_table_responsive.cart tr:nth-child(even) {
  background-color: transparent;
}

.woocommerce table.shop_table td {
  padding: 19px 20px;
}

.woocommerce-cart table.cart img {
  width: 70px;
}

.woocommerce td.product-name dl.variation dd, .woocommerce td.product-name dl.variation dt {
  margin-bottom: 0;
}

.woocommerce a.remove {
  width: auto;
}

.cart_item input.input-text.qty.text {
  padding: 15px 0 15px 10px;
  border-bottom-width: 1px;
}

.woocommerce-cart table.cart td.actions .coupon .input-text {
  padding: 15px 23px 15px;
  height: 49px;
}

.woocommerce .cart input.button[name="apply_coupon"] {
  height: 49px;
}

.woocommerce #content table.cart td.actions .input-text, .woocommerce table.cart td.actions .input-text, .woocommerce-page #content table.cart td.actions .input-text, .woocommerce-page table.cart td.actions .input-text {
  width: 180px;
}

.woocommerce .cart .button, .woocommerce .cart input.button {
  padding: 19px 20px 18px;
  border-radius: 0;
  font-size: 12px;
}

.woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button {
  padding: 15px 20px;
  border-radius: 0;
  font-size: 12px;
}

.woocommerce #respond input#submit.disabled, .woocommerce #respond input#submit:disabled, .woocommerce #respond input#submit:disabled[disabled], .woocommerce a.button.disabled, .woocommerce a.button:disabled, .woocommerce a.button:disabled[disabled], .woocommerce button.button.disabled, .woocommerce button.button:disabled, .woocommerce button.button:disabled[disabled], .woocommerce input.button.disabled, .woocommerce input.button:disabled, .woocommerce input.button:disabled[disabled] {
  padding: inherit;
  opacity: inherit;
  padding-left: 23px;
  padding-right: 23px;
}

.woocommerce .cart_totals h2 {
  font-size: 18px;
}

.woocommerce-cart .cart-collaterals .cart_totals tr td, .woocommerce-cart .cart-collaterals .cart_totals tr th {
  border-top: 0;
}

.woocommerce-cart .cart-collaterals .cart_totals table {
  border-spacing: 0;
  margin: 0;
}

.woocommerce-cart .wc-proceed-to-checkout {
  padding: 0;
}

.woocommerce table.shop_table tbody:first-child tr:first-child td, .woocommerce table.shop_table tbody:first-child tr:first-child th {
  padding-left: 20px;
  padding-right: 20px;
}

.woocommerce-cart .cart-collaterals .cart_totals tr td, .woocommerce-cart .cart-collaterals .cart_totals tr th {
  padding-left: 20px;
  padding-right: 20px;
}

.woocommerce a.showcoupon {
  margin-left: 20px;
  color: #baf2ff;
}

.woocommerce .woocommerce-billing-fields h3, .woocommerce .woocommerce-shipping-fields h3, #order_review_heading {
  font-size: 17px;
}

.woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea {
  padding: 13px 15px;
  border-bottom-width: 1px;
}

.woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-1 {
  margin-bottom: 35px;
}

.woocommerce .col2-set, .woocommerce-page .col2-set {
  width: 47%;
  float: left;
  margin-right: 3%;
}

.woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-1 {
  width: 100%;
}

.woocommerce .col2-set .col-2, .woocommerce-page .col2-set .col-2 {
  width: 100%;
}

div#order_review {
  float: left;
  width: 50%;
}

.woocommerce form .form-row {
  margin: 0 0 14px;
}

.woocommerce form p.form-row.form-row-first {
  margin: 0;
}

.select2-container .select2-choice {
  padding: 6px 1px 6px 13px;
}

.woocommerce form .form-row-first, .woocommerce form .form-row-last, .woocommerce-page form .form-row-first, .woocommerce-page form .form-row-last {
  width: 49%;
}

.shop_table.woocommerce-checkout-review-order-table {
  border-spacing: 0;
}

.woocommerce table.shop_table th {
  padding: 17px 20px 17px 20px;
}

.woocommerce .cart_item td.product-total {
  padding-right: 20px;
  padding-left: 20px;
}

.woocommerce .cart_item td.product-name {
  padding-left: 20px;
}

.woocommerce table.shop_table td {
  padding-right: 20px;
}

.checkout_coupon .form-row.form-row-last {
  margin-bottom: 0;
}

table tr.cart_item {
  background: transparent;
}

.woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea {
  font-size: 13px;
}

html .woocommerce a.remove {
  border-radius: 100%;
  color: #333 !important;
}

.woocommerce-checkout table tr.cart_item {
  background: transparent;
}

li.woocommerce-MyAccount-navigation-link {
  padding: 13px 0;
  padding-bottom: 13px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.woocommerce-account .woocommerce-MyAccount-navigation {
  width: 28%;
}

li.woocommerce-MyAccount-navigation-link.is-active {
  font-weight: 600;
}

header.woocommerce-Address-title.title h3 {
  font-size: 18px;
}

.woocommerce-Address address {
  padding: 25px;
}

.u-columns.woocommerce-Addresses.col2-set.addresses {
  width: 100%;
  margin-bottom: 40px;
}

.woocommerce-account .woocommerce-MyAccount-content {
  padding: 31px;
}

.woocommerce-Address address,
.woocommerce-account .woocommerce-MyAccount-content {
  background: #f5f5f5;
}

/*-- Widget --*/
.woocommerce ul.cart_list li dl dd, .woocommerce ul.cart_list li dl dt, .woocommerce ul.product_list_widget li dl dd, .woocommerce ul.product_list_widget li dl dt {
  margin-bottom: 0em;
}

.woocommerce ul.cart_list li dl, .woocommerce ul.product_list_widget li dl {
  padding-left: 0;
  border-left: 0;
}

.woocommerce .widget_shopping_cart .cart_list li, .woocommerce.widget_shopping_cart .cart_list li {
  margin-bottom: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 15px;
}

.woocommerce .widget_shopping_cart .cart_list li:last-child, .woocommerce.widget_shopping_cart .cart_list li:last-child {
  margin-bottom: 15px;
}

.woocommerce .widget_shopping_cart .total, .woocommerce.widget_shopping_cart .total {
  border-top: 0;
  font-size: 17px;
}

.widget_shopping_cart_content .total span.woocommerce-Price-amount.amount {
  margin-left: 5px;
}

.woocommerce ul.cart_list li img, .woocommerce ul.product_list_widget li img {
  width: 47px;
}

.widget_shopping_cart a.button.wc-forward {
  padding: 19px 28px;
  font-size: 14px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.woocommerce .widget_price_filter .price_slider_amount .button {
  float: left;
  font-size: 14px;
  padding: 16px 26px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.price_slider_amount .price_label {
  margin-top: 24px;
  font-size: 17px;
  font-weight: 700;
}

.woocommerce ul.cart_list li, .woocommerce ul.product_list_widget li {
  padding: 15px 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.woocommerce ul.cart_list li, .woocommerce ul.product_list_widget li {
  padding: 15px 0;
  margin: 0;
  list-style: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

html ul.cart_list li a, html .woocommerce ul.product_list_widget li a {
  font-weight: 400;
}

html .woocommerce ul.cart_list li a, html .woocommerce ul.product_list_widget li a {
  font-weight: 400;
}

html .woocommerce ul.cart_list li .star-rating {
  float: left;
  margin-top: 10px;
}

html .woocommerce ul.product_list_widget li .star-rating {
  float: left;
  margin-top: 3px;
  margin-right: 10px;
}

html .woocommerce ul.product_list_widget li del {
  margin-right: 10px;
}

html .product_list_widget span.reviewer {
  margin-top: 4px;
  margin-left: 12px;
  float: left;
}

html .widget_product_search label.screen-reader-text {
  display: none;
}

html .widget_product_search input[type="submit"] {
  height: 47px;
}

html .widget_product_search input#woocommerce-product-search-field {
  height: 47px;
  padding: 14px 12px;
  border-bottom-width: 1px;
  width: 62%;
  font-size: 13px;
}

.widget.woocommerce ul.cart_list li:first-child, .widget.woocommerce ul.product_list_widget li:first-child {
  padding-top: 0;
}

/*-- Fixed Color --*/
.woocommerce .woocommerce-message:before {
  color: #fff;
}

.woocommerce .woocommerce-error {
  background-color: #bd584e;
  border-top: 3px solid #bd584e;
  color: #fff;
}

.woocommerce .woocommerce-info {
  background-color: #46a5ca;
  border-top: 3px solid #46a5ca;
  color: #fff;
}

.woocommerce .woocommerce-message {
  background-color: #46ca7b;
  border-top: 3px solid #46ca7b;
  color: #fff;
}

.woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button {
  color: #ffffff;
}

.single-product.woocommerce div.product .quantity .qty {
  background: #f3f3f3;
}

.woocommerce .woocommerce-info:before {
  color: #fff;
}

.woocommerce .woocommerce-error:before, .woocommerce .woocommerce-info:before, .woocommerce .woocommerce-message:before {
  color: #fff !important;
  top: inherit;
}

html .woocommerce a.remove:hover {
  color: #afafaf !important;
  background: transparent !important;
}

html .woocommerce form .form-row .required {
  color: #c5c5c5;
}

html .woocommerce form .form-row.woocommerce-invalid .select2-container, html .woocommerce form .form-row.woocommerce-invalid input.input-text, html .woocommerce form .form-row.woocommerce-invalid select {
  border-color: #d4d4d4;
}

html .woocommerce form .form-row.woocommerce-invalid label {
  color: #9e9e9e;
}

html .woocommerce form .form-row.woocommerce-validated .select2-container, html .woocommerce form .form-row.woocommerce-validated input.input-text, html .woocommerce form .form-row.woocommerce-validated select {
  border-color: #d4d4d4;
}

/* single product */
html .woocommerce span.onsale {
  font-size: 13px;
  font-weight: 600;
  line-height: 48px;
  width: 48px;
  padding: 2px 0 0;
  height: 48px;
}

.single-product.woocommerce .realfactory-page-wrapper {
  border-top-width: 1px;
  border-top-style: solid;
}

.single-product.woocommerce .woocommerce-breadcrumb {
  font-size: 14px;
}

.single-product.woocommerce .product_title {
  font-size: 40px;
  margin-bottom: 2px;
}

.single-product.woocommerce div.product .woocommerce-product-rating {
  margin-bottom: 2px;
}

.single-product.woocommerce div.product .star-rating {
  font-size: 14px;
  padding-right: 15px;
  box-sizing: content-box;
  margin-right: 10px;
}

.single-product.woocommerce div.product .star-rating:before,
.single-product.woocommerce div.product .star-rating span:before {
  letter-spacing: 3px;
}

.single-product.woocommerce div.product .woocommerce-review-link {
  font-size: 13px;
}

.single-product.woocommerce div.product p.price {
  padding-top: 27px;
  margin-bottom: 28px;
}

.single-product.woocommerce div.product p.price ins {
  text-decoration: none;
}

.single-product.woocommerce div.product p.price .woocommerce-Price-amount {
  font-size: 26px;
  font-weight: bold;
}

.single-product.woocommerce div.product p.price del .woocommerce-Price-amount {
  font-size: 23px;
  margin-right: 9px;
}

.single-product.woocommerce div.product span.onsale {
  position: static;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
}

.single-product.woocommerce div.product .quantity .qty {
  height: 60px;
  font-size: 16px;
  width: 75px;
  padding-left: 15px;
  border: none;
}

.single-product.woocommerce div.product form.cart {
  margin: 25px 30px 55px 0;
  float: left;
}

.single-product.woocommerce div.product form.cart.variations_form {
  width: 300px;
}

.single-product.woocommerce div.product form.cart .button {
  font-size: 13px;
  height: 60px;
  text-transform: uppercase;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  padding: 10px 30px;
}

.single-product.woocommerce div.product .product_meta {
  margin-top: 50px;
}

.single-product.woocommerce div.product .product_meta {
  overflow: hidden;
  padding-top: 5px;
}

.single-product.woocommerce div.product .product_meta > span {
  display: block;
  font-size: 13px;
  margin-bottom: 5px;
}

.single-product.woocommerce div.product .product_meta > span .realfactory-head {
  font-weight: 600;
}

.single-product.woocommerce div.product .woocommerce-variation-add-to-cart-disabled {
  display: none;
}

.single-product.woocommerce div.product .realfactory-woocommerce-social-share {
  clear: both;
  padding-top: 15px;
  margin-top: 50px;
  border-top-width: 2px;
  border-top-style: solid;
}

.single-product.woocommerce div.product .gdlr-core-social-share-item a {
  font-size: 14px;
}

.single-product.woocommerce div.product .realfactory-woocommerce-tab {
  clear: both;
  padding-top: 60px;
  margin-bottom: 50px;
}

.single-product.woocommerce div.product .realfactory-woocommerce-tab .gdlr-core-tab-item-title {
  font-size: 16px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 600;
  padding-bottom: 18px;
}

.single-product.woocommerce div.product .realfactory-woocommerce-tab .gdlr-core-tab-item-title-line {
  border-bottom-width: 1px;
  bottom: -1px;
}

.single-product.woocommerce div.product .realfactory-woocommerce-tab .gdlr-core-tab-item-title-wrap {
  border-bottom-width: 1px;
}

.single-product.woocommerce div.product .realfactory-woocommerce-tab .gdlr-core-tab-item-content {
  max-width: 900px;
  margin: 0 auto;
}

.single-product.woocommerce .woocommerce-Reviews-title {
  display: none;
}

.single-product.woocommerce #reviews {
  padding-top: 43px;
}

.single-product.woocommerce #reviews .comment-respond {
  text-align: left;
}

.single-product.woocommerce #reviews #comments ol.commentlist li img.avatar {
  border: none;
  width: 85px;
  position: static;
  padding: 0;
  border-radius: 50%;
  margin-right: 30px;
}

.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text {
  overflow: hidden;
  margin-left: 0;
  padding: 0;
  border: none;
  text-align: left;
}

.single-product.woocommerce #reviews #comments ol.commentlist li {
  padding: 39px 49px;
  margin-bottom: 30px;
}

.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta {
  margin-bottom: 18px;
}

.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta strong[itemprop="author"] {
  display: block;
  font-size: 17px;
  text-transform: capitalize;
}

.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta time[itemprop="datePublished"] {
  display: block;
  font-size: 15px;
}

.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text .star-rating {
  float: none;
  margin-top: 24px;
}

.single-product.woocommerce #reviews #review_form_wrapper {
  padding-top: 35px;
}

.single-product.woocommerce #reviews .comment-reply-title {
  font-size: 22px;
  margin-bottom: 30px;
}

.single-product.woocommerce #review_form #respond label {
  font-size: 15px;
  font-weight: 600;
}

.single-product.woocommerce #review_form #respond p {
  margin-bottom: 30px;
}

.single-product.woocommerce #review_form #respond label[for="rating"] {
  display: inline-block;
  margin-right: 30px;
  vertical-align: middle;
}

.single-product.woocommerce #review_form #respond p.stars {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  font-size: 19px;
  line-height: 1;
}

.single-product.woocommerce #review_form #respond p.stars a {
  margin-right: 5px;
}

.single-product.woocommerce #review_form #respond input[type="text"],
.single-product.woocommerce #review_form #respond input[type="email"] {
  display: block;
  margin-top: 20px;
  border-bottom-width: 1px;
  width: 100%;
  height: 55px;
}

.single-product.woocommerce #review_form #respond textarea {
  display: block;
  margin-top: 20px;
  border-bottom-width: 1px;
  width: 100%;
  height: 150px;
}

.single-product.woocommerce #review_form #respond .form-submit input {
  font-size: 13px;
  height: 58px;
  text-transform: uppercase;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  padding: 10px 38px;
}

.woocommerce.single-product .related.products {
  clear: left;
}

/*-------------*/
/*--- mmenu ---*/
/*-------------*/
.mm-menu, .mm-panels, .mm-panels > .mm-panel {
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  box-sizing: border-box;
}

.mm-btn, .mm-menu {
  box-sizing: border-box;
}

.mm-listview a, .mm-listview a:hover, .mm-navbar a, .mm-navbar a:hover {
  text-decoration: none;
}

.mm-hidden {
  display: none !important;
}

.mm-wrapper {
  overflow-x: hidden;
  position: relative;
}

.mm-menu {
  display: block;
  padding: 0;
  position: absolute;
}

.mm-panels, .mm-panels > .mm-panel {
  background: inherit;
  border-color: inherit;
  position: absolute;
}

.mm-btn, .mm-panel.mm-highest {
  z-index: 1;
}

.mm-panels {
  overflow: hidden;
}

.mm-panel {
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.mm-panel.mm-opened {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mm-panel.mm-subopened {
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0);
}

.mm-panels > .mm-panel {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px;
}

.mm-listview .mm-divider, .mm-listview > li > a, .mm-listview > li > span, .mm-navbar .mm-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mm-panels > .mm-panel.mm-hasnavbar {
  padding-top: 40px;
}

.mm-vertical .mm-panel {
  -webkit-transform: none !important;
  transform: none !important;
}

.mm-listview .mm-vertical .mm-panel, .mm-vertical .mm-listview .mm-panel {
  display: none;
  padding: 10px 0 10px 10px;
}

.mm-listview .mm-vertical .mm-panel .mm-listview > li:last-child:after, .mm-vertical .mm-listview .mm-panel .mm-listview > li:last-child:after {
  border-color: transparent;
}

.mm-vertical li.mm-opened > .mm-panel, li.mm-vertical.mm-opened > .mm-panel {
  display: block;
}

.mm-listview > li.mm-vertical > .mm-next, .mm-vertical .mm-listview > li > .mm-next {
  height: 40px;
  bottom: auto;
}

.mm-listview > li.mm-vertical > .mm-next:after, .mm-vertical .mm-listview > li > .mm-next:after {
  top: 16px;
  bottom: auto;
}

.mm-listview > li.mm-vertical.mm-opened > .mm-next:after, .mm-vertical .mm-listview > li.mm-opened > .mm-next:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 19px;
}

.mm-btn {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
}

.mm-clear:after, .mm-clear:before, .mm-close:after, .mm-close:before {
  content: '';
  border: 2px solid transparent;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mm-clear:before, .mm-close:before {
  border-right: none;
  border-bottom: none;
  right: 18px;
}

.mm-clear:after, .mm-close:after {
  border-left: none;
  border-top: none;
  right: 25px;
}

.mm-arrow:after, .mm-next:after {
  content: '';
  border: 1px solid transparent;
  display: block;
  width: 6px;
  height: 6px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mm-prev:before {
  border-right: none;
  border-bottom: none;
  left: 0;
}

.mm-arrow:after, .mm-next:after {
  border-top: none;
  border-left: none;
  right: 2px;
}

.mm-navbar {
  text-align: center;
  line-height: 20px;
  height: 40px;
  margin: 0;
}

.mm-navbar .mm-btn:last-child {
  text-align: right;
  padding-right: 20px;
  right: 0;
}

.mm-panel .mm-navbar {
  display: none;
}

.mm-panel.mm-hasnavbar .mm-navbar {
  display: block;
  margin: 0 25px 30px;
  position: relative;
}

.mm-listview, .mm-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.mm-listview {
  font: inherit;
  font-size: 14px;
  line-height: 20px;
}

.mm-listview > li {
  position: relative;
}

.mm-listview > li, .mm-listview > li .mm-next, .mm-listview > li .mm-next:before, .mm-listview > li:after {
  border-color: inherit;
}

.mm-listview > li > a, .mm-listview > li > span {
  color: inherit;
  display: block;
  margin: 0;
}

.mm-listview > li > a.mm-arrow, .mm-listview > li > span.mm-arrow {
  padding-right: 50px;
}

.mm-listview .mm-next {
  background: rgba(3, 2, 1, 0);
  width: 50px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.mm-listview .mm-next.mm-fullsubopen {
  width: 100%;
}

.mm-listview .mm-next.mm-fullsubopen:before {
  border-left: none;
}

.mm-listview .mm-next.mm-fullsubopen + a, .mm-listview .mm-next.mm-fullsubopen + span {
  padding-right: 50px;
  margin-right: 0;
}

.mm-listview .mm-inset {
  list-style: disc inside;
  padding: 0 10px 15px 40px;
  margin: 0;
}

.mm-listview .mm-inset > li {
  padding: 5px 0;
}

.mm-listview .mm-divider {
  font-size: 10px;
  text-transform: uppercase;
  text-indent: 20px;
  line-height: 25px;
}

.mm-listview .mm-spacer {
  padding-top: 40px;
}

.mm-listview .mm-spacer > .mm-next {
  top: 40px;
}

.mm-listview .mm-spacer.mm-divider {
  padding-top: 25px;
}

.mm-page {
  box-sizing: border-box;
  position: relative;
}

.mm-slideout {
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
  z-index: 2;
}

html.mm-opened {
  overflow-x: hidden;
  position: static;
}

#mm-blocker {
  background: rgba(3, 2, 1, 0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

html.mm-blocking #mm-blocker {
  display: block;
}

.mm-menu.mm-offcanvas {
  z-index: 0;
  display: none;
  position: fixed;
}

.mm-menu.mm-offcanvas.mm-current {
  display: block;
}

.mm-menu {
  width: 350px;
}

@media all and (max-width: 550px) {
  .mm-menu {
    width: 250px;
  }
}

html.mm-opening .mm-slideout {
  -webkit-transform: translate3d(350px, 0, 0);
  transform: translate3d(350px, 0, 0);
}

@media all and (max-width: 550px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0);
  }
}

/* right effect */
html.mm-right.mm-opened .mm-menu.mm-effect-menu-zoom {
  -webkit-transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  transform: scale(0.7, 0.7) translate3d(30%, 0, 0);
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

html.mm-right.mm-opening .mm-menu.mm-effect-menu-zoom {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0);
}

html.mm-right.mm-opened .mm-menu.mm-effect-menu-slide {
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
}

html.mm-right.mm-opening .mm-menu.mm-effect-menu-slide {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

html.mm-right.mm-opening.mm-fullscreen .mm-slideout {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.mm-menu.mm-pageshadow.mm-right:after {
  left: auto;
  right: 100%;
}

.mm-menu.mm-right {
  left: auto;
  right: 0;
}

html.mm-right.mm-opening .mm-slideout {
  -webkit-transform: translate3d(-350px, 0, 0);
  transform: translate3d(-350px, 0, 0);
}

@media all and (max-width: 550px) {
  html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate3d(-250px, 0, 0);
    transform: translate3d(-250px, 0, 0);
  }
}

.mm-menu.mm-front.mm-right, .mm-menu.mm-next.mm-right {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

/* page dim */
html.mm-pagedim #mm-blocker, html.mm-pagedim-black #mm-blocker, html.mm-pagedim-white #mm-blocker {
  opacity: 0;
}

html.mm-pagedim-black.mm-opening #mm-blocker, html.mm-pagedim-white.mm-opening #mm-blocker, html.mm-pagedim.mm-opening #mm-blocker {
  opacity: .3;
  transition: ease .4s;
}

html.mm-pagedim #mm-blocker {
  background: inherit;
}

html.mm-pagedim-white #mm-blocker {
  background: #fff;
}

html.mm-pagedim-black #mm-blocker {
  background: #000;
}

/* custom */
ul.mm-listview li {
  margin: 0 25px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

ul.mm-listview li a, ul.mm-listview li span.realfactory-mm-menu-blank {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 17px 0;
}

.mm-navbar .mm-btn {
  line-height: 40px;
  font-size: 30px;
  display: block;
  text-align: left;
  left: -8px;
  padding: 0;
}

.mm-navbar .mm-btn:before {
  content: "\34";
  font-family: elegantIcons;
}

.mm-navbar .mmenu-custom-close {
  line-height: 40px;
  font-size: 30px;
  display: block;
  text-align: left;
  margin-left: -8px;
  cursor: pointer;
}

.mm-navbar .mmenu-custom-close:after {
  content: "\4d";
  font-family: elegantIcons;
}

.mm-navbar .mm-title {
  display: none;
}

.mm-navbar .mm-title:first-child {
  display: block;
}

html.realfactory-mmenu-left .realfactory-sticky-navigation.realfactory-fixed-navigation,
html.realfactory-mmenu-right .realfactory-sticky-navigation.realfactory-fixed-navigation {
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

html.mm-opening.realfactory-mmenu-left .realfactory-sticky-navigation.realfactory-fixed-navigation {
  -webkit-transform: translate3d(350px, 0, 0);
  transform: translate3d(350px, 0, 0);
}

html.mm-opening.realfactory-mmenu-right .realfactory-sticky-navigation.realfactory-fixed-navigation {
  -webkit-transform: translate3d(-350px, 0, 0);
  transform: translate3d(-350px, 0, 0);
}

html.realfactory-mmenu-left .realfactory-mobile-header-wrap .realfactory-top-bar,
html.realfactory-mmenu-left .realfactory-mobile-header,
html.realfactory-mmenu-right .realfactory-mobile-header-wrap .realfactory-top-bar,
html.realfactory-mmenu-right .realfactory-mobile-header {
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

html.mm-opening.realfactory-mmenu-left .realfactory-mobile-header-wrap .realfactory-top-bar,
html.mm-opening.realfactory-mmenu-left .realfactory-mobile-header {
  -webkit-transform: translate3d(350px, 0, 0);
  transform: translate3d(350px, 0, 0);
}

html.mm-opening.realfactory-mmenu-right .realfactory-mobile-header-wrap .realfactory-top-bar,
html.mm-opening.realfactory-mmenu-right .realfactory-mobile-header {
  -webkit-transform: translate3d(-350px, 0, 0);
  transform: translate3d(-350px, 0, 0);
}

@media all and (max-width: 550px) {
  html.mm-opening.realfactory-mmenu-left .realfactory-sticky-navigation.realfactory-fixed-navigation {
    -webkit-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0);
  }
  html.mm-opening.realfactory-mmenu-right .realfactory-sticky-navigation.realfactory-fixed-navigation {
    -webkit-transform: translate3d(-250px, 0, 0);
    transform: translate3d(-250px, 0, 0);
  }
  html.mm-opening.realfactory-mmenu-left .realfactory-mobile-header-wrap .realfactory-top-bar,
  html.mm-opening.realfactory-mmenu-left .realfactory-mobile-header {
    -webkit-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0);
  }
  html.mm-opening.realfactory-mmenu-right .realfactory-mobile-header-wrap .realfactory-top-bar,
  html.mm-opening.realfactory-mmenu-right .realfactory-mobile-header {
    -webkit-transform: translate3d(-250px, 0, 0);
    transform: translate3d(-250px, 0, 0);
  }
}

/*-----------------*/
/*--- superfish ---*/
/*-----------------*/
/* top level */
.sf-menu {
  list-style: none;
  margin-left: 0;
}

.sf-menu > li {
  float: left;
  cursor: pointer;
}

.sf-menu > li > a {
  display: block;
  text-decoration: none;
  position: relative;
  z-index: 9;
}

/* normal menu */
.sf-menu > .realfactory-normal-menu ul {
  list-style: none;
  position: absolute;
  display: none;
  z-index: 99;
  min-width: 220px;
}

.sf-menu > .realfactory-normal-menu ul {
  margin-left: -15px;
}

.sf-menu > .realfactory-normal-menu ul ul {
  margin-left: 0;
}

.sf-menu > .realfactory-normal-menu.sfHover > ul,
.sf-menu > .realfactory-normal-menu.sfHover > ul:hover,
.sf-menu > .realfactory-normal-menu li.sfHover > ul {
  display: block;
}

.sf-menu > .realfactory-normal-menu ul ul {
  top: 0;
  left: 100%;
}

.sf-menu > .realfactory-normal-menu ul ul.sub-menu-right {
  left: auto;
  right: 100%;
}

.sf-menu > .realfactory-normal-menu li {
  position: relative;
  white-space: nowrap;
}

.sf-menu > .realfactory-normal-menu li a {
  text-decoration: none;
}

.sf-menu > .realfactory-normal-menu li > a.sf-with-ul-pre:after {
  content: '>';
}

/* mega menu */
.sf-menu > .realfactory-mega-menu .sf-mega {
  position: absolute;
  display: none;
  z-index: 99;
}

.sf-menu > .realfactory-mega-menu.sfHover .sf-mega,
.sf-menu > .realfactory-mega-menu.sfHover .sf-mega:hover {
  display: block;
}

.sf-menu > .realfactory-mega-menu .sf-mega-section-inner a {
  text-decoration: none;
}

.sf-menu > .realfactory-mega-menu .sf-mega-section-inner ul {
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
}

/* vertical menu */
.sf-vertical {
  list-style: none;
  margin-left: 0;
  left: 100%;
  top: 0;
  margin-bottom: 0;
}

.sf-vertical li {
  position: relative;
}

.sf-vertical ul.sub-menu {
  list-style: none;
  position: absolute;
  display: none;
  left: 100%;
  top: 0;
  z-index: 9;
  min-width: 190px;
}

.sf-vertical ul.sub-menu li {
  white-space: nowrap;
}

#gdlr-core-button-id-38517 {
  color: #edbf2a;
  padding: 13px 30px 13px 30px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-width: 2px 2px 2px 2px;
  border-color: #edbf2a;
}

#gdlr-core-button-id-38517:hover {
  border-color: #edbf2a;
}

#gdlr-core-title-item-id-27983 .gdlr-core-title-item-title a {
  color: #141414;
}

.submit-button {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  padding: 15px 33px;
  display: inline-block;
  -webkit-appearance: none;
  background-color: #333;
  border: 0;
  color: #fff;
  cursor: pointer;
  width: 100%;
}

.realfactory-body h1, .realfactory-body h2, .realfactory-body h3, .realfactory-body h4, .realfactory-body h5, .realfactory-body h6 {
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 1.2;
  font-weight: 700;
}

#poststuff .gdlr-core-page-builder-body h2 {
  padding: 0;
  margin-bottom: 20px;
  line-height: 1.2;
  font-weight: 700;
}

#poststuff .gdlr-core-page-builder-body h1 {
  padding: 0;
  font-weight: 700;
}

/* custom style for specific theme */
.gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-title {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
  text-transform: none;
}

.gdlr-core-button {
  text-transform: none;
  letter-spacing: 0.5px;
}

.gdlr-core-blog-date-wrapper {
  margin-top: 0;
  border-right-width: 3px;
  padding-bottom: 18px;
  padding-right: 25px;
  width: 63px;
}

.gdlr-core-blog-date-wrapper .gdlr-core-blog-date-day {
  letter-spacing: 1px;
  margin-top: 0;
}

.gdlr-core-blog-info-wrapper .gdlr-core-blog-info {
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  letter-spacing: 0;
}

.gdlr-core-blog-list .gdlr-core-blog-title {
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
}

.gdlr-core-blog-list .gdlr-core-blog-list-frame {
  padding: 26px 30px 27px;
}

.gdlr-core-blog-full {
  margin-bottom: 55px;
}

.gdlr-core-blog-full .gdlr-core-excerpt-read-more {
  margin-top: 25px;
}

.gdlr-core-blog-full .gdlr-core-blog-title {
  font-size: 32px;
  margin-bottom: 0;
}

.gdlr-core-blog-full .gdlr-core-blog-info-wrapper {
  margin-bottom: 10px;
}

.gdlr-core-blog-full .gdlr-core-blog-info-wrapper .gdlr-core-blog-info {
  font-size: 15px;
}

.gdlr-core-style-blog-full-with-frame .gdlr-core-blog-full-frame {
  padding: 50px 50px 25px;
}

.gdlr-core-blog-grid .gdlr-core-blog-title {
  font-size: 24px;
}

.gdlr-core-blog-grid .gdlr-core-blog-info-wrapper {
  padding-top: 0;
  border: 0;
  margin-bottom: 10px;
}

.gdlr-core-blog-grid .gdlr-core-excerpt-read-more {
  display: inline-block;
}

.gdlr-core-blog-grid .gdlr-core-blog-content {
  margin-bottom: 20px;
}

.gdlr-core-excerpt-read-more {
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  margin-top: 24px;
}

.gdlr-core-excerpt-read-more i {
  margin-left: 15px;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-content {
  font-style: normal;
}

.gdlr-core-testimonial-style-center .gdlr-core-testimonial-quote {
  display: none;
}

.gdlr-core-flexslider .flex-control-nav {
  margin-top: 20px;
}

.gdlr-core-flexslider[data-nav-parent="gdlr-core-portfolio-item"] {
  position: static;
}

.gdlr-core-flexslider[data-nav-parent="gdlr-core-portfolio-item"] .flex-direction-nav li {
  margin-top: -30px;
}

.gdlr-core-flexslider[data-nav-parent="gdlr-core-portfolio-item"] .flex-direction-nav li a {
  border-radius: 0;
  padding: 16px 14px;
}

.gdlr-core-flexslider[data-nav-parent="gdlr-core-portfolio-item"] .flex-direction-nav li a i {
  font-size: 28px;
  width: 28px;
}

.gdlr-core-flexslider[data-nav-parent="gdlr-core-portfolio-item"] .flex-direction-nav .flex-nav-prev {
  left: 0;
}

.gdlr-core-flexslider[data-nav-parent="gdlr-core-portfolio-item"] .flex-direction-nav .flex-nav-next {
  right: 0;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-title {
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 400;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-info {
  font-size: 14px;
  font-style: normal;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-title-tag .gdlr-core-portfolio-info {
  margin-top: 12px;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-title-icon .gdlr-core-portfolio-title {
  margin-bottom: 20px;
}

.gdlr-core-portfolio-thumbnail.gdlr-core-style-icon-title-tag .gdlr-core-portfolio-title, .gdlr-core-portfolio-thumbnail.gdlr-core-style-margin-icon-title-tag .gdlr-core-portfolio-title {
  margin-top: 20px;
}

.gdlr-core-portfolio-medium .gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-info {
  font-size: 14px;
}

.gdlr-core-portfolio-grid .gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-info {
  font-size: 14px;
  font-style: normal;
}

.gdlr-core-portfolio-slider-widget-wrap {
  padding-top: 0;
  margin-top: -10px;
}

.gdlr-core-recent-portfolio-widget {
  max-width: 80px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.gdlr-core-title-item.gdlr-core-left-align .gdlr-core-title-item-link:after {
  font-family: "fontAwesome";
  margin-left: 10px;
  content: "\f178";
  vertical-align: middle;
}

.gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-title {
  padding: 7px 13px 7px 17px;
  margin-top: -4px;
}

.gdlr-core-product-item.woocommerce .gdlr-core-product-thumbnail-info i {
  font-size: 13px;
  margin-right: 7px;
}

.gdlr-core-product-item.woocommerce .gdlr-core-product-thumbnail-info > a {
  font-size: 12px;
}

span.gdlr-core-counter-item-suffix, span.gdlr-core-counter-item-count {
  font-weight: 700;
}

body.realfactory-boxed .realfactory-body-wrapper {
  margin-top: 0;
  margin-bottom: 0;
}

.realfactory-body-wrapper.realfactory-with-frame {
  margin: 0;
}

body.realfactory-full .realfactory-fixed-footer {
  margin: 0;
}

body.realfactory-boxed.realfactory-background-pattern .realfactory-body-outer-wrapper {
  background-image: url(https://demo.goodlayers.com/realfactory/wp-content/plugins/goodlayers-core/include/images/pattern/pattern-1.png);
}

.realfactory-item-pdlr, .gdlr-core-item-pdlr {
  padding-left: 15px;
  padding-right: 15px;
}

.realfactory-item-rvpdlr, .gdlr-core-item-rvpdlr {
  margin-left: -15px;
  margin-right: -15px;
}

.gdlr-core-metro-rvpdlr {
  margin-top: -15px;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
}

.realfactory-item-mglr, .gdlr-core-item-mglr, .realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega {
  margin-left: 15px;
  margin-right: 15px;
}

.realfactory-container, .gdlr-core-container {
  max-width: 1180px;
}

body.realfactory-boxed .realfactory-body-wrapper {
  max-width: 1180px;
}

body.realfactory-boxed .realfactory-fixed-footer .realfactory-footer-wrapper,
body.realfactory-boxed .realfactory-fixed-footer .realfactory-copyright-wrapper {
  max-width: 1180px;
}

.realfactory-body-front .gdlr-core-container {
  padding-left: 15px;
  padding-right: 15px;
}

.realfactory-body-front .realfactory-container {
  padding-left: 15px;
  padding-right: 15px;
}

.realfactory-body-front .realfactory-container .realfactory-container, .realfactory-body-front .realfactory-container .gdlr-core-container {
  padding-left: 0;
  padding-right: 0;
}

.realfactory-body-front .gdlr-core-container .gdlr-core-container {
  padding-left: 0;
  padding-right: 0;
}

.realfactory-top-bar-container.realfactory-top-bar-custom-container {
  max-width: 1140px;
}

.realfactory-top-bar-container.realfactory-top-bar-full {
  padding-right: 15px;
  padding-left: 15px;
}

.realfactory-top-bar {
  padding-top: 16px;
  padding-bottom: 14px;
  font-size: 15px;
  border-bottom-width: 0;
}

.realfactory-header-style-plain {
  border-bottom-width: 0;
}

.realfactory-header-background-transparent .realfactory-top-bar-background {
  opacity: 0.5;
}

.realfactory-header-background-transparent .realfactory-header-background {
  opacity: 0;
}

.realfactory-navigation-bar-wrap.realfactory-style-transparent .realfactory-navigation-background {
  opacity: 0.82;
}

.realfactory-header-boxed-wrap .realfactory-top-bar-background {
  opacity: 0;
  margin-bottom: -0px;
}

.realfactory-header-style-boxed {
  margin-top: 0;
}

.realfactory-header-container.realfactory-header-custom-container {
  max-width: 1140px;
}

.realfactory-header-container.realfactory-header-full {
  padding-right: 15px;
  padding-left: 15px;
}

.realfactory-header-boxed-wrap .realfactory-header-background {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.realfactory-header-style-boxed .realfactory-header-container-item {
  padding-left: 30px;
  padding-right: 30px;
}

.realfactory-navigation-right {
  right: 30px;
}

.realfactory-navigation-left {
  left: 30px;
}

.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-navigation .sf-menu > li > a, .realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-main-menu-left-wrap, .realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-main-menu-right-wrap {
  padding-top: 0;
}

.realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-navigation .sf-menu > li > a, .realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-main-menu-left-wrap, .realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-main-menu-right-wrap {
  padding-top: 0;
}

.realfactory-navigation .sf-menu > li {
  padding-left: 23px;
  padding-right: 23px;
}

.realfactory-header-side-nav {
  width: 340px;
}

.realfactory-header-side-content.realfactory-style-left {
  margin-left: 340px;
}

.realfactory-header-side-content.realfactory-style-right {
  margin-right: 340px;
}

.realfactory-header-side-nav.realfactory-style-side {
  padding-left: 70px;
  padding-right: 70px;
}

.realfactory-header-side-nav.realfactory-style-left .sf-vertical > li > ul.sub-menu {
  padding-left: 70px;
}

.realfactory-header-side-nav.realfactory-style-right .sf-vertical > li > ul.sub-menu {
  padding-right: 70px;
}

.realfactory-navigation .sf-vertical > li {
  padding-top: 16px;
  padding-bottom: 16px;
}

.realfactory-header-style-bar .realfactory-logo-right-text {
  padding-top: 53px;
}

.realfactory-logo {
  padding-top: 15px;
  padding-bottom: 15px;
}

.realfactory-logo-inner {
  max-width: 375px;
}

.realfactory-navigation {
  padding-top: 23px;
}

.realfactory-navigation-top {
  top: 23px;
}

.realfactory-navigation .sf-menu > li > a {
  padding-bottom: 23px;
}

.realfactory-page-title-wrap.realfactory-style-custom .realfactory-page-title-content {
  padding-top: 93px;
  padding-bottom: 87px;
}

.realfactory-page-title-wrap.realfactory-style-custom .realfactory-page-caption {
  margin-top: 13px;
}

.realfactory-page-title-wrap .realfactory-page-title {
  text-transform: none;
}

.realfactory-page-title-wrap.realfactory-style-custom .realfactory-page-title {
  font-size: 55px;
  letter-spacing: 0;
}

.realfactory-page-title-wrap.realfactory-style-custom .realfactory-page-caption {
  font-size: 18px;
  letter-spacing: 0;
}

.realfactory-page-title-wrap .realfactory-page-title-overlay {
  opacity: 0;
}

.realfactory-not-found-wrap .realfactory-not-found-background {
  opacity: 0.27;
}

.realfactory-blog-title-wrap.realfactory-style-custom .realfactory-blog-title-content {
  padding-top: 340px;
  padding-bottom: 80px;
}

.realfactory-blog-title-wrap .realfactory-blog-title-overlay {
  opacity: 0.41;
}

body.single-post .realfactory-sidebar-style-none, body.blog .realfactory-sidebar-style-none {
  max-width: 900px;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-icon {
  font-size: 22px;
}

.realfactory-footer-wrapper {
  padding-top: 70px;
  padding-bottom: 50px;
}

.realfactory-copyright-text {
  padding-top: 30px;
  padding-bottom: 30px;
}

.realfactory-body h3 {
  font-size: 30px;
}

.realfactory-body h4 {
  font-size: 24px;
}

.realfactory-body h5 {
  font-size: 20px;
}

.realfactory-body h6 {
  font-size: 16px;
}

.realfactory-body h1, .realfactory-body h2, .realfactory-body h3, .realfactory-body h4, .realfactory-body h5, .realfactory-body h6 {
  font-weight: 400;
}

.realfactory-navigation .sf-menu > li > a, .realfactory-navigation .sf-vertical > li > a {
  font-size: 14px;
}

.realfactory-navigation .sf-menu > li > a {
  font-weight: 600;
  text-transform: uppercase;
}

.realfactory-body {
  font-size: 15px;
  line-height: 1.7;
}

.realfactory-body p {
  line-height: 1.7;
}

.realfactory-line-height, .gdlr-core-line-height {
  line-height: 1.7;
}

.realfactory-page-preload {
  background-color: #ffffff;
}

.realfactory-top-bar-background {
  background-color: #ededed;
}

.realfactory-body .realfactory-top-bar {
  border-bottom-color: #ffffff;
}

.realfactory-top-bar {
  color: #727272;
}

.realfactory-body .realfactory-top-bar a {
  color: #ffffff;
}

.realfactory-body .realfactory-top-bar a:hover {
  color: #ffffff;
}

.realfactory-top-bar .realfactory-top-bar-right-social a {
  color: #2e2e2e;
}

.realfactory-top-bar .realfactory-top-bar-right-social a:hover {
  color: #e44444;
}

.realfactory-header-background, .realfactory-sticky-menu-placeholder, .realfactory-header-style-boxed.realfactory-fixed-navigation {
  background-color: #e6e6e6;
}

.realfactory-header-wrap.realfactory-header-style-plain {
  border-color: #e8e8e8;
}

.realfactory-logo-right-block .realfactory-logo-right-block-icon, .realfactory-logo-right-block .realfactory-logo-right-block-title {
  color: #191919;
}

.realfactory-logo-right-block .realfactory-logo-right-block-caption {
  color: #929292;
}

.realfactory-body .realfactory-header-right-button {
  color: #1c1c1c;
  background-color: #f8c12c;
  border-color: #f8c12c;
}

.realfactory-navigation-background {
  background-color: #000000;
}

.realfactory-navigation .realfactory-navigation-slide-bar {
  border-color: #ffbe32;
}

.realfactory-navigation .realfactory-navigation-slide-bar:before {
  border-bottom-color: #ffbe32;
}

.realfactory-header-side-nav.realfactory-style-side-toggle .realfactory-logo {
  background-color: #ffffff;
}

.realfactory-main-menu-search i {
  color: #ffffff;
}

.realfactory-main-menu-cart i {
  color: #ffffff;
}

.realfactory-main-menu-cart > .realfactory-top-cart-count {
  background-color: #e8b62d;
  color: #ffffff;
}

.realfactory-top-menu-button i, .realfactory-mobile-menu-button i {
  color: #ffffff;
}

.realfactory-mobile-button-hamburger:before,
.realfactory-mobile-button-hamburger:after,
.realfactory-mobile-button-hamburger span {
  background: #ffffff;
}

.realfactory-main-menu-right .realfactory-top-menu-button, .realfactory-mobile-menu .realfactory-mobile-menu-button {
  border-color: #ffffff;
}

.realfactory-mobile-header .realfactory-mobile-menu-button i, .realfactory-mobile-header .realfactory-main-menu-search i, .realfactory-mobile-header .realfactory-main-menu-cart i {
  color: #383838;
}

.realfactory-mobile-header .realfactory-mobile-button-hamburger:before, .realfactory-mobile-header .realfactory-mobile-button-hamburger:after, .realfactory-mobile-header .realfactory-mobile-button-hamburger span {
  background: #383838;
}

.realfactory-mobile-menu .realfactory-mobile-menu-button {
  border-color: #dddddd;
}

.realfactory-top-search-wrap {
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.88);
}

.realfactory-top-cart-content-wrap .realfactory-top-cart-content {
  background-color: #303030;
}

.realfactory-top-cart-content-wrap .realfactory-top-cart-content span {
  color: #b5b5b5;
}

.realfactory-top-cart-content-wrap .realfactory-top-cart-content span.woocommerce-Price-amount.amount {
  color: #b5b5b5;
}

.realfactory-top-cart-content-wrap .realfactory-top-cart-button {
  color: #ffffff;
}

.realfactory-top-cart-content-wrap .realfactory-top-cart-button:hover {
  color: #ffffff;
}

.realfactory-top-cart-content-wrap .realfactory-top-cart-checkout-button {
  color: #f8c12c;
}

.realfactory-top-cart-content-wrap .realfactory-top-cart-checkout-button:hover {
  color: #f8c12c;
}

.realfactory-body .realfactory-breadcrumbs {
  background-color: #efefef;
  color: #898989;
}

.realfactory-body .realfactory-breadcrumbs a span {
  color: #898989;
}

.realfactory-body .realfactory-breadcrumbs span, .realfactory-body .realfactory-breadcrumbs a:hover span {
  color: #545454;
}

.sf-menu > li > a, .sf-vertical > li > a {
  color: #ffffff;
}

.sf-menu > li > a:hover, .sf-menu > li.current-menu-item > a, .sf-menu > li.current-menu-ancestor > a {
  color: #ffffff;
}

.sf-vertical > li > a:hover, .sf-vertical > li.current-menu-item > a, .sf-vertical > li.current-menu-ancestor > a {
  color: #ffffff;
}

.sf-menu > .realfactory-normal-menu li, .sf-menu > .realfactory-mega-menu > .sf-mega {
  background-color: #0e0e0e;
}

.sf-vertical ul.sub-menu li, ul.sf-menu > .menu-item-language li {
  background-color: #0e0e0e;
}

.sf-menu > li > .sub-menu a, .sf-menu > .realfactory-mega-menu > .sf-mega a {
  color: #bebebe;
}

.sf-vertical ul.sub-menu li a {
  color: #bebebe;
}

.sf-menu > li > .sub-menu a:hover, .sf-menu > li > .sub-menu .current-menu-item > a, .sf-menu > li > .sub-menu .current-menu-ancestor > a {
  color: #e8b62d;
}

.sf-menu > .realfactory-mega-menu > .sf-mega a:hover, .sf-menu > .realfactory-mega-menu > .sf-mega .current-menu-item > a {
  color: #e8b62d;
}

.sf-vertical > li > .sub-menu a:hover, .sf-vertical > li > .sub-menu .current-menu-item > a, .sf-vertical > li > .sub-menu .current-menu-ancestor > a {
  color: #e8b62d;
}

.sf-menu > li > .sub-menu a:hover, .sf-menu > li > .sub-menu .current-menu-item > a, .sf-menu > li > .sub-menu .current-menu-ancestor > a {
  background-color: #0e0e0e;
}

.sf-menu > .realfactory-mega-menu > .sf-mega a:hover, .sf-menu > .realfactory-mega-menu > .sf-mega .current-menu-item > a {
  background-color: #0e0e0e;
}

.sf-vertical > li > .sub-menu a:hover, .sf-vertical > li > .sub-menu .current-menu-item > a, .sf-vertical > li > .sub-menu .current-menu-ancestor > a {
  background-color: #0e0e0e;
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega-section-inner > a {
  color: #ffffff;
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega-section {
  border-color: #303030;
}

.mm-navbar .mm-title, .mm-navbar .mm-btn {
  color: #979797;
}

ul.mm-listview li > a, ul.mm-listview li > span {
  color: #979797;
}

ul.mm-listview li a {
  border-color: #979797;
}

.mm-arrow:after, .mm-next:after, .mm-prev:before {
  border-color: #979797;
}

.mm-navbar .mm-title:hover, .mm-navbar .mm-btn:hover {
  color: #ffffff;
}

ul.mm-listview li a:hover, ul.mm-listview li > span:hover, ul.mm-listview li.current-menu-item > a {
  color: #ffffff;
}

ul.mm-listview li.current-menu-ancestor > a, ul.mm-listview li.current-menu-ancestor > span {
  color: #ffffff;
}

.mm-menu {
  background-color: #1f1f1f;
}

ul.mm-listview li {
  border-color: #626262;
}

.realfactory-overlay-menu-content {
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.88);
}

.realfactory-overlay-menu-content ul.menu > li, .realfactory-overlay-menu-content ul.sub-menu ul.sub-menu {
  border-color: #424242;
}

.realfactory-overlay-menu-content ul li a {
  color: #ffffff;
}

.realfactory-overlay-menu-content .realfactory-overlay-menu-close {
  color: #ffffff;
}

.realfactory-overlay-menu-content ul li a:hover {
  color: #a8a8a8;
}

.realfactory-bullet-anchor a:before {
  background-color: #777777;
}

.realfactory-bullet-anchor a:hover, .realfactory-bullet-anchor a.current-menu-item {
  border-color: #ffffff;
}

.realfactory-bullet-anchor a:hover:before, .realfactory-bullet-anchor a.current-menu-item:before {
  background: #ffffff;
}

.realfactory-body .realfactory-main-menu-right-button {
  color: #333333;
  border-color: #333333;
}

.realfactory-body .realfactory-main-menu-right-button:hover {
  color: #555555;
  border-color: #555555;
}

.realfactory-body-outer-wrapper {
  background-color: #ffffff;
}

body.realfactory-full .realfactory-body-wrapper, body.realfactory-boxed .realfactory-body-wrapper {
  background-color: #ffffff;
}

.gdlr-core-page-builder .gdlr-core-page-builder-body.gdlr-core-pb-livemode {
  background-color: #ffffff;
}

.realfactory-page-title-wrap .realfactory-page-title {
  color: #ffffff;
}

.realfactory-page-title-wrap .realfactory-page-caption {
  color: #ffffff;
}

.realfactory-page-title-wrap .realfactory-page-caption .woocommerce-breadcrumb {
  color: #ffffff;
}

.realfactory-page-title-wrap .realfactory-page-caption .woocommerce-breadcrumb a {
  color: #ffffff;
}

.realfactory-page-title-wrap .realfactory-page-title-overlay {
  background-color: #000000;
}

.realfactory-body {
  color: #727272;
}

.realfactory-body span.wpcf7-not-valid-tip {
  color: #727272;
}

.realfactory-body h1, .realfactory-body h2, .realfactory-body h3, .realfactory-body h4, .realfactory-body h5, .realfactory-body h6 {
  color: #383838;
}

.woocommerce table.shop_attributes th, .woocommerce table.shop_table th {
  color: #383838;
}

.single-product.woocommerce div.product .product_meta .realfactory-head {
  color: #383838;
}

.realfactory-body a {
  color: #545454;
}

.realfactory-body a:hover {
  color: #333333;
}

.realfactory-body * {
  border-color: #e6e6e6;
}

.gdlr-core-columnize-item .gdlr-core-columnize-item-content {
  column-rule-color: #e6e6e6;
  -moz-column-rule-color: #e6e6e6;
  -webkit-column-rule-color: #e6e6e6;
}

.realfactory-body input, .realfactory-body textarea {
  background-color: #ffffff;
}

.realfactory-body input, .realfactory-body textarea {
  border-color: #d7d7d7;
}

.realfactory-body input, .realfactory-body textarea, .realfactory-body ::-webkit-input-placeholder, .realfactory-body ::-moz-placeholder, .realfactory-body :-ms-input-placeholder, .realfactory-body :-moz-placeholder {
  color: #4e4e4e;
}

.realfactory-not-found-wrap {
  background-color: #23618e;
}

.realfactory-not-found-wrap .realfactory-not-found-head, .realfactory-not-found-wrap .realfactory-not-found-title {
  color: #ffffff;
}

.realfactory-not-found-wrap .realfactory-not-found-caption {
  color: #c3e7ff;
}

.realfactory-not-found-wrap form.search-form input.search-field {
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
}

.realfactory-not-found-wrap .realfactory-top-search-submit {
  color: #ffffff;
}

.realfactory-not-found-wrap input::-webkit-input-placeholder, .realfactory-not-found-wrap input:-moz-placeholder, .realfactory-not-found-wrap input::-moz-placeholder, .realfactory-not-found-wrap input:-ms-input-placeholder {
  color: #ffffff;
}

.realfactory-not-found-wrap .realfactory-not-found-back-to-home a {
  color: #ffffff;
}

.realfactory-not-found-wrap .realfactory-not-found-back-to-home a:hover {
  color: #ffffff;
}

.realfactory-sidebar-area .realfactory-widget-title {
  color: #161616;
}

.realfactory-sidebar-area a {
  color: #a3a3a3;
}

.widget_recent_entries ul li:before, .widget_recent_comments ul li:before, .widget_pages ul li:before, .widget_rss ul li:before, .widget_archive ul li:before, .widget_categories ul li:before, .widget_nav_menu ul li:before, .widget_meta ul li:before {
  color: #a3a3a3;
}

.realfactory-sidebar-area a:hover, .realfactory-sidebar-area .current-menu-item > a {
  color: #ddb42c;
}

.gdlr-core-recent-post-widget .gdlr-core-blog-info {
  color: #a0a0a0;
}

.gdlr-core-recent-post-widget .gdlr-core-blog-info i {
  color: #9c9c9c;
}

.gdlr-core-recent-post-widget .gdlr-core-blog-info a {
  color: #a0a0a0;
}

.gdlr-core-recent-post-widget .gdlr-core-blog-info a:hover {
  color: #a0a0a0;
}

.gdlr-core-post-slider-widget-overlay .gdlr-core-post-slider-widget-title {
  color: #ffffff;
}

.gdlr-core-post-slider-widget-overlay .gdlr-core-blog-info {
  color: #9c9c9c;
}

.gdlr-core-post-slider-widget-overlay .gdlr-core-blog-info i {
  color: #9c9c9c;
}

.gdlr-core-post-slider-widget-overlay .gdlr-core-blog-info a {
  color: #9c9c9c;
}

.gdlr-core-post-slider-widget-overlay .gdlr-core-blog-info a:hover {
  color: #9c9c9c;
}

.widget_search input.search-field, .widget_search input::-webkit-input-placeholder, .widget_search input:-moz-placeholder, .widget_search input::-moz-placeholder, .widget_search input:-ms-input-placeholder {
  color: #b5b5b5;
}

.widget_search input.search-field {
  border-color: #e0e0e0;
}

.widget_search form:after {
  border-color: #c7c7c7;
}

ul.gdlr-core-twitter-widget-wrap li:before {
  color: #303030;
}

ul.gdlr-core-twitter-widget-wrap li .gdlr-core-twitter-widget-date a {
  color: #b5b5b5;
}

ul.gdlr-core-twitter-widget-wrap li .gdlr-core-twitter-widget-date a:hover {
  color: #b5b5b5;
}

.realfactory-footer-wrapper {
  background-color: #242424;
  color: #f7f7f7;
}

.realfactory-footer-wrapper .realfactory-widget-title {
  color: #f8c12c;
}

.realfactory-footer-wrapper .widget_recent_entries ul li:before, .realfactory-footer-wrapper .widget_recent_comments ul li:before, .realfactory-footer-wrapper .widget_pages ul li:before, .realfactory-footer-wrapper .widget_rss ul li:before, .realfactory-footer-wrapper .widget_archive ul li:before, .realfactory-footer-wrapper .widget_categories ul li:before {
  color: #f7f7f7;
}

.widget_nav_menu ul li:before {
  color: #f7f7f7;
}

.realfactory-footer-wrapper {
  border-color: #353535;
}

.realfactory-footer-wrapper .widget_meta ul li:before {
  color: #f7f7f7;
}

.realfactory-footer-wrapper a {
  color: #b2b2b2;
}

.realfactory-footer-wrapper a:hover {
  color: #f8c12c;
}

.realfactory-footer-wrapper * {
  border-color: #353535;
}

.realfactory-copyright-wrapper {
  background-color: #131313;
  color: #838383;
}

.realfactory-footer-back-to-top-button {
  background-color: #dbdbdb;
  color: #313131;
}

.realfactory-body .realfactory-blog-title-wrap .realfactory-single-article-title {
  color: #ffffff;
}

.realfactory-body .realfactory-blog-title-wrap .realfactory-blog-info-wrapper {
  color: #ffffff;
}

.realfactory-body .realfactory-blog-title-wrap .realfactory-blog-info-wrapper a {
  color: #ffffff;
}

.realfactory-body .realfactory-blog-title-wrap .realfactory-blog-info-wrapper a:hover {
  color: #ffffff;
}

.realfactory-body .realfactory-blog-title-wrap .realfactory-blog-info-wrapper i {
  color: #ffffff;
}

.realfactory-body .realfactory-blog-title-wrap .realfactory-single-article-date-day {
  color: #ffffff;
}

.realfactory-body .realfactory-blog-title-wrap .realfactory-single-article-date-month {
  color: #d8d8d8;
}

.realfactory-body .realfactory-blog-title-wrap .realfactory-single-article-date-wrapper {
  border-color: #ffffff;
}

.realfactory-blog-title-wrap .realfactory-blog-title-overlay {
  background-color: #000000;
}

.realfactory-single-author .realfactory-single-author-title a {
  color: #464646;
}

.realfactory-single-author .realfactory-single-author-title a:hover {
  color: #464646;
}

.realfactory-single-author .realfactory-single-author-caption {
  color: #b1b1b1;
}

.realfactory-single-nav a {
  color: #bcbcbc;
}

.realfactory-single-nav a:hover {
  color: #bcbcbc;
}

.realfactory-comments-area .realfactory-comments-title, .realfactory-comments-area .comment-author, .realfactory-comments-area .comment-reply-title {
  color: #464646;
}

.realfactory-comments-area .comment-reply a {
  color: #2b2b2b;
}

.realfactory-comments-area .comment-reply a:hover {
  color: #2b2b2b;
}

.realfactory-comments-area .comment-time a {
  color: #b1b1b1;
}

.realfactory-comments-area .comment-time a:hover {
  color: #b1b1b1;
}

.gdlr-core-blog-title a {
  color: #343434;
}

.realfactory-body .realfactory-single-article-title {
  color: #343434;
}

.realfactory-body .realfactory-single-article-title a {
  color: #343434;
}

.gdlr-core-blog-title a:hover, .realfactory-body .realfactory-single-article-title a:hover {
  color: #f1bf37;
}

.gdlr-core-sticky-banner, .realfactory-sticky-banner {
  color: #262626;
}

.gdlr-core-sticky-banner, .realfactory-sticky-banner {
  background-color: #f1bf37;
}

.realfactory-single-article .realfactory-blog-info-wrapper, .gdlr-core-blog-info-wrapper {
  color: #959595;
}

.realfactory-single-article .realfactory-blog-info-wrapper a, .gdlr-core-blog-info-wrapper a {
  color: #959595;
}

.realfactory-single-article .realfactory-blog-info-wrapper a:hover, .gdlr-core-blog-info-wrapper a:hover {
  color: #959595;
}

.gdlr-core-blog-grid .gdlr-core-blog-info-date a {
  color: #959595;
}

.realfactory-single-article .realfactory-blog-info-wrapper i, .gdlr-core-blog-info-wrapper i {
  color: #f1bf37;
}

.gdlr-core-blog-date-wrapper .gdlr-core-blog-date-day, .realfactory-single-article .realfactory-single-article-date-day {
  color: #353535;
}

.gdlr-core-blog-date-wrapper .gdlr-core-blog-date-month, .realfactory-single-article .realfactory-single-article-date-month {
  color: #9b9b9b;
}

.realfactory-body .gdlr-core-blog-date-wrapper, .realfactory-body .realfactory-single-article-date-wrapper {
  border-color: #f8c12c;
}

.gdlr-core-blog-grid-frame, .gdlr-core-blog-full-frame, .gdlr-core-blog-list-frame {
  background-color: #ffffff;
}

.gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-info-wrapper {
  color: #ffffff;
}

.gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-info-wrapper a, .gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-info-wrapper i {
  color: #ffffff;
}

.gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-title a {
  color: #ffffff;
}

.gdlr-core-blog-metro.gdlr-core-with-image .gdlr-core-blog-info-wrapper {
  color: #ffffff;
}

.gdlr-core-blog-metro.gdlr-core-with-image .gdlr-core-blog-info-wrapper a, .gdlr-core-blog-metro.gdlr-core-with-image .gdlr-core-blog-info-wrapper i {
  color: #ffffff;
}

.gdlr-core-blog-metro.gdlr-core-with-image .gdlr-core-blog-title a {
  color: #ffffff;
}

.realfactory-blog-aside-format .realfactory-single-article-content, .gdlr-core-blog-aside-format .gdlr-core-blog-content, .realfactory-blog-quote-format .realfactory-single-article-content, .gdlr-core-blog-quote-format .gdlr-core-blog-content {
  background-color: #f2bd2b;
}

.realfactory-blog-aside-format .realfactory-single-article-content, .gdlr-core-blog-aside-format .gdlr-core-blog-content {
  color: #ffffff;
}

.realfactory-blog-quote-format .realfactory-single-article-content {
  color: #ffffff;
}

.realfactory-blog-quote-format .realfactory-single-article-content blockquote, .realfactory-blog-quote-format .realfactory-single-article-content q {
  color: #ffffff;
}

.gdlr-core-blog-quote-format .gdlr-core-blog-content {
  color: #ffffff;
}

.gdlr-core-blog-quote-format .gdlr-core-blog-content blockquote, .gdlr-core-blog-quote-format .gdlr-core-blog-content q {
  color: #ffffff;
}

.gdlr-core-pagination a {
  background-color: #e2e2e2;
  color: #515151;
}

.gdlr-core-pagination a:hover, .gdlr-core-pagination a.gdlr-core-active {
  background-color: #190000;
}

.gdlr-core-pagination span {
  background-color: #190000;
}

.gdlr-core-pagination a:hover, .gdlr-core-pagination a.gdlr-core-active {
  color: #ffffff;
}

.gdlr-core-pagination span {
  color: #ffffff;
}

.gdlr-core-pagination.gdlr-core-with-border a {
  color: #b4b4b4;
  border-color: #b4b4b4;
}

.gdlr-core-pagination.gdlr-core-style-plain a {
  color: #b4b4b4;
}

.gdlr-core-pagination.gdlr-core-style-plain a:before {
  color: #b4b4b4;
}

.gdlr-core-pagination.gdlr-core-style-plain span:before {
  color: #b4b4b4;
}

.gdlr-core-pagination.gdlr-core-with-border a:hover, .gdlr-core-pagination.gdlr-core-with-border a.gdlr-core-active {
  color: #424242;
  border-color: #424242;
}

.gdlr-core-pagination.gdlr-core-with-border span {
  color: #424242;
  border-color: #424242;
}

.gdlr-core-pagination.gdlr-core-style-plain a:hover, .gdlr-core-pagination.gdlr-core-style-plain a.gdlr-core-active {
  color: #424242;
}

.gdlr-core-pagination.gdlr-core-style-plain span {
  color: #424242;
}

.gdlr-core-portfolio-single-nav {
  color: #bcbcbc;
}

.gdlr-core-portfolio-single-nav a {
  color: #bcbcbc;
}

.gdlr-core-portfolio-single-nav a:hover {
  color: #bcbcbc;
}

.gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-title a {
  color: #191919;
}

.gdlr-core-port-info-item .gdlr-core-port-info-key, .gdlr-core-port-info2 .gdlr-core-port-info2-key {
  color: #343434;
}

.gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-info {
  color: #898989;
}

.gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-info a {
  color: #898989;
}

.gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-info a:hover {
  color: #898989;
}

.gdlr-core-portfolio-badge {
  color: #ffffff;
  background-color: #e24a3b;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-icon {
  color: #e8b62d;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-title a {
  color: #e8b62d;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-title a:hover {
  color: #e8b62d;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-info {
  color: #cecece;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-info a {
  color: #cecece;
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-info a:hover {
  color: #cecece;
}

.gdlr-core-filterer-wrap.gdlr-core-style-text a {
  color: #aaaaaa;
}

.gdlr-core-filterer-wrap.gdlr-core-style-text a:hover, .gdlr-core-filterer-wrap.gdlr-core-style-text a.gdlr-core-active {
  color: #747474;
}

.gdlr-core-filterer-wrap.gdlr-core-style-button a {
  color: #838383;
  background-color: #f1f1f1;
}

.gdlr-core-filterer-wrap.gdlr-core-style-button a:hover, .gdlr-core-filterer-wrap.gdlr-core-style-button a.gdlr-core-active {
  color: #ffffff;
}

.gdlr-core-filterer-wrap.gdlr-core-style-button a:hover, .gdlr-core-filterer-wrap.gdlr-core-style-button a.gdlr-core-active {
  background-color: #f2bd2b;
}

.gdlr-core-price-table-item .gdlr-core-price-table {
  background-color: #f8f8f8;
}

.gdlr-core-price-table .gdlr-core-price-table-head {
  background-color: #3e3e3e;
  background: -webkit-linear-gradient(#525252, #3e3e3e);
  background: -o-linear-gradient(#525252, #3e3e3e);
  background: -moz-linear-gradient(#525252, #3e3e3e);
  background: linear-gradient(#525252, #3e3e3e);
}

.gdlr-core-price-table .gdlr-core-price-table-icon, .gdlr-core-price-table .gdlr-core-price-table-title {
  color: #ffffff;
}

.gdlr-core-price-table .gdlr-core-price-table-caption {
  color: #acacac;
}

.gdlr-core-price-table .gdlr-core-price-table-price {
  background-color: #ebebeb;
}

.gdlr-core-price-table .gdlr-core-price-table-price-number, .gdlr-core-price-table .gdlr-core-price-prefix {
  color: #323232;
}

.gdlr-core-price-table .gdlr-core-price-suffix {
  color: #acacac;
}

.gdlr-core-price-table .gdlr-core-price-table-button {
  color: #ffffff;
  background-color: #575757;
  background: -webkit-linear-gradient(#414141, #575757);
  background: -o-linear-gradient(#414141, #575757);
  background: -moz-linear-gradient(#414141, #575757);
  background: linear-gradient(#414141, #575757);
}

.gdlr-core-price-table .gdlr-core-price-table-button:hover {
  color: #ffffff;
  background-color: #575757;
  background: -webkit-linear-gradient(#414141, #575757);
  background: -o-linear-gradient(#414141, #575757);
  background: -moz-linear-gradient(#414141, #575757);
  background: linear-gradient(#414141, #575757);
}

.gdlr-core-price-table .gdlr-core-price-table-content * {
  border-color: #e5e5e5;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-head {
  background-color: #e8b62d;
  background: -webkit-linear-gradient(#e8c35f, #e8b62d);
  background: -o-linear-gradient(#e8c35f, #e8b62d);
  background: -moz-linear-gradient(#e8c35f, #e8b62d);
  background: linear-gradient(#e8c35f, #e8b62d);
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-icon, .gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-title {
  color: #ffffff;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-caption {
  color: #fff5c1;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-price {
  background-color: #ececec;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-price-number {
  color: #323232;
}

.gdlr-core-price-table .gdlr-core-price-prefix {
  color: #323232;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-suffix {
  color: #acacac;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-button {
  color: #ffffff;
}

.gdlr-core-price-table .gdlr-core-price-table-button:hover {
  color: #ffffff;
}

.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-button, .gdlr-core-price-table .gdlr-core-price-table-button:hover {
  background-color: #e8b62d;
  background: -webkit-linear-gradient(#e8c668, #e8b62d);
  background: -o-linear-gradient(#e8c668, #e8b62d);
  background: -moz-linear-gradient(#e8c668, #e8b62d);
  background: linear-gradient(#e8c668, #e8b62d);
}

.gdlr-core-accordion-style-icon .gdlr-core-accordion-item-icon, .gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-icon, .gdlr-core-toggle-box-style-icon .gdlr-core-toggle-box-item-icon, .gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-icon, .gdlr-core-accordion-style-icon .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-icon .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-title {
  color: #191919;
}

.gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-icon, .gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-icon {
  background-color: #f8c12c;
}

.gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-icon, .gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-icon {
  border-color: #f8c12c;
}

.gdlr-core-accordion-style-background-title-icon .gdlr-core-accordion-item-title:before, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-accordion-item-title:before, .gdlr-core-accordion-style-background-title .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-background-title-icon .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-background-title .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-toggle-box-item-title {
  color: #191919;
}

.gdlr-core-accordion-style-background-title .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-background-title-icon .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-background-title .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-toggle-box-item-title {
  background-color: #f3f3f3;
}

.gdlr-core-accordion-style-background-title-icon .gdlr-core-active .gdlr-core-accordion-item-title:before, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-active .gdlr-core-accordion-item-title:before, .gdlr-core-accordion-style-background-title .gdlr-core-active .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-background-title-icon .gdlr-core-active .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-background-title .gdlr-core-active .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-active .gdlr-core-toggle-box-item-title {
  color: #191919;
}

.gdlr-core-accordion-style-background-title .gdlr-core-active .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-background-title-icon .gdlr-core-active .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-background-title .gdlr-core-active .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-active .gdlr-core-toggle-box-item-title {
  background-color: #f3f3f3;
}

.gdlr-core-audio {
  background-color: #e7e7e7;
}

.gdlr-core-audio .mejs-container .mejs-controls {
  background-color: #e7e7e7;
}

.gdlr-core-audio .mejs-container .mejs-controls .mejs-volume-button:before, .gdlr-core-audio .mejs-container .mejs-controls .mejs-playpause-button:before, .gdlr-core-audio .mejs-container .mejs-controls .mejs-time {
  color: #202020;
}

.gdlr-core-audio .mejs-controls .mejs-time-rail .mejs-time-total, .gdlr-core-audio .mejs-controls .mejs-time-rail .mejs-time-loaded {
  background-color: #afafaf;
}

.gdlr-core-audio .mejs-controls .mejs-time-rail .mejs-time-current {
  background-color: #e8b62d;
}

.gdlr-core-audio .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
  background-color: #afafaf;
}

.gdlr-core-audio .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
  background-color: #646464;
}

.gdlr-core-alert-box-item .gdlr-core-alert-box-item-inner {
  background-color: #ef5e68;
  border-color: #cd515a;
  color: #ffffff;
}

.gdlr-core-alert-box-item .gdlr-core-alert-box-item-inner.alert-warning {
  background-color: #fcf8e3 !important;
  border-color: #e0ca74 !important;
  color: #997521 !important;
}

.gdlr-core-alert-box-item .gdlr-core-alert-box-item-inner.alert-warning .gdlr-core-alert-box-item-title {
  color: #997521 !important;
}

.gdlr-core-alert-box-item .gdlr-core-alert-box-item-title {
  color: #ffffff;
}

blockquote, q {
  color: #777777;
}

blockquote, q {
  background-color: #f5f5f5;
}

.realfactory-body blockquote, .realfactory-body q {
  border-color: #e2e2e2;
}

.gdlr-core-blockquote-item-quote, .gdlr-core-blockquote-item-content, .gdlr-core-blockquote-item-author {
  color: #4e4e4e;
}

.gdlr-core-body .gdlr-core-load-more, .gdlr-core-body .gdlr-core-button {
  color: #ffffff;
}

.realfactory-body .realfactory-button {
  color: #ffffff;
}

.realfactory-body input[type="button"], .realfactory-body input[type="submit"] {
  color: #ffffff;
}

.gdlr-core-body .gdlr-core-load-more:hover, .gdlr-core-body .gdlr-core-button:hover {
  color: #ffffff;
}

.gdlr-core-body .gdlr-core-load-more, .gdlr-core-body .gdlr-core-button {
  background-color: #2F2F2F;
}

.realfactory-body .realfactory-button {
  background-color: #2F2F2F;
}

.realfactory-body input[type="button"], .realfactory-body input[type="submit"] {
  background-color: #2F2F2F;
}

.gdlr-core-body .gdlr-core-load-more:hover, .gdlr-core-body .gdlr-core-button:hover {
  background-color: #2F2F2F;
}

.gdlr-core-body .gdlr-core-button-with-border {
  border-color: #383838;
}

.gdlr-core-body .gdlr-core-button-with-border.gdlr-core-button-transparent {
  color: #383838;
}

.gdlr-core-body .gdlr-core-button-with-border:hover {
  border-color: #000000;
}

.gdlr-core-body .gdlr-core-button-with-border.gdlr-core-button-transparent:hover {
  color: #000000;
}

.gdlr-core-body .gdlr-core-button.gdlr-core-button-gradient {
  background-color: #e8b62d;
  background: -webkit-linear-gradient(#e8c158, #e8b62d);
  background: -o-linear-gradient(#e8c158, #e8b62d);
  background: -moz-linear-gradient(#e8c158, #e8b62d);
  background: linear-gradient(#e8c158, #e8b62d);
}

.gdlr-core-call-to-action-item-title {
  color: #2c2c2c;
}

.gdlr-core-call-to-action-item-caption {
  color: #535353;
}

.gdlr-core-counter-item-top-text {
  color: #828282;
}

.gdlr-core-counter-item-top-icon, .gdlr-core-counter-item-number {
  color: #393939;
}

.gdlr-core-counter-item-divider {
  border-color: #393939;
}

.gdlr-core-counter-item-bottom-text {
  color: #393939;
}

.gdlr-core-column-service-item .gdlr-core-column-service-icon {
  color: #9d9d9d;
}

.gdlr-core-column-service-item .gdlr-core-icon-style-round i {
  background-color: #f3f3f3;
}

.gdlr-core-column-service-item .gdlr-core-column-service-title {
  color: #383838;
}

.gdlr-core-column-service-item .gdlr-core-column-service-caption {
  color: #747474;
}

.gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-title, .gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-head-wrap {
  background-color: #212121;
}

.gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-title {
  color: #f7c02e;
}

.gdlr-core-flipbox-item .gdlr-core-flipbox-front, .gdlr-core-flipbox-item .gdlr-core-flipbox-back {
  background-color: #e8b62d;
}

.gdlr-core-feature-box-item .gdlr-core-feature-box {
  background-color: #e8b62d;
}

.gdlr-core-flipbox-item .gdlr-core-flipbox-front, .gdlr-core-flipbox-item .gdlr-core-flipbox-back, .gdlr-core-flipbox-item .gdlr-core-flipbox-frame {
  border-color: #d39d1d;
}

.gdlr-core-feature-box-item .gdlr-core-feature-box, .gdlr-core-feature-box-item .gdlr-core-feature-box-frame {
  border-color: #d39d1d;
}

.gdlr-core-flipbox-item .gdlr-core-flipbox-item-icon, .gdlr-core-feature-box-item .gdlr-core-feature-box-item-icon, .gdlr-core-flipbox-item .gdlr-core-flipbox-item-title, .gdlr-core-feature-box-item .gdlr-core-feature-box-item-title, .gdlr-core-flipbox-item .gdlr-core-flipbox-item-caption, .gdlr-core-feature-box-item .gdlr-core-feature-box-item-caption, .gdlr-core-flipbox-item .gdlr-core-flipbox-item-content, .gdlr-core-feature-box-item .gdlr-core-feature-box-item-content {
  color: #ffffff;
}

.gdlr-core-image-overlay {
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.6);
}

.gdlr-core-image-overlay.gdlr-core-gallery-image-overlay .gdlr-core-image-overlay-title {
  color: #ffffff;
}

.gdlr-core-image-overlay.gdlr-core-gallery-image-overlay .gdlr-core-image-overlay-caption {
  color: #cecece;
}

.gdlr-core-image-overlay-content {
  color: #e8b62d;
}

.gdlr-core-image-overlay-content a {
  color: #e8b62d;
}

.gdlr-core-image-overlay-icon {
  color: #e8b62d;
}

.gdlr-core-page-builder-body [data-skin] .gdlr-core-image-overlay-icon, .gdlr-core-page-builder-body .gdlr-core-pbf-column[data-skin] .gdlr-core-image-overlay-icon {
  color: #e8b62d;
}

.gdlr-core-body .gdlr-core-image-item-wrap {
  border-color: #000000;
}

.gdlr-core-block-item-title-wrap .gdlr-core-block-item-title {
  color: #343434;
}

.gdlr-core-block-item-title-wrap .gdlr-core-block-item-caption {
  color: #747474;
}

.gdlr-core-block-item-title-wrap a {
  color: #747474;
}

.gdlr-core-block-item-title-wrap a:hover {
  color: #747474;
}

.gdlr-core-icon-list-item i {
  color: #222222;
}

.gdlr-core-icon-list-with-background-round .gdlr-core-icon-list-icon-wrap, .gdlr-core-icon-list-with-background-circle .gdlr-core-icon-list-icon-wrap {
  color: #f3f3f3;
}

.gdlr-core-opening-hour-item .gdlr-core-opening-hour-day {
  color: #a5a5a5;
}

.gdlr-core-opening-hour-item .gdlr-core-opening-hour-open {
  color: #262626;
}

.gdlr-core-opening-hour-item .gdlr-core-opening-hour-close {
  color: #c8c8c8;
}

.gdlr-core-opening-hour-item .gdlr-core-opening-hour-time i {
  color: #8a8989;
}

.gdlr-core-opening-hour-item .gdlr-core-opening-hour-list-item {
  border-color: #a6a6a6;
}

.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-title {
  color: #383838;
}

.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-title a {
  color: #383838;
}

.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-position {
  color: #888888;
}

.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-divider {
  color: #cecece;
}

.gdlr-core-personnel-style-grid.gdlr-core-with-background .gdlr-core-personnel-list-content-wrap {
  background-color: #f9f9f9;
}

.gdlr-core-personnel-style-modern .gdlr-core-personnel-list-title {
  color: #ffffff;
}

.gdlr-core-personnel-style-modern .gdlr-core-personnel-list-title a {
  color: #ffffff;
}

.gdlr-core-personnel-style-modern .gdlr-core-personnel-list-position {
  color: #ffffff;
}

.gdlr-core-promo-box-item .gdlr-core-promo-box-item-title {
  color: #383838;
}

.gdlr-core-promo-box-item .gdlr-core-promo-box-content-wrap {
  border-color: #e8e7e7;
}

.gdlr-core-post-slider-item .gdlr-core-post-slider-title a {
  color: #ffffff;
}

.gdlr-core-post-slider-item .gdlr-core-blog-info {
  color: #c5c5c5;
}

.gdlr-core-post-slider-item .gdlr-core-blog-info a {
  color: #c5c5c5;
}

.gdlr-core-skill-bar-item .gdlr-core-skill-bar-title, .gdlr-core-skill-bar-item .gdlr-core-skill-bar-right {
  color: #505050;
}

.gdlr-core-skill-bar-item .gdlr-core-skill-bar-icon {
  color: #616161;
}

.gdlr-core-skill-bar-item .gdlr-core-skill-bar-progress {
  background-color: #f3f3f3;
}

.gdlr-core-skill-bar-item .gdlr-core-skill-bar-filled {
  background-color: #e8b62d;
}

.gdlr-core-flexslider-nav .flex-direction-nav li a, .gdlr-core-flexslider.gdlr-core-nav-style-middle-large .flex-direction-nav li a {
  color: #a7a7a7;
  border-color: #a7a7a7;
}

.gdlr-core-flexslider-nav.gdlr-core-round-style li a {
  background-color: #f1f1f1;
}

.gdlr-core-flexslider .flex-control-nav li a {
  border-color: #8a8a8a;
}

.gdlr-core-flexslider .flex-control-nav li a.flex-active {
  background-color: #8a8a8a;
}

.gdlr-core-social-share-item a {
  color: #202020;
}

.gdlr-core-social-share-item .gdlr-core-divider {
  border-color: #e5e5e5;
}

.gdlr-core-social-share-item .gdlr-core-social-share-count {
  color: #202020;
}

.gdlr-core-stunning-text-item-caption {
  color: #747474;
}

.gdlr-core-stunning-text-item-title {
  color: #383838;
}

.gdlr-core-tab-item-title {
  color: #8d8d8d;
}

.gdlr-core-tab-style1-horizontal .gdlr-core-tab-item-title, .gdlr-core-tab-style1-vertical .gdlr-core-tab-item-title {
  background-color: #f7f7f7;
}

.gdlr-core-tab-item-title-wrap, .gdlr-core-tab-item-content-wrap, .gdlr-core-tab-item-title {
  border-color: #ebebeb;
}

.gdlr-core-tab-item-title-line {
  border-color: #e8b62d;
}

.gdlr-core-tab-item-title.gdlr-core-active {
  color: #464646;
}

.gdlr-core-tab-style1-horizontal .gdlr-core-tab-item-title.gdlr-core-active, .gdlr-core-tab-style1-vertical .gdlr-core-tab-item-title.gdlr-core-active {
  background-color: #ffffff;
}

table tr th {
  background-color: #e8b62d;
  color: #0c0c0c;
}

table tr:nth-child(odd) {
  background-color: #f9f9f9;
  color: #949494;
}

table tr:nth-child(even) {
  background-color: #f3f3f3;
  color: #949494;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-item-title {
  color: #232323;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-content {
  color: #707070;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-title, .gdlr-core-testimonial-item .gdlr-core-testimonial-position {
  color: #3b3b3b;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-quote {
  color: #282828;
}

.gdlr-core-title-item .gdlr-core-title-item-title {
  color: #383838;
}

.gdlr-core-title-item .gdlr-core-title-item-title a {
  color: #383838;
}

.gdlr-core-title-item .gdlr-core-title-item-caption {
  color: #747474;
}

.woocommerce .star-rating span {
  color: #e8b62d;
}

.single-product.woocommerce #review_form #respond p.stars a {
  color: #e8b62d;
}

.single-product.woocommerce div.product .product_meta {
  color: #e8b62d;
}

.single-product.woocommerce div.product .product_meta a {
  color: #e8b62d;
}

.woocommerce #respond input#submit.alt, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt, .woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button {
  background-color: #e8b62d;
}

html .woocommerce input.button, html .woocommerce span.onsale {
  background-color: #e8b62d;
}

span.woocommerce-Price-amount.amount {
  color: #e8b62d;
}

.woocommerce .price del, .gdlr-core-product-price del, del span.woocommerce-Price-amount.amount {
  color: #949494;
}

.woocommerce #respond input#submit.alt:hover, .woocommerce a.button.alt:hover, .woocommerce button.button.alt:hover, .woocommerce input.button.alt:hover, .woocommerce #respond input#submit:hover, .woocommerce a.button:hover, .woocommerce button.button:hover, .woocommerce input.button:hover {
  background-color: #e8b62d;
}

.woocommerce #respond input#submit.alt, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt, .woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button {
  color: #ffffff;
}

.woocommerce #respond input#submit.disabled, .woocommerce a.button.disabled, .woocommerce button.button.disabled, .woocommerce input.button.disabled {
  color: #ffffff;
}

.woocommerce #respond input#submit:disabled, .woocommerce a.button:disabled, .woocommerce button.button:disabled, .woocommerce input.button:disabled {
  color: #ffffff;
}

.woocommerce #respond input#submit:disabled[disabled], .woocommerce a.button:disabled[disabled], .woocommerce button.button:disabled[disabled], .woocommerce input.button:disabled[disabled] {
  color: #ffffff;
}

.woocommerce #respond input#submit.alt:hover, .woocommerce a.button.alt:hover, .woocommerce button.button.alt:hover, .woocommerce input.button.alt:hover, .woocommerce #respond input#submit:hover, .woocommerce a.button:hover, .woocommerce button.button:hover, .woocommerce input.button:hover {
  color: #ffffff;
}

.single-product.woocommerce div.product .quantity .qty, #add_payment_method #payment, .woocommerce-checkout #payment, .single-product.woocommerce #reviews #comments ol.commentlist li {
  background-color: #f3f3f3;
}

.gdlr-core-product-grid .gdlr-core-product-title a {
  color: #191919;
}

.gdlr-core-product-grid .gdlr-core-product-title a:hover {
  color: #434343;
}

.gdlr-core-product-thumbnail .gdlr-core-product-view-detail {
  color: #ffffff;
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.9);
}

.gdlr-core-product-thumbnail .gdlr-core-product-view-detail:hover {
  color: #ffffff;
}

.gdlr-core-product-thumbnail .added_to_cart, .gdlr-core-product-thumbnail .gdlr-core-product-add-to-cart {
  color: #ffffff;
}

.gdlr-core-product-thumbnail .added_to_cart:hover, .gdlr-core-product-thumbnail .gdlr-core-product-add-to-cart:hover {
  color: #ffffff;
}

.gdlr-core-product-thumbnail .added_to_cart, .gdlr-core-product-thumbnail .gdlr-core-product-add-to-cart {
  background-color: #e8b62d;
  background-color: rgba(232, 182, 45, 0.9);
}

.woocommerce .widget_price_filter .price_slider_wrapper .ui-widget-content {
  background-color: #d6d6d6;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-range {
  background-color: #eaeaea;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle {
  background-color: #cccccc;
}

/*--Custom --*/
.realfactory-top-bar-right {
  line-height: 32px;
}

.realfactory-logo-right-block .realfactory-logo-right-block-content {
  display: inline-block;
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .hide-tablet {
    display: none;
  }
}

.realfactory-mobile-header .realfactory-logo-inner {
  max-width: 160px;
}

.gdlr-core-page-builder-body [data-skin="Dark"] {
  color: #d8d8d8;
}

.gdlr-core-page-builder-body [data-skin="Dark"] h1, .gdlr-core-page-builder-body [data-skin="Dark"] h2, .gdlr-core-page-builder-body [data-skin="Dark"] h3, .gdlr-core-page-builder-body [data-skin="Dark"] h4, .gdlr-core-page-builder-body [data-skin="Dark"] h5, .gdlr-core-page-builder-body [data-skin="Dark"] h6 {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-title {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-title a {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-caption {
  color: #c4c4c4;
}

.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-caption a {
  color: #c4c4c4;
}

.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-content {
  color: #d8d8d8;
}

.gdlr-core-page-builder-body [data-skin="Dark"] i {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-icon {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-icon:before {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Dark"] .realfactory-widget ul li:before, .gdlr-core-page-builder-body [data-skin="Dark"] a, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-link {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Dark"] a:hover, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-link:hover {
  color: #e6b52f;
}

.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-e-background, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-flexslider-nav.gdlr-core-round-style li a {
  background-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-flexslider .flex-control-nav li a {
  border-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-flexslider .flex-control-nav li a.flex-active {
  background-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-button-color {
  color: #e6b52f;
}

.gdlr-core-page-builder-body [data-skin="Dark"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Dark"] input[type="submit"] {
  color: #e6b52f;
}

.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-pagination a {
  color: #e6b52f;
}

.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-button-color, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-pagination a {
  border-color: #e6b52f;
}

.gdlr-core-page-builder-body [data-skin="Call to action"] {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Call to action"] h1, .gdlr-core-page-builder-body [data-skin="Call to action"] h2, .gdlr-core-page-builder-body [data-skin="Call to action"] h3, .gdlr-core-page-builder-body [data-skin="Call to action"] h4, .gdlr-core-page-builder-body [data-skin="Call to action"] h5, .gdlr-core-page-builder-body [data-skin="Call to action"] h6 {
  color: #e6b52f;
}

.gdlr-core-page-builder-body [data-skin="Call to action"] .gdlr-core-skin-title {
  color: #e6b52f;
}

.gdlr-core-page-builder-body [data-skin="Call to action"] .gdlr-core-skin-title a {
  color: #e6b52f;
}

.gdlr-core-page-builder-body [data-skin="Call to action"] .gdlr-core-skin-content {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Call to action"] a, .gdlr-core-page-builder-body [data-skin="Call to action"] .gdlr-core-skin-link {
  color: #ebb727;
}

.gdlr-core-page-builder-body [data-skin="Call to action"] a:hover, .gdlr-core-page-builder-body [data-skin="Call to action"] .gdlr-core-skin-link:hover {
  color: #cc9322;
}

.gdlr-core-page-builder-body [data-skin="Call to action"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Call to action"] .gdlr-core-button-color {
  color: #e6b52f;
}

.gdlr-core-page-builder-body [data-skin="Call to action"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Call to action"] input[type="submit"] {
  color: #e6b52f;
}

.gdlr-core-page-builder-body [data-skin="Call to action"] .gdlr-core-pagination a {
  color: #e6b52f;
}

.gdlr-core-page-builder-body [data-skin="Call to action"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Call to action"] .gdlr-core-button-color {
  background-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Call to action"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Call to action"] input[type="submit"] {
  background-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Call to action"] .gdlr-core-pagination a {
  background-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Double Button"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Double Button"] .gdlr-core-button-color {
  color: #f8c12c;
}

.gdlr-core-page-builder-body [data-skin="Double Button"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Double Button"] input[type="submit"] {
  color: #f8c12c;
}

.gdlr-core-page-builder-body [data-skin="Double Button"] .gdlr-core-pagination a, .gdlr-core-page-builder-body [data-skin="Double Button"] .gdlr-core-button:hover {
  color: #f8c12c;
}

.gdlr-core-page-builder-body [data-skin="Double Button"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="Double Button"] .gdlr-core-button-color.gdlr-core-active {
  color: #f8c12c;
}

.gdlr-core-page-builder-body [data-skin="Double Button"] input[type="button"]:hover, .gdlr-core-page-builder-body [data-skin="Double Button"] input[type="submit"]:hover {
  color: #f8c12c;
}

.gdlr-core-page-builder-body [data-skin="Double Button"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="Double Button"] .gdlr-core-pagination a.gdlr-core-active {
  color: #f8c12c;
}

.gdlr-core-page-builder-body [data-skin="Double Button"] .gdlr-core-pagination span {
  color: #f8c12c;
}

.gdlr-core-page-builder-body [data-skin="Double Button"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Double Button"] .gdlr-core-button-color {
  background-color: #2d2d2d;
}

.gdlr-core-page-builder-body [data-skin="Double Button"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Double Button"] input[type="submit"] {
  background-color: #2d2d2d;
}

.gdlr-core-page-builder-body [data-skin="Double Button"] .gdlr-core-pagination a {
  background-color: #2d2d2d;
}

.gdlr-core-page-builder-body [data-skin="Double Button"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Double Button"] .gdlr-core-button-color, .gdlr-core-page-builder-body [data-skin="Double Button"] .gdlr-core-pagination a {
  border-color: #f8c12c;
}

button-border-hover-color .gdlr-core-page-builder-body [data-skin="White Text"] h1 {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] h2, .gdlr-core-page-builder-body [data-skin="White Text"] h3, .gdlr-core-page-builder-body [data-skin="White Text"] h4, .gdlr-core-page-builder-body [data-skin="White Text"] h5, .gdlr-core-page-builder-body [data-skin="White Text"] h6 {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-title {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-title a {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-caption {
  color: #e5e5e5;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-caption a {
  color: #e5e5e5;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-content, .gdlr-core-page-builder-body [data-skin="White Text"] i {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-icon {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-icon:before {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .realfactory-widget ul li:before, .gdlr-core-page-builder-body [data-skin="White Text"] a, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-link {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] a:hover, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-link:hover {
  color: #dddddd;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-divider {
  border-color: #f2f2f2;
  column-rule-color: #f2f2f2;
  -moz-column-rule-color: #f2f2f2;
  -webkit-column-rule-color: #f2f2f2;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider.gdlr-core-nav-style-middle-large .flex-direction-nav li a {
  border-color: #f2f2f2;
}

.gdlr-core-page-builder-body [data-skin="White Text"] *, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-border, .gdlr-core-page-builder-body [data-skin="White Text"] input:not([type="button"]):not([type="submit"]):not([type="reset"]) {
  border-color: #ededed;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-e-background, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider-nav.gdlr-core-round-style li a {
  background-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider .flex-control-nav li a {
  border-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider .flex-control-nav li a.flex-active {
  background-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-e-content, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider-nav.gdlr-core-round-style li a i {
  color: #3f3f3f;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button-color {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] input[type="button"], .gdlr-core-page-builder-body [data-skin="White Text"] input[type="submit"] {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination a, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button:hover {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button-color.gdlr-core-active {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] input[type="button"]:hover, .gdlr-core-page-builder-body [data-skin="White Text"] input[type="submit"]:hover {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination a.gdlr-core-active {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination span {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button-color {
  background-color: #191919;
}

.gdlr-core-page-builder-body [data-skin="White Text"] input[type="button"], .gdlr-core-page-builder-body [data-skin="White Text"] input[type="submit"] {
  background-color: #191919;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination a {
  background-color: #191919;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button:hover {
  background-color: #3a3a3a;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button-color.gdlr-core-active {
  background-color: #3a3a3a;
}

.gdlr-core-page-builder-body [data-skin="White Text"] input[type="button"]:hover, .gdlr-core-page-builder-body [data-skin="White Text"] input[type="submit"]:hover {
  background-color: #3a3a3a;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination a.gdlr-core-active {
  background-color: #3a3a3a;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination span {
  background-color: #3a3a3a;
}

.gdlr-core-page-builder-body [data-skin="Grey"] {
  color: #a3a3a3;
}

.gdlr-core-page-builder-body [data-skin="Grey"] h1, .gdlr-core-page-builder-body [data-skin="Grey"] h2, .gdlr-core-page-builder-body [data-skin="Grey"] h3, .gdlr-core-page-builder-body [data-skin="Grey"] h4, .gdlr-core-page-builder-body [data-skin="Grey"] h5, .gdlr-core-page-builder-body [data-skin="Grey"] h6 {
  color: #000000;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-title {
  color: #000000;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-title a {
  color: #000000;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-title a:hover {
  color: #e6b52f;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-caption {
  color: #8c8c8c;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-caption a {
  color: #8c8c8c;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-content {
  color: #a3a3a3;
}

.gdlr-core-page-builder-body [data-skin="Grey"] i {
  color: #000000;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-icon {
  color: #000000;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-icon:before {
  color: #000000;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .realfactory-widget ul li:before {
  color: #000000;
}

.gdlr-core-page-builder-body [data-skin="Grey"] a, .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-link {
  color: #232323;
}

.gdlr-core-page-builder-body [data-skin="Grey"] a:hover, .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-link:hover {
  color: #7c7c7c;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-button-color {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Grey"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Grey"] input[type="submit"] {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-pagination a, .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-button:hover {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-button-color.gdlr-core-active {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Grey"] input[type="button"]:hover, .gdlr-core-page-builder-body [data-skin="Grey"] input[type="submit"]:hover {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-pagination a.gdlr-core-active {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-pagination span {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-button-color {
  background-color: #232323;
}

.gdlr-core-page-builder-body [data-skin="Grey"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Grey"] input[type="submit"] {
  background-color: #232323;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-pagination a, .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-button:hover {
  background-color: #232323;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-button-color.gdlr-core-active {
  background-color: #232323;
}

.gdlr-core-page-builder-body [data-skin="Grey"] input[type="button"]:hover, .gdlr-core-page-builder-body [data-skin="Grey"] input[type="submit"]:hover {
  background-color: #232323;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-pagination a.gdlr-core-active {
  background-color: #232323;
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-pagination span {
  background-color: #232323;
}

.gdlr-core-page-builder-body [data-skin="About Counter"] {
  color: #f8c12c;
}

.gdlr-core-page-builder-body [data-skin="About Counter"] h1, .gdlr-core-page-builder-body [data-skin="About Counter"] h2, .gdlr-core-page-builder-body [data-skin="About Counter"] h3, .gdlr-core-page-builder-body [data-skin="About Counter"] h4, .gdlr-core-page-builder-body [data-skin="About Counter"] h5, .gdlr-core-page-builder-body [data-skin="About Counter"] h6 {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="About Counter"] .gdlr-core-skin-title {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="About Counter"] .gdlr-core-skin-title a {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="About Counter"] .gdlr-core-skin-caption {
  color: #f8c12c;
}

.gdlr-core-page-builder-body [data-skin="About Counter"] .gdlr-core-skin-caption a {
  color: #f8c12c;
}

.gdlr-core-page-builder-body [data-skin="About Counter"] .gdlr-core-skin-content {
  color: #f8c12c;
}

.gdlr-core-page-builder-body [data-skin="Portfolio Homepage"] h1, .gdlr-core-page-builder-body [data-skin="Portfolio Homepage"] h2, .gdlr-core-page-builder-body [data-skin="Portfolio Homepage"] h3, .gdlr-core-page-builder-body [data-skin="Portfolio Homepage"] h4, .gdlr-core-page-builder-body [data-skin="Portfolio Homepage"] h5, .gdlr-core-page-builder-body [data-skin="Portfolio Homepage"] h6 {
  color: #000000;
}

.gdlr-core-page-builder-body [data-skin="Portfolio Homepage"] .gdlr-core-skin-title {
  color: #000000;
}

.gdlr-core-page-builder-body [data-skin="Portfolio Homepage"] .gdlr-core-skin-title a {
  color: #000000;
}

.gdlr-core-page-builder-body [data-skin="Portfolio Homepage"] .gdlr-core-skin-caption {
  color: #222222;
}

.gdlr-core-page-builder-body [data-skin="Portfolio Homepage"] .gdlr-core-skin-caption a {
  color: #222222;
}

.gdlr-core-page-builder-body [data-skin="Portfolio Homepage"] a:hover, .gdlr-core-page-builder-body [data-skin="Portfolio Homepage"] .gdlr-core-skin-link:hover {
  color: #3d3d3d;
}

.gdlr-core-page-builder-body [data-skin="Yellow Link"] a, .gdlr-core-page-builder-body [data-skin="Yellow Link"] .gdlr-core-skin-link {
  color: #d8a419;
}

.gdlr-core-page-builder-body [data-skin="Yellow Link"] a:hover, .gdlr-core-page-builder-body [data-skin="Yellow Link"] .gdlr-core-skin-link:hover {
  color: #f2b91d;
}

.gdlr-core-page-builder-body [data-skin="Yellow Service"] {
  color: #000000;
}

.gdlr-core-page-builder-body [data-skin="Yellow Service"] h1, .gdlr-core-page-builder-body [data-skin="Yellow Service"] h2, .gdlr-core-page-builder-body [data-skin="Yellow Service"] h3, .gdlr-core-page-builder-body [data-skin="Yellow Service"] h4, .gdlr-core-page-builder-body [data-skin="Yellow Service"] h5, .gdlr-core-page-builder-body [data-skin="Yellow Service"] h6 {
  color: #000000;
}

.gdlr-core-page-builder-body [data-skin="Yellow Service"] .gdlr-core-skin-title {
  color: #000000;
}

.gdlr-core-page-builder-body [data-skin="Yellow Service"] .gdlr-core-skin-title a {
  color: #000000;
}

.gdlr-core-page-builder-body [data-skin="Yellow Service"] .gdlr-core-skin-content {
  color: #000000;
}

.gdlr-core-page-builder-body [data-skin="Yellow Service"] a, .gdlr-core-page-builder-body [data-skin="Yellow Service"] .gdlr-core-skin-link {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Yellow Service"] a:hover, .gdlr-core-page-builder-body [data-skin="Yellow Service"] .gdlr-core-skin-link:hover {
  color: #cecece;
}

.gdlr-core-page-builder-body [data-skin="Yellow Service"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Yellow Service"] .gdlr-core-button-color {
  color: #191919;
}

.gdlr-core-page-builder-body [data-skin="Yellow Service"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Yellow Service"] input[type="submit"] {
  color: #191919;
}

.gdlr-core-page-builder-body [data-skin="Yellow Service"] .gdlr-core-pagination a {
  color: #191919;
}

.gdlr-core-page-builder-body [data-skin="Yellow Service"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Yellow Service"] .gdlr-core-button-color {
  background-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Yellow Service"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Yellow Service"] input[type="submit"] {
  background-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Yellow Service"] .gdlr-core-pagination a {
  background-color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Call To action 2"] h1, .gdlr-core-page-builder-body [data-skin="Call To action 2"] h2, .gdlr-core-page-builder-body [data-skin="Call To action 2"] h3, .gdlr-core-page-builder-body [data-skin="Call To action 2"] h4, .gdlr-core-page-builder-body [data-skin="Call To action 2"] h5, .gdlr-core-page-builder-body [data-skin="Call To action 2"] h6 {
  color: #000000;
}

.gdlr-core-page-builder-body [data-skin="Call To action 2"] .gdlr-core-skin-title {
  color: #000000;
}

.gdlr-core-page-builder-body [data-skin="Call To action 2"] .gdlr-core-skin-title a {
  color: #000000;
}

.gdlr-core-page-builder-body [data-skin="Call To action 2"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Call To action 2"] .gdlr-core-button-color {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Call To action 2"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Call To action 2"] input[type="submit"] {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Call To action 2"] .gdlr-core-pagination a {
  color: #ffffff;
}

.gdlr-core-page-builder-body [data-skin="Call To action 2"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Call To action 2"] .gdlr-core-button-color {
  background-color: #1e1e1e;
}

.gdlr-core-page-builder-body [data-skin="Call To action 2"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Call To action 2"] input[type="submit"] {
  background-color: #1e1e1e;
}

.gdlr-core-page-builder-body [data-skin="Call To action 2"] .gdlr-core-pagination a {
  background-color: #1e1e1e;
}

.gdlr-core-page-builder-body [data-skin="Blog Homepage"] .gdlr-core-skin-e-background, .gdlr-core-page-builder-body [data-skin="Blog Homepage"] .gdlr-core-flexslider-nav.gdlr-core-round-style li a {
  background-color: #f7f7f7;
}

.gdlr-core-page-builder-body [data-skin="Blog Homepage"] .gdlr-core-flexslider .flex-control-nav li a {
  border-color: #f7f7f7;
}

.gdlr-core-page-builder-body [data-skin="Blog Homepage"] .gdlr-core-flexslider .flex-control-nav li a.flex-active {
  background-color: #f7f7f7;
}

.gdlr-core-page-builder-body [data-skin="Features"] h1, .gdlr-core-page-builder-body [data-skin="Features"] h2, .gdlr-core-page-builder-body [data-skin="Features"] h3, .gdlr-core-page-builder-body [data-skin="Features"] h4, .gdlr-core-page-builder-body [data-skin="Features"] h5, .gdlr-core-page-builder-body [data-skin="Features"] h6 {
  color: #595959;
}

.gdlr-core-page-builder-body [data-skin="Features"] .gdlr-core-skin-title {
  color: #595959;
}

.gdlr-core-page-builder-body [data-skin="Features"] .gdlr-core-skin-title a {
  color: #595959;
}

.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button.gdlr-core-button-gradient {
  background: -webkit-linear-gradient(#191919, #3a3a3a);
  background: -o-linear-gradient(#191919, #3a3a3a);
  background: -moz-linear-gradient(#191919, #3a3a3a);
  background: linear-gradient(#191919, #3a3a3a);
}

.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-button.gdlr-core-button-gradient {
  background: -webkit-linear-gradient(#232323, #232323);
  background: -o-linear-gradient(#232323, #232323);
  background: -moz-linear-gradient(#232323, #232323);
  background: linear-gradient(#232323, #232323);
}

.realfactory-item-mgb, .gdlr-core-item-mgb {
  margin-bottom: 30px;
}

.realfactory-sidebar-left .realfactory-sidebar-area {
  padding-right: 40px;
}

.realfactory-sidebar-right .realfactory-sidebar-area {
  padding-left: 40px;
}

.realfactory-sidebar-left .realfactory-sidebar-area {
  margin-right: 25px;
}

.realfactory-sidebar-right .realfactory-sidebar-area {
  margin-left: 25px;
}

/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.428571429;
  color: #333333;
  background-color: #fff;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #337ab7;
  text-decoration: none;
}

a:hover, a:focus {
  color: #23527c;
  text-decoration: underline;
}

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.img-rounded {
  border-radius: 6px;
}

.img-thumbnail {
  padding: 4px;
  line-height: 1.428571429;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.img-circle {
  border-radius: 50%;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

[role="button"] {
  cursor: pointer;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container:before, .container:after {
  display: table;
  content: " ";
}

.container:after {
  clear: both;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid:before, .container-fluid:after {
  display: table;
  content: " ";
}

.container-fluid:after {
  clear: both;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.row:before, .row:after {
  display: table;
  content: " ";
}

.row:after {
  clear: both;
}

.row-no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row-no-gutters [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-1 {
  width: 8.3333333333%;
}

.col-xs-2 {
  width: 16.6666666667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.3333333333%;
}

.col-xs-5 {
  width: 41.6666666667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.3333333333%;
}

.col-xs-8 {
  width: 66.6666666667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.3333333333%;
}

.col-xs-11 {
  width: 91.6666666667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.3333333333%;
}

.col-xs-pull-2 {
  right: 16.6666666667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.3333333333%;
}

.col-xs-pull-5 {
  right: 41.6666666667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.3333333333%;
}

.col-xs-pull-8 {
  right: 66.6666666667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.3333333333%;
}

.col-xs-pull-11 {
  right: 91.6666666667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.3333333333%;
}

.col-xs-push-2 {
  left: 16.6666666667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.3333333333%;
}

.col-xs-push-5 {
  left: 41.6666666667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.3333333333%;
}

.col-xs-push-8 {
  left: 66.6666666667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.3333333333%;
}

.col-xs-push-11 {
  left: 91.6666666667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 8.3333333333%;
}

.col-xs-offset-2 {
  margin-left: 16.6666666667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.3333333333%;
}

.col-xs-offset-5 {
  margin-left: 41.6666666667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.3333333333%;
}

.col-xs-offset-8 {
  margin-left: 66.6666666667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.3333333333%;
}

.col-xs-offset-11 {
  margin-left: 91.6666666667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-1 {
    width: 8.3333333333%;
  }
  .col-sm-2 {
    width: 16.6666666667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.3333333333%;
  }
  .col-sm-5 {
    width: 41.6666666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.3333333333%;
  }
  .col-sm-8 {
    width: 66.6666666667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.3333333333%;
  }
  .col-sm-11 {
    width: 91.6666666667%;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-pull-1 {
    right: 8.3333333333%;
  }
  .col-sm-pull-2 {
    right: 16.6666666667%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-4 {
    right: 33.3333333333%;
  }
  .col-sm-pull-5 {
    right: 41.6666666667%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-7 {
    right: 58.3333333333%;
  }
  .col-sm-pull-8 {
    right: 66.6666666667%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-10 {
    right: 83.3333333333%;
  }
  .col-sm-pull-11 {
    right: 91.6666666667%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-push-1 {
    left: 8.3333333333%;
  }
  .col-sm-push-2 {
    left: 16.6666666667%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-4 {
    left: 33.3333333333%;
  }
  .col-sm-push-5 {
    left: 41.6666666667%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-7 {
    left: 58.3333333333%;
  }
  .col-sm-push-8 {
    left: 66.6666666667%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-10 {
    left: 83.3333333333%;
  }
  .col-sm-push-11 {
    left: 91.6666666667%;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  .col-sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-1 {
    width: 8.3333333333%;
  }
  .col-md-2 {
    width: 16.6666666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.3333333333%;
  }
  .col-md-5 {
    width: 41.6666666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.3333333333%;
  }
  .col-md-8 {
    width: 66.6666666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.3333333333%;
  }
  .col-md-11 {
    width: 91.6666666667%;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-pull-1 {
    right: 8.3333333333%;
  }
  .col-md-pull-2 {
    right: 16.6666666667%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-4 {
    right: 33.3333333333%;
  }
  .col-md-pull-5 {
    right: 41.6666666667%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-7 {
    right: 58.3333333333%;
  }
  .col-md-pull-8 {
    right: 66.6666666667%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-10 {
    right: 83.3333333333%;
  }
  .col-md-pull-11 {
    right: 91.6666666667%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-push-1 {
    left: 8.3333333333%;
  }
  .col-md-push-2 {
    left: 16.6666666667%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-4 {
    left: 33.3333333333%;
  }
  .col-md-push-5 {
    left: 41.6666666667%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-7 {
    left: 58.3333333333%;
  }
  .col-md-push-8 {
    left: 66.6666666667%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-10 {
    left: 83.3333333333%;
  }
  .col-md-push-11 {
    left: 91.6666666667%;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
  .col-md-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-1 {
    width: 8.3333333333%;
  }
  .col-lg-2 {
    width: 16.6666666667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.3333333333%;
  }
  .col-lg-5 {
    width: 41.6666666667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.3333333333%;
  }
  .col-lg-8 {
    width: 66.6666666667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.3333333333%;
  }
  .col-lg-11 {
    width: 91.6666666667%;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-pull-1 {
    right: 8.3333333333%;
  }
  .col-lg-pull-2 {
    right: 16.6666666667%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-4 {
    right: 33.3333333333%;
  }
  .col-lg-pull-5 {
    right: 41.6666666667%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-7 {
    right: 58.3333333333%;
  }
  .col-lg-pull-8 {
    right: 66.6666666667%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-10 {
    right: 83.3333333333%;
  }
  .col-lg-pull-11 {
    right: 91.6666666667%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-push-1 {
    left: 8.3333333333%;
  }
  .col-lg-push-2 {
    left: 16.6666666667%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-4 {
    left: 33.3333333333%;
  }
  .col-lg-push-5 {
    left: 41.6666666667%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-7 {
    left: 58.3333333333%;
  }
  .col-lg-push-8 {
    left: 66.6666666667%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-10 {
    left: 83.3333333333%;
  }
  .col-lg-push-11 {
    left: 91.6666666667%;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  .col-lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

table {
  background-color: transparent;
}

table col[class*="col-"] {
  position: static;
  display: table-column;
  float: none;
}

table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  display: table-cell;
  float: none;
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777777;
  text-align: left;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.428571429;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.table > caption + thead > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > th,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.table > tbody + tbody {
  border-top: 2px solid #ddd;
}

.table .table {
  background-color: #fff;
}

.table-condensed > thead > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > tfoot > tr > td {
  padding: 5px;
}

.table-bordered {
  border: 1px solid #ddd;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
  border: 1px solid #ddd;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}

.table > thead > tr > td.active,
.table > thead > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}

.table > thead > tr > td.success,
.table > thead > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th {
  background-color: #dff0d8;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}

.table > thead > tr > td.info,
.table > thead > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th {
  background-color: #d9edf7;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}

.table > thead > tr > td.warning,
.table > thead > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}

.table > thead > tr > td.danger,
.table > thead > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th {
  background-color: #f2dede;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}

.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}

input[type="radio"][disabled], input[type="radio"].disabled,
fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
input[type="checkbox"].disabled,
fieldset[disabled]
input[type="checkbox"] {
  cursor: not-allowed;
}

input[type="file"] {
  display: block;
}

input[type="range"] {
  display: block;
  width: 100%;
}

select[multiple],
select[size] {
  height: auto;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555555;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control::-webkit-input-placeholder {
  color: #999;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control[disabled], .form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eeeeee;
  opacity: 1;
}

.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}

textarea.form-control {
  height: auto;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 34px;
  }
  input[type="date"].input-sm, .input-group-sm > input.form-control[type="date"],
  .input-group-sm > input.input-group-addon[type="date"],
  .input-group-sm > .input-group-btn > input.btn[type="date"],
  .input-group-sm input[type="date"],
  input[type="time"].input-sm,
  .input-group-sm > input.form-control[type="time"],
  .input-group-sm > input.input-group-addon[type="time"],
  .input-group-sm > .input-group-btn > input.btn[type="time"],
  .input-group-sm
  input[type="time"],
  input[type="datetime-local"].input-sm,
  .input-group-sm > input.form-control[type="datetime-local"],
  .input-group-sm > input.input-group-addon[type="datetime-local"],
  .input-group-sm > .input-group-btn > input.btn[type="datetime-local"],
  .input-group-sm
  input[type="datetime-local"],
  input[type="month"].input-sm,
  .input-group-sm > input.form-control[type="month"],
  .input-group-sm > input.input-group-addon[type="month"],
  .input-group-sm > .input-group-btn > input.btn[type="month"],
  .input-group-sm
  input[type="month"] {
    line-height: 30px;
  }
  input[type="date"].input-lg, .input-group-lg > input.form-control[type="date"],
  .input-group-lg > input.input-group-addon[type="date"],
  .input-group-lg > .input-group-btn > input.btn[type="date"],
  .input-group-lg input[type="date"],
  input[type="time"].input-lg,
  .input-group-lg > input.form-control[type="time"],
  .input-group-lg > input.input-group-addon[type="time"],
  .input-group-lg > .input-group-btn > input.btn[type="time"],
  .input-group-lg
  input[type="time"],
  input[type="datetime-local"].input-lg,
  .input-group-lg > input.form-control[type="datetime-local"],
  .input-group-lg > input.input-group-addon[type="datetime-local"],
  .input-group-lg > .input-group-btn > input.btn[type="datetime-local"],
  .input-group-lg
  input[type="datetime-local"],
  input[type="month"].input-lg,
  .input-group-lg > input.form-control[type="month"],
  .input-group-lg > input.input-group-addon[type="month"],
  .input-group-lg > .input-group-btn > input.btn[type="month"],
  .input-group-lg
  input[type="month"] {
    line-height: 46px;
  }
}

.form-group {
  margin-bottom: 15px;
}

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.radio.disabled label,
fieldset[disabled] .radio label,
.checkbox.disabled label,
fieldset[disabled]
.checkbox label {
  cursor: not-allowed;
}

.radio label,
.checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-top: 4px \9;
  margin-left: -20px;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px;
}

.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
}

.radio-inline.disabled,
fieldset[disabled] .radio-inline,
.checkbox-inline.disabled,
fieldset[disabled]
.checkbox-inline {
  cursor: not-allowed;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

.form-control-static {
  min-height: 34px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
}

.form-control-static.input-lg, .input-group-lg > .form-control-static.form-control,
.input-group-lg > .form-control-static.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-static.btn, .form-control-static.input-sm, .input-group-sm > .form-control-static.form-control,
.input-group-sm > .form-control-static.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-static.btn {
  padding-right: 0;
  padding-left: 0;
}

.input-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

select.input-sm, .input-group-sm > select.form-control,
.input-group-sm > select.input-group-addon,
.input-group-sm > .input-group-btn > select.btn {
  height: 30px;
  line-height: 30px;
}

textarea.input-sm, .input-group-sm > textarea.form-control,
.input-group-sm > textarea.input-group-addon,
.input-group-sm > .input-group-btn > textarea.btn,
select[multiple].input-sm,
.input-group-sm > select.form-control[multiple],
.input-group-sm > select.input-group-addon[multiple],
.input-group-sm > .input-group-btn > select.btn[multiple] {
  height: auto;
}

.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px;
}

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto;
}

.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.input-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

select.input-lg, .input-group-lg > select.form-control,
.input-group-lg > select.input-group-addon,
.input-group-lg > .input-group-btn > select.btn {
  height: 46px;
  line-height: 46px;
}

textarea.input-lg, .input-group-lg > textarea.form-control,
.input-group-lg > textarea.input-group-addon,
.input-group-lg > .input-group-btn > textarea.btn,
select[multiple].input-lg,
.input-group-lg > select.form-control[multiple],
.input-group-lg > select.input-group-addon[multiple],
.input-group-lg > .input-group-btn > select.btn[multiple] {
  height: auto;
}

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px;
}

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto;
}

.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}

.has-feedback {
  position: relative;
}

.has-feedback .form-control {
  padding-right: 42.5px;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.input-lg + .form-control-feedback, .input-group-lg > .form-control + .form-control-feedback, .input-group-lg > .input-group-addon + .form-control-feedback, .input-group-lg > .input-group-btn > .btn + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.input-sm + .form-control-feedback, .input-group-sm > .form-control + .form-control-feedback, .input-group-sm > .input-group-addon + .form-control-feedback, .input-group-sm > .input-group-btn > .btn + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #3c763d;
}

.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

.has-success .input-group-addon {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #3c763d;
}

.has-success .form-control-feedback {
  color: #3c763d;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #8a6d3b;
}

.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-warning .form-control:focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}

.has-warning .input-group-addon {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #8a6d3b;
}

.has-warning .form-control-feedback {
  color: #8a6d3b;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #a94442;
}

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}

.has-error .form-control-feedback {
  color: #a94442;
}

.has-feedback label ~ .form-control-feedback {
  top: 25px;
}

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-static {
    display: inline-block;
  }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn,
  .form-inline .input-group .form-control {
    width: auto;
  }
  .form-inline .input-group > .form-control {
    width: 100%;
  }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio label,
  .form-inline .checkbox label {
    padding-left: 0;
  }
  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0;
}

.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 27px;
}

.form-horizontal .form-group {
  margin-right: -15px;
  margin-left: -15px;
}

.form-horizontal .form-group:before, .form-horizontal .form-group:after {
  display: table;
  content: " ";
}

.form-horizontal .form-group:after {
  clear: both;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
  }
}

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px;
}

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px;
  }
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn:hover, .btn:focus, .btn.focus {
  color: #333;
  text-decoration: none;
}

.btn:active, .btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn.disabled, .btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-default:focus, .btn-default.focus {
  color: #333;
  background-color: #e6e5e5;
  border-color: #8c8c8c;
}

.btn-default:hover {
  color: #333;
  background-color: #e6e5e5;
  border-color: #adadad;
}

.btn-default:active, .btn-default.active,
.open > .btn-default.dropdown-toggle {
  color: #333;
  background-color: #e6e5e5;
  background-image: none;
  border-color: #adadad;
}

.btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus,
.open > .btn-default.dropdown-toggle:hover,
.open > .btn-default.dropdown-toggle:focus,
.open > .btn-default.dropdown-toggle.focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default .badge {
  color: #fff;
  background-color: #333;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}

.btn-primary:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.btn-primary:active, .btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #286090;
  background-image: none;
  border-color: #204d74;
}

.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus,
.open > .btn-primary.dropdown-toggle:hover,
.open > .btn-primary.dropdown-toggle:focus,
.open > .btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}

.btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-primary .badge {
  color: #337ab7;
  background-color: #fff;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #449d44;
  border-color: #255625;
}

.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.btn-success:active, .btn-success.active,
.open > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  background-image: none;
  border-color: #398439;
}

.btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus,
.open > .btn-success.dropdown-toggle:hover,
.open > .btn-success.dropdown-toggle:focus,
.open > .btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}

.btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #1b6d85;
}

.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}

.btn-info:active, .btn-info.active,
.open > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  background-image: none;
  border-color: #269abc;
}

.btn-info:active:hover, .btn-info:active:focus, .btn-info:active.focus, .btn-info.active:hover, .btn-info.active:focus, .btn-info.active.focus,
.open > .btn-info.dropdown-toggle:hover,
.open > .btn-info.dropdown-toggle:focus,
.open > .btn-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}

.btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d;
}

.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.btn-warning:active, .btn-warning.active,
.open > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  background-image: none;
  border-color: #d58512;
}

.btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:active.focus, .btn-warning.active:hover, .btn-warning.active:focus, .btn-warning.active.focus,
.open > .btn-warning.dropdown-toggle:hover,
.open > .btn-warning.dropdown-toggle:focus,
.open > .btn-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}

.btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #761c19;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.btn-danger:active, .btn-danger.active,
.open > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  background-image: none;
  border-color: #ac2925;
}

.btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:active.focus, .btn-danger.active:hover, .btn-danger.active:focus, .btn-danger.active.focus,
.open > .btn-danger.dropdown-toggle:hover,
.open > .btn-danger.dropdown-toggle:focus,
.open > .btn-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}

.btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}

.btn-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0;
}

.btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
  border-color: transparent;
}

.btn-link:hover, .btn-link:focus {
  color: #23527c;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link[disabled]:hover, .btn-link[disabled]:focus,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus {
  color: #777777;
  text-decoration: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-xs, .btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 5px;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.428571429;
  color: #333333;
  white-space: nowrap;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
  outline: 0;
}

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  color: #777777;
}

.dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}

.open > .dropdown-menu {
  display: block;
}

.open > a {
  outline: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.428571429;
  color: #777777;
  white-space: nowrap;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}

@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }
  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto;
  }
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  float: left;
}

.btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 2;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  margin-left: -5px;
}

.btn-toolbar:before, .btn-toolbar:after {
  display: table;
  content: " ";
}

.btn-toolbar:after {
  clear: both;
}

.btn-toolbar .btn,
.btn-toolbar .btn-group,
.btn-toolbar .input-group {
  float: left;
}

.btn-toolbar > .btn,
.btn-toolbar > .btn-group,
.btn-toolbar > .input-group {
  margin-left: 5px;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn-group {
  float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}

.btn-group > .btn + .dropdown-toggle {
  padding-right: 8px;
  padding-left: 8px;
}

.btn-group > .btn-lg + .dropdown-toggle, .btn-group-lg.btn-group > .btn + .dropdown-toggle {
  padding-right: 12px;
  padding-left: 12px;
}

.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-group.open .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn .caret {
  margin-left: 0;
}

.btn-lg .caret, .btn-group-lg > .btn .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0;
}

.dropup .btn-lg .caret, .dropup .btn-group-lg > .btn .caret {
  border-width: 0 5px 5px;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
}

.btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after {
  display: table;
  content: " ";
}

.btn-group-vertical > .btn-group:after {
  clear: both;
}

.btn-group-vertical > .btn-group > .btn {
  float: none;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}

.btn-group-justified > .btn,
.btn-group-justified > .btn-group {
  display: table-cell;
  float: none;
  width: 1%;
}

.btn-group-justified > .btn-group .btn {
  width: 100%;
}

.btn-group-justified > .btn-group .dropdown-menu {
  left: auto;
}

[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}

.input-group[class*="col-"] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

.input-group .form-control:focus {
  z-index: 3;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell;
}

.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555555;
  text-align: center;
  background-color: #eeeeee;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-group-addon.input-sm,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .input-group-addon.btn {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
}

.input-group-addon.input-lg,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .input-group-addon.btn {
  padding: 10px 16px;
  font-size: 18px;
  border-radius: 6px;
}

.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"] {
  margin-top: 0;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-addon:first-child {
  border-right: 0;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-addon:last-child {
  border-left: 0;
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}

.input-group-btn > .btn {
  position: relative;
}

.input-group-btn > .btn + .btn {
  margin-left: -1px;
}

.input-group-btn > .btn:hover, .input-group-btn > .btn:focus, .input-group-btn > .btn:active {
  z-index: 2;
}

.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
  margin-right: -1px;
}

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  z-index: 2;
  margin-left: -1px;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.pagination > li {
  display: inline;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.428571429;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pagination > li > a:hover, .pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
  z-index: 2;
  color: #23527c;
  background-color: #eeeeee;
  border-color: #ddd;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

.label:empty {
  display: none;
}

.btn .label {
  position: relative;
  top: -1px;
}

a.label:hover, a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.label-default {
  background-color: #777777;
}

.label-default[href]:hover, .label-default[href]:focus {
  background-color: #5e5e5e;
}

.label-primary {
  background-color: #337ab7;
}

.label-primary[href]:hover, .label-primary[href]:focus {
  background-color: #286090;
}

.label-success {
  background-color: #5cb85c;
}

.label-success[href]:hover, .label-success[href]:focus {
  background-color: #449d44;
}

.label-info {
  background-color: #5bc0de;
}

.label-info[href]:hover, .label-info[href]:focus {
  background-color: #31b0d5;
}

.label-warning {
  background-color: #f0ad4e;
}

.label-warning[href]:hover, .label-warning[href]:focus {
  background-color: #ec971f;
}

.label-danger {
  background-color: #d9534f;
}

.label-danger[href]:hover, .label-danger[href]:focus {
  background-color: #c9302c;
}

.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.428571429;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}

.thumbnail > img,
.thumbnail a > img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}

.thumbnail .caption {
  padding: 9px;
  color: #333333;
}

a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: #337ab7;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert h4 {
  margin-top: 0;
  color: inherit;
}

.alert .alert-link {
  font-weight: bold;
}

.alert > p,
.alert > ul {
  margin-bottom: 0;
}

.alert > p + p {
  margin-top: 5px;
}

.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
}

.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.alert-warning hr {
  border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-danger hr {
  border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
  color: #843534;
}

.media {
  margin-top: 15px;
}

.media:first-child {
  margin-top: 0;
}

.media,
.media-body {
  overflow: hidden;
  zoom: 1;
}

.media-body {
  width: 10000px;
}

.media-object {
  display: block;
}

.media-object.img-thumbnail {
  max-width: none;
}

.media-right,
.media > .pull-right {
  padding-left: 10px;
}

.media-left,
.media > .pull-left {
  padding-right: 10px;
}

.media-left,
.media-right,
.media-body {
  display: table-cell;
  vertical-align: top;
}

.media-middle {
  vertical-align: middle;
}

.media-bottom {
  vertical-align: bottom;
}

.media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.media-list {
  padding-left: 0;
  list-style: none;
}

.list-group {
  padding-left: 0;
  margin-bottom: 20px;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.list-group-item.disabled, .list-group-item.disabled:hover, .list-group-item.disabled:focus {
  color: #777777;
  cursor: not-allowed;
  background-color: #eeeeee;
}

.list-group-item.disabled .list-group-item-heading, .list-group-item.disabled:hover .list-group-item-heading, .list-group-item.disabled:focus .list-group-item-heading {
  color: inherit;
}

.list-group-item.disabled .list-group-item-text, .list-group-item.disabled:hover .list-group-item-text, .list-group-item.disabled:focus .list-group-item-text {
  color: #777777;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  z-index: 2;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}

.list-group-item.active .list-group-item-heading,
.list-group-item.active .list-group-item-heading > small,
.list-group-item.active .list-group-item-heading > .small, .list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading > small,
.list-group-item.active:hover .list-group-item-heading > .small, .list-group-item.active:focus .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading > small,
.list-group-item.active:focus .list-group-item-heading > .small {
  color: inherit;
}

.list-group-item.active .list-group-item-text, .list-group-item.active:hover .list-group-item-text, .list-group-item.active:focus .list-group-item-text {
  color: #c7ddef;
}

a.list-group-item,
button.list-group-item {
  color: #555;
}

a.list-group-item .list-group-item-heading,
button.list-group-item .list-group-item-heading {
  color: #333;
}

a.list-group-item:hover, a.list-group-item:focus,
button.list-group-item:hover,
button.list-group-item:focus {
  color: #555;
  text-decoration: none;
  background-color: #f5f5f5;
}

button.list-group-item {
  width: 100%;
  text-align: left;
}

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8;
}

a.list-group-item-success,
button.list-group-item-success {
  color: #3c763d;
}

a.list-group-item-success .list-group-item-heading,
button.list-group-item-success .list-group-item-heading {
  color: inherit;
}

a.list-group-item-success:hover, a.list-group-item-success:focus,
button.list-group-item-success:hover,
button.list-group-item-success:focus {
  color: #3c763d;
  background-color: #d0e9c6;
}

a.list-group-item-success.active, a.list-group-item-success.active:hover, a.list-group-item-success.active:focus,
button.list-group-item-success.active,
button.list-group-item-success.active:hover,
button.list-group-item-success.active:focus {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d;
}

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7;
}

a.list-group-item-info,
button.list-group-item-info {
  color: #31708f;
}

a.list-group-item-info .list-group-item-heading,
button.list-group-item-info .list-group-item-heading {
  color: inherit;
}

a.list-group-item-info:hover, a.list-group-item-info:focus,
button.list-group-item-info:hover,
button.list-group-item-info:focus {
  color: #31708f;
  background-color: #c4e3f3;
}

a.list-group-item-info.active, a.list-group-item-info.active:hover, a.list-group-item-info.active:focus,
button.list-group-item-info.active,
button.list-group-item-info.active:hover,
button.list-group-item-info.active:focus {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}

a.list-group-item-warning,
button.list-group-item-warning {
  color: #8a6d3b;
}

a.list-group-item-warning .list-group-item-heading,
button.list-group-item-warning .list-group-item-heading {
  color: inherit;
}

a.list-group-item-warning:hover, a.list-group-item-warning:focus,
button.list-group-item-warning:hover,
button.list-group-item-warning:focus {
  color: #8a6d3b;
  background-color: #faf2cc;
}

a.list-group-item-warning.active, a.list-group-item-warning.active:hover, a.list-group-item-warning.active:focus,
button.list-group-item-warning.active,
button.list-group-item-warning.active:hover,
button.list-group-item-warning.active:focus {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;
}

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede;
}

a.list-group-item-danger,
button.list-group-item-danger {
  color: #a94442;
}

a.list-group-item-danger .list-group-item-heading,
button.list-group-item-danger .list-group-item-heading {
  color: inherit;
}

a.list-group-item-danger:hover, a.list-group-item-danger:focus,
button.list-group-item-danger:hover,
button.list-group-item-danger:focus {
  color: #a94442;
  background-color: #ebcccc;
}

a.list-group-item-danger.active, a.list-group-item-danger.active:hover, a.list-group-item-danger.active:focus,
button.list-group-item-danger.active,
button.list-group-item-danger.active:hover,
button.list-group-item-danger.active:focus {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442;
}

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}

.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
}

.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.5;
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
}

.clearfix:before, .clearfix:after {
  display: table;
  content: " ";
}

.clearfix:after {
  clear: both;
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

@-ms-viewport {
  width: device-width;
}

.visible-xs {
  display: none !important;
}

.visible-sm {
  display: none !important;
}

.visible-md {
  display: none !important;
}

.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

/*******************
Max width
*******************/
.max-300 {
  max-width: 300px;
}

.max-400 {
  max-width: 400px;
}

.max-500 {
  max-width: 500px;
}

.max-600 {
  max-width: 600px;
}

.no-margin {
  margin: 0 !important;
}

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.no-padding {
  padding: 0 !important;
}

.section-padding {
  padding: 20px 0;
}

.section-padding-0 {
  padding: 0px 0 !important;
}

.effect-0 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-0 {
  margin-top: 0px !important;
}

.m-bot-0 {
  margin-bottom: 0px !important;
}

.m-rgt-0 {
  margin-right: 0px !important;
}

.m-lft-0 {
  margin-left: 0px !important;
}

.p-top-0 {
  padding-top: 0px !important;
}

.p-bot-0 {
  padding-bottom: 0px !important;
}

.p-rgt-0 {
  padding-right: 0px !important;
}

.p-lft-0 {
  padding-left: 0px !important;
}

.p-top-bot-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.p-lft-rgt-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.padding-0 {
  padding: 0px !important;
}

.margin-0 {
  margin: 0px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-0 {
    padding: 0px 0 !important;
  }
  .md-m-top-0 {
    margin-top: 0px !important;
  }
  .md-m-bot-0 {
    margin-bottom: 0px !important;
  }
  .md-m-rgt-0 {
    margin-right: 0px !important;
  }
  .md-m-lft-0 {
    margin-left: 0px !important;
  }
  .md-p-top-0 {
    padding-top: 0px !important;
  }
  .md-p-bot-0 {
    padding-bottom: 0px !important;
  }
  .md-p-rgt-0 {
    padding-right: 0px !important;
  }
  .md-p-lft-0 {
    padding-left: 0px !important;
  }
  .md-p-top-bot-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .md-m-top-bot-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .md-p-lft-rgt-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .md-padding-0 {
    padding: 0px !important;
  }
  .md-margin-0 {
    margin: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-0 {
    padding: 0px 0 !important;
  }
  .xs-m-top-0 {
    margin-top: 0px !important;
  }
  .xs-m-bot-0 {
    margin-bottom: 0px !important;
  }
  .xs-m-rgt-0 {
    margin-right: 0px !important;
  }
  .xs-m-lft-0 {
    margin-left: 0px !important;
  }
  .xs-p-top-0 {
    padding-top: 0px !important;
  }
  .xs-p-bot-0 {
    padding-bottom: 0px !important;
  }
  .xs-p-rgt-0 {
    padding-right: 0px !important;
  }
  .xs-p-lft-0 {
    padding-left: 0px !important;
  }
  .xs-p-top-bot-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .xs-p-lft-rgt-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .xs-padding-0 {
    padding: 0px !important;
  }
  .xs-margin-0 {
    margin: 0px !important;
  }
}

.section-padding-5 {
  padding: 5px 0 !important;
}

.effect-5 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-5 {
  margin-top: 5px !important;
}

.m-bot-5 {
  margin-bottom: 5px !important;
}

.m-rgt-5 {
  margin-right: 5px !important;
}

.m-lft-5 {
  margin-left: 5px !important;
}

.p-top-5 {
  padding-top: 5px !important;
}

.p-bot-5 {
  padding-bottom: 5px !important;
}

.p-rgt-5 {
  padding-right: 5px !important;
}

.p-lft-5 {
  padding-left: 5px !important;
}

.p-top-bot-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.p-lft-rgt-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.padding-5 {
  padding: 5px !important;
}

.margin-5 {
  margin: 5px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-5 {
    padding: 5px 0 !important;
  }
  .md-m-top-5 {
    margin-top: 5px !important;
  }
  .md-m-bot-5 {
    margin-bottom: 5px !important;
  }
  .md-m-rgt-5 {
    margin-right: 5px !important;
  }
  .md-m-lft-5 {
    margin-left: 5px !important;
  }
  .md-p-top-5 {
    padding-top: 5px !important;
  }
  .md-p-bot-5 {
    padding-bottom: 5px !important;
  }
  .md-p-rgt-5 {
    padding-right: 5px !important;
  }
  .md-p-lft-5 {
    padding-left: 5px !important;
  }
  .md-p-top-bot-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .md-m-top-bot-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .md-p-lft-rgt-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .md-padding-5 {
    padding: 5px !important;
  }
  .md-margin-5 {
    margin: 5px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-5 {
    padding: 5px 0 !important;
  }
  .xs-m-top-5 {
    margin-top: 5px !important;
  }
  .xs-m-bot-5 {
    margin-bottom: 5px !important;
  }
  .xs-m-rgt-5 {
    margin-right: 5px !important;
  }
  .xs-m-lft-5 {
    margin-left: 5px !important;
  }
  .xs-p-top-5 {
    padding-top: 5px !important;
  }
  .xs-p-bot-5 {
    padding-bottom: 5px !important;
  }
  .xs-p-rgt-5 {
    padding-right: 5px !important;
  }
  .xs-p-lft-5 {
    padding-left: 5px !important;
  }
  .xs-p-top-bot-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .xs-p-lft-rgt-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .xs-padding-5 {
    padding: 5px !important;
  }
  .xs-margin-5 {
    margin: 5px !important;
  }
}

.section-padding-10 {
  padding: 10px 0 !important;
}

.effect-10 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-10 {
  margin-top: 10px !important;
}

.m-bot-10 {
  margin-bottom: 10px !important;
}

.m-rgt-10 {
  margin-right: 10px !important;
}

.m-lft-10 {
  margin-left: 10px !important;
}

.p-top-10 {
  padding-top: 10px !important;
}

.p-bot-10 {
  padding-bottom: 10px !important;
}

.p-rgt-10 {
  padding-right: 10px !important;
}

.p-lft-10 {
  padding-left: 10px !important;
}

.p-top-bot-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.p-lft-rgt-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.padding-10 {
  padding: 10px !important;
}

.margin-10 {
  margin: 10px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-10 {
    padding: 10px 0 !important;
  }
  .md-m-top-10 {
    margin-top: 10px !important;
  }
  .md-m-bot-10 {
    margin-bottom: 10px !important;
  }
  .md-m-rgt-10 {
    margin-right: 10px !important;
  }
  .md-m-lft-10 {
    margin-left: 10px !important;
  }
  .md-p-top-10 {
    padding-top: 10px !important;
  }
  .md-p-bot-10 {
    padding-bottom: 10px !important;
  }
  .md-p-rgt-10 {
    padding-right: 10px !important;
  }
  .md-p-lft-10 {
    padding-left: 10px !important;
  }
  .md-p-top-bot-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .md-m-top-bot-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .md-p-lft-rgt-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .md-padding-10 {
    padding: 10px !important;
  }
  .md-margin-10 {
    margin: 10px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-10 {
    padding: 10px 0 !important;
  }
  .xs-m-top-10 {
    margin-top: 10px !important;
  }
  .xs-m-bot-10 {
    margin-bottom: 10px !important;
  }
  .xs-m-rgt-10 {
    margin-right: 10px !important;
  }
  .xs-m-lft-10 {
    margin-left: 10px !important;
  }
  .xs-p-top-10 {
    padding-top: 10px !important;
  }
  .xs-p-bot-10 {
    padding-bottom: 10px !important;
  }
  .xs-p-rgt-10 {
    padding-right: 10px !important;
  }
  .xs-p-lft-10 {
    padding-left: 10px !important;
  }
  .xs-p-top-bot-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .xs-p-lft-rgt-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .xs-padding-10 {
    padding: 10px !important;
  }
  .xs-margin-10 {
    margin: 10px !important;
  }
}

.section-padding-15 {
  padding: 15px 0 !important;
}

.effect-15 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-15 {
  margin-top: 15px !important;
}

.m-bot-15 {
  margin-bottom: 15px !important;
}

.m-rgt-15 {
  margin-right: 15px !important;
}

.m-lft-15 {
  margin-left: 15px !important;
}

.p-top-15 {
  padding-top: 15px !important;
}

.p-bot-15 {
  padding-bottom: 15px !important;
}

.p-rgt-15 {
  padding-right: 15px !important;
}

.p-lft-15 {
  padding-left: 15px !important;
}

.p-top-bot-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.p-lft-rgt-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.padding-15 {
  padding: 15px !important;
}

.margin-15 {
  margin: 15px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-15 {
    padding: 15px 0 !important;
  }
  .md-m-top-15 {
    margin-top: 15px !important;
  }
  .md-m-bot-15 {
    margin-bottom: 15px !important;
  }
  .md-m-rgt-15 {
    margin-right: 15px !important;
  }
  .md-m-lft-15 {
    margin-left: 15px !important;
  }
  .md-p-top-15 {
    padding-top: 15px !important;
  }
  .md-p-bot-15 {
    padding-bottom: 15px !important;
  }
  .md-p-rgt-15 {
    padding-right: 15px !important;
  }
  .md-p-lft-15 {
    padding-left: 15px !important;
  }
  .md-p-top-bot-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .md-m-top-bot-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .md-p-lft-rgt-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .md-padding-15 {
    padding: 15px !important;
  }
  .md-margin-15 {
    margin: 15px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-15 {
    padding: 15px 0 !important;
  }
  .xs-m-top-15 {
    margin-top: 15px !important;
  }
  .xs-m-bot-15 {
    margin-bottom: 15px !important;
  }
  .xs-m-rgt-15 {
    margin-right: 15px !important;
  }
  .xs-m-lft-15 {
    margin-left: 15px !important;
  }
  .xs-p-top-15 {
    padding-top: 15px !important;
  }
  .xs-p-bot-15 {
    padding-bottom: 15px !important;
  }
  .xs-p-rgt-15 {
    padding-right: 15px !important;
  }
  .xs-p-lft-15 {
    padding-left: 15px !important;
  }
  .xs-p-top-bot-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .xs-p-lft-rgt-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .xs-padding-15 {
    padding: 15px !important;
  }
  .xs-margin-15 {
    margin: 15px !important;
  }
}

.section-padding-20 {
  padding: 20px 0 !important;
}

.effect-20 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-20 {
  margin-top: 20px !important;
}

.m-bot-20 {
  margin-bottom: 20px !important;
}

.m-rgt-20 {
  margin-right: 20px !important;
}

.m-lft-20 {
  margin-left: 20px !important;
}

.p-top-20 {
  padding-top: 20px !important;
}

.p-bot-20 {
  padding-bottom: 20px !important;
}

.p-rgt-20 {
  padding-right: 20px !important;
}

.p-lft-20 {
  padding-left: 20px !important;
}

.p-top-bot-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.p-lft-rgt-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.padding-20 {
  padding: 20px !important;
}

.margin-20 {
  margin: 20px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-20 {
    padding: 20px 0 !important;
  }
  .md-m-top-20 {
    margin-top: 20px !important;
  }
  .md-m-bot-20 {
    margin-bottom: 20px !important;
  }
  .md-m-rgt-20 {
    margin-right: 20px !important;
  }
  .md-m-lft-20 {
    margin-left: 20px !important;
  }
  .md-p-top-20 {
    padding-top: 20px !important;
  }
  .md-p-bot-20 {
    padding-bottom: 20px !important;
  }
  .md-p-rgt-20 {
    padding-right: 20px !important;
  }
  .md-p-lft-20 {
    padding-left: 20px !important;
  }
  .md-p-top-bot-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .md-m-top-bot-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .md-p-lft-rgt-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .md-padding-20 {
    padding: 20px !important;
  }
  .md-margin-20 {
    margin: 20px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-20 {
    padding: 20px 0 !important;
  }
  .xs-m-top-20 {
    margin-top: 20px !important;
  }
  .xs-m-bot-20 {
    margin-bottom: 20px !important;
  }
  .xs-m-rgt-20 {
    margin-right: 20px !important;
  }
  .xs-m-lft-20 {
    margin-left: 20px !important;
  }
  .xs-p-top-20 {
    padding-top: 20px !important;
  }
  .xs-p-bot-20 {
    padding-bottom: 20px !important;
  }
  .xs-p-rgt-20 {
    padding-right: 20px !important;
  }
  .xs-p-lft-20 {
    padding-left: 20px !important;
  }
  .xs-p-top-bot-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .xs-p-lft-rgt-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .xs-padding-20 {
    padding: 20px !important;
  }
  .xs-margin-20 {
    margin: 20px !important;
  }
}

.section-padding-25 {
  padding: 25px 0 !important;
}

.effect-25 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-25 {
  margin-top: 25px !important;
}

.m-bot-25 {
  margin-bottom: 25px !important;
}

.m-rgt-25 {
  margin-right: 25px !important;
}

.m-lft-25 {
  margin-left: 25px !important;
}

.p-top-25 {
  padding-top: 25px !important;
}

.p-bot-25 {
  padding-bottom: 25px !important;
}

.p-rgt-25 {
  padding-right: 25px !important;
}

.p-lft-25 {
  padding-left: 25px !important;
}

.p-top-bot-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.p-lft-rgt-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.padding-25 {
  padding: 25px !important;
}

.margin-25 {
  margin: 25px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-25 {
    padding: 25px 0 !important;
  }
  .md-m-top-25 {
    margin-top: 25px !important;
  }
  .md-m-bot-25 {
    margin-bottom: 25px !important;
  }
  .md-m-rgt-25 {
    margin-right: 25px !important;
  }
  .md-m-lft-25 {
    margin-left: 25px !important;
  }
  .md-p-top-25 {
    padding-top: 25px !important;
  }
  .md-p-bot-25 {
    padding-bottom: 25px !important;
  }
  .md-p-rgt-25 {
    padding-right: 25px !important;
  }
  .md-p-lft-25 {
    padding-left: 25px !important;
  }
  .md-p-top-bot-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .md-m-top-bot-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .md-p-lft-rgt-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .md-padding-25 {
    padding: 25px !important;
  }
  .md-margin-25 {
    margin: 25px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-25 {
    padding: 25px 0 !important;
  }
  .xs-m-top-25 {
    margin-top: 25px !important;
  }
  .xs-m-bot-25 {
    margin-bottom: 25px !important;
  }
  .xs-m-rgt-25 {
    margin-right: 25px !important;
  }
  .xs-m-lft-25 {
    margin-left: 25px !important;
  }
  .xs-p-top-25 {
    padding-top: 25px !important;
  }
  .xs-p-bot-25 {
    padding-bottom: 25px !important;
  }
  .xs-p-rgt-25 {
    padding-right: 25px !important;
  }
  .xs-p-lft-25 {
    padding-left: 25px !important;
  }
  .xs-p-top-bot-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .xs-p-lft-rgt-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .xs-padding-25 {
    padding: 25px !important;
  }
  .xs-margin-25 {
    margin: 25px !important;
  }
}

.section-padding-30 {
  padding: 30px 0 !important;
}

.effect-30 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-30 {
  margin-top: 30px !important;
}

.m-bot-30 {
  margin-bottom: 30px !important;
}

.m-rgt-30 {
  margin-right: 30px !important;
}

.m-lft-30 {
  margin-left: 30px !important;
}

.p-top-30 {
  padding-top: 30px !important;
}

.p-bot-30 {
  padding-bottom: 30px !important;
}

.p-rgt-30 {
  padding-right: 30px !important;
}

.p-lft-30 {
  padding-left: 30px !important;
}

.p-top-bot-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.p-lft-rgt-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.padding-30 {
  padding: 30px !important;
}

.margin-30 {
  margin: 30px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-30 {
    padding: 30px 0 !important;
  }
  .md-m-top-30 {
    margin-top: 30px !important;
  }
  .md-m-bot-30 {
    margin-bottom: 30px !important;
  }
  .md-m-rgt-30 {
    margin-right: 30px !important;
  }
  .md-m-lft-30 {
    margin-left: 30px !important;
  }
  .md-p-top-30 {
    padding-top: 30px !important;
  }
  .md-p-bot-30 {
    padding-bottom: 30px !important;
  }
  .md-p-rgt-30 {
    padding-right: 30px !important;
  }
  .md-p-lft-30 {
    padding-left: 30px !important;
  }
  .md-p-top-bot-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .md-m-top-bot-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .md-p-lft-rgt-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .md-padding-30 {
    padding: 30px !important;
  }
  .md-margin-30 {
    margin: 30px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-30 {
    padding: 30px 0 !important;
  }
  .xs-m-top-30 {
    margin-top: 30px !important;
  }
  .xs-m-bot-30 {
    margin-bottom: 30px !important;
  }
  .xs-m-rgt-30 {
    margin-right: 30px !important;
  }
  .xs-m-lft-30 {
    margin-left: 30px !important;
  }
  .xs-p-top-30 {
    padding-top: 30px !important;
  }
  .xs-p-bot-30 {
    padding-bottom: 30px !important;
  }
  .xs-p-rgt-30 {
    padding-right: 30px !important;
  }
  .xs-p-lft-30 {
    padding-left: 30px !important;
  }
  .xs-p-top-bot-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .xs-p-lft-rgt-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .xs-padding-30 {
    padding: 30px !important;
  }
  .xs-margin-30 {
    margin: 30px !important;
  }
}

.section-padding-35 {
  padding: 35px 0 !important;
}

.effect-35 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-35 {
  margin-top: 35px !important;
}

.m-bot-35 {
  margin-bottom: 35px !important;
}

.m-rgt-35 {
  margin-right: 35px !important;
}

.m-lft-35 {
  margin-left: 35px !important;
}

.p-top-35 {
  padding-top: 35px !important;
}

.p-bot-35 {
  padding-bottom: 35px !important;
}

.p-rgt-35 {
  padding-right: 35px !important;
}

.p-lft-35 {
  padding-left: 35px !important;
}

.p-top-bot-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.p-lft-rgt-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.padding-35 {
  padding: 35px !important;
}

.margin-35 {
  margin: 35px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-35 {
    padding: 35px 0 !important;
  }
  .md-m-top-35 {
    margin-top: 35px !important;
  }
  .md-m-bot-35 {
    margin-bottom: 35px !important;
  }
  .md-m-rgt-35 {
    margin-right: 35px !important;
  }
  .md-m-lft-35 {
    margin-left: 35px !important;
  }
  .md-p-top-35 {
    padding-top: 35px !important;
  }
  .md-p-bot-35 {
    padding-bottom: 35px !important;
  }
  .md-p-rgt-35 {
    padding-right: 35px !important;
  }
  .md-p-lft-35 {
    padding-left: 35px !important;
  }
  .md-p-top-bot-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .md-m-top-bot-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .md-p-lft-rgt-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .md-padding-35 {
    padding: 35px !important;
  }
  .md-margin-35 {
    margin: 35px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-35 {
    padding: 35px 0 !important;
  }
  .xs-m-top-35 {
    margin-top: 35px !important;
  }
  .xs-m-bot-35 {
    margin-bottom: 35px !important;
  }
  .xs-m-rgt-35 {
    margin-right: 35px !important;
  }
  .xs-m-lft-35 {
    margin-left: 35px !important;
  }
  .xs-p-top-35 {
    padding-top: 35px !important;
  }
  .xs-p-bot-35 {
    padding-bottom: 35px !important;
  }
  .xs-p-rgt-35 {
    padding-right: 35px !important;
  }
  .xs-p-lft-35 {
    padding-left: 35px !important;
  }
  .xs-p-top-bot-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .xs-p-lft-rgt-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .xs-padding-35 {
    padding: 35px !important;
  }
  .xs-margin-35 {
    margin: 35px !important;
  }
}

.section-padding-40 {
  padding: 40px 0 !important;
}

.effect-40 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-40 {
  margin-top: 40px !important;
}

.m-bot-40 {
  margin-bottom: 40px !important;
}

.m-rgt-40 {
  margin-right: 40px !important;
}

.m-lft-40 {
  margin-left: 40px !important;
}

.p-top-40 {
  padding-top: 40px !important;
}

.p-bot-40 {
  padding-bottom: 40px !important;
}

.p-rgt-40 {
  padding-right: 40px !important;
}

.p-lft-40 {
  padding-left: 40px !important;
}

.p-top-bot-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.p-lft-rgt-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.padding-40 {
  padding: 40px !important;
}

.margin-40 {
  margin: 40px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-40 {
    padding: 40px 0 !important;
  }
  .md-m-top-40 {
    margin-top: 40px !important;
  }
  .md-m-bot-40 {
    margin-bottom: 40px !important;
  }
  .md-m-rgt-40 {
    margin-right: 40px !important;
  }
  .md-m-lft-40 {
    margin-left: 40px !important;
  }
  .md-p-top-40 {
    padding-top: 40px !important;
  }
  .md-p-bot-40 {
    padding-bottom: 40px !important;
  }
  .md-p-rgt-40 {
    padding-right: 40px !important;
  }
  .md-p-lft-40 {
    padding-left: 40px !important;
  }
  .md-p-top-bot-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .md-m-top-bot-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .md-p-lft-rgt-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .md-padding-40 {
    padding: 40px !important;
  }
  .md-margin-40 {
    margin: 40px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-40 {
    padding: 40px 0 !important;
  }
  .xs-m-top-40 {
    margin-top: 40px !important;
  }
  .xs-m-bot-40 {
    margin-bottom: 40px !important;
  }
  .xs-m-rgt-40 {
    margin-right: 40px !important;
  }
  .xs-m-lft-40 {
    margin-left: 40px !important;
  }
  .xs-p-top-40 {
    padding-top: 40px !important;
  }
  .xs-p-bot-40 {
    padding-bottom: 40px !important;
  }
  .xs-p-rgt-40 {
    padding-right: 40px !important;
  }
  .xs-p-lft-40 {
    padding-left: 40px !important;
  }
  .xs-p-top-bot-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .xs-p-lft-rgt-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .xs-padding-40 {
    padding: 40px !important;
  }
  .xs-margin-40 {
    margin: 40px !important;
  }
}

.section-padding-45 {
  padding: 45px 0 !important;
}

.effect-45 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-45 {
  margin-top: 45px !important;
}

.m-bot-45 {
  margin-bottom: 45px !important;
}

.m-rgt-45 {
  margin-right: 45px !important;
}

.m-lft-45 {
  margin-left: 45px !important;
}

.p-top-45 {
  padding-top: 45px !important;
}

.p-bot-45 {
  padding-bottom: 45px !important;
}

.p-rgt-45 {
  padding-right: 45px !important;
}

.p-lft-45 {
  padding-left: 45px !important;
}

.p-top-bot-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.p-lft-rgt-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.padding-45 {
  padding: 45px !important;
}

.margin-45 {
  margin: 45px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-45 {
    padding: 45px 0 !important;
  }
  .md-m-top-45 {
    margin-top: 45px !important;
  }
  .md-m-bot-45 {
    margin-bottom: 45px !important;
  }
  .md-m-rgt-45 {
    margin-right: 45px !important;
  }
  .md-m-lft-45 {
    margin-left: 45px !important;
  }
  .md-p-top-45 {
    padding-top: 45px !important;
  }
  .md-p-bot-45 {
    padding-bottom: 45px !important;
  }
  .md-p-rgt-45 {
    padding-right: 45px !important;
  }
  .md-p-lft-45 {
    padding-left: 45px !important;
  }
  .md-p-top-bot-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .md-m-top-bot-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .md-p-lft-rgt-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .md-padding-45 {
    padding: 45px !important;
  }
  .md-margin-45 {
    margin: 45px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-45 {
    padding: 45px 0 !important;
  }
  .xs-m-top-45 {
    margin-top: 45px !important;
  }
  .xs-m-bot-45 {
    margin-bottom: 45px !important;
  }
  .xs-m-rgt-45 {
    margin-right: 45px !important;
  }
  .xs-m-lft-45 {
    margin-left: 45px !important;
  }
  .xs-p-top-45 {
    padding-top: 45px !important;
  }
  .xs-p-bot-45 {
    padding-bottom: 45px !important;
  }
  .xs-p-rgt-45 {
    padding-right: 45px !important;
  }
  .xs-p-lft-45 {
    padding-left: 45px !important;
  }
  .xs-p-top-bot-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .xs-p-lft-rgt-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .xs-padding-45 {
    padding: 45px !important;
  }
  .xs-margin-45 {
    margin: 45px !important;
  }
}

.section-padding-50 {
  padding: 50px 0 !important;
}

.effect-50 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-50 {
  margin-top: 50px !important;
}

.m-bot-50 {
  margin-bottom: 50px !important;
}

.m-rgt-50 {
  margin-right: 50px !important;
}

.m-lft-50 {
  margin-left: 50px !important;
}

.p-top-50 {
  padding-top: 50px !important;
}

.p-bot-50 {
  padding-bottom: 50px !important;
}

.p-rgt-50 {
  padding-right: 50px !important;
}

.p-lft-50 {
  padding-left: 50px !important;
}

.p-top-bot-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.p-lft-rgt-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.padding-50 {
  padding: 50px !important;
}

.margin-50 {
  margin: 50px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-50 {
    padding: 50px 0 !important;
  }
  .md-m-top-50 {
    margin-top: 50px !important;
  }
  .md-m-bot-50 {
    margin-bottom: 50px !important;
  }
  .md-m-rgt-50 {
    margin-right: 50px !important;
  }
  .md-m-lft-50 {
    margin-left: 50px !important;
  }
  .md-p-top-50 {
    padding-top: 50px !important;
  }
  .md-p-bot-50 {
    padding-bottom: 50px !important;
  }
  .md-p-rgt-50 {
    padding-right: 50px !important;
  }
  .md-p-lft-50 {
    padding-left: 50px !important;
  }
  .md-p-top-bot-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .md-m-top-bot-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .md-p-lft-rgt-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .md-padding-50 {
    padding: 50px !important;
  }
  .md-margin-50 {
    margin: 50px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-50 {
    padding: 50px 0 !important;
  }
  .xs-m-top-50 {
    margin-top: 50px !important;
  }
  .xs-m-bot-50 {
    margin-bottom: 50px !important;
  }
  .xs-m-rgt-50 {
    margin-right: 50px !important;
  }
  .xs-m-lft-50 {
    margin-left: 50px !important;
  }
  .xs-p-top-50 {
    padding-top: 50px !important;
  }
  .xs-p-bot-50 {
    padding-bottom: 50px !important;
  }
  .xs-p-rgt-50 {
    padding-right: 50px !important;
  }
  .xs-p-lft-50 {
    padding-left: 50px !important;
  }
  .xs-p-top-bot-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .xs-p-lft-rgt-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .xs-padding-50 {
    padding: 50px !important;
  }
  .xs-margin-50 {
    margin: 50px !important;
  }
}

.section-padding-55 {
  padding: 55px 0 !important;
}

.effect-55 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-55 {
  margin-top: 55px !important;
}

.m-bot-55 {
  margin-bottom: 55px !important;
}

.m-rgt-55 {
  margin-right: 55px !important;
}

.m-lft-55 {
  margin-left: 55px !important;
}

.p-top-55 {
  padding-top: 55px !important;
}

.p-bot-55 {
  padding-bottom: 55px !important;
}

.p-rgt-55 {
  padding-right: 55px !important;
}

.p-lft-55 {
  padding-left: 55px !important;
}

.p-top-bot-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.p-lft-rgt-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.padding-55 {
  padding: 55px !important;
}

.margin-55 {
  margin: 55px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-55 {
    padding: 55px 0 !important;
  }
  .md-m-top-55 {
    margin-top: 55px !important;
  }
  .md-m-bot-55 {
    margin-bottom: 55px !important;
  }
  .md-m-rgt-55 {
    margin-right: 55px !important;
  }
  .md-m-lft-55 {
    margin-left: 55px !important;
  }
  .md-p-top-55 {
    padding-top: 55px !important;
  }
  .md-p-bot-55 {
    padding-bottom: 55px !important;
  }
  .md-p-rgt-55 {
    padding-right: 55px !important;
  }
  .md-p-lft-55 {
    padding-left: 55px !important;
  }
  .md-p-top-bot-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .md-m-top-bot-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .md-p-lft-rgt-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .md-padding-55 {
    padding: 55px !important;
  }
  .md-margin-55 {
    margin: 55px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-55 {
    padding: 55px 0 !important;
  }
  .xs-m-top-55 {
    margin-top: 55px !important;
  }
  .xs-m-bot-55 {
    margin-bottom: 55px !important;
  }
  .xs-m-rgt-55 {
    margin-right: 55px !important;
  }
  .xs-m-lft-55 {
    margin-left: 55px !important;
  }
  .xs-p-top-55 {
    padding-top: 55px !important;
  }
  .xs-p-bot-55 {
    padding-bottom: 55px !important;
  }
  .xs-p-rgt-55 {
    padding-right: 55px !important;
  }
  .xs-p-lft-55 {
    padding-left: 55px !important;
  }
  .xs-p-top-bot-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .xs-p-lft-rgt-55 {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .xs-padding-55 {
    padding: 55px !important;
  }
  .xs-margin-55 {
    margin: 55px !important;
  }
}

.section-padding-60 {
  padding: 60px 0 !important;
}

.effect-60 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-60 {
  margin-top: 60px !important;
}

.m-bot-60 {
  margin-bottom: 60px !important;
}

.m-rgt-60 {
  margin-right: 60px !important;
}

.m-lft-60 {
  margin-left: 60px !important;
}

.p-top-60 {
  padding-top: 60px !important;
}

.p-bot-60 {
  padding-bottom: 60px !important;
}

.p-rgt-60 {
  padding-right: 60px !important;
}

.p-lft-60 {
  padding-left: 60px !important;
}

.p-top-bot-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.p-lft-rgt-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.padding-60 {
  padding: 60px !important;
}

.margin-60 {
  margin: 60px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-60 {
    padding: 60px 0 !important;
  }
  .md-m-top-60 {
    margin-top: 60px !important;
  }
  .md-m-bot-60 {
    margin-bottom: 60px !important;
  }
  .md-m-rgt-60 {
    margin-right: 60px !important;
  }
  .md-m-lft-60 {
    margin-left: 60px !important;
  }
  .md-p-top-60 {
    padding-top: 60px !important;
  }
  .md-p-bot-60 {
    padding-bottom: 60px !important;
  }
  .md-p-rgt-60 {
    padding-right: 60px !important;
  }
  .md-p-lft-60 {
    padding-left: 60px !important;
  }
  .md-p-top-bot-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .md-m-top-bot-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .md-p-lft-rgt-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .md-padding-60 {
    padding: 60px !important;
  }
  .md-margin-60 {
    margin: 60px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-60 {
    padding: 60px 0 !important;
  }
  .xs-m-top-60 {
    margin-top: 60px !important;
  }
  .xs-m-bot-60 {
    margin-bottom: 60px !important;
  }
  .xs-m-rgt-60 {
    margin-right: 60px !important;
  }
  .xs-m-lft-60 {
    margin-left: 60px !important;
  }
  .xs-p-top-60 {
    padding-top: 60px !important;
  }
  .xs-p-bot-60 {
    padding-bottom: 60px !important;
  }
  .xs-p-rgt-60 {
    padding-right: 60px !important;
  }
  .xs-p-lft-60 {
    padding-left: 60px !important;
  }
  .xs-p-top-bot-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .xs-p-lft-rgt-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .xs-padding-60 {
    padding: 60px !important;
  }
  .xs-margin-60 {
    margin: 60px !important;
  }
}

.section-padding-65 {
  padding: 65px 0 !important;
}

.effect-65 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-65 {
  margin-top: 65px !important;
}

.m-bot-65 {
  margin-bottom: 65px !important;
}

.m-rgt-65 {
  margin-right: 65px !important;
}

.m-lft-65 {
  margin-left: 65px !important;
}

.p-top-65 {
  padding-top: 65px !important;
}

.p-bot-65 {
  padding-bottom: 65px !important;
}

.p-rgt-65 {
  padding-right: 65px !important;
}

.p-lft-65 {
  padding-left: 65px !important;
}

.p-top-bot-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.p-lft-rgt-65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.padding-65 {
  padding: 65px !important;
}

.margin-65 {
  margin: 65px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-65 {
    padding: 65px 0 !important;
  }
  .md-m-top-65 {
    margin-top: 65px !important;
  }
  .md-m-bot-65 {
    margin-bottom: 65px !important;
  }
  .md-m-rgt-65 {
    margin-right: 65px !important;
  }
  .md-m-lft-65 {
    margin-left: 65px !important;
  }
  .md-p-top-65 {
    padding-top: 65px !important;
  }
  .md-p-bot-65 {
    padding-bottom: 65px !important;
  }
  .md-p-rgt-65 {
    padding-right: 65px !important;
  }
  .md-p-lft-65 {
    padding-left: 65px !important;
  }
  .md-p-top-bot-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .md-m-top-bot-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .md-p-lft-rgt-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .md-padding-65 {
    padding: 65px !important;
  }
  .md-margin-65 {
    margin: 65px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-65 {
    padding: 65px 0 !important;
  }
  .xs-m-top-65 {
    margin-top: 65px !important;
  }
  .xs-m-bot-65 {
    margin-bottom: 65px !important;
  }
  .xs-m-rgt-65 {
    margin-right: 65px !important;
  }
  .xs-m-lft-65 {
    margin-left: 65px !important;
  }
  .xs-p-top-65 {
    padding-top: 65px !important;
  }
  .xs-p-bot-65 {
    padding-bottom: 65px !important;
  }
  .xs-p-rgt-65 {
    padding-right: 65px !important;
  }
  .xs-p-lft-65 {
    padding-left: 65px !important;
  }
  .xs-p-top-bot-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .xs-p-lft-rgt-65 {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .xs-padding-65 {
    padding: 65px !important;
  }
  .xs-margin-65 {
    margin: 65px !important;
  }
}

.section-padding-70 {
  padding: 70px 0 !important;
}

.effect-70 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-70 {
  margin-top: 70px !important;
}

.m-bot-70 {
  margin-bottom: 70px !important;
}

.m-rgt-70 {
  margin-right: 70px !important;
}

.m-lft-70 {
  margin-left: 70px !important;
}

.p-top-70 {
  padding-top: 70px !important;
}

.p-bot-70 {
  padding-bottom: 70px !important;
}

.p-rgt-70 {
  padding-right: 70px !important;
}

.p-lft-70 {
  padding-left: 70px !important;
}

.p-top-bot-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.p-lft-rgt-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.padding-70 {
  padding: 70px !important;
}

.margin-70 {
  margin: 70px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-70 {
    padding: 70px 0 !important;
  }
  .md-m-top-70 {
    margin-top: 70px !important;
  }
  .md-m-bot-70 {
    margin-bottom: 70px !important;
  }
  .md-m-rgt-70 {
    margin-right: 70px !important;
  }
  .md-m-lft-70 {
    margin-left: 70px !important;
  }
  .md-p-top-70 {
    padding-top: 70px !important;
  }
  .md-p-bot-70 {
    padding-bottom: 70px !important;
  }
  .md-p-rgt-70 {
    padding-right: 70px !important;
  }
  .md-p-lft-70 {
    padding-left: 70px !important;
  }
  .md-p-top-bot-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .md-m-top-bot-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .md-p-lft-rgt-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .md-padding-70 {
    padding: 70px !important;
  }
  .md-margin-70 {
    margin: 70px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-70 {
    padding: 70px 0 !important;
  }
  .xs-m-top-70 {
    margin-top: 70px !important;
  }
  .xs-m-bot-70 {
    margin-bottom: 70px !important;
  }
  .xs-m-rgt-70 {
    margin-right: 70px !important;
  }
  .xs-m-lft-70 {
    margin-left: 70px !important;
  }
  .xs-p-top-70 {
    padding-top: 70px !important;
  }
  .xs-p-bot-70 {
    padding-bottom: 70px !important;
  }
  .xs-p-rgt-70 {
    padding-right: 70px !important;
  }
  .xs-p-lft-70 {
    padding-left: 70px !important;
  }
  .xs-p-top-bot-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .xs-p-lft-rgt-70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .xs-padding-70 {
    padding: 70px !important;
  }
  .xs-margin-70 {
    margin: 70px !important;
  }
}

.section-padding-75 {
  padding: 75px 0 !important;
}

.effect-75 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-75 {
  margin-top: 75px !important;
}

.m-bot-75 {
  margin-bottom: 75px !important;
}

.m-rgt-75 {
  margin-right: 75px !important;
}

.m-lft-75 {
  margin-left: 75px !important;
}

.p-top-75 {
  padding-top: 75px !important;
}

.p-bot-75 {
  padding-bottom: 75px !important;
}

.p-rgt-75 {
  padding-right: 75px !important;
}

.p-lft-75 {
  padding-left: 75px !important;
}

.p-top-bot-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.p-lft-rgt-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.padding-75 {
  padding: 75px !important;
}

.margin-75 {
  margin: 75px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-75 {
    padding: 75px 0 !important;
  }
  .md-m-top-75 {
    margin-top: 75px !important;
  }
  .md-m-bot-75 {
    margin-bottom: 75px !important;
  }
  .md-m-rgt-75 {
    margin-right: 75px !important;
  }
  .md-m-lft-75 {
    margin-left: 75px !important;
  }
  .md-p-top-75 {
    padding-top: 75px !important;
  }
  .md-p-bot-75 {
    padding-bottom: 75px !important;
  }
  .md-p-rgt-75 {
    padding-right: 75px !important;
  }
  .md-p-lft-75 {
    padding-left: 75px !important;
  }
  .md-p-top-bot-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .md-m-top-bot-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .md-p-lft-rgt-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .md-padding-75 {
    padding: 75px !important;
  }
  .md-margin-75 {
    margin: 75px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-75 {
    padding: 75px 0 !important;
  }
  .xs-m-top-75 {
    margin-top: 75px !important;
  }
  .xs-m-bot-75 {
    margin-bottom: 75px !important;
  }
  .xs-m-rgt-75 {
    margin-right: 75px !important;
  }
  .xs-m-lft-75 {
    margin-left: 75px !important;
  }
  .xs-p-top-75 {
    padding-top: 75px !important;
  }
  .xs-p-bot-75 {
    padding-bottom: 75px !important;
  }
  .xs-p-rgt-75 {
    padding-right: 75px !important;
  }
  .xs-p-lft-75 {
    padding-left: 75px !important;
  }
  .xs-p-top-bot-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .xs-p-lft-rgt-75 {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .xs-padding-75 {
    padding: 75px !important;
  }
  .xs-margin-75 {
    margin: 75px !important;
  }
}

.section-padding-80 {
  padding: 80px 0 !important;
}

.effect-80 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-80 {
  margin-top: 80px !important;
}

.m-bot-80 {
  margin-bottom: 80px !important;
}

.m-rgt-80 {
  margin-right: 80px !important;
}

.m-lft-80 {
  margin-left: 80px !important;
}

.p-top-80 {
  padding-top: 80px !important;
}

.p-bot-80 {
  padding-bottom: 80px !important;
}

.p-rgt-80 {
  padding-right: 80px !important;
}

.p-lft-80 {
  padding-left: 80px !important;
}

.p-top-bot-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.p-lft-rgt-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.padding-80 {
  padding: 80px !important;
}

.margin-80 {
  margin: 80px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-80 {
    padding: 80px 0 !important;
  }
  .md-m-top-80 {
    margin-top: 80px !important;
  }
  .md-m-bot-80 {
    margin-bottom: 80px !important;
  }
  .md-m-rgt-80 {
    margin-right: 80px !important;
  }
  .md-m-lft-80 {
    margin-left: 80px !important;
  }
  .md-p-top-80 {
    padding-top: 80px !important;
  }
  .md-p-bot-80 {
    padding-bottom: 80px !important;
  }
  .md-p-rgt-80 {
    padding-right: 80px !important;
  }
  .md-p-lft-80 {
    padding-left: 80px !important;
  }
  .md-p-top-bot-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .md-m-top-bot-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .md-p-lft-rgt-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .md-padding-80 {
    padding: 80px !important;
  }
  .md-margin-80 {
    margin: 80px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-80 {
    padding: 80px 0 !important;
  }
  .xs-m-top-80 {
    margin-top: 80px !important;
  }
  .xs-m-bot-80 {
    margin-bottom: 80px !important;
  }
  .xs-m-rgt-80 {
    margin-right: 80px !important;
  }
  .xs-m-lft-80 {
    margin-left: 80px !important;
  }
  .xs-p-top-80 {
    padding-top: 80px !important;
  }
  .xs-p-bot-80 {
    padding-bottom: 80px !important;
  }
  .xs-p-rgt-80 {
    padding-right: 80px !important;
  }
  .xs-p-lft-80 {
    padding-left: 80px !important;
  }
  .xs-p-top-bot-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .xs-p-lft-rgt-80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .xs-padding-80 {
    padding: 80px !important;
  }
  .xs-margin-80 {
    margin: 80px !important;
  }
}

.section-padding-85 {
  padding: 85px 0 !important;
}

.effect-85 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-85 {
  margin-top: 85px !important;
}

.m-bot-85 {
  margin-bottom: 85px !important;
}

.m-rgt-85 {
  margin-right: 85px !important;
}

.m-lft-85 {
  margin-left: 85px !important;
}

.p-top-85 {
  padding-top: 85px !important;
}

.p-bot-85 {
  padding-bottom: 85px !important;
}

.p-rgt-85 {
  padding-right: 85px !important;
}

.p-lft-85 {
  padding-left: 85px !important;
}

.p-top-bot-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.p-lft-rgt-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.padding-85 {
  padding: 85px !important;
}

.margin-85 {
  margin: 85px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-85 {
    padding: 85px 0 !important;
  }
  .md-m-top-85 {
    margin-top: 85px !important;
  }
  .md-m-bot-85 {
    margin-bottom: 85px !important;
  }
  .md-m-rgt-85 {
    margin-right: 85px !important;
  }
  .md-m-lft-85 {
    margin-left: 85px !important;
  }
  .md-p-top-85 {
    padding-top: 85px !important;
  }
  .md-p-bot-85 {
    padding-bottom: 85px !important;
  }
  .md-p-rgt-85 {
    padding-right: 85px !important;
  }
  .md-p-lft-85 {
    padding-left: 85px !important;
  }
  .md-p-top-bot-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .md-m-top-bot-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .md-p-lft-rgt-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .md-padding-85 {
    padding: 85px !important;
  }
  .md-margin-85 {
    margin: 85px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-85 {
    padding: 85px 0 !important;
  }
  .xs-m-top-85 {
    margin-top: 85px !important;
  }
  .xs-m-bot-85 {
    margin-bottom: 85px !important;
  }
  .xs-m-rgt-85 {
    margin-right: 85px !important;
  }
  .xs-m-lft-85 {
    margin-left: 85px !important;
  }
  .xs-p-top-85 {
    padding-top: 85px !important;
  }
  .xs-p-bot-85 {
    padding-bottom: 85px !important;
  }
  .xs-p-rgt-85 {
    padding-right: 85px !important;
  }
  .xs-p-lft-85 {
    padding-left: 85px !important;
  }
  .xs-p-top-bot-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .xs-p-lft-rgt-85 {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .xs-padding-85 {
    padding: 85px !important;
  }
  .xs-margin-85 {
    margin: 85px !important;
  }
}

.section-padding-90 {
  padding: 90px 0 !important;
}

.effect-90 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-90 {
  margin-top: 90px !important;
}

.m-bot-90 {
  margin-bottom: 90px !important;
}

.m-rgt-90 {
  margin-right: 90px !important;
}

.m-lft-90 {
  margin-left: 90px !important;
}

.p-top-90 {
  padding-top: 90px !important;
}

.p-bot-90 {
  padding-bottom: 90px !important;
}

.p-rgt-90 {
  padding-right: 90px !important;
}

.p-lft-90 {
  padding-left: 90px !important;
}

.p-top-bot-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.p-lft-rgt-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.padding-90 {
  padding: 90px !important;
}

.margin-90 {
  margin: 90px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-90 {
    padding: 90px 0 !important;
  }
  .md-m-top-90 {
    margin-top: 90px !important;
  }
  .md-m-bot-90 {
    margin-bottom: 90px !important;
  }
  .md-m-rgt-90 {
    margin-right: 90px !important;
  }
  .md-m-lft-90 {
    margin-left: 90px !important;
  }
  .md-p-top-90 {
    padding-top: 90px !important;
  }
  .md-p-bot-90 {
    padding-bottom: 90px !important;
  }
  .md-p-rgt-90 {
    padding-right: 90px !important;
  }
  .md-p-lft-90 {
    padding-left: 90px !important;
  }
  .md-p-top-bot-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .md-m-top-bot-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .md-p-lft-rgt-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .md-padding-90 {
    padding: 90px !important;
  }
  .md-margin-90 {
    margin: 90px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-90 {
    padding: 90px 0 !important;
  }
  .xs-m-top-90 {
    margin-top: 90px !important;
  }
  .xs-m-bot-90 {
    margin-bottom: 90px !important;
  }
  .xs-m-rgt-90 {
    margin-right: 90px !important;
  }
  .xs-m-lft-90 {
    margin-left: 90px !important;
  }
  .xs-p-top-90 {
    padding-top: 90px !important;
  }
  .xs-p-bot-90 {
    padding-bottom: 90px !important;
  }
  .xs-p-rgt-90 {
    padding-right: 90px !important;
  }
  .xs-p-lft-90 {
    padding-left: 90px !important;
  }
  .xs-p-top-bot-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .xs-p-lft-rgt-90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .xs-padding-90 {
    padding: 90px !important;
  }
  .xs-margin-90 {
    margin: 90px !important;
  }
}

.section-padding-95 {
  padding: 95px 0 !important;
}

.effect-95 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-95 {
  margin-top: 95px !important;
}

.m-bot-95 {
  margin-bottom: 95px !important;
}

.m-rgt-95 {
  margin-right: 95px !important;
}

.m-lft-95 {
  margin-left: 95px !important;
}

.p-top-95 {
  padding-top: 95px !important;
}

.p-bot-95 {
  padding-bottom: 95px !important;
}

.p-rgt-95 {
  padding-right: 95px !important;
}

.p-lft-95 {
  padding-left: 95px !important;
}

.p-top-bot-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.p-lft-rgt-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.padding-95 {
  padding: 95px !important;
}

.margin-95 {
  margin: 95px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-95 {
    padding: 95px 0 !important;
  }
  .md-m-top-95 {
    margin-top: 95px !important;
  }
  .md-m-bot-95 {
    margin-bottom: 95px !important;
  }
  .md-m-rgt-95 {
    margin-right: 95px !important;
  }
  .md-m-lft-95 {
    margin-left: 95px !important;
  }
  .md-p-top-95 {
    padding-top: 95px !important;
  }
  .md-p-bot-95 {
    padding-bottom: 95px !important;
  }
  .md-p-rgt-95 {
    padding-right: 95px !important;
  }
  .md-p-lft-95 {
    padding-left: 95px !important;
  }
  .md-p-top-bot-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .md-m-top-bot-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .md-p-lft-rgt-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .md-padding-95 {
    padding: 95px !important;
  }
  .md-margin-95 {
    margin: 95px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-95 {
    padding: 95px 0 !important;
  }
  .xs-m-top-95 {
    margin-top: 95px !important;
  }
  .xs-m-bot-95 {
    margin-bottom: 95px !important;
  }
  .xs-m-rgt-95 {
    margin-right: 95px !important;
  }
  .xs-m-lft-95 {
    margin-left: 95px !important;
  }
  .xs-p-top-95 {
    padding-top: 95px !important;
  }
  .xs-p-bot-95 {
    padding-bottom: 95px !important;
  }
  .xs-p-rgt-95 {
    padding-right: 95px !important;
  }
  .xs-p-lft-95 {
    padding-left: 95px !important;
  }
  .xs-p-top-bot-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .xs-p-lft-rgt-95 {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .xs-padding-95 {
    padding: 95px !important;
  }
  .xs-margin-95 {
    margin: 95px !important;
  }
}

.section-padding-100 {
  padding: 100px 0 !important;
}

.effect-100 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-100 {
  margin-top: 100px !important;
}

.m-bot-100 {
  margin-bottom: 100px !important;
}

.m-rgt-100 {
  margin-right: 100px !important;
}

.m-lft-100 {
  margin-left: 100px !important;
}

.p-top-100 {
  padding-top: 100px !important;
}

.p-bot-100 {
  padding-bottom: 100px !important;
}

.p-rgt-100 {
  padding-right: 100px !important;
}

.p-lft-100 {
  padding-left: 100px !important;
}

.p-top-bot-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.p-lft-rgt-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.padding-100 {
  padding: 100px !important;
}

.margin-100 {
  margin: 100px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-100 {
    padding: 100px 0 !important;
  }
  .md-m-top-100 {
    margin-top: 100px !important;
  }
  .md-m-bot-100 {
    margin-bottom: 100px !important;
  }
  .md-m-rgt-100 {
    margin-right: 100px !important;
  }
  .md-m-lft-100 {
    margin-left: 100px !important;
  }
  .md-p-top-100 {
    padding-top: 100px !important;
  }
  .md-p-bot-100 {
    padding-bottom: 100px !important;
  }
  .md-p-rgt-100 {
    padding-right: 100px !important;
  }
  .md-p-lft-100 {
    padding-left: 100px !important;
  }
  .md-p-top-bot-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .md-m-top-bot-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .md-p-lft-rgt-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .md-padding-100 {
    padding: 100px !important;
  }
  .md-margin-100 {
    margin: 100px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-100 {
    padding: 100px 0 !important;
  }
  .xs-m-top-100 {
    margin-top: 100px !important;
  }
  .xs-m-bot-100 {
    margin-bottom: 100px !important;
  }
  .xs-m-rgt-100 {
    margin-right: 100px !important;
  }
  .xs-m-lft-100 {
    margin-left: 100px !important;
  }
  .xs-p-top-100 {
    padding-top: 100px !important;
  }
  .xs-p-bot-100 {
    padding-bottom: 100px !important;
  }
  .xs-p-rgt-100 {
    padding-right: 100px !important;
  }
  .xs-p-lft-100 {
    padding-left: 100px !important;
  }
  .xs-p-top-bot-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .xs-p-lft-rgt-100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .xs-padding-100 {
    padding: 100px !important;
  }
  .xs-margin-100 {
    margin: 100px !important;
  }
}

.section-padding-105 {
  padding: 105px 0 !important;
}

.effect-105 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-105 {
  margin-top: 105px !important;
}

.m-bot-105 {
  margin-bottom: 105px !important;
}

.m-rgt-105 {
  margin-right: 105px !important;
}

.m-lft-105 {
  margin-left: 105px !important;
}

.p-top-105 {
  padding-top: 105px !important;
}

.p-bot-105 {
  padding-bottom: 105px !important;
}

.p-rgt-105 {
  padding-right: 105px !important;
}

.p-lft-105 {
  padding-left: 105px !important;
}

.p-top-bot-105 {
  padding-top: 105px !important;
  padding-bottom: 105px !important;
}

.p-lft-rgt-105 {
  padding-left: 105px !important;
  padding-right: 105px !important;
}

.padding-105 {
  padding: 105px !important;
}

.margin-105 {
  margin: 105px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-105 {
    padding: 105px 0 !important;
  }
  .md-m-top-105 {
    margin-top: 105px !important;
  }
  .md-m-bot-105 {
    margin-bottom: 105px !important;
  }
  .md-m-rgt-105 {
    margin-right: 105px !important;
  }
  .md-m-lft-105 {
    margin-left: 105px !important;
  }
  .md-p-top-105 {
    padding-top: 105px !important;
  }
  .md-p-bot-105 {
    padding-bottom: 105px !important;
  }
  .md-p-rgt-105 {
    padding-right: 105px !important;
  }
  .md-p-lft-105 {
    padding-left: 105px !important;
  }
  .md-p-top-bot-105 {
    padding-top: 105px !important;
    padding-bottom: 105px !important;
  }
  .md-m-top-bot-105 {
    margin-top: 105px !important;
    margin-bottom: 105px !important;
  }
  .md-p-lft-rgt-105 {
    padding-left: 105px !important;
    padding-right: 105px !important;
  }
  .md-padding-105 {
    padding: 105px !important;
  }
  .md-margin-105 {
    margin: 105px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-105 {
    padding: 105px 0 !important;
  }
  .xs-m-top-105 {
    margin-top: 105px !important;
  }
  .xs-m-bot-105 {
    margin-bottom: 105px !important;
  }
  .xs-m-rgt-105 {
    margin-right: 105px !important;
  }
  .xs-m-lft-105 {
    margin-left: 105px !important;
  }
  .xs-p-top-105 {
    padding-top: 105px !important;
  }
  .xs-p-bot-105 {
    padding-bottom: 105px !important;
  }
  .xs-p-rgt-105 {
    padding-right: 105px !important;
  }
  .xs-p-lft-105 {
    padding-left: 105px !important;
  }
  .xs-p-top-bot-105 {
    padding-top: 105px !important;
    padding-bottom: 105px !important;
  }
  .xs-p-lft-rgt-105 {
    padding-left: 105px !important;
    padding-right: 105px !important;
  }
  .xs-padding-105 {
    padding: 105px !important;
  }
  .xs-margin-105 {
    margin: 105px !important;
  }
}

.section-padding-110 {
  padding: 110px 0 !important;
}

.effect-110 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-110 {
  margin-top: 110px !important;
}

.m-bot-110 {
  margin-bottom: 110px !important;
}

.m-rgt-110 {
  margin-right: 110px !important;
}

.m-lft-110 {
  margin-left: 110px !important;
}

.p-top-110 {
  padding-top: 110px !important;
}

.p-bot-110 {
  padding-bottom: 110px !important;
}

.p-rgt-110 {
  padding-right: 110px !important;
}

.p-lft-110 {
  padding-left: 110px !important;
}

.p-top-bot-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.p-lft-rgt-110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

.padding-110 {
  padding: 110px !important;
}

.margin-110 {
  margin: 110px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-110 {
    padding: 110px 0 !important;
  }
  .md-m-top-110 {
    margin-top: 110px !important;
  }
  .md-m-bot-110 {
    margin-bottom: 110px !important;
  }
  .md-m-rgt-110 {
    margin-right: 110px !important;
  }
  .md-m-lft-110 {
    margin-left: 110px !important;
  }
  .md-p-top-110 {
    padding-top: 110px !important;
  }
  .md-p-bot-110 {
    padding-bottom: 110px !important;
  }
  .md-p-rgt-110 {
    padding-right: 110px !important;
  }
  .md-p-lft-110 {
    padding-left: 110px !important;
  }
  .md-p-top-bot-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .md-m-top-bot-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
  }
  .md-p-lft-rgt-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .md-padding-110 {
    padding: 110px !important;
  }
  .md-margin-110 {
    margin: 110px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-110 {
    padding: 110px 0 !important;
  }
  .xs-m-top-110 {
    margin-top: 110px !important;
  }
  .xs-m-bot-110 {
    margin-bottom: 110px !important;
  }
  .xs-m-rgt-110 {
    margin-right: 110px !important;
  }
  .xs-m-lft-110 {
    margin-left: 110px !important;
  }
  .xs-p-top-110 {
    padding-top: 110px !important;
  }
  .xs-p-bot-110 {
    padding-bottom: 110px !important;
  }
  .xs-p-rgt-110 {
    padding-right: 110px !important;
  }
  .xs-p-lft-110 {
    padding-left: 110px !important;
  }
  .xs-p-top-bot-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .xs-p-lft-rgt-110 {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .xs-padding-110 {
    padding: 110px !important;
  }
  .xs-margin-110 {
    margin: 110px !important;
  }
}

.section-padding-115 {
  padding: 115px 0 !important;
}

.effect-115 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-115 {
  margin-top: 115px !important;
}

.m-bot-115 {
  margin-bottom: 115px !important;
}

.m-rgt-115 {
  margin-right: 115px !important;
}

.m-lft-115 {
  margin-left: 115px !important;
}

.p-top-115 {
  padding-top: 115px !important;
}

.p-bot-115 {
  padding-bottom: 115px !important;
}

.p-rgt-115 {
  padding-right: 115px !important;
}

.p-lft-115 {
  padding-left: 115px !important;
}

.p-top-bot-115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

.p-lft-rgt-115 {
  padding-left: 115px !important;
  padding-right: 115px !important;
}

.padding-115 {
  padding: 115px !important;
}

.margin-115 {
  margin: 115px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-115 {
    padding: 115px 0 !important;
  }
  .md-m-top-115 {
    margin-top: 115px !important;
  }
  .md-m-bot-115 {
    margin-bottom: 115px !important;
  }
  .md-m-rgt-115 {
    margin-right: 115px !important;
  }
  .md-m-lft-115 {
    margin-left: 115px !important;
  }
  .md-p-top-115 {
    padding-top: 115px !important;
  }
  .md-p-bot-115 {
    padding-bottom: 115px !important;
  }
  .md-p-rgt-115 {
    padding-right: 115px !important;
  }
  .md-p-lft-115 {
    padding-left: 115px !important;
  }
  .md-p-top-bot-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .md-m-top-bot-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
  }
  .md-p-lft-rgt-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  .md-padding-115 {
    padding: 115px !important;
  }
  .md-margin-115 {
    margin: 115px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-115 {
    padding: 115px 0 !important;
  }
  .xs-m-top-115 {
    margin-top: 115px !important;
  }
  .xs-m-bot-115 {
    margin-bottom: 115px !important;
  }
  .xs-m-rgt-115 {
    margin-right: 115px !important;
  }
  .xs-m-lft-115 {
    margin-left: 115px !important;
  }
  .xs-p-top-115 {
    padding-top: 115px !important;
  }
  .xs-p-bot-115 {
    padding-bottom: 115px !important;
  }
  .xs-p-rgt-115 {
    padding-right: 115px !important;
  }
  .xs-p-lft-115 {
    padding-left: 115px !important;
  }
  .xs-p-top-bot-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important;
  }
  .xs-p-lft-rgt-115 {
    padding-left: 115px !important;
    padding-right: 115px !important;
  }
  .xs-padding-115 {
    padding: 115px !important;
  }
  .xs-margin-115 {
    margin: 115px !important;
  }
}

.section-padding-120 {
  padding: 120px 0 !important;
}

.effect-120 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-120 {
  margin-top: 120px !important;
}

.m-bot-120 {
  margin-bottom: 120px !important;
}

.m-rgt-120 {
  margin-right: 120px !important;
}

.m-lft-120 {
  margin-left: 120px !important;
}

.p-top-120 {
  padding-top: 120px !important;
}

.p-bot-120 {
  padding-bottom: 120px !important;
}

.p-rgt-120 {
  padding-right: 120px !important;
}

.p-lft-120 {
  padding-left: 120px !important;
}

.p-top-bot-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.p-lft-rgt-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.padding-120 {
  padding: 120px !important;
}

.margin-120 {
  margin: 120px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-120 {
    padding: 120px 0 !important;
  }
  .md-m-top-120 {
    margin-top: 120px !important;
  }
  .md-m-bot-120 {
    margin-bottom: 120px !important;
  }
  .md-m-rgt-120 {
    margin-right: 120px !important;
  }
  .md-m-lft-120 {
    margin-left: 120px !important;
  }
  .md-p-top-120 {
    padding-top: 120px !important;
  }
  .md-p-bot-120 {
    padding-bottom: 120px !important;
  }
  .md-p-rgt-120 {
    padding-right: 120px !important;
  }
  .md-p-lft-120 {
    padding-left: 120px !important;
  }
  .md-p-top-bot-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .md-m-top-bot-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
  .md-p-lft-rgt-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .md-padding-120 {
    padding: 120px !important;
  }
  .md-margin-120 {
    margin: 120px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-120 {
    padding: 120px 0 !important;
  }
  .xs-m-top-120 {
    margin-top: 120px !important;
  }
  .xs-m-bot-120 {
    margin-bottom: 120px !important;
  }
  .xs-m-rgt-120 {
    margin-right: 120px !important;
  }
  .xs-m-lft-120 {
    margin-left: 120px !important;
  }
  .xs-p-top-120 {
    padding-top: 120px !important;
  }
  .xs-p-bot-120 {
    padding-bottom: 120px !important;
  }
  .xs-p-rgt-120 {
    padding-right: 120px !important;
  }
  .xs-p-lft-120 {
    padding-left: 120px !important;
  }
  .xs-p-top-bot-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .xs-p-lft-rgt-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .xs-padding-120 {
    padding: 120px !important;
  }
  .xs-margin-120 {
    margin: 120px !important;
  }
}

.section-padding-125 {
  padding: 125px 0 !important;
}

.effect-125 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-125 {
  margin-top: 125px !important;
}

.m-bot-125 {
  margin-bottom: 125px !important;
}

.m-rgt-125 {
  margin-right: 125px !important;
}

.m-lft-125 {
  margin-left: 125px !important;
}

.p-top-125 {
  padding-top: 125px !important;
}

.p-bot-125 {
  padding-bottom: 125px !important;
}

.p-rgt-125 {
  padding-right: 125px !important;
}

.p-lft-125 {
  padding-left: 125px !important;
}

.p-top-bot-125 {
  padding-top: 125px !important;
  padding-bottom: 125px !important;
}

.p-lft-rgt-125 {
  padding-left: 125px !important;
  padding-right: 125px !important;
}

.padding-125 {
  padding: 125px !important;
}

.margin-125 {
  margin: 125px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-125 {
    padding: 125px 0 !important;
  }
  .md-m-top-125 {
    margin-top: 125px !important;
  }
  .md-m-bot-125 {
    margin-bottom: 125px !important;
  }
  .md-m-rgt-125 {
    margin-right: 125px !important;
  }
  .md-m-lft-125 {
    margin-left: 125px !important;
  }
  .md-p-top-125 {
    padding-top: 125px !important;
  }
  .md-p-bot-125 {
    padding-bottom: 125px !important;
  }
  .md-p-rgt-125 {
    padding-right: 125px !important;
  }
  .md-p-lft-125 {
    padding-left: 125px !important;
  }
  .md-p-top-bot-125 {
    padding-top: 125px !important;
    padding-bottom: 125px !important;
  }
  .md-m-top-bot-125 {
    margin-top: 125px !important;
    margin-bottom: 125px !important;
  }
  .md-p-lft-rgt-125 {
    padding-left: 125px !important;
    padding-right: 125px !important;
  }
  .md-padding-125 {
    padding: 125px !important;
  }
  .md-margin-125 {
    margin: 125px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-125 {
    padding: 125px 0 !important;
  }
  .xs-m-top-125 {
    margin-top: 125px !important;
  }
  .xs-m-bot-125 {
    margin-bottom: 125px !important;
  }
  .xs-m-rgt-125 {
    margin-right: 125px !important;
  }
  .xs-m-lft-125 {
    margin-left: 125px !important;
  }
  .xs-p-top-125 {
    padding-top: 125px !important;
  }
  .xs-p-bot-125 {
    padding-bottom: 125px !important;
  }
  .xs-p-rgt-125 {
    padding-right: 125px !important;
  }
  .xs-p-lft-125 {
    padding-left: 125px !important;
  }
  .xs-p-top-bot-125 {
    padding-top: 125px !important;
    padding-bottom: 125px !important;
  }
  .xs-p-lft-rgt-125 {
    padding-left: 125px !important;
    padding-right: 125px !important;
  }
  .xs-padding-125 {
    padding: 125px !important;
  }
  .xs-margin-125 {
    margin: 125px !important;
  }
}

.section-padding-130 {
  padding: 130px 0 !important;
}

.effect-130 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-130 {
  margin-top: 130px !important;
}

.m-bot-130 {
  margin-bottom: 130px !important;
}

.m-rgt-130 {
  margin-right: 130px !important;
}

.m-lft-130 {
  margin-left: 130px !important;
}

.p-top-130 {
  padding-top: 130px !important;
}

.p-bot-130 {
  padding-bottom: 130px !important;
}

.p-rgt-130 {
  padding-right: 130px !important;
}

.p-lft-130 {
  padding-left: 130px !important;
}

.p-top-bot-130 {
  padding-top: 130px !important;
  padding-bottom: 130px !important;
}

.p-lft-rgt-130 {
  padding-left: 130px !important;
  padding-right: 130px !important;
}

.padding-130 {
  padding: 130px !important;
}

.margin-130 {
  margin: 130px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-130 {
    padding: 130px 0 !important;
  }
  .md-m-top-130 {
    margin-top: 130px !important;
  }
  .md-m-bot-130 {
    margin-bottom: 130px !important;
  }
  .md-m-rgt-130 {
    margin-right: 130px !important;
  }
  .md-m-lft-130 {
    margin-left: 130px !important;
  }
  .md-p-top-130 {
    padding-top: 130px !important;
  }
  .md-p-bot-130 {
    padding-bottom: 130px !important;
  }
  .md-p-rgt-130 {
    padding-right: 130px !important;
  }
  .md-p-lft-130 {
    padding-left: 130px !important;
  }
  .md-p-top-bot-130 {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .md-m-top-bot-130 {
    margin-top: 130px !important;
    margin-bottom: 130px !important;
  }
  .md-p-lft-rgt-130 {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }
  .md-padding-130 {
    padding: 130px !important;
  }
  .md-margin-130 {
    margin: 130px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-130 {
    padding: 130px 0 !important;
  }
  .xs-m-top-130 {
    margin-top: 130px !important;
  }
  .xs-m-bot-130 {
    margin-bottom: 130px !important;
  }
  .xs-m-rgt-130 {
    margin-right: 130px !important;
  }
  .xs-m-lft-130 {
    margin-left: 130px !important;
  }
  .xs-p-top-130 {
    padding-top: 130px !important;
  }
  .xs-p-bot-130 {
    padding-bottom: 130px !important;
  }
  .xs-p-rgt-130 {
    padding-right: 130px !important;
  }
  .xs-p-lft-130 {
    padding-left: 130px !important;
  }
  .xs-p-top-bot-130 {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .xs-p-lft-rgt-130 {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }
  .xs-padding-130 {
    padding: 130px !important;
  }
  .xs-margin-130 {
    margin: 130px !important;
  }
}

.section-padding-135 {
  padding: 135px 0 !important;
}

.effect-135 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-135 {
  margin-top: 135px !important;
}

.m-bot-135 {
  margin-bottom: 135px !important;
}

.m-rgt-135 {
  margin-right: 135px !important;
}

.m-lft-135 {
  margin-left: 135px !important;
}

.p-top-135 {
  padding-top: 135px !important;
}

.p-bot-135 {
  padding-bottom: 135px !important;
}

.p-rgt-135 {
  padding-right: 135px !important;
}

.p-lft-135 {
  padding-left: 135px !important;
}

.p-top-bot-135 {
  padding-top: 135px !important;
  padding-bottom: 135px !important;
}

.p-lft-rgt-135 {
  padding-left: 135px !important;
  padding-right: 135px !important;
}

.padding-135 {
  padding: 135px !important;
}

.margin-135 {
  margin: 135px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-135 {
    padding: 135px 0 !important;
  }
  .md-m-top-135 {
    margin-top: 135px !important;
  }
  .md-m-bot-135 {
    margin-bottom: 135px !important;
  }
  .md-m-rgt-135 {
    margin-right: 135px !important;
  }
  .md-m-lft-135 {
    margin-left: 135px !important;
  }
  .md-p-top-135 {
    padding-top: 135px !important;
  }
  .md-p-bot-135 {
    padding-bottom: 135px !important;
  }
  .md-p-rgt-135 {
    padding-right: 135px !important;
  }
  .md-p-lft-135 {
    padding-left: 135px !important;
  }
  .md-p-top-bot-135 {
    padding-top: 135px !important;
    padding-bottom: 135px !important;
  }
  .md-m-top-bot-135 {
    margin-top: 135px !important;
    margin-bottom: 135px !important;
  }
  .md-p-lft-rgt-135 {
    padding-left: 135px !important;
    padding-right: 135px !important;
  }
  .md-padding-135 {
    padding: 135px !important;
  }
  .md-margin-135 {
    margin: 135px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-135 {
    padding: 135px 0 !important;
  }
  .xs-m-top-135 {
    margin-top: 135px !important;
  }
  .xs-m-bot-135 {
    margin-bottom: 135px !important;
  }
  .xs-m-rgt-135 {
    margin-right: 135px !important;
  }
  .xs-m-lft-135 {
    margin-left: 135px !important;
  }
  .xs-p-top-135 {
    padding-top: 135px !important;
  }
  .xs-p-bot-135 {
    padding-bottom: 135px !important;
  }
  .xs-p-rgt-135 {
    padding-right: 135px !important;
  }
  .xs-p-lft-135 {
    padding-left: 135px !important;
  }
  .xs-p-top-bot-135 {
    padding-top: 135px !important;
    padding-bottom: 135px !important;
  }
  .xs-p-lft-rgt-135 {
    padding-left: 135px !important;
    padding-right: 135px !important;
  }
  .xs-padding-135 {
    padding: 135px !important;
  }
  .xs-margin-135 {
    margin: 135px !important;
  }
}

.section-padding-140 {
  padding: 140px 0 !important;
}

.effect-140 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-140 {
  margin-top: 140px !important;
}

.m-bot-140 {
  margin-bottom: 140px !important;
}

.m-rgt-140 {
  margin-right: 140px !important;
}

.m-lft-140 {
  margin-left: 140px !important;
}

.p-top-140 {
  padding-top: 140px !important;
}

.p-bot-140 {
  padding-bottom: 140px !important;
}

.p-rgt-140 {
  padding-right: 140px !important;
}

.p-lft-140 {
  padding-left: 140px !important;
}

.p-top-bot-140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}

.p-lft-rgt-140 {
  padding-left: 140px !important;
  padding-right: 140px !important;
}

.padding-140 {
  padding: 140px !important;
}

.margin-140 {
  margin: 140px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-140 {
    padding: 140px 0 !important;
  }
  .md-m-top-140 {
    margin-top: 140px !important;
  }
  .md-m-bot-140 {
    margin-bottom: 140px !important;
  }
  .md-m-rgt-140 {
    margin-right: 140px !important;
  }
  .md-m-lft-140 {
    margin-left: 140px !important;
  }
  .md-p-top-140 {
    padding-top: 140px !important;
  }
  .md-p-bot-140 {
    padding-bottom: 140px !important;
  }
  .md-p-rgt-140 {
    padding-right: 140px !important;
  }
  .md-p-lft-140 {
    padding-left: 140px !important;
  }
  .md-p-top-bot-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .md-m-top-bot-140 {
    margin-top: 140px !important;
    margin-bottom: 140px !important;
  }
  .md-p-lft-rgt-140 {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .md-padding-140 {
    padding: 140px !important;
  }
  .md-margin-140 {
    margin: 140px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-140 {
    padding: 140px 0 !important;
  }
  .xs-m-top-140 {
    margin-top: 140px !important;
  }
  .xs-m-bot-140 {
    margin-bottom: 140px !important;
  }
  .xs-m-rgt-140 {
    margin-right: 140px !important;
  }
  .xs-m-lft-140 {
    margin-left: 140px !important;
  }
  .xs-p-top-140 {
    padding-top: 140px !important;
  }
  .xs-p-bot-140 {
    padding-bottom: 140px !important;
  }
  .xs-p-rgt-140 {
    padding-right: 140px !important;
  }
  .xs-p-lft-140 {
    padding-left: 140px !important;
  }
  .xs-p-top-bot-140 {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .xs-p-lft-rgt-140 {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .xs-padding-140 {
    padding: 140px !important;
  }
  .xs-margin-140 {
    margin: 140px !important;
  }
}

.section-padding-145 {
  padding: 145px 0 !important;
}

.effect-145 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-145 {
  margin-top: 145px !important;
}

.m-bot-145 {
  margin-bottom: 145px !important;
}

.m-rgt-145 {
  margin-right: 145px !important;
}

.m-lft-145 {
  margin-left: 145px !important;
}

.p-top-145 {
  padding-top: 145px !important;
}

.p-bot-145 {
  padding-bottom: 145px !important;
}

.p-rgt-145 {
  padding-right: 145px !important;
}

.p-lft-145 {
  padding-left: 145px !important;
}

.p-top-bot-145 {
  padding-top: 145px !important;
  padding-bottom: 145px !important;
}

.p-lft-rgt-145 {
  padding-left: 145px !important;
  padding-right: 145px !important;
}

.padding-145 {
  padding: 145px !important;
}

.margin-145 {
  margin: 145px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-145 {
    padding: 145px 0 !important;
  }
  .md-m-top-145 {
    margin-top: 145px !important;
  }
  .md-m-bot-145 {
    margin-bottom: 145px !important;
  }
  .md-m-rgt-145 {
    margin-right: 145px !important;
  }
  .md-m-lft-145 {
    margin-left: 145px !important;
  }
  .md-p-top-145 {
    padding-top: 145px !important;
  }
  .md-p-bot-145 {
    padding-bottom: 145px !important;
  }
  .md-p-rgt-145 {
    padding-right: 145px !important;
  }
  .md-p-lft-145 {
    padding-left: 145px !important;
  }
  .md-p-top-bot-145 {
    padding-top: 145px !important;
    padding-bottom: 145px !important;
  }
  .md-m-top-bot-145 {
    margin-top: 145px !important;
    margin-bottom: 145px !important;
  }
  .md-p-lft-rgt-145 {
    padding-left: 145px !important;
    padding-right: 145px !important;
  }
  .md-padding-145 {
    padding: 145px !important;
  }
  .md-margin-145 {
    margin: 145px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-145 {
    padding: 145px 0 !important;
  }
  .xs-m-top-145 {
    margin-top: 145px !important;
  }
  .xs-m-bot-145 {
    margin-bottom: 145px !important;
  }
  .xs-m-rgt-145 {
    margin-right: 145px !important;
  }
  .xs-m-lft-145 {
    margin-left: 145px !important;
  }
  .xs-p-top-145 {
    padding-top: 145px !important;
  }
  .xs-p-bot-145 {
    padding-bottom: 145px !important;
  }
  .xs-p-rgt-145 {
    padding-right: 145px !important;
  }
  .xs-p-lft-145 {
    padding-left: 145px !important;
  }
  .xs-p-top-bot-145 {
    padding-top: 145px !important;
    padding-bottom: 145px !important;
  }
  .xs-p-lft-rgt-145 {
    padding-left: 145px !important;
    padding-right: 145px !important;
  }
  .xs-padding-145 {
    padding: 145px !important;
  }
  .xs-margin-145 {
    margin: 145px !important;
  }
}

.section-padding-150 {
  padding: 150px 0 !important;
}

.effect-150 {
  -webkit-box-shadow: 0, 0, 10px;
  box-shadow: 0, 0, 10px;
}

.m-top-150 {
  margin-top: 150px !important;
}

.m-bot-150 {
  margin-bottom: 150px !important;
}

.m-rgt-150 {
  margin-right: 150px !important;
}

.m-lft-150 {
  margin-left: 150px !important;
}

.p-top-150 {
  padding-top: 150px !important;
}

.p-bot-150 {
  padding-bottom: 150px !important;
}

.p-rgt-150 {
  padding-right: 150px !important;
}

.p-lft-150 {
  padding-left: 150px !important;
}

.p-top-bot-150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

.p-lft-rgt-150 {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.padding-150 {
  padding: 150px !important;
}

.margin-150 {
  margin: 150px !important;
}

@media screen and (min-width: 979px) {
  .md-section-padding-150 {
    padding: 150px 0 !important;
  }
  .md-m-top-150 {
    margin-top: 150px !important;
  }
  .md-m-bot-150 {
    margin-bottom: 150px !important;
  }
  .md-m-rgt-150 {
    margin-right: 150px !important;
  }
  .md-m-lft-150 {
    margin-left: 150px !important;
  }
  .md-p-top-150 {
    padding-top: 150px !important;
  }
  .md-p-bot-150 {
    padding-bottom: 150px !important;
  }
  .md-p-rgt-150 {
    padding-right: 150px !important;
  }
  .md-p-lft-150 {
    padding-left: 150px !important;
  }
  .md-p-top-bot-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .md-m-top-bot-150 {
    margin-top: 150px !important;
    margin-bottom: 150px !important;
  }
  .md-p-lft-rgt-150 {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .md-padding-150 {
    padding: 150px !important;
  }
  .md-margin-150 {
    margin: 150px !important;
  }
}

@media screen and (max-width: 768px) {
  .xs-section-padding-150 {
    padding: 150px 0 !important;
  }
  .xs-m-top-150 {
    margin-top: 150px !important;
  }
  .xs-m-bot-150 {
    margin-bottom: 150px !important;
  }
  .xs-m-rgt-150 {
    margin-right: 150px !important;
  }
  .xs-m-lft-150 {
    margin-left: 150px !important;
  }
  .xs-p-top-150 {
    padding-top: 150px !important;
  }
  .xs-p-bot-150 {
    padding-bottom: 150px !important;
  }
  .xs-p-rgt-150 {
    padding-right: 150px !important;
  }
  .xs-p-lft-150 {
    padding-left: 150px !important;
  }
  .xs-p-top-bot-150 {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .xs-p-lft-rgt-150 {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .xs-padding-150 {
    padding: 150px !important;
  }
  .xs-margin-150 {
    margin: 150px !important;
  }
}

@media screen and (max-width: 768px) {
  .padding-top-220 {
    padding-top: 170px;
  }
}

@media screen and (min-width: 992px) {
  /*padding left*/
  .pl-100 {
    padding-left: 100px;
  }
}

/*Margin for small devices*/
@media screen and (max-width: 991px) {
  /*margin top*/
  .mt-sm-30 {
    margin-top: 30px;
  }
  .mt-sm-50 {
    margin-top: 50px;
  }
  /*margin-bottom*/
  .mb-sm-30 {
    margin-bottom: 30px;
  }
  .mb-sm-50 {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .mt-xs-30 {
    margin-top: 30px;
  }
  .mt-xs-46 {
    margin-top: 46px;
  }
}

.height-0 {
  height: 0px !important;
}

.height-5 {
  height: 5px !important;
}

.height-10 {
  height: 10px !important;
}

.height-15 {
  height: 15px !important;
}

.height-20 {
  height: 20px !important;
}

.height-25 {
  height: 25px !important;
}

.height-30 {
  height: 30px !important;
}

.height-35 {
  height: 35px !important;
}

.height-40 {
  height: 40px !important;
}

.height-45 {
  height: 45px !important;
}

.height-50 {
  height: 50px !important;
}

.height-55 {
  height: 55px !important;
}

.height-60 {
  height: 60px !important;
}

.height-65 {
  height: 65px !important;
}

.height-70 {
  height: 70px !important;
}

.height-75 {
  height: 75px !important;
}

.height-80 {
  height: 80px !important;
}

.height-85 {
  height: 85px !important;
}

.height-90 {
  height: 90px !important;
}

.height-95 {
  height: 95px !important;
}

.height-100 {
  height: 100px !important;
}

.height-105 {
  height: 105px !important;
}

.height-110 {
  height: 110px !important;
}

.height-115 {
  height: 115px !important;
}

.height-120 {
  height: 120px !important;
}

.height-125 {
  height: 125px !important;
}

.height-130 {
  height: 130px !important;
}

.height-135 {
  height: 135px !important;
}

.height-140 {
  height: 140px !important;
}

.height-145 {
  height: 145px !important;
}

.height-150 {
  height: 150px !important;
}

.height-155 {
  height: 155px !important;
}

.height-160 {
  height: 160px !important;
}

.height-165 {
  height: 165px !important;
}

.height-170 {
  height: 170px !important;
}

.height-175 {
  height: 175px !important;
}

.height-180 {
  height: 180px !important;
}

.height-185 {
  height: 185px !important;
}

.height-190 {
  height: 190px !important;
}

.height-195 {
  height: 195px !important;
}

.height-200 {
  height: 200px !important;
}

.height-205 {
  height: 205px !important;
}

.height-210 {
  height: 210px !important;
}

.height-215 {
  height: 215px !important;
}

.height-220 {
  height: 220px !important;
}

.height-225 {
  height: 225px !important;
}

.height-230 {
  height: 230px !important;
}

.height-235 {
  height: 235px !important;
}

.height-240 {
  height: 240px !important;
}

.height-245 {
  height: 245px !important;
}

.height-250 {
  height: 250px !important;
}

.height-255 {
  height: 255px !important;
}

.height-260 {
  height: 260px !important;
}

.height-265 {
  height: 265px !important;
}

.height-270 {
  height: 270px !important;
}

.height-275 {
  height: 275px !important;
}

.height-280 {
  height: 280px !important;
}

.height-285 {
  height: 285px !important;
}

.height-290 {
  height: 290px !important;
}

.height-295 {
  height: 295px !important;
}

.height-300 {
  height: 300px !important;
}

.height-305 {
  height: 305px !important;
}

.height-310 {
  height: 310px !important;
}

.height-315 {
  height: 315px !important;
}

.height-320 {
  height: 320px !important;
}

.height-325 {
  height: 325px !important;
}

.height-330 {
  height: 330px !important;
}

.height-335 {
  height: 335px !important;
}

.height-340 {
  height: 340px !important;
}

.height-345 {
  height: 345px !important;
}

.height-350 {
  height: 350px !important;
}

.height-355 {
  height: 355px !important;
}

.height-360 {
  height: 360px !important;
}

.height-365 {
  height: 365px !important;
}

.height-370 {
  height: 370px !important;
}

.height-375 {
  height: 375px !important;
}

.height-380 {
  height: 380px !important;
}

.height-385 {
  height: 385px !important;
}

.height-390 {
  height: 390px !important;
}

.height-395 {
  height: 395px !important;
}

.height-400 {
  height: 400px !important;
}

.height-405 {
  height: 405px !important;
}

.height-410 {
  height: 410px !important;
}

.height-415 {
  height: 415px !important;
}

.height-420 {
  height: 420px !important;
}

.height-425 {
  height: 425px !important;
}

.height-430 {
  height: 430px !important;
}

.height-435 {
  height: 435px !important;
}

.height-440 {
  height: 440px !important;
}

.height-445 {
  height: 445px !important;
}

.height-450 {
  height: 450px !important;
}

.height-455 {
  height: 455px !important;
}

.height-460 {
  height: 460px !important;
}

.height-465 {
  height: 465px !important;
}

.height-470 {
  height: 470px !important;
}

.height-475 {
  height: 475px !important;
}

.height-480 {
  height: 480px !important;
}

.height-485 {
  height: 485px !important;
}

.height-490 {
  height: 490px !important;
}

.height-495 {
  height: 495px !important;
}

.height-500 {
  height: 500px !important;
}

.height-505 {
  height: 505px !important;
}

.height-510 {
  height: 510px !important;
}

.height-515 {
  height: 515px !important;
}

.height-520 {
  height: 520px !important;
}

.height-525 {
  height: 525px !important;
}

.height-530 {
  height: 530px !important;
}

.height-535 {
  height: 535px !important;
}

.height-540 {
  height: 540px !important;
}

.height-545 {
  height: 545px !important;
}

.height-550 {
  height: 550px !important;
}

.height-555 {
  height: 555px !important;
}

.height-560 {
  height: 560px !important;
}

.height-565 {
  height: 565px !important;
}

.height-570 {
  height: 570px !important;
}

.height-575 {
  height: 575px !important;
}

.height-580 {
  height: 580px !important;
}

.height-585 {
  height: 585px !important;
}

.height-590 {
  height: 590px !important;
}

.height-595 {
  height: 595px !important;
}

.height-600 {
  height: 600px !important;
}

.height-605 {
  height: 605px !important;
}

.height-610 {
  height: 610px !important;
}

.height-615 {
  height: 615px !important;
}

.height-620 {
  height: 620px !important;
}

.height-625 {
  height: 625px !important;
}

.height-630 {
  height: 630px !important;
}

.height-635 {
  height: 635px !important;
}

.height-640 {
  height: 640px !important;
}

.height-645 {
  height: 645px !important;
}

.height-650 {
  height: 650px !important;
}

.height-655 {
  height: 655px !important;
}

.height-660 {
  height: 660px !important;
}

.height-665 {
  height: 665px !important;
}

.height-670 {
  height: 670px !important;
}

.height-675 {
  height: 675px !important;
}

.height-680 {
  height: 680px !important;
}

.height-685 {
  height: 685px !important;
}

.height-690 {
  height: 690px !important;
}

.height-695 {
  height: 695px !important;
}

.height-700 {
  height: 700px !important;
}

.height-705 {
  height: 705px !important;
}

.height-710 {
  height: 710px !important;
}

.height-715 {
  height: 715px !important;
}

.height-720 {
  height: 720px !important;
}

.height-725 {
  height: 725px !important;
}

.height-730 {
  height: 730px !important;
}

.height-735 {
  height: 735px !important;
}

.height-740 {
  height: 740px !important;
}

.height-745 {
  height: 745px !important;
}

.height-750 {
  height: 750px !important;
}

.height-755 {
  height: 755px !important;
}

.height-760 {
  height: 760px !important;
}

.height-765 {
  height: 765px !important;
}

.height-770 {
  height: 770px !important;
}

.height-775 {
  height: 775px !important;
}

.height-780 {
  height: 780px !important;
}

.height-785 {
  height: 785px !important;
}

.height-790 {
  height: 790px !important;
}

.height-795 {
  height: 795px !important;
}

.height-800 {
  height: 800px !important;
}

.height-805 {
  height: 805px !important;
}

.height-810 {
  height: 810px !important;
}

.height-815 {
  height: 815px !important;
}

.height-820 {
  height: 820px !important;
}

.height-825 {
  height: 825px !important;
}

.height-830 {
  height: 830px !important;
}

.height-835 {
  height: 835px !important;
}

.height-840 {
  height: 840px !important;
}

.height-845 {
  height: 845px !important;
}

.height-850 {
  height: 850px !important;
}

.height-855 {
  height: 855px !important;
}

.height-860 {
  height: 860px !important;
}

.height-865 {
  height: 865px !important;
}

.height-870 {
  height: 870px !important;
}

.height-875 {
  height: 875px !important;
}

.height-880 {
  height: 880px !important;
}

.height-885 {
  height: 885px !important;
}

.height-890 {
  height: 890px !important;
}

.height-895 {
  height: 895px !important;
}

.height-900 {
  height: 900px !important;
}

.height-905 {
  height: 905px !important;
}

.height-910 {
  height: 910px !important;
}

.height-915 {
  height: 915px !important;
}

.height-920 {
  height: 920px !important;
}

.height-925 {
  height: 925px !important;
}

.height-930 {
  height: 930px !important;
}

.height-935 {
  height: 935px !important;
}

.height-940 {
  height: 940px !important;
}

.height-945 {
  height: 945px !important;
}

.height-950 {
  height: 950px !important;
}

.height-955 {
  height: 955px !important;
}

.height-960 {
  height: 960px !important;
}

.height-965 {
  height: 965px !important;
}

.height-970 {
  height: 970px !important;
}

.height-975 {
  height: 975px !important;
}

.height-980 {
  height: 980px !important;
}

.height-985 {
  height: 985px !important;
}

.height-990 {
  height: 990px !important;
}

.height-995 {
  height: 995px !important;
}

.height-1000 {
  height: 1000px !important;
}

#back-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 9999;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 30px;
  background: orange;
  color: #444;
  cursor: pointer;
  border: 0;
  border-radius: 20px;
  text-decoration: none;
  transition: opacity 0.2s ease-out;
  opacity: 0;
  font-size: 20px;
}

#back-to-top:hover {
  background: #e9ebec;
}

#back-to-top.show {
  opacity: 1;
}
