///Style

.realfactory-body {
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 1.2;
    font-weight: 700;
  }
}

#poststuff .gdlr-core-page-builder-body {
  h2 {
    padding: 0;
    margin-bottom: 20px;
    line-height: 1.2;
    font-weight: 700;
  }
  h1 {
    padding: 0;
    font-weight: 700;
  }
}

/* custom style for specific theme */

.gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-title {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
  text-transform: none;
}

.gdlr-core-button {
  text-transform: none;
  letter-spacing: 0.5px;
}

.gdlr-core-blog-date-wrapper {
  margin-top: 0;
  border-right-width: 3px;
  padding-bottom: 18px;
  padding-right: 25px;
  width: 63px;
  .gdlr-core-blog-date-day {
    letter-spacing: 1px;
    margin-top: 0;
  }
}

.gdlr-core-blog-info-wrapper .gdlr-core-blog-info {
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  letter-spacing: 0;
}

.gdlr-core-blog-list {
  .gdlr-core-blog-title {
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
  }
  .gdlr-core-blog-list-frame {
    padding: 26px 30px 27px;
  }
}

.gdlr-core-blog-full {
  margin-bottom: 55px;
  .gdlr-core-excerpt-read-more {
    margin-top: 25px;
  }
  .gdlr-core-blog-title {
    font-size: 32px;
    margin-bottom: 0;
  }
  .gdlr-core-blog-info-wrapper {
    margin-bottom: 10px;
    .gdlr-core-blog-info {
      font-size: 15px;
    }
  }
}

.gdlr-core-style-blog-full-with-frame .gdlr-core-blog-full-frame {
  padding: 50px 50px 25px;
}

.gdlr-core-blog-grid {
  .gdlr-core-blog-title {
    font-size: 24px;
  }
  .gdlr-core-blog-info-wrapper {
    padding-top: 0;
    border: 0;
    margin-bottom: 10px;
  }
  .gdlr-core-excerpt-read-more {
    display: inline-block;
  }
  .gdlr-core-blog-content {
    margin-bottom: 20px;
  }
}

.gdlr-core-excerpt-read-more {
  i {
    margin-left: 15px;
  }
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  margin-top: 24px;
}

.gdlr-core-testimonial-item .gdlr-core-testimonial-content {
  font-style: normal;
}

.gdlr-core-testimonial-style-center .gdlr-core-testimonial-quote {
  display: none;
}

.gdlr-core-flexslider {
  .flex-control-nav {
    margin-top: 20px;
  }
  &[data-nav-parent="gdlr-core-portfolio-item"] {
    position: static;
    .flex-direction-nav {
      li {
        margin-top: -30px;
        a {
          border-radius: 0;
          padding: 16px 14px;
          i {
            font-size: 28px;
            width: 28px;
          }
        }
      }
      .flex-nav-prev {
        left: 0;
      }
      .flex-nav-next {
        right: 0;
      }
    }
  }
}

.gdlr-core-portfolio-thumbnail {
  .gdlr-core-portfolio-title {
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 400;
  }
  .gdlr-core-portfolio-info {
    font-size: 14px;
    font-style: normal;
  }
  &.gdlr-core-style-title-tag .gdlr-core-portfolio-info {
    margin-top: 12px;
  }
  &.gdlr-core-style-title-icon .gdlr-core-portfolio-title {
    margin-bottom: 20px;
  }
  &.gdlr-core-style-icon-title-tag .gdlr-core-portfolio-title, &.gdlr-core-style-margin-icon-title-tag .gdlr-core-portfolio-title {
    margin-top: 20px;
  }
}

.gdlr-core-portfolio-medium .gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-info {
  font-size: 14px;
}

.gdlr-core-portfolio-grid .gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-info {
  font-size: 14px;
  font-style: normal;
}

.gdlr-core-portfolio-slider-widget-wrap {
  padding-top: 0;
  margin-top: -10px;
}

.gdlr-core-recent-portfolio-widget {
  max-width: 80px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.gdlr-core-title-item.gdlr-core-left-align .gdlr-core-title-item-link:after {
  font-family: "fontAwesome";
  margin-left: 10px;
  content: "\f178";
  vertical-align: middle;
}

.gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-title {
  padding: 7px 13px 7px 17px;
  margin-top: -4px;
}

.gdlr-core-product-item.woocommerce .gdlr-core-product-thumbnail-info {
  i {
    font-size: 13px;
    margin-right: 7px;
  }
  > a {
    font-size: 12px;
  }
}

span {
  &.gdlr-core-counter-item-suffix, &.gdlr-core-counter-item-count {
    font-weight: 700;
  }
}

body.realfactory-boxed .realfactory-body-wrapper {
  margin-top: 0;
  margin-bottom: 0;
}

.realfactory-body-wrapper.realfactory-with-frame {
  margin: 0;
}

body {
  &.realfactory-full .realfactory-fixed-footer {
    margin: 0;
  }
  &.realfactory-boxed.realfactory-background-pattern .realfactory-body-outer-wrapper {
    background-image: url(https://demo.goodlayers.com/realfactory/wp-content/plugins/goodlayers-core/include/images/pattern/pattern-1.png);
  }
}

.realfactory-item-pdlr, .gdlr-core-item-pdlr {
  padding-left: 15px;
  padding-right: 15px;
}

.realfactory-item-rvpdlr, .gdlr-core-item-rvpdlr {
  margin-left: -15px;
  margin-right: -15px;
}

.gdlr-core-metro-rvpdlr {
  margin-top: -15px;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
}

.realfactory-item-mglr, .gdlr-core-item-mglr, .realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega {
  margin-left: 15px;
  margin-right: 15px;
}

.realfactory-container, .gdlr-core-container {
  max-width: 1180px;
}

body.realfactory-boxed {
  .realfactory-body-wrapper {
    max-width: 1180px;
  }
  .realfactory-fixed-footer {
    .realfactory-footer-wrapper, .realfactory-copyright-wrapper {
      max-width: 1180px;
    }
  }
}

.realfactory-body-front {
  .gdlr-core-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .realfactory-container {
    padding-left: 15px;
    padding-right: 15px;
    .realfactory-container, .gdlr-core-container {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .gdlr-core-container .gdlr-core-container {
    padding-left: 0;
    padding-right: 0;
  }
}

.realfactory-top-bar-container {
  &.realfactory-top-bar-custom-container {
    max-width: 1140px;
  }
  &.realfactory-top-bar-full {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.realfactory-top-bar {
  padding-top: 16px;
  padding-bottom: 14px;
  font-size: 15px;
  border-bottom-width: 0;
}

.realfactory-header-style-plain {
  border-bottom-width: 0;
}

.realfactory-header-background-transparent {
  .realfactory-top-bar-background {
    opacity: 0.5;
  }
  .realfactory-header-background {
    opacity: 0;
  }
}

.realfactory-navigation-bar-wrap.realfactory-style-transparent .realfactory-navigation-background {
  opacity: 0.82;
}

.realfactory-header-boxed-wrap .realfactory-top-bar-background {
  opacity: 0;
  margin-bottom: -0px;
}

.realfactory-header-style-boxed {
  margin-top: 0;
}

.realfactory-header-container {
  &.realfactory-header-custom-container {
    max-width: 1140px;
  }
  &.realfactory-header-full {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.realfactory-header-boxed-wrap .realfactory-header-background {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.realfactory-header-style-boxed .realfactory-header-container-item {
  padding-left: 30px;
  padding-right: 30px;
}

.realfactory-navigation-right {
  right: 30px;
}

.realfactory-navigation-left {
  left: 30px;
}

.realfactory-header-style-plain.realfactory-style-splitted-menu {
  .realfactory-navigation .sf-menu > li > a, .realfactory-main-menu-left-wrap, .realfactory-main-menu-right-wrap {
    padding-top: 0;
  }
}

.realfactory-header-style-boxed.realfactory-style-splitted-menu {
  .realfactory-navigation .sf-menu > li > a, .realfactory-main-menu-left-wrap, .realfactory-main-menu-right-wrap {
    padding-top: 0;
  }
}

.realfactory-navigation .sf-menu > li {
  padding-left: 23px;
  padding-right: 23px;
}

.realfactory-header-side-nav {
  width: 340px;
}

.realfactory-header-side-content {
  &.realfactory-style-left {
    margin-left: 340px;
  }
  &.realfactory-style-right {
    margin-right: 340px;
  }
}

.realfactory-header-side-nav {
  &.realfactory-style-side {
    padding-left: 70px;
    padding-right: 70px;
  }
  &.realfactory-style-left .sf-vertical > li > ul.sub-menu {
    padding-left: 70px;
  }
  &.realfactory-style-right .sf-vertical > li > ul.sub-menu {
    padding-right: 70px;
  }
}

.realfactory-navigation .sf-vertical > li {
  padding-top: 16px;
  padding-bottom: 16px;
}

.realfactory-header-style-bar .realfactory-logo-right-text {
  padding-top: 53px;
}

.realfactory-logo {
  padding-top: 15px;
  padding-bottom: 15px;
}

.realfactory-logo-inner {
  max-width: 375px;
}

.realfactory-navigation {
  padding-top: 23px;
}

.realfactory-navigation-top {
  top: 23px;
}

.realfactory-navigation .sf-menu > li > a {
  padding-bottom: 23px;
}

.realfactory-page-title-wrap {
  &.realfactory-style-custom {
    .realfactory-page-title-content {
      padding-top: 93px;
      padding-bottom: 87px;
    }
    .realfactory-page-caption {
      margin-top: 13px;
    }
  }
  .realfactory-page-title {
    text-transform: none;
  }
  &.realfactory-style-custom {
    .realfactory-page-title {
      font-size: 55px;
      letter-spacing: 0;
    }
    .realfactory-page-caption {
      font-size: 18px;
      letter-spacing: 0;
    }
  }
  .realfactory-page-title-overlay {
    opacity: 0;
  }
}

.realfactory-not-found-wrap .realfactory-not-found-background {
  opacity: 0.27;
}

.realfactory-blog-title-wrap {
  &.realfactory-style-custom .realfactory-blog-title-content {
    padding-top: 340px;
    padding-bottom: 80px;
  }
  .realfactory-blog-title-overlay {
    opacity: 0.41;
  }
}

body {
  &.single-post .realfactory-sidebar-style-none, &.blog .realfactory-sidebar-style-none {
    max-width: 900px;
  }
}

.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-icon {
  font-size: 22px;
}

.realfactory-footer-wrapper {
  padding-top: 70px;
  padding-bottom: 50px;
}

.realfactory-copyright-text {
  padding-top: 30px;
  padding-bottom: 30px;
}

//.realfactory-body {
//  h1 {
//    font-size: 52px;
//  }
//  h2 {
//    font-size: 48px;
//  }
//}

//#poststuff .gdlr-core-page-builder-body h2 {
//  font-size: 48px;
//}

.realfactory-body {
  h3 {
    font-size: 30px;
  }
  h4 {
    font-size: 24px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 16px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: 400;
  }
}

.realfactory-navigation {
  .sf-menu > li > a, .sf-vertical > li > a {
    font-size: 14px;
  }
  .sf-menu > li > a {
    font-weight: 600;
    text-transform: uppercase;
  }
}

.realfactory-body {
  font-size: 15px;
  line-height: 1.7;
  p {
    line-height: 1.7;
  }
}

.realfactory-line-height, .gdlr-core-line-height {
  line-height: 1.7;
}

.realfactory-page-preload {
  background-color: #ffffff;
}

.realfactory-top-bar-background {
  background-color: #ededed;
}

.realfactory-body .realfactory-top-bar {
  border-bottom-color: #ffffff;
}

.realfactory-top-bar {
  color: #727272;
}

.realfactory-body .realfactory-top-bar a {
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
}

.realfactory-top-bar .realfactory-top-bar-right-social a {
  color: #2e2e2e;
  &:hover {
    color: #e44444;
  }
}

.realfactory-header-background, .realfactory-sticky-menu-placeholder, .realfactory-header-style-boxed.realfactory-fixed-navigation {
  background-color: #e6e6e6;
}

.realfactory-header-wrap.realfactory-header-style-plain {
  border-color: #e8e8e8;
}

.realfactory-logo-right-block {
  .realfactory-logo-right-block-icon, .realfactory-logo-right-block-title {
    color: #191919;
  }
  .realfactory-logo-right-block-caption {
    color: #929292;
  }
}

.realfactory-body .realfactory-header-right-button {
  color: #1c1c1c;
  background-color: #f8c12c;
  border-color: #f8c12c;
}

.realfactory-navigation-background {
  background-color: #000000;
}

.realfactory-navigation .realfactory-navigation-slide-bar {
  border-color: #ffbe32;
  &:before {
    border-bottom-color: #ffbe32;
  }
}

.realfactory-header-side-nav.realfactory-style-side-toggle .realfactory-logo {
  background-color: #ffffff;
}

.realfactory-main-menu-search i {
  color: #ffffff;
}

.realfactory-main-menu-cart {
  i {
    color: #ffffff;
  }
  > .realfactory-top-cart-count {
    background-color: #e8b62d;
    color: #ffffff;
  }
}

.realfactory-top-menu-button i, .realfactory-mobile-menu-button i {
  color: #ffffff;
}

.realfactory-mobile-button-hamburger {
  &:before, &:after, span {
    background: #ffffff;
  }
}

.realfactory-main-menu-right .realfactory-top-menu-button, .realfactory-mobile-menu .realfactory-mobile-menu-button {
  border-color: #ffffff;
}

.realfactory-mobile-header {
  .realfactory-mobile-menu-button i, .realfactory-main-menu-search i, .realfactory-main-menu-cart i {
    color: #383838;
  }
  .realfactory-mobile-button-hamburger {
    &:before, &:after, span {
      background: #383838;
    }
  }
}

.realfactory-mobile-menu .realfactory-mobile-menu-button {
  border-color: #dddddd;
}

.realfactory-top-search-wrap {
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.88);
}

.realfactory-top-cart-content-wrap {
  .realfactory-top-cart-content {
    background-color: #303030;
    span {
      color: #b5b5b5;
      &.woocommerce-Price-amount.amount {
        color: #b5b5b5;
      }
    }
  }
  .realfactory-top-cart-button {
    color: #ffffff;
    &:hover {
      color: #ffffff;
    }
  }
  .realfactory-top-cart-checkout-button {
    color: #f8c12c;
    &:hover {
      color: #f8c12c;
    }
  }
}

.realfactory-body .realfactory-breadcrumbs {
  background-color: #efefef;
  color: #898989;
  a span {
    color: #898989;
  }
  span, a:hover span {
    color: #545454;
  }
}

.sf-menu > li > a, .sf-vertical > li > a {
  color: #ffffff;
}

.sf-menu > li {
  > a:hover, &.current-menu-item > a, &.current-menu-ancestor > a {
    color: #ffffff;
  }
}

.sf-vertical > li {
  > a:hover, &.current-menu-item > a, &.current-menu-ancestor > a {
    color: #ffffff;
  }
}

.sf-menu > {
  .realfactory-normal-menu li, .realfactory-mega-menu > .sf-mega {
    background-color: #0e0e0e;
  }
}

.sf-vertical ul.sub-menu li, ul.sf-menu > .menu-item-language li {
  background-color: #0e0e0e;
}

.sf-menu > {
  li > .sub-menu a, .realfactory-mega-menu > .sf-mega a {
    color: #bebebe;
  }
}

.sf-vertical ul.sub-menu li a {
  color: #bebebe;
}

.sf-menu > {
  li > .sub-menu {
    a:hover, .current-menu-item > a, .current-menu-ancestor > a {
      color: #e8b62d;
    }
  }
  .realfactory-mega-menu > .sf-mega {
    a:hover, .current-menu-item > a {
      color: #e8b62d;
    }
  }
}

.sf-vertical > li > .sub-menu {
  a:hover, .current-menu-item > a, .current-menu-ancestor > a {
    color: #e8b62d;
  }
}

.sf-menu > {
  li > .sub-menu {
    a:hover, .current-menu-item > a, .current-menu-ancestor > a {
      background-color: #0e0e0e;
    }
  }
  .realfactory-mega-menu > .sf-mega {
    a:hover, .current-menu-item > a {
      background-color: #0e0e0e;
    }
  }
}

.sf-vertical > li > .sub-menu {
  a:hover, .current-menu-item > a, .current-menu-ancestor > a {
    background-color: #0e0e0e;
  }
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu {
  .sf-mega-section-inner > a {
    color: #ffffff;
  }
  .sf-mega-section {
    border-color: #303030;
  }
}

.mm-navbar {
  .mm-title, .mm-btn {
    color: #979797;
  }
}

ul.mm-listview li {
  > {
    a, span {
      color: #979797;
    }
  }
  a {
    border-color: #979797;
  }
}

.mm-arrow:after, .mm-next:after, .mm-prev:before {
  border-color: #979797;
}

.mm-navbar {
  .mm-title:hover, .mm-btn:hover {
    color: #ffffff;
  }
}

ul.mm-listview li {
  a:hover, > span:hover, &.current-menu-item > a {
    color: #ffffff;
  }
  &.current-menu-ancestor > {
    a, span {
      color: #ffffff;
    }
  }
}

.mm-menu {
  background-color: #1f1f1f;
}

ul.mm-listview li {
  border-color: #626262;
}

.realfactory-overlay-menu-content {
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.88);
  ul {
    &.menu > li, &.sub-menu ul.sub-menu {
      border-color: #424242;
    }
    li a {
      color: #ffffff;
    }
  }
  .realfactory-overlay-menu-close {
    color: #ffffff;
  }
  ul li a:hover {
    color: #a8a8a8;
  }
}

.realfactory-bullet-anchor a {
  &:before {
    background-color: #777777;
  }
  &:hover, &.current-menu-item {
    border-color: #ffffff;
  }
  &:hover:before, &.current-menu-item:before {
    background: #ffffff;
  }
}

.realfactory-body .realfactory-main-menu-right-button {
  color: #333333;
  border-color: #333333;
  &:hover {
    color: #555555;
    border-color: #555555;
  }
}

.realfactory-body-outer-wrapper {
  background-color: #ffffff;
}

body {
  &.realfactory-full .realfactory-body-wrapper, &.realfactory-boxed .realfactory-body-wrapper {
    background-color: #ffffff;
  }
}

.gdlr-core-page-builder .gdlr-core-page-builder-body.gdlr-core-pb-livemode {
  background-color: #ffffff;
}

.realfactory-page-title-wrap {
  .realfactory-page-title {
    color: #ffffff;
  }
  .realfactory-page-caption {
    color: #ffffff;
    .woocommerce-breadcrumb {
      color: #ffffff;
      a {
        color: #ffffff;
      }
    }
  }
  .realfactory-page-title-overlay {
    background-color: #000000;
  }
}

.realfactory-body {
  color: #727272;
  span.wpcf7-not-valid-tip {
    color: #727272;
  }
  h1, h2, h3, h4, h5, h6 {
    color: #383838;
  }
}

.woocommerce table {
  &.shop_attributes th, &.shop_table th {
    color: #383838;
  }
}

.single-product.woocommerce div.product .product_meta .realfactory-head {
  color: #383838;
}

.realfactory-body {
  a {
    color: #545454;
    &:hover {
      color: #333333;
    }
  }
  * {
    border-color: #e6e6e6;
  }
}

.gdlr-core-columnize-item .gdlr-core-columnize-item-content {
  column-rule-color: #e6e6e6;
  -moz-column-rule-color: #e6e6e6;
  -webkit-column-rule-color: #e6e6e6;
}

.realfactory-body {
  input, textarea {
    background-color: #ffffff;
  }
  input, textarea {
    border-color: #d7d7d7;
  }
  input, textarea, ::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder, :-moz-placeholder {
    color: #4e4e4e;
  }
}

.realfactory-not-found-wrap {
  background-color: #23618e;
  .realfactory-not-found-head, .realfactory-not-found-title {
    color: #ffffff;
  }
  .realfactory-not-found-caption {
    color: #c3e7ff;
  }
  form.search-form input.search-field {
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0.4);
    color: #ffffff;
  }
  .realfactory-top-search-submit {
    color: #ffffff;
  }
  input {
    &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
      color: #ffffff;
    }
  }
  .realfactory-not-found-back-to-home a {
    color: #ffffff;
    &:hover {
      color: #ffffff;
    }
  }
}

.realfactory-sidebar-area {
  .realfactory-widget-title {
    color: #161616;
  }
  a {
    color: #a3a3a3;
  }
}

.widget_recent_entries ul li:before, .widget_recent_comments ul li:before, .widget_pages ul li:before, .widget_rss ul li:before, .widget_archive ul li:before, .widget_categories ul li:before, .widget_nav_menu ul li:before, .widget_meta ul li:before {
  color: #a3a3a3;
}

.realfactory-sidebar-area {
  a:hover, .current-menu-item > a {
    color: #ddb42c;
  }
}

.gdlr-core-recent-post-widget .gdlr-core-blog-info {
  i {
    color: #9c9c9c;
  }
  color: #a0a0a0;
  a {
    color: #a0a0a0;
    &:hover {
      color: #a0a0a0;
    }
  }
}

.gdlr-core-post-slider-widget-overlay {
  .gdlr-core-post-slider-widget-title {
    color: #ffffff;
  }
  .gdlr-core-blog-info {
    color: #9c9c9c;
    i {
      color: #9c9c9c;
    }
    a {
      color: #9c9c9c;
      &:hover {
        color: #9c9c9c;
      }
    }
  }
}

.widget_search {
  input {
    &.search-field, &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
      color: #b5b5b5;
    }
    &.search-field {
      border-color: #e0e0e0;
    }
  }
  form:after {
    border-color: #c7c7c7;
  }
}

ul.gdlr-core-twitter-widget-wrap li {
  &:before {
    color: #303030;
  }
  .gdlr-core-twitter-widget-date a {
    color: #b5b5b5;
    &:hover {
      color: #b5b5b5;
    }
  }
}

.realfactory-footer-wrapper {
  background-color: #242424;
  .realfactory-widget-title {
    color: #f8c12c;
  }
  color: #f7f7f7;
  .widget_recent_entries ul li:before, .widget_recent_comments ul li:before, .widget_pages ul li:before, .widget_rss ul li:before, .widget_archive ul li:before, .widget_categories ul li:before {
    color: #f7f7f7;
  }
}

.widget_nav_menu ul li:before {
  color: #f7f7f7;
}

.realfactory-footer-wrapper {
  .widget_meta ul li:before {
    color: #f7f7f7;
  }
  a {
    color: #b2b2b2;
    &:hover {
      color: #f8c12c;
    }
  }
  border-color: #353535;
  * {
    border-color: #353535;
  }
}

.realfactory-copyright-wrapper {
  background-color: #131313;
  color: #838383;
}

.realfactory-footer-back-to-top-button {
  background-color: #dbdbdb;
  color: #313131;
}

.realfactory-body .realfactory-blog-title-wrap {
  .realfactory-single-article-title {
    color: #ffffff;
  }
  .realfactory-blog-info-wrapper {
    color: #ffffff;
    a {
      color: #ffffff;
      &:hover {
        color: #ffffff;
      }
    }
    i {
      color: #ffffff;
    }
  }
  .realfactory-single-article-date-day {
    color: #ffffff;
  }
  .realfactory-single-article-date-month {
    color: #d8d8d8;
  }
  .realfactory-single-article-date-wrapper {
    border-color: #ffffff;
  }
}

.realfactory-blog-title-wrap .realfactory-blog-title-overlay {
  background-color: #000000;
}

.realfactory-single-author {
  .realfactory-single-author-title a {
    color: #464646;
    &:hover {
      color: #464646;
    }
  }
  .realfactory-single-author-caption {
    color: #b1b1b1;
  }
}

.realfactory-single-nav a {
  color: #bcbcbc;
  &:hover {
    color: #bcbcbc;
  }
}

.realfactory-comments-area {
  .realfactory-comments-title, .comment-author, .comment-reply-title {
    color: #464646;
  }
  .comment-reply a {
    color: #2b2b2b;
    &:hover {
      color: #2b2b2b;
    }
  }
  .comment-time a {
    color: #b1b1b1;
    &:hover {
      color: #b1b1b1;
    }
  }
}

.gdlr-core-blog-title a {
  color: #343434;
}

.realfactory-body .realfactory-single-article-title {
  color: #343434;
  a {
    color: #343434;
  }
}

.gdlr-core-blog-title a:hover, .realfactory-body .realfactory-single-article-title a:hover {
  color: #f1bf37;
}

.gdlr-core-sticky-banner, .realfactory-sticky-banner {
  color: #262626;
}

.gdlr-core-sticky-banner, .realfactory-sticky-banner {
  background-color: #f1bf37;
}

.realfactory-single-article .realfactory-blog-info-wrapper, .gdlr-core-blog-info-wrapper {
  color: #959595;
  a {
    color: #959595;
    &:hover {
      color: #959595;
    }
  }
}

.gdlr-core-blog-grid .gdlr-core-blog-info-date a {
  color: #959595;
}

.realfactory-single-article .realfactory-blog-info-wrapper i, .gdlr-core-blog-info-wrapper i {
  color: #f1bf37;
}

.gdlr-core-blog-date-wrapper .gdlr-core-blog-date-day, .realfactory-single-article .realfactory-single-article-date-day {
  color: #353535;
}

.gdlr-core-blog-date-wrapper .gdlr-core-blog-date-month, .realfactory-single-article .realfactory-single-article-date-month {
  color: #9b9b9b;
}

.realfactory-body {
  .gdlr-core-blog-date-wrapper, .realfactory-single-article-date-wrapper {
    border-color: #f8c12c;
  }
}

.gdlr-core-blog-grid-frame, .gdlr-core-blog-full-frame, .gdlr-core-blog-list-frame {
  background-color: #ffffff;
}

.gdlr-core-blog-modern.gdlr-core-with-image {
  .gdlr-core-blog-info-wrapper {
    color: #ffffff;
    a, i {
      color: #ffffff;
    }
  }
  .gdlr-core-blog-title a {
    color: #ffffff;
  }
}

.gdlr-core-blog-metro.gdlr-core-with-image {
  .gdlr-core-blog-info-wrapper {
    color: #ffffff;
    a, i {
      color: #ffffff;
    }
  }
  .gdlr-core-blog-title a {
    color: #ffffff;
  }
}

.realfactory-blog-aside-format .realfactory-single-article-content, .gdlr-core-blog-aside-format .gdlr-core-blog-content, .realfactory-blog-quote-format .realfactory-single-article-content, .gdlr-core-blog-quote-format .gdlr-core-blog-content {
  background-color: #f2bd2b;
}

.realfactory-blog-aside-format .realfactory-single-article-content, .gdlr-core-blog-aside-format .gdlr-core-blog-content {
  color: #ffffff;
}

.realfactory-blog-quote-format .realfactory-single-article-content {
  blockquote, q {
    color: #ffffff;
  }
  color: #ffffff;
}

.gdlr-core-blog-quote-format .gdlr-core-blog-content {
  blockquote, q {
    color: #ffffff;
  }
  color: #ffffff;
}

.gdlr-core-pagination {
  a {
    background-color: #e2e2e2;
    color: #515151;
    &:hover, &.gdlr-core-active {
      background-color: #190000;
    }
  }
  span {
    background-color: #190000;
  }
  a {
    &:hover, &.gdlr-core-active {
      color: #ffffff;
    }
  }
  span {
    color: #ffffff;
  }
  &.gdlr-core-with-border a {
    color: #b4b4b4;
    border-color: #b4b4b4;
  }
  &.gdlr-core-style-plain {
    a {
      color: #b4b4b4;
      &:before {
        color: #b4b4b4;
      }
    }
    span:before {
      color: #b4b4b4;
    }
  }
  &.gdlr-core-with-border {
    a {
      &:hover, &.gdlr-core-active {
        color: #424242;
        border-color: #424242;
      }
    }
    span {
      color: #424242;
      border-color: #424242;
    }
  }
  &.gdlr-core-style-plain {
    a {
      &:hover, &.gdlr-core-active {
        color: #424242;
      }
    }
    span {
      color: #424242;
    }
  }
}

.gdlr-core-portfolio-single-nav {
  color: #bcbcbc;
  a {
    color: #bcbcbc;
    &:hover {
      color: #bcbcbc;
    }
  }
}

.gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-title a {
  color: #191919;
}

.gdlr-core-port-info-item .gdlr-core-port-info-key, .gdlr-core-port-info2 .gdlr-core-port-info2-key {
  color: #343434;
}

.gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-info {
  color: #898989;
  a {
    color: #898989;
    &:hover {
      color: #898989;
    }
  }
}

.gdlr-core-portfolio-badge {
  color: #ffffff;
  background-color: #e24a3b;
}

.gdlr-core-portfolio-thumbnail {
  .gdlr-core-portfolio-icon {
    color: #e8b62d;
  }
  .gdlr-core-portfolio-title a {
    color: #e8b62d;
    &:hover {
      color: #e8b62d;
    }
  }
  .gdlr-core-portfolio-info {
    color: #cecece;
    a {
      color: #cecece;
      &:hover {
        color: #cecece;
      }
    }
  }
}

.gdlr-core-filterer-wrap {
  &.gdlr-core-style-text a {
    color: #aaaaaa;
    &:hover, &.gdlr-core-active {
      color: #747474;
    }
  }
  &.gdlr-core-style-button a {
    color: #838383;
    background-color: #f1f1f1;
    &:hover, &.gdlr-core-active {
      color: #ffffff;
    }
    &:hover, &.gdlr-core-active {
      background-color: #f2bd2b;
    }
  }
}

.gdlr-core-price-table-item .gdlr-core-price-table {
  background-color: #f8f8f8;
}

.gdlr-core-price-table {
  .gdlr-core-price-table-head {
    background-color: #3e3e3e;
    background: -webkit-linear-gradient(#525252, #3e3e3e);
    background: -o-linear-gradient(#525252, #3e3e3e);
    background: -moz-linear-gradient(#525252, #3e3e3e);
    background: linear-gradient(#525252, #3e3e3e);
  }
  .gdlr-core-price-table-icon, .gdlr-core-price-table-title {
    color: #ffffff;
  }
  .gdlr-core-price-table-caption {
    color: #acacac;
  }
  .gdlr-core-price-table-price {
    background-color: #ebebeb;
  }
  .gdlr-core-price-table-price-number, .gdlr-core-price-prefix {
    color: #323232;
  }
  .gdlr-core-price-suffix {
    color: #acacac;
  }
  .gdlr-core-price-table-button {
    color: #ffffff;
    &:hover {
      color: #ffffff;
      background-color: #575757;
      background: -webkit-linear-gradient(#414141, #575757);
      background: -o-linear-gradient(#414141, #575757);
      background: -moz-linear-gradient(#414141, #575757);
      background: linear-gradient(#414141, #575757);
    }
    background-color: #575757;
    background: -webkit-linear-gradient(#414141, #575757);
    background: -o-linear-gradient(#414141, #575757);
    background: -moz-linear-gradient(#414141, #575757);
    background: linear-gradient(#414141, #575757);
  }
  .gdlr-core-price-table-content * {
    border-color: #e5e5e5;
  }
  &.gdlr-core-active {
    .gdlr-core-price-table-head {
      background-color: #e8b62d;
      background: -webkit-linear-gradient(#e8c35f, #e8b62d);
      background: -o-linear-gradient(#e8c35f, #e8b62d);
      background: -moz-linear-gradient(#e8c35f, #e8b62d);
      background: linear-gradient(#e8c35f, #e8b62d);
    }
    .gdlr-core-price-table-icon, .gdlr-core-price-table-title {
      color: #ffffff;
    }
    .gdlr-core-price-table-caption {
      color: #fff5c1;
    }
    .gdlr-core-price-table-price {
      background-color: #ececec;
    }
    .gdlr-core-price-table-price-number {
      color: #323232;
    }
  }
  .gdlr-core-price-prefix {
    color: #323232;
  }
  &.gdlr-core-active {
    .gdlr-core-price-suffix {
      color: #acacac;
    }
    .gdlr-core-price-table-button {
      color: #ffffff;
    }
  }
  .gdlr-core-price-table-button:hover {
    color: #ffffff;
  }
  &.gdlr-core-active .gdlr-core-price-table-button, .gdlr-core-price-table-button:hover {
    background-color: #e8b62d;
    background: -webkit-linear-gradient(#e8c668, #e8b62d);
    background: -o-linear-gradient(#e8c668, #e8b62d);
    background: -moz-linear-gradient(#e8c668, #e8b62d);
    background: linear-gradient(#e8c668, #e8b62d);
  }
}

.gdlr-core-accordion-style-icon .gdlr-core-accordion-item-icon, .gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-icon, .gdlr-core-toggle-box-style-icon .gdlr-core-toggle-box-item-icon, .gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-icon, .gdlr-core-accordion-style-icon .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-icon .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-title {
  color: #191919;
}

.gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-icon, .gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-icon {
  background-color: #f8c12c;
}

.gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-icon, .gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-icon {
  border-color: #f8c12c;
}

.gdlr-core-accordion-style-background-title-icon .gdlr-core-accordion-item-title:before, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-accordion-item-title:before, .gdlr-core-accordion-style-background-title .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-background-title-icon .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-background-title .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-toggle-box-item-title {
  color: #191919;
}

.gdlr-core-accordion-style-background-title .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-background-title-icon .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-background-title .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-toggle-box-item-title {
  background-color: #f3f3f3;
}

.gdlr-core-accordion-style-background-title-icon .gdlr-core-active .gdlr-core-accordion-item-title:before, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-active .gdlr-core-accordion-item-title:before, .gdlr-core-accordion-style-background-title .gdlr-core-active .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-background-title-icon .gdlr-core-active .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-background-title .gdlr-core-active .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-active .gdlr-core-toggle-box-item-title {
  color: #191919;
}

.gdlr-core-accordion-style-background-title .gdlr-core-active .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-background-title-icon .gdlr-core-active .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-background-title .gdlr-core-active .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-active .gdlr-core-toggle-box-item-title {
  background-color: #f3f3f3;
}

.gdlr-core-audio {
  background-color: #e7e7e7;
  .mejs-container .mejs-controls {
    background-color: #e7e7e7;
    .mejs-volume-button:before, .mejs-playpause-button:before, .mejs-time {
      color: #202020;
    }
  }
  .mejs-controls {
    .mejs-time-rail {
      .mejs-time-total, .mejs-time-loaded {
        background-color: #afafaf;
      }
      .mejs-time-current {
        background-color: #e8b62d;
      }
    }
    .mejs-horizontal-volume-slider {
      .mejs-horizontal-volume-total {
        background-color: #afafaf;
      }
      .mejs-horizontal-volume-current {
        background-color: #646464;
      }
    }
  }
}

.gdlr-core-alert-box-item {
  .gdlr-core-alert-box-item-inner {
    background-color: #ef5e68;
    border-color: #cd515a;
    color: #ffffff;
    &.alert-warning {
      background-color: #fcf8e3 !important;
      border-color: #e0ca74 !important;
      color: #997521 !important;
      .gdlr-core-alert-box-item-title {
        color: #997521 !important;
      }
    }
  }
  .gdlr-core-alert-box-item-title {
    color: #ffffff;
  }
}

blockquote, q {
  color: #777777;
}

blockquote, q {
  background-color: #f5f5f5;
}

.realfactory-body {
  blockquote, q {
    border-color: #e2e2e2;
  }
}

.gdlr-core-blockquote-item-quote, .gdlr-core-blockquote-item-content, .gdlr-core-blockquote-item-author {
  color: #4e4e4e;
}

.gdlr-core-body {
  .gdlr-core-load-more, .gdlr-core-button {
    color: #ffffff;
  }
}

.realfactory-body {
  .realfactory-button {
    color: #ffffff;
  }
  input {
    &[type="button"], &[type="submit"] {
      color: #ffffff;
    }
  }
}

.gdlr-core-body {
  .gdlr-core-load-more:hover, .gdlr-core-button:hover {
    color: #ffffff;
  }
  .gdlr-core-load-more, .gdlr-core-button {
    background-color: #2F2F2F;
  }
}

.realfactory-body {
  .realfactory-button {
    background-color: #2F2F2F;
  }
  input {
    &[type="button"], &[type="submit"] {
      background-color: #2F2F2F;
    }
  }
}

.gdlr-core-body {
  .gdlr-core-load-more:hover, .gdlr-core-button:hover {
    background-color: #2F2F2F;
  }
  .gdlr-core-button-with-border {
    border-color: #383838;
    &.gdlr-core-button-transparent {
      color: #383838;
    }
    &:hover {
      border-color: #000000;
    }
    &.gdlr-core-button-transparent:hover {
      color: #000000;
    }
  }
  .gdlr-core-button.gdlr-core-button-gradient {
    background-color: #e8b62d;
    background: -webkit-linear-gradient(#e8c158, #e8b62d);
    background: -o-linear-gradient(#e8c158, #e8b62d);
    background: -moz-linear-gradient(#e8c158, #e8b62d);
    background: linear-gradient(#e8c158, #e8b62d);
  }
}

.gdlr-core-call-to-action-item-title {
  color: #2c2c2c;
}

.gdlr-core-call-to-action-item-caption {
  color: #535353;
}

.gdlr-core-counter-item-top-text {
  color: #828282;
}

.gdlr-core-counter-item-top-icon, .gdlr-core-counter-item-number {
  color: #393939;
}

.gdlr-core-counter-item-divider {
  border-color: #393939;
}

.gdlr-core-counter-item-bottom-text {
  color: #393939;
}

.gdlr-core-column-service-item {
  .gdlr-core-column-service-icon {
    color: #9d9d9d;
  }
  .gdlr-core-icon-style-round i {
    background-color: #f3f3f3;
  }
  .gdlr-core-column-service-title {
    color: #383838;
  }
  .gdlr-core-column-service-caption {
    color: #747474;
  }
}

.gdlr-core-dropdown-tab {
  .gdlr-core-dropdown-tab-title, .gdlr-core-dropdown-tab-head-wrap {
    background-color: #212121;
  }
  .gdlr-core-dropdown-tab-title {
    color: #f7c02e;
  }
}

.gdlr-core-flipbox-item {
  .gdlr-core-flipbox-front, .gdlr-core-flipbox-back {
    background-color: #e8b62d;
  }
}

.gdlr-core-feature-box-item .gdlr-core-feature-box {
  background-color: #e8b62d;
}

.gdlr-core-flipbox-item {
  .gdlr-core-flipbox-front, .gdlr-core-flipbox-back, .gdlr-core-flipbox-frame {
    border-color: #d39d1d;
  }
}

.gdlr-core-feature-box-item {
  .gdlr-core-feature-box, .gdlr-core-feature-box-frame {
    border-color: #d39d1d;
  }
}

.gdlr-core-flipbox-item .gdlr-core-flipbox-item-icon, .gdlr-core-feature-box-item .gdlr-core-feature-box-item-icon, .gdlr-core-flipbox-item .gdlr-core-flipbox-item-title, .gdlr-core-feature-box-item .gdlr-core-feature-box-item-title, .gdlr-core-flipbox-item .gdlr-core-flipbox-item-caption, .gdlr-core-feature-box-item .gdlr-core-feature-box-item-caption, .gdlr-core-flipbox-item .gdlr-core-flipbox-item-content, .gdlr-core-feature-box-item .gdlr-core-feature-box-item-content {
  color: #ffffff;
}

.gdlr-core-image-overlay {
  &.gdlr-core-gallery-image-overlay {
    .gdlr-core-image-overlay-title {
      color: #ffffff;
    }
    .gdlr-core-image-overlay-caption {
      color: #cecece;
    }
  }
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.6);
}

.gdlr-core-image-overlay-content {
  color: #e8b62d;
  a {
    color: #e8b62d;
  }
}

.gdlr-core-image-overlay-icon {
  color: #e8b62d;
}

.gdlr-core-page-builder-body {
  [data-skin] .gdlr-core-image-overlay-icon, .gdlr-core-pbf-column[data-skin] .gdlr-core-image-overlay-icon {
    color: #e8b62d;
  }
}

.gdlr-core-body .gdlr-core-image-item-wrap {
  border-color: #000000;
}

.gdlr-core-block-item-title-wrap {
  .gdlr-core-block-item-title {
    color: #343434;
  }
  .gdlr-core-block-item-caption {
    color: #747474;
  }
  a {
    color: #747474;
    &:hover {
      color: #747474;
    }
  }
}

.gdlr-core-icon-list-item i {
  color: #222222;
}

.gdlr-core-icon-list-with-background-round .gdlr-core-icon-list-icon-wrap, .gdlr-core-icon-list-with-background-circle .gdlr-core-icon-list-icon-wrap {
  color: #f3f3f3;
}

.gdlr-core-opening-hour-item {
  .gdlr-core-opening-hour-day {
    color: #a5a5a5;
  }
  .gdlr-core-opening-hour-open {
    color: #262626;
  }
  .gdlr-core-opening-hour-close {
    color: #c8c8c8;
  }
  .gdlr-core-opening-hour-time i {
    color: #8a8989;
  }
  .gdlr-core-opening-hour-list-item {
    border-color: #a6a6a6;
  }
}

.gdlr-core-personnel-style-grid {
  .gdlr-core-personnel-list-title {
    color: #383838;
    a {
      color: #383838;
    }
  }
  .gdlr-core-personnel-list-position {
    color: #888888;
  }
  .gdlr-core-personnel-list-divider {
    color: #cecece;
  }
  &.gdlr-core-with-background .gdlr-core-personnel-list-content-wrap {
    background-color: #f9f9f9;
  }
}

.gdlr-core-personnel-style-modern {
  .gdlr-core-personnel-list-title {
    color: #ffffff;
    a {
      color: #ffffff;
    }
  }
  .gdlr-core-personnel-list-position {
    color: #ffffff;
  }
}

.gdlr-core-promo-box-item {
  .gdlr-core-promo-box-item-title {
    color: #383838;
  }
  .gdlr-core-promo-box-content-wrap {
    border-color: #e8e7e7;
  }
}

.gdlr-core-post-slider-item {
  .gdlr-core-post-slider-title a {
    color: #ffffff;
  }
  .gdlr-core-blog-info {
    color: #c5c5c5;
    a {
      color: #c5c5c5;
    }
  }
}

.gdlr-core-skill-bar-item {
  .gdlr-core-skill-bar-title, .gdlr-core-skill-bar-right {
    color: #505050;
  }
  .gdlr-core-skill-bar-icon {
    color: #616161;
  }
  .gdlr-core-skill-bar-progress {
    background-color: #f3f3f3;
  }
  .gdlr-core-skill-bar-filled {
    background-color: #e8b62d;
  }
}

.gdlr-core-flexslider-nav .flex-direction-nav li a, .gdlr-core-flexslider.gdlr-core-nav-style-middle-large .flex-direction-nav li a {
  color: #a7a7a7;
  border-color: #a7a7a7;
}

.gdlr-core-flexslider-nav.gdlr-core-round-style li a {
  background-color: #f1f1f1;
}

.gdlr-core-flexslider .flex-control-nav li a {
  border-color: #8a8a8a;
  &.flex-active {
    background-color: #8a8a8a;
  }
}

.gdlr-core-social-share-item {
  a {
    color: #202020;
  }
  .gdlr-core-divider {
    border-color: #e5e5e5;
  }
  .gdlr-core-social-share-count {
    color: #202020;
  }
}

.gdlr-core-stunning-text-item-caption {
  color: #747474;
}

.gdlr-core-stunning-text-item-title {
  color: #383838;
}

.gdlr-core-tab-item-title {
  color: #8d8d8d;
}

.gdlr-core-tab-style1-horizontal .gdlr-core-tab-item-title, .gdlr-core-tab-style1-vertical .gdlr-core-tab-item-title {
  background-color: #f7f7f7;
}

.gdlr-core-tab-item-title-wrap, .gdlr-core-tab-item-content-wrap, .gdlr-core-tab-item-title {
  border-color: #ebebeb;
}

.gdlr-core-tab-item-title-line {
  border-color: #e8b62d;
}

.gdlr-core-tab-item-title.gdlr-core-active {
  color: #464646;
}

.gdlr-core-tab-style1-horizontal .gdlr-core-tab-item-title.gdlr-core-active, .gdlr-core-tab-style1-vertical .gdlr-core-tab-item-title.gdlr-core-active {
  background-color: #ffffff;
}

table tr {
  th {
    background-color: #e8b62d;
    color: #0c0c0c;
  }
  &:nth-child(odd) {
    background-color: #f9f9f9;
    color: #949494;
  }
  &:nth-child(even) {
    background-color: #f3f3f3;
    color: #949494;
  }
}

.gdlr-core-testimonial-item {
  .gdlr-core-testimonial-item-title {
    color: #232323;
  }
  .gdlr-core-testimonial-content {
    color: #707070;
  }
  .gdlr-core-testimonial-title, .gdlr-core-testimonial-position {
    color: #3b3b3b;
  }
  .gdlr-core-testimonial-quote {
    color: #282828;
  }
}

.gdlr-core-title-item {
  .gdlr-core-title-item-title {
    color: #383838;
    a {
      color: #383838;
    }
  }
  .gdlr-core-title-item-caption {
    color: #747474;
  }
}

.woocommerce .star-rating span {
  color: #e8b62d;
}

.single-product.woocommerce {
  #review_form #respond p.stars a {
    color: #e8b62d;
  }
  div.product .product_meta {
    color: #e8b62d;
    a {
      color: #e8b62d;
    }
  }
}

.woocommerce {
  #respond input#submit.alt, a.button.alt, button.button.alt, input.button.alt, #respond input#submit, a.button, button.button {
    background-color: #e8b62d;
  }
}

html .woocommerce {
  input.button, span.onsale {
    background-color: #e8b62d;
  }
}

span.woocommerce-Price-amount.amount {
  color: #e8b62d;
}

.woocommerce .price del, .gdlr-core-product-price del, del span.woocommerce-Price-amount.amount {
  color: #949494;
}

.woocommerce {
  #respond input#submit.alt:hover, a.button.alt:hover, button.button.alt:hover, input.button.alt:hover, #respond input#submit:hover, a.button:hover, button.button:hover, input.button:hover {
    background-color: #e8b62d;
  }
  #respond input#submit.alt, a.button.alt, button.button.alt, input.button.alt, #respond input#submit, a.button, button.button, input.button {
    color: #ffffff;
  }
  #respond input#submit, a.button, button.button, input.button {
    &.disabled {
      color: #ffffff;
    }
    &:disabled {
      color: #ffffff;
      &[disabled] {
        color: #ffffff;
      }
    }
  }
  #respond input#submit.alt:hover, a.button.alt:hover, button.button.alt:hover, input.button.alt:hover, #respond input#submit:hover, a.button:hover, button.button:hover, input.button:hover {
    color: #ffffff;
  }
}

.single-product.woocommerce div.product .quantity .qty, #add_payment_method #payment, .woocommerce-checkout #payment, .single-product.woocommerce #reviews #comments ol.commentlist li {
  background-color: #f3f3f3;
}

.gdlr-core-product-grid .gdlr-core-product-title a {
  color: #191919;
  &:hover {
    color: #434343;
  }
}

.gdlr-core-product-thumbnail {
  .gdlr-core-product-view-detail {
    color: #ffffff;
    &:hover {
      color: #ffffff;
    }
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0.9);
  }
  .added_to_cart, .gdlr-core-product-add-to-cart {
    color: #ffffff;
    &:hover {
      color: #ffffff;
    }
  }
  .added_to_cart, .gdlr-core-product-add-to-cart {
    background-color: #e8b62d;
    background-color: rgba(232, 182, 45, 0.9);
  }
}

.woocommerce .widget_price_filter {
  .price_slider_wrapper .ui-widget-content {
    background-color: #d6d6d6;
  }
  .ui-slider {
    .ui-slider-range {
      background-color: #eaeaea;
    }
    .ui-slider-handle {
      background-color: #cccccc;
    }
  }
}

/*--Custom --*/

.realfactory-top-bar-right {
  line-height: 32px;
}

.realfactory-logo-right-block .realfactory-logo-right-block-content {
  display: inline-block;
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .hide-tablet {
    display: none;
  }
}

.realfactory-mobile-header .realfactory-logo-inner {
  max-width: 160px;
}

.gdlr-core-page-builder-body {
  [data-skin="Dark"] {
    h1, h2, h3, h4, h5, h6 {
      color: #ffffff;
    }
    .gdlr-core-skin-title {
      color: #ffffff;
      a {
        color: #ffffff;
      }
    }
    .gdlr-core-skin-caption {
      color: #c4c4c4;
      a {
        color: #c4c4c4;
      }
    }
    color: #d8d8d8;
    .gdlr-core-skin-content {
      color: #d8d8d8;
    }
    i {
      color: #ffffff;
    }
    .gdlr-core-skin-icon {
      color: #ffffff;
      &:before {
        color: #ffffff;
      }
    }
    .realfactory-widget ul li:before, a, .gdlr-core-skin-link {
      color: #ffffff;
    }
    a:hover, .gdlr-core-skin-link:hover {
      color: #e6b52f;
    }
    .gdlr-core-skin-e-background, .gdlr-core-flexslider-nav.gdlr-core-round-style li a {
      background-color: #ffffff;
    }
    .gdlr-core-flexslider .flex-control-nav li a {
      border-color: #ffffff;
      &.flex-active {
        background-color: #ffffff;
      }
    }
    .gdlr-core-button, .gdlr-core-button-color {
      color: #e6b52f;
    }
    input {
      &[type="button"], &[type="submit"] {
        color: #e6b52f;
      }
    }
    .gdlr-core-pagination a {
      color: #e6b52f;
    }
    .gdlr-core-button, .gdlr-core-button-color, .gdlr-core-pagination a {
      border-color: #e6b52f;
    }
  }
  [data-skin="Call to action"] {
    h1, h2, h3, h4, h5, h6 {
      color: #e6b52f;
    }
    .gdlr-core-skin-title {
      color: #e6b52f;
      a {
        color: #e6b52f;
      }
    }
    color: #ffffff;
    .gdlr-core-skin-content {
      color: #ffffff;
    }
    a, .gdlr-core-skin-link {
      color: #ebb727;
    }
    a:hover, .gdlr-core-skin-link:hover {
      color: #cc9322;
    }
    .gdlr-core-button, .gdlr-core-button-color {
      color: #e6b52f;
    }
    input {
      &[type="button"], &[type="submit"] {
        color: #e6b52f;
      }
    }
    .gdlr-core-pagination a {
      color: #e6b52f;
    }
    .gdlr-core-button, .gdlr-core-button-color {
      background-color: #ffffff;
    }
    input {
      &[type="button"], &[type="submit"] {
        background-color: #ffffff;
      }
    }
    .gdlr-core-pagination a {
      background-color: #ffffff;
    }
  }
  [data-skin="Double Button"] {
    .gdlr-core-button, .gdlr-core-button-color {
      color: #f8c12c;
    }
    input {
      &[type="button"], &[type="submit"] {
        color: #f8c12c;
      }
    }
    .gdlr-core-pagination a, .gdlr-core-button:hover {
      color: #f8c12c;
    }
    .gdlr-core-button-color {
      &:hover, &.gdlr-core-active {
        color: #f8c12c;
      }
    }
    input {
      &[type="button"]:hover, &[type="submit"]:hover {
        color: #f8c12c;
      }
    }
    .gdlr-core-pagination {
      a {
        &:hover, &.gdlr-core-active {
          color: #f8c12c;
        }
      }
      span {
        color: #f8c12c;
      }
    }
    .gdlr-core-button, .gdlr-core-button-color {
      background-color: #2d2d2d;
    }
    input {
      &[type="button"], &[type="submit"] {
        background-color: #2d2d2d;
      }
    }
    .gdlr-core-pagination a {
      background-color: #2d2d2d;
    }
    .gdlr-core-button, .gdlr-core-button-color, .gdlr-core-pagination a {
      border-color: #f8c12c;
    }
  }
}

button-border-hover-color .gdlr-core-page-builder-body [data-skin="White Text"] h1 {
  color: #ffffff;
}

.gdlr-core-page-builder-body {
  [data-skin="White Text"] {
    h2, h3, h4, h5, h6 {
      color: #ffffff;
    }
    .gdlr-core-skin-title {
      color: #ffffff;
      a {
        color: #ffffff;
      }
    }
    .gdlr-core-skin-caption {
      color: #e5e5e5;
      a {
        color: #e5e5e5;
      }
    }
    color: #ffffff;
    .gdlr-core-skin-content, i {
      color: #ffffff;
    }
    .gdlr-core-skin-icon {
      color: #ffffff;
      &:before {
        color: #ffffff;
      }
    }
    .realfactory-widget ul li:before, a, .gdlr-core-skin-link {
      color: #ffffff;
    }
    a:hover, .gdlr-core-skin-link:hover {
      color: #dddddd;
    }
    .gdlr-core-skin-divider {
      border-color: #f2f2f2;
      column-rule-color: #f2f2f2;
      -moz-column-rule-color: #f2f2f2;
      -webkit-column-rule-color: #f2f2f2;
    }
    .gdlr-core-flexslider.gdlr-core-nav-style-middle-large .flex-direction-nav li a {
      border-color: #f2f2f2;
    }
    *, .gdlr-core-skin-border, input:not([type="button"]):not([type="submit"]):not([type="reset"]) {
      border-color: #ededed;
    }
    .gdlr-core-skin-e-background, .gdlr-core-flexslider-nav.gdlr-core-round-style li a {
      background-color: #ffffff;
    }
    .gdlr-core-flexslider .flex-control-nav li a {
      border-color: #ffffff;
      &.flex-active {
        background-color: #ffffff;
      }
    }
    .gdlr-core-skin-e-content, .gdlr-core-flexslider-nav.gdlr-core-round-style li a i {
      color: #3f3f3f;
    }
    .gdlr-core-button, .gdlr-core-button-color {
      color: #ffffff;
    }
    input {
      &[type="button"], &[type="submit"] {
        color: #ffffff;
      }
    }
    .gdlr-core-pagination a, .gdlr-core-button:hover {
      color: #ffffff;
    }
    .gdlr-core-button-color {
      &:hover, &.gdlr-core-active {
        color: #ffffff;
      }
    }
    input {
      &[type="button"]:hover, &[type="submit"]:hover {
        color: #ffffff;
      }
    }
    .gdlr-core-pagination {
      a {
        &:hover, &.gdlr-core-active {
          color: #ffffff;
        }
      }
      span {
        color: #ffffff;
      }
    }
    .gdlr-core-button, .gdlr-core-button-color {
      background-color: #191919;
    }
    input {
      &[type="button"], &[type="submit"] {
        background-color: #191919;
      }
    }
    .gdlr-core-pagination a {
      background-color: #191919;
    }
    .gdlr-core-button:hover {
      background-color: #3a3a3a;
    }
    .gdlr-core-button-color {
      &:hover, &.gdlr-core-active {
        background-color: #3a3a3a;
      }
    }
    input {
      &[type="button"]:hover, &[type="submit"]:hover {
        background-color: #3a3a3a;
      }
    }
    .gdlr-core-pagination {
      a {
        &:hover, &.gdlr-core-active {
          background-color: #3a3a3a;
        }
      }
      span {
        background-color: #3a3a3a;
      }
    }
  }
  [data-skin="Grey"] {
    h1, h2, h3, h4, h5, h6 {
      color: #000000;
    }
    .gdlr-core-skin-title {
      color: #000000;
      a {
        color: #000000;
        &:hover {
          color: #e6b52f;
        }
      }
    }
    .gdlr-core-skin-caption {
      color: #8c8c8c;
      a {
        color: #8c8c8c;
      }
    }
    color: #a3a3a3;
    .gdlr-core-skin-content {
      color: #a3a3a3;
    }
    i {
      color: #000000;
    }
    .gdlr-core-skin-icon {
      color: #000000;
      &:before {
        color: #000000;
      }
    }
    .realfactory-widget ul li:before {
      color: #000000;
    }
    a, .gdlr-core-skin-link {
      color: #232323;
    }
    a:hover, .gdlr-core-skin-link:hover {
      color: #7c7c7c;
    }
    .gdlr-core-button, .gdlr-core-button-color {
      color: #ffffff;
    }
    input {
      &[type="button"], &[type="submit"] {
        color: #ffffff;
      }
    }
    .gdlr-core-pagination a, .gdlr-core-button:hover {
      color: #ffffff;
    }
    .gdlr-core-button-color {
      &:hover, &.gdlr-core-active {
        color: #ffffff;
      }
    }
    input {
      &[type="button"]:hover, &[type="submit"]:hover {
        color: #ffffff;
      }
    }
    .gdlr-core-pagination {
      a {
        &:hover, &.gdlr-core-active {
          color: #ffffff;
        }
      }
      span {
        color: #ffffff;
      }
    }
    .gdlr-core-button, .gdlr-core-button-color {
      background-color: #232323;
    }
    input {
      &[type="button"], &[type="submit"] {
        background-color: #232323;
      }
    }
    .gdlr-core-pagination a, .gdlr-core-button:hover {
      background-color: #232323;
    }
    .gdlr-core-button-color {
      &:hover, &.gdlr-core-active {
        background-color: #232323;
      }
    }
    input {
      &[type="button"]:hover, &[type="submit"]:hover {
        background-color: #232323;
      }
    }
    .gdlr-core-pagination {
      a {
        &:hover, &.gdlr-core-active {
          background-color: #232323;
        }
      }
      span {
        background-color: #232323;
      }
    }
  }
  [data-skin="About Counter"] {
    h1, h2, h3, h4, h5, h6 {
      color: #ffffff;
    }
    .gdlr-core-skin-title {
      color: #ffffff;
      a {
        color: #ffffff;
      }
    }
    .gdlr-core-skin-caption {
      color: #f8c12c;
      a {
        color: #f8c12c;
      }
    }
    color: #f8c12c;
    .gdlr-core-skin-content {
      color: #f8c12c;
    }
  }
  [data-skin="Portfolio Homepage"] {
    h1, h2, h3, h4, h5, h6 {
      color: #000000;
    }
    .gdlr-core-skin-title {
      color: #000000;
      a {
        color: #000000;
      }
    }
    .gdlr-core-skin-caption {
      color: #222222;
      a {
        color: #222222;
      }
    }
    a:hover, .gdlr-core-skin-link:hover {
      color: #3d3d3d;
    }
  }
  [data-skin="Yellow Link"] {
    a, .gdlr-core-skin-link {
      color: #d8a419;
    }
    a:hover, .gdlr-core-skin-link:hover {
      color: #f2b91d;
    }
  }
  [data-skin="Yellow Service"] {
    h1, h2, h3, h4, h5, h6 {
      color: #000000;
    }
    .gdlr-core-skin-title {
      color: #000000;
      a {
        color: #000000;
      }
    }
    color: #000000;
    .gdlr-core-skin-content {
      color: #000000;
    }
    a, .gdlr-core-skin-link {
      color: #ffffff;
    }
    a:hover, .gdlr-core-skin-link:hover {
      color: #cecece;
    }
    .gdlr-core-button, .gdlr-core-button-color {
      color: #191919;
    }
    input {
      &[type="button"], &[type="submit"] {
        color: #191919;
      }
    }
    .gdlr-core-pagination a {
      color: #191919;
    }
    .gdlr-core-button, .gdlr-core-button-color {
      background-color: #ffffff;
    }
    input {
      &[type="button"], &[type="submit"] {
        background-color: #ffffff;
      }
    }
    .gdlr-core-pagination a {
      background-color: #ffffff;
    }
  }
  [data-skin="Call To action 2"] {
    h1, h2, h3, h4, h5, h6 {
      color: #000000;
    }
    .gdlr-core-skin-title {
      color: #000000;
      a {
        color: #000000;
      }
    }
    .gdlr-core-button, .gdlr-core-button-color {
      color: #ffffff;
    }
    input {
      &[type="button"], &[type="submit"] {
        color: #ffffff;
      }
    }
    .gdlr-core-pagination a {
      color: #ffffff;
    }
    .gdlr-core-button, .gdlr-core-button-color {
      background-color: #1e1e1e;
    }
    input {
      &[type="button"], &[type="submit"] {
        background-color: #1e1e1e;
      }
    }
    .gdlr-core-pagination a {
      background-color: #1e1e1e;
    }
  }
  [data-skin="Blog Homepage"] {
    .gdlr-core-skin-e-background, .gdlr-core-flexslider-nav.gdlr-core-round-style li a {
      background-color: #f7f7f7;
    }
    .gdlr-core-flexslider .flex-control-nav li a {
      border-color: #f7f7f7;
      &.flex-active {
        background-color: #f7f7f7;
      }
    }
  }
  [data-skin="Features"] {
    h1, h2, h3, h4, h5, h6 {
      color: #595959;
    }
    .gdlr-core-skin-title {
      color: #595959;
      a {
        color: #595959;
      }
    }
  }
  [data-skin="White Text"] .gdlr-core-button.gdlr-core-button-gradient {
    background: -webkit-linear-gradient(#191919, #3a3a3a);
    background: -o-linear-gradient(#191919, #3a3a3a);
    background: -moz-linear-gradient(#191919, #3a3a3a);
    background: linear-gradient(#191919, #3a3a3a);
  }
  [data-skin="Grey"] .gdlr-core-button.gdlr-core-button-gradient {
    background: -webkit-linear-gradient(#232323, #232323);
    background: -o-linear-gradient(#232323, #232323);
    background: -moz-linear-gradient(#232323, #232323);
    background: linear-gradient(#232323, #232323);
  }
}

.realfactory-item-mgb, .gdlr-core-item-mgb {
  margin-bottom: 30px;
}

.realfactory-sidebar-left .realfactory-sidebar-area {
  padding-right: 40px;
}

.realfactory-sidebar-right .realfactory-sidebar-area {
  padding-left: 40px;
}

.realfactory-sidebar-left .realfactory-sidebar-area {
  margin-right: 25px;
}

.realfactory-sidebar-right .realfactory-sidebar-area {
  margin-left: 25px;
}
