html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q,
s, samp, small, strike, strong, sub, sup, tt, var, b, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, legend, table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, audio, canvas, details, figcaption, figure, footer, header, hgroup,
mark, menu, meter, nav, output, progress, section, summary, time, video {
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  margin: 0;
  padding: 0;
  font-family: "Roboto",sans-serif;
}

article, aside, dialog, figure, footer, header, hgroup, nav, section, blockquote {
  display: block;
}

nav ul {
  list-style: none;
}

ol {
  list-style: decimal;
}

ul {
  list-style: disc;
}

ul ul {
  list-style: circle;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

ins {
  text-decoration: underline;
}

del {
  text-decoration: line-through;
}

mark {
  background: none;
}

abbr[title], dfn[title], acronym[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input[type=submit], input[type=button], button {
  margin: 0;
  padding: 0;
}

input, select, a img {
  vertical-align: middle;
}

b, strong {
  font-weight: bold;
}

i, em {
  font-style: italic;
}

html {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  -moz-box-sizing: inherit;
  -webkit-box-sizing: inherit;
}

/* generic class */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
  word-break: break-word;
}

p {
  margin-bottom: 20px;
}

ul, ol {
  margin-left: 35px;
  margin-bottom: 20px;
}

dl {
  margin-bottom: 20px;
}

dl dt {
  font-weight: 700;
  margin-top: 10px;
}

dl dt:first-child {
  margin-top: 0;
}

dl dd {
  margin-left: 20px;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
  margin-bottom: 20px;
}

figure {
  max-width: 100%;
}

.clear {
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
  zoom: 1;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: " ";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

/* wordpress core */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter, div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto
}

.wp-caption {
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

figcaption.wp-caption-text {
  margin-top: 20px;
}

.bypostauthor {
}

.gallery-caption {
}

.sticky {
}

.navigation {
  text-align: right;
  margin-bottom: 30px;
}

.navigation .screen-reader-text {
  display: none;
}

.navigation a, .navigation span {
  margin: 0 5px;
}

.post-password-form input[type="password"] {
  padding: 14px 20px;
  margin-left: 5px;
  margin-right: 6px;
}

/* layout */
.realfactory-body-outer-wrapper {
  position: relative;
  overflow: hidden;
}

.realfactory-body-wrapper.realfactory-with-frame {
  position: relative;
}

.realfactory-page-wrapper {
  clear: both;
}

body.realfactory-boxed .realfactory-body-wrapper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

body.realfactory-boxed .realfactory-body-wrapper.realfactory-left {
  margin-left: 0;
}

body.realfactory-boxed .realfactory-body-wrapper.realfactory-right {
  margin-right: 0;
}

body.realfactory-boxed.realfactory-boxed-border .realfactory-body-wrapper {
  box-shadow: 0 -5px 9px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 -5px 9px rgba(0, 0, 0, 0.25);
}

body.realfactory-boxed.realfactory-background-pattern {
  background-position: center;
  background-repeat: repeat;
}

body.realfactory-boxed.realfactory-background-image .realfactory-body-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: cover;
}

.realfactory-container {
  margin-left: auto;
  margin-right: auto;
}

.realfactory-column-10 {
  float: left;
  width: 16.6666%;
}

.realfactory-column-12 {
  float: left;
  width: 20%;
}

.realfactory-column-15 {
  float: left;
  width: 25%;
}

.realfactory-column-20 {
  float: left;
  width: 33.3333%;
}

.realfactory-column-24 {
  float: left;
  width: 40%;
}

.realfactory-column-30 {
  float: left;
  width: 50%;
}

.realfactory-column-36 {
  float: left;
  width: 60%;
}

.realfactory-column-40 {
  float: left;
  width: 66.6666%;
}

.realfactory-column-45 {
  float: left;
  width: 75%;
}

.realfactory-column-48 {
  float: left;
  width: 80%;
}

.realfactory-column-50 {
  float: left;
  width: 83.3333%;
}

.realfactory-column-60 {
  float: left;
  width: 100%;
}

.realfactory-sidebar-right {
  float: right;
}

.realfactory-sidebar-center {
  float: none;
  display: inline-block;
}

.realfactory-content-area {
  padding-top: 60px;
  padding-bottom: 40px;
}

.realfactory-sidebar-area {
  padding-top: 60px;
}

.realfactory-line-height-0 {
  line-height: 0;
}

a, button {
  text-decoration: none;
  transition: background 300ms, color 300ms, border-color 300ms;
  -moz-transition: background 300ms, color 300ms, border-color 300ms;
  -o-transition: background 300ms, color 300ms, border-color 300ms;
  -webkit-transition: background 300ms, color 300ms, border-color 300ms;
}

img {
  max-width: 100%;
  height: auto;
}

blockquote {
  quotes: none;
  padding: 30px 30px 15px;
  font-size: 17px;
  margin-bottom: 35px;
  font-style: italic;
  margin-top: 35px;
  border-left-width: 2px;
  border-left-style: solid;
}

q {
  margin-right: 15px;
  display: inline-block;
  padding: 10px 20px;
  vertical-align: middle;
  border-left-width: 2px;
  border-left-style: solid;
}

.realfactory-media-image {
  line-height: 0;
  position: relative;
}

.realfactory-left-align {
  text-align: left;
}

.realfactory-center-align {
  text-align: center;
}

.realfactory-right-align {
  text-align: right;
}

.realfactory-body-wrapper {
  overflow: hidden;
  z-index: 1;
}

/* input */
input, textarea {
  border-width: 1px 1px 2px 1px;
  border-style: solid;
}

input:focus, textarea:focus {
  outline: none;
}

/* button */
.realfactory-button, input[type="button"], input[type="submit"], input[type="reset"] {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  padding: 15px 33px;
  display: inline-block;
  -webkit-appearance: none;
}

input[type="button"], input[type="submit"], input[type="reset"] {
  border-width: 0;
  border-style: solid;
  cursor: pointer;
  transition: background 300ms, color 300ms, border-color 300ms;
  -moz-transition: background 300ms, color 300ms, border-color 300ms;
  -o-transition: background 300ms, color 300ms, border-color 300ms;
  -webkit-transition: background 300ms, color 300ms, border-color 300ms;
}

input[type="button"]:focus, input[type="submit"]:focus, input[type="reset"]:focus {
  outline: none;
}

/**
 * 2.0 - Header
 */

/* preload */
.realfactory-page-preload {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  background-image: url('../images/preload.gif');
  background-position: center;
  background-repeat: no-repeat;
}

.realfactory-page-preload.realfactory-out {
  background-image: none;
}

/* top bar */
.realfactory-top-bar {
  position: relative;
  border-bottom-style: solid;
}

.realfactory-top-bar-background {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.realfactory-top-bar-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.realfactory-top-bar-left {
  float: left;
}

.realfactory-top-bar-right {
  float: right;
}

.realfactory-top-bar-right-text {
  display: inline;
}

.realfactory-top-bar-right-social {
  display: inline;
  margin-left: 10px;
}

.realfactory-top-bar-right-social a {
  margin-left: 25px;
}

/* mobile menu */
.realfactory-mm-menu-wrap {
  display: none;
}

.admin-bar .mm-menu {
  margin-top: 32px;
}

.realfactory-mobile-button-hamburger-with-border {
  font-size: 16px;
  padding: 6px;
  line-height: 0;
  width: 32px;
  height: 32px;
  text-align: center;
  display: inline-block;
  border-width: 2px;
  border-style: solid;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.realfactory-right-menu-button {
  display: inline-block;
  margin-left: 27px;
}

.realfactory-mobile-menu {
  float: left;
  margin-top: -8px;
  margin-left: 30px;
}

.realfactory-mobile-menu-right {
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -9px;
}

/* hamburger */
.realfactory-mobile-button-hamburger {
  box-sizing: border-box;
  display: block;
  width: 32px;
  height: 32px;
  position: relative;
}

.realfactory-mobile-button-hamburger:before,
.realfactory-mobile-button-hamburger:after,
.realfactory-mobile-button-hamburger span {
  content: '';
  display: block;
  width: 22px;
  height: 2px;
  position: absolute;
  left: 5px;
}

.realfactory-mobile-button-hamburger:before {
  top: 8px;
}

.realfactory-mobile-button-hamburger span {
  top: 15px;
}

.realfactory-mobile-button-hamburger:after {
  top: 22px;
}

.realfactory-mobile-button-hamburger:before {
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.realfactory-mobile-button-hamburger span {
  transition: opacity 0.15s 0.4s ease;
}

.realfactory-mobile-button-hamburger:after {
  transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.realfactory-mobile-button-hamburger.realfactory-active:before {
  -webkit-transform: translate3d(0, 7px, 0) rotate(135deg);
  transform: translate3d(0, 7px, 0) rotate(135deg);
  transition-delay: 0.1s;
}

.realfactory-mobile-button-hamburger.realfactory-active span {
  transition-delay: 0s;
  opacity: 0;
}

.realfactory-mobile-button-hamburger.realfactory-active:after {
  transition-delay: 0.1s;
  -webkit-transform: translate3d(0, -7px, 0) rotate(-135deg);
  transform: translate3d(0, -7px, 0) rotate(-135deg);
}

/* navigation menu */
.realfactory-navigation .sf-menu {
  display: inline-block;
  line-height: 1.7;
  text-align: left;
  margin-bottom: 0;
}

.realfactory-navigation .sf-menu > li:first-child {
  padding-left: 0;
}

.realfactory-navigation .sf-menu > li:last-child {
  padding-right: 0;
}

.realfactory-navigation .sf-menu > li > a {
  letter-spacing: 0.5px;
}

.realfactory-navigation-submenu-indicator .sf-menu > li > a.sf-with-ul-pre:after {
  display: inline-block;
  margin-left: 13px;
  content: "\f107";
  font-family: "fontAwesome",sans-serif;
}

.realfactory-navigation .sf-menu > .realfactory-normal-menu li {
  padding: 1px 11px;
}

.realfactory-navigation .sf-menu > .realfactory-normal-menu li:first-child {
  padding-top: 12px;
}

.realfactory-navigation .sf-menu > .realfactory-normal-menu li:last-child {
  padding-bottom: 12px;
}

.realfactory-navigation .sf-menu > .realfactory-normal-menu li li { /* padding-left: 0; */
}

.realfactory-navigation .sf-menu > .realfactory-normal-menu li > a {
  display: block;
  padding: 6px 12px;
}

.realfactory-navigation .sf-menu > .realfactory-normal-menu li > a.sf-with-ul-pre:after {
  float: right;
  margin-left: 18px;
  content: "\f105";
  font-family: fontAwesome;
}

.realfactory-navigation .sf-menu > .realfactory-normal-menu .sub-menu {
  font-size: 14px;
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega-full {
  left: 0;
  right: 0;
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega-section-wrap {
  overflow: hidden;
  border-top-width: 1px;
  border-top-style: solid;
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega-section-wrap:first-child {
  border-top: none;
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega-section {
  padding-bottom: 999px;
  margin-bottom: -999px;
  border-left-width: 1px;
  border-left-style: solid;
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega-section:first-child {
  border-left: none;
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega-section-inner {
  padding: 31px 28px;
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega-section-inner > a {
  font-size: 15px;
  display: block;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 0 12px;
}

.realfactory-navigation .sf-menu > .realfactory-mega-menu .sf-mega-section-inner .sub-menu a {
  font-size: 14px;
  padding: 5px 12px;
  display: block;
}

.realfactory-navigation .realfactory-navigation-slide-bar {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  position: absolute;
  display: none;
  margin-top: -4px;
  overflow: hidden;
}

.realfactory-navigation .sf-vertical > li {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.realfactory-navigation .sf-vertical > li:first-child {
  padding-top: 0;
}

.realfactory-navigation .sf-vertical > li > a {
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: block;
}

.realfactory-navigation .sf-vertical ul.sub-menu {
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

.realfactory-navigation .sf-vertical ul.sub-menu li {
  padding: 2px 11px;
}

.realfactory-navigation .sf-vertical ul.sub-menu li:first-child {
  padding-top: 20px;
}

.realfactory-navigation .sf-vertical ul.sub-menu li:last-child {
  padding-bottom: 20px;
}

.realfactory-navigation .sf-vertical ul.sub-menu li > a {
  display: block;
  padding: 4px 12px;
}

.realfactory-navigation .sf-vertical ul.sub-menu li > a.sf-with-ul:after,
.realfactory-navigation-submenu-indicator .sf-vertical > li > a.sf-with-ul:after {
  float: right;
  margin-left: 18px;
  content: "\f105";
  font-family: fontAwesome;
}

/* bullet anchor */
.realfactory-bullet-anchor {
  position: fixed;
  top: 50%;
  right: 30px;
  margin-top: -50px;
  z-index: 10;
  opacity: 0;
  transition: opacity 200ms;
  -moz-transition: opacity 200ms;
  -o-transition: opacity 200ms;
  -webkit-transition: opacity 200ms;
}

.realfactory-bullet-anchor.realfactory-init {
  opacity: 1;
}

.realfactory-bullet-anchor a {
  display: block;
  width: 17px;
  height: 17px;
  margin-bottom: 5px;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.realfactory-bullet-anchor a:before {
  content: " ";
  width: 9px;
  height: 9px;
  margin: 3px;
  display: block;
  overflow: hidden;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  transition: background-color 200ms;
  -moz-transition: background-color 200ms;
  -o-transition: background-color 200ms;
  -webkit-transition: background-color 200ms;
}

/* breadcrumbs */
.realfactory-breadcrumbs a {
  color: #c0c0c0;
}

.realfactory-breadcrumbs,
.realfactory-breadcrumbs a:hover {
  color: #777;
}

.realfactory-breadcrumbs {
  font-size: 15px;
  padding: 21px 0 19px;
}

.realfactory-breadcrumbs span[property="itemListElement"] {
  margin: 0 15px;
}

.realfactory-breadcrumbs span[property="itemListElement"]:first-child {
  margin-left: 0;
}

.realfactory-breadcrumbs a span {
  transition: color 200ms;
  -moz-transition: color 200ms;
  -o-transition: color 200ms;
  -webkit-transition: color 200ms;
}

/* top search */
.realfactory-top-search-wrap input.search-field {
  border-bottom-color: #303030;
  color: #fff;
}

.realfactory-top-search-wrap .realfactory-top-search-submit,
.realfactory-top-search-wrap .realfactory-top-search-close {
  color: #fff;
}

.realfactory-top-search-wrap input::-webkit-input-placeholder {
  color: #fff;
}

.realfactory-top-search-wrap input::-moz-placeholder {
  color: #fff;
}

.realfactory-top-search-wrap input:-ms-input-placeholder {
  color: #fff;
}

.realfactory-top-search-wrap input:-moz-placeholder {
  color: #fff;
}

.realfactory-top-search-row {
  display: table;
  width: 100%;
  height: 100%;
}

.realfactory-top-search-cell {
  display: table-cell;
  vertical-align: middle;
  padding-left: 30px;
  padding-right: 30px;
}

.realfactory-top-search-wrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: auto;
  display: none;
}

.realfactory-top-search-wrap form {
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.realfactory-top-search-wrap input.search-field {
  background: transparent;
  width: 100%;
  border-width: 0 0 3px;
  font-size: 40px;
  padding: 0 120px 25px 0;
  line-height: 48px;
  letter-spacing: 0.5px;
}

.realfactory-top-search-wrap input.search-submit {
  position: absolute;
  top: 0;
  right: 52px;
  width: 48px;
  height: 48px;
  padding: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.realfactory-top-search-wrap .realfactory-top-search-submit {
  font-size: 30px;
  position: absolute;
  right: 52px;
  top: 0;
  line-height: 48px;
  width: 48px;
  text-align: center;
}

.realfactory-top-search-wrap .realfactory-top-search-close {
  font-size: 48px;
  position: absolute;
  right: 0;
  top: 0;
  line-height: 48px;
  width: 48px;
  text-align: center;
  cursor: pointer;
}

/* overlay menu */
.realfactory-overlay-menu-row {
  display: table;
  width: 100%;
  height: 100%;
}

.realfactory-overlay-menu-cell {
  display: table-cell;
  vertical-align: middle;
}

.realfactory-overlay-menu-content {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  overflow-y: auto;
  display: none;
}

.realfactory-overlay-menu-content ul.menu {
  list-style: none;
  text-align: center;
  padding-top: 140px;
  padding-bottom: 180px;
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
}

.realfactory-overlay-menu-content ul.menu > li {
  font-size: 23px;
  font-weight: bold;
  text-transform: uppercase;
  opacity: 0;
  margin-bottom: 17px;
  padding-bottom: 17px;
  letter-spacing: 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  -webkit-transform: translate3d(0, -30px, 0);
  transform: translate3d(0, -30px, 0);
  transition: opacity 300ms, transform 300ms;
  -moz-transition: opacity 300ms, -moz-transform 300ms;
  -o-transition: opacity 300ms, -o-transform 300ms;
  -webkit-transition: opacity 300ms, -webkit-transform 300ms;
}

.realfactory-overlay-menu-content.realfactory-active ul.menu > li {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: none;
  transform: none;
}

.realfactory-overlay-menu-content ul.sub-menu {
  display: none;
  padding-top: 25px;
  padding-bottom: 10px;
  list-style: none;
  margin-left: 0;
}

.realfactory-overlay-menu-content ul.sub-menu > li {
  font-size: 14px;
  text-transform: none;
  font-weight: normal;
  margin-bottom: 15px;
}

.realfactory-overlay-menu-content ul.sub-menu ul.sub-menu {
  max-width: 380px;
  margin: 25px auto 25px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
}

.realfactory-overlay-menu-content .realfactory-overlay-menu-close {
  line-height: 1;
  font-size: 48px;
  position: absolute;
  left: 50%;
  top: 60px;
  margin-left: -24px;
  cursor: pointer;
}

.realfactory-overlay-menu-content .realfactory-overlay-menu-close:after {
  content: "\4d";
  font-family: elegantIcons;
}

.realfactory-overlay-menu-content ul li.menu-item-has-children > a:after {
  content: "\f107";
  font-family: "fontAwesome";
  margin-left: 15px;
}

body.admin-bar .realfactory-overlay-menu-content {
  margin-top: 32px;
}

/* menu right area */
.realfactory-main-menu-right-button {
  float: left;
  padding: 8px 15px;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: -8px;
  margin-left: 30px;
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
}

.realfactory-main-menu-right {
  float: left;
  margin-top: -5px;
}

.realfactory-main-menu-search {
  float: left;
  margin-top: -1px;
  margin-left: 25px;
}

.realfactory-main-menu-search i {
  font-size: 14px;
  margin-top: -1px;
  cursor: pointer;
}

.realfactory-main-menu-cart {
  float: left;
  margin-top: -0px;
  padding-left: 20px;
  margin-left: 5px;
  position: relative;
}

.realfactory-main-menu-cart i {
  font-size: 16px;
  margin-top: -1px;
  cursor: pointer;
}

.realfactory-main-menu-cart > .realfactory-top-cart-count {
  font-size: 10px;
  font-weight: 700;
  vertical-align: super;
  width: 16px;
  height: 16px;
  line-height: 15px;
  position: absolute;
  top: -5px;
  right: -8px;
  text-align: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.realfactory-top-cart-content-wrap {
  position: absolute;
  width: 210px;
  right: 0;
  top: 100%;
  padding-top: 24px;
  z-index: 99;
}

.realfactory-top-cart-content-wrap .realfactory-top-cart-content {
  padding: 25px;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
}

.realfactory-top-cart-content-wrap .woocommerce-Price-amount.amount {
  float: none;
}

.realfactory-top-cart-content-wrap .realfactory-top-cart-count-wrap {
  margin-bottom: 8px;
}

.realfactory-top-cart-content-wrap .realfactory-top-cart-amount-wrap {
  margin-bottom: 23px;
}

.realfactory-top-cart-content-wrap .realfactory-top-cart-button {
  display: block;
  margin-bottom: 5px;
}

.realfactory-top-cart-content-wrap .realfactory-top-cart-checkout-button {
  display: block;
}

.realfactory-main-menu-cart .realfactory-top-cart-hover-area {
  position: absolute;
  top: -20px;
  right: 0;
  bottom: -20px;
  left: 0;
}

.realfactory-top-cart-hover-area + .realfactory-top-cart-content-wrap {
  opacity: 0;
  visibility: hidden;
  transform: translate3d(0, 15px, 0);
  -webkit-transform: translate3d(0, 15px, 0);
  transition: opacity 400ms, transform 400ms;
  -moz-transition: opacity 400ms, -moz-transform 400ms;
  -o-transition: opacity 400ms, -o-transform 400ms;
  -webkit-transition: opacity 400ms, -webkit-transform 400ms;
}

.realfactory-main-menu-cart:hover .realfactory-top-cart-content-wrap,
.realfactory-top-cart-hover-area:hover + .realfactory-top-cart-content-wrap {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

/* header */
.realfactory-logo {
  position: relative;
}

.realfactory-header-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.realfactory-header-container-inner {
  position: relative;
}

/* header right */
.realfactory-logo-right-block {
  text-align: left;
  display: inline-block;
  padding: 2px 0 2px 20px;
  margin-left: 20px;
  border-left-width: 1px;
  border-left-style: solid;
  vertical-align: middle;
}

.realfactory-logo-right-block:first-child {
  padding-left: 0;
  border: none;
  margin-left: 0;
}

.realfactory-logo-right-block .realfactory-logo-right-block-icon {
  font-size: 15px;
  float: left;
  margin-right: 12px;
}

.realfactory-logo-right-block .realfactory-logo-right-block-content {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
}

.realfactory-logo-right-block .realfactory-logo-right-block-title {
  font-size: 16px;
  font-weight: 600;
}

.realfactory-logo-right-block .realfactory-logo-right-block-caption {
  font-size: 13px;
  text-transform: uppercase;
  margin-top: 8px;
}

.realfactory-header-right-button {
  display: inline-block;
  padding: 12px 25px;
  font-size: 13px;
  font-weight: bold;
  margin-top: -8px;
  margin-left: 50px;
  border-radius: 3px;
  vertical-align: middle;
  border-width: 2px;
  border-style: solid;
}

.realfactory-header-right-button:first-child {
  margin-left: 0;
}

/* header plain */
.realfactory-header-style-plain {
  position: relative;
  border-bottom-style: solid;
}

.realfactory-header-style-plain .realfactory-navigation {
  line-height: 0;
}

.realfactory-header-style-plain .realfactory-header-background {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.realfactory-header-background-transparent {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.realfactory-header-style-plain.realfactory-style-menu-right .realfactory-logo {
  float: left;
}

.realfactory-header-style-plain.realfactory-style-menu-right .realfactory-navigation {
  float: right;
}

.realfactory-header-style-plain.realfactory-style-menu-right .realfactory-main-menu {
  display: inline-block;
  vertical-align: top;
}

.realfactory-header-style-plain.realfactory-style-menu-right .realfactory-main-menu-right-wrap {
  display: inline-block;
  line-height: 1.7;
  margin-left: 10px;
  vertical-align: top;
}

.realfactory-header-style-plain.realfactory-style-center-logo .realfactory-logo-inner {
  margin-left: auto;
  margin-right: auto;
}

.realfactory-header-style-plain.realfactory-style-center-logo .realfactory-navigation {
  text-align: center;
  position: relative;
}

.realfactory-header-style-plain.realfactory-style-center-logo .realfactory-main-menu {
  display: inline-block;
  vertical-align: top;
}

.realfactory-header-style-plain.realfactory-style-center-logo .realfactory-main-menu-right-wrap {
  position: absolute;
  right: 0;
  line-height: 1.7;
}

.realfactory-header-style-plain.realfactory-style-center-menu .realfactory-logo {
  position: absolute;
}

.realfactory-header-style-plain.realfactory-style-center-menu .realfactory-navigation {
  text-align: center;
}

.realfactory-header-style-plain.realfactory-style-center-menu .realfactory-main-menu {
  display: inline-block;
  vertical-align: top;
}

.realfactory-header-style-plain.realfactory-style-center-menu .realfactory-main-menu-right-wrap {
  position: absolute;
  right: 0;
  line-height: 1.7;
}

.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-logo {
  padding-top: 0;
  padding-bottom: 0;
}

.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-navigation {
  text-align: center;
}

.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-main-menu {
  display: inline-block;
  vertical-align: top;
}

.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-main-menu-right-wrap {
  position: absolute;
  right: 0;
  line-height: 1.7;
}

.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-main-menu-left-wrap {
  position: absolute;
  left: 0;
  line-height: 1.7;
}

.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-right-menu-button {
  margin-left: 0;
}

/* header box */
.realfactory-header-boxed-wrap {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.realfactory-header-style-boxed .realfactory-header-container-item {
  position: relative;
}

.realfactory-header-style-boxed .realfactory-navigation {
  line-height: 0;
}

.realfactory-header-style-boxed .realfactory-header-background {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.realfactory-header-style-boxed.realfactory-style-menu-right .realfactory-logo {
  float: left;
}

.realfactory-header-style-boxed.realfactory-style-menu-right .realfactory-navigation {
  float: right;
}

.realfactory-header-style-boxed.realfactory-style-menu-right .realfactory-main-menu {
  display: inline-block;
  vertical-align: top;
}

.realfactory-header-style-boxed.realfactory-style-menu-right .realfactory-main-menu-right-wrap {
  display: inline-block;
  line-height: 1.7;
  margin-left: 10px;
  vertical-align: top;
}

.realfactory-header-style-boxed.realfactory-style-center-menu .realfactory-logo {
  position: absolute;
}

.realfactory-header-style-boxed.realfactory-style-center-menu .realfactory-navigation {
  text-align: center;
}

.realfactory-header-style-boxed.realfactory-style-center-menu .realfactory-main-menu {
  display: inline-block;
  vertical-align: top;
}

.realfactory-header-style-boxed.realfactory-style-center-menu .realfactory-main-menu-right-wrap {
  position: absolute;
  line-height: 1.7;
}

.realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-logo {
  padding-top: 0;
  padding-bottom: 0;
}

.realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-navigation {
  text-align: center;
}

.realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-main-menu {
  display: inline-block;
  vertical-align: top;
}

.realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-main-menu-right-wrap {
  position: absolute;
  line-height: 1.7;
}

.realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-main-menu-left-wrap {
  position: absolute;
  line-height: 1.7;
}

.realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-right-menu-button {
  margin-left: 0;
}

/* header bar */
.realfactory-header-style-bar .realfactory-logo {
  float: left;
}

.realfactory-header-style-bar .realfactory-logo-right-text {
  float: right;
  text-align: right;
  font-size: 13px;
}

.realfactory-header-style-bar .realfactory-header-container-inner {
  position: relative;
}

.realfactory-navigation-bar-wrap {
  position: relative;
}

.realfactory-navigation-bar-wrap.realfactory-style-transparent {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.realfactory-navigation-bar-wrap .realfactory-navigation {
  line-height: 0;
  position: relative;
}

.realfactory-navigation-bar-wrap .realfactory-navigation-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.realfactory-navigation-bar-wrap .realfactory-navigation-container {
  position: relative;
}

.realfactory-navigation-bar-wrap .realfactory-main-menu {
  display: inline-block;
  vertical-align: top;
}

.realfactory-navigation-bar-wrap.realfactory-style-left .realfactory-main-menu-right-wrap {
  float: right;
  line-height: 1.7;
  margin-left: 10px;
}

.realfactory-navigation-bar-wrap.realfactory-style-center {
  text-align: center;
}

.realfactory-navigation-bar-wrap.realfactory-style-center .realfactory-main-menu-right-wrap {
  position: absolute;
  right: 0;
  line-height: 1.7;
}

.realfactory-header-style-bar.realfactory-style-center .realfactory-logo {
  float: none;
}

.realfactory-header-style-bar.realfactory-style-center .realfactory-logo-inner {
  margin-left: auto;
  margin-right: auto;
}

.realfactory-header-style-bar.realfactory-style-center .realfactory-logo-right-text {
  position: absolute;
  top: 0;
  right: 0;
}

/* header side */
.realfactory-header-side-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 99;
}

.realfactory-header-side-nav.realfactory-allow-slide {
  position: absolute;
  bottom: auto;
}

.realfactory-header-side-nav.realfactory-fix-bottom {
  position: fixed;
  top: auto;
  bottom: 0;
}

.realfactory-header-side-nav.realfactory-fix-top {
  position: fixed;
}

.realfactory-header-side-nav.realfactory-style-left {
  left: 0;
}

.realfactory-header-side-nav.realfactory-style-right {
  right: 0;
}

.realfactory-header-side-nav .realfactory-pos-bottom,
.realfactory-header-side-nav.realfactory-style-middle .realfactory-pos-middle {
  opacity: 0;
  filter: alpha(opacity=0);
  transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
}

.realfactory-header-side-nav .realfactory-pos-bottom.realfactory-active,
.realfactory-header-side-nav.realfactory-style-middle .realfactory-pos-middle.realfactory-active {
  opacity: 1;
  filter: alpha(opacity=100);
}

.realfactory-header-side-nav.realfactory-style-side .realfactory-header-social {
  padding-top: 20px;
  padding-bottom: 40px;
}

.realfactory-header-side-nav.realfactory-style-side .realfactory-header-social a {
  margin-left: 25px;
}

.realfactory-header-side-nav.realfactory-style-side .realfactory-header-social a:first-child {
  margin-left: 0;
}

.realfactory-header-side-nav.realfactory-style-side .realfactory-main-menu-right-wrap {
  padding-top: 22px;
}

.realfactory-header-side-nav.realfactory-style-side .realfactory-main-menu-search {
  float: none;
  display: inline-block;
  margin-top: 0;
  margin-left: 20px;
}

.realfactory-header-side-nav.realfactory-style-side .realfactory-main-menu-cart {
  float: none;
  display: inline-block;
  margin-top: 0;
  margin-left: 0;
}

.realfactory-header-side-nav.realfactory-style-side .realfactory-main-menu-right-wrap > div:first-child {
  margin-left: 0;
}

.realfactory-header-side-nav.realfactory-style-left .sf-vertical ul.sub-menu {
  text-align: left;
  margin-left: 0;
}

.realfactory-header-side-nav.realfactory-style-right .sf-vertical ul.sub-menu {
  left: auto;
  right: 100%;
  text-align: right;
}

.realfactory-header-side-nav.realfactory-style-right .sf-vertical ul.sub-menu li > a.sf-with-ul:after {
  content: "\f104";
  float: left;
  margin-left: 0;
  margin-right: 18px;
}

.realfactory-header-style-side.realfactory-center-align .realfactory-logo-inner {
  margin-right: auto;
  margin-left: auto;
}

.realfactory-header-style-side.realfactory-right-align .realfactory-logo-inner {
  margin-right: 0;
  margin-left: auto;
}

.realfactory-header-side-nav.realfactory-style-side-toggle {
  text-align: center;
}

.realfactory-header-side-nav.realfactory-style-side-toggle .realfactory-logo-inner {
  margin-left: auto;
  margin-right: auto;
}

.realfactory-header-side-nav.realfactory-style-side-toggle .realfactory-main-menu-search,
.realfactory-header-side-nav.realfactory-style-side-toggle .realfactory-main-menu-cart {
  float: none;
  margin-bottom: 8px;
  padding-left: 0;
  margin-left: 0;
  margin-top: 0;
}

.realfactory-header-side-nav.realfactory-style-side-toggle .realfactory-header-icon {
  padding-bottom: 20px;
  padding-top: 30px;
}

body.admin-bar .realfactory-header-side-nav {
  padding-top: 32px;
}

html.mm-opened body.admin-bar .realfactory-header-side-nav {
  padding-top: 0;
}

/* fixed nav bar */
.realfactory-sticky-navigation.realfactory-style-fixed .realfactory-logo,
.realfactory-sticky-navigation.realfactory-style-fixed .realfactory-navigation,
.realfactory-sticky-navigation.realfactory-style-fixed .realfactory-navigation .sf-menu > li > a {
  transition: background 300ms, color 300ms, border-color 300ms, padding 200ms;
  -moz-transition: background 300ms, color 300ms, border-color 300ms, padding 200ms;
  -o-transition: background 300ms, color 300ms, border-color 300ms, padding 200ms;
  -webkit-transition: background 300ms, color 300ms, border-color 300ms, padding 200ms;
}

.realfactory-sticky-navigation.realfactory-style-fixed .realfactory-navigation-top {
  transition: top 200ms;
  -moz-transition: top 200ms;
  -o-transition: top 200ms;
  -webkit-transition: top 200ms;
}

.realfactory-sticky-navigation.realfactory-style-fixed.realfactory-animate-fixed-navigation .realfactory-logo-inner img {
  transition: height 200ms;
  -moz-transition: height 200ms;
  -o-transition: height 200ms;
  -webkit-transition: height 200ms;
}

.realfactory-fixed-navigation.realfactory-style-fixed,
.realfactory-fixed-navigation.realfactory-style-slide {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.realfactory-fixed-navigation.realfactory-style-slide .realfactory-logo-inner img {
  max-height: 35px !important;
  width: auto;
}

body.realfactory-sticky-navigation-no-logo .realfactory-fixed-navigation .realfactory-logo {
  display: none;
}

body.realfactory-sticky-navigation-no-logo .realfactory-mobile-header.realfactory-fixed-navigation .realfactory-logo {
  display: block;
}

body.admin-bar .realfactory-fixed-navigation.realfactory-style-fixed,
body.admin-bar .realfactory-fixed-navigation.realfactory-style-slide {
  margin-top: 32px;
}

@media screen and (max-width: 782px) {
  body.admin-bar .realfactory-fixed-navigation.realfactory-style-slide {
    margin-top: 0;
  }
}

.realfactory-animate-fixed-navigation.realfactory-header-style-plain .realfactory-logo {
  padding-top: 20px;
  padding-bottom: 20px;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-plain .realfactory-navigation {
  padding-top: 30px;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-plain .realfactory-navigation .sf-menu > li > a {
  padding-bottom: 25px;
  line-height: 20px;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-plain .realfactory-navigation-top {
  top: 30px;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-plain.realfactory-animate-logo-height .realfactory-logo-inner img {
  height: 35px !important;
  width: auto;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-logo {
  margin-top: -10px;
  padding-top: 0;
  padding-bottom: 0;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-navigation .sf-menu > li > a {
  padding-top: 0;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-plain.realfactory-style-splitted-menu .realfactory-navigation-top {
  top: 22px;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-plain.realfactory-style-center-logo .realfactory-logo {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-boxed .realfactory-logo {
  padding-top: 20px;
  padding-bottom: 20px;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-boxed .realfactory-navigation {
  padding-top: 30px;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-boxed .realfactory-navigation .sf-menu > li > a {
  padding-bottom: 25px;
  line-height: 20px;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-boxed.realfactory-animate-logo-height .realfactory-logo-inner img {
  height: 35px !important;
  width: auto;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-logo {
  margin-top: -10px;
  padding-top: 0;
  padding-bottom: 0;
}

.realfactory-animate-fixed-navigation.realfactory-header-style-boxed.realfactory-style-splitted-menu .realfactory-navigation .sf-menu > li > a {
  padding-top: 0;
}

/* page title */
.realfactory-page-title-wrap {
  background-image: url('../images/page-title-background.jpg');
  background-position: center;
  background-size: cover;
  position: relative;
}

.realfactory-page-title-wrap .realfactory-page-title-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.realfactory-page-title-wrap .realfactory-page-title-container {
  position: relative;
}

.realfactory-page-title-wrap .realfactory-page-title {
  text-transform: uppercase;
}

.realfactory-page-title-wrap.realfactory-style-small .realfactory-page-title {
  font-size: 37px;
  margin-bottom: 0;
}

.realfactory-page-title-wrap.realfactory-style-small .realfactory-page-caption {
  font-size: 16px;
  margin-top: 13px;
}

.realfactory-page-title-wrap.realfactory-style-small .realfactory-page-title-content {
  padding-top: 93px;
  padding-bottom: 87px;
}

.realfactory-page-title-wrap.realfactory-style-medium .realfactory-page-title {
  font-size: 54px;
  margin-bottom: 0;
}

.realfactory-page-title-wrap.realfactory-style-medium .realfactory-page-caption {
  font-size: 19px;
  margin-top: 13px;
}

.realfactory-page-title-wrap.realfactory-style-medium .realfactory-page-title-content {
  padding-top: 126px;
  padding-bottom: 116px;
}

.realfactory-page-title-wrap.realfactory-style-large .realfactory-page-title {
  font-size: 75px;
  margin-bottom: 0;
}

.realfactory-page-title-wrap.realfactory-style-large .realfactory-page-caption {
  font-size: 25px;
  margin-top: 13px;
}

.realfactory-page-title-wrap.realfactory-style-large .realfactory-page-title-content {
  padding-top: 184px;
  padding-bottom: 168px;
}

.realfactory-page-title-wrap.realfactory-style-custom .realfactory-page-title {
  font-size: 37px;
  margin-bottom: 0;
}

.realfactory-page-title-wrap.realfactory-style-custom .realfactory-page-caption {
  font-size: 16px;
}

/**
 * 3.0 - Footer
 */

/* footer */
.realfactory-footer-wrapper .realfactory-footer-container {
  overflow: hidden;
}

.realfactory-footer-wrapper .realfactory-footer-column {
  padding-bottom: 1029px;
  margin-bottom: -1020px;
  border-left: 1px;
  border-style: solid;
  border-color: inherit;
  padding-left: 30px;
  padding-right: 30px;
}

.realfactory-footer-wrapper .realfactory-footer-column:first-child {
  border: none;
}

.realfactory-footer-wrapper .realfactory-widget-title {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 35px;
}

.realfactory-footer-wrapper .realfactory-widget-title .gdlr-core-flexslider-nav {
  float: right;
  margin-left: 20px;
}

.realfactory-footer-wrapper .widget {
  margin-bottom: 60px;
}

.realfactory-footer-wrapper .widget:last-child {
  margin-bottom: 0;
}

.realfactory-fixed-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

body.realfactory-boxed .realfactory-fixed-footer .realfactory-footer-wrapper,
body.realfactory-boxed .realfactory-fixed-footer .realfactory-copyright-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.realfactory-footer-back-to-top-button {
  position: fixed;
  z-index: 9;
  right: 30px;
  bottom: 30px;
  font-size: 18px;
  line-height: 20px;
  width: 45px;
  height: 45px;
  text-align: center;
  padding: 12px;
  cursor: pointer;
  opacity: 0;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  pointer-events: none;
  transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
}

.realfactory-footer-back-to-top-button.realfactory-scrolled {
  opacity: 1;
  pointer-events: auto;
}

/* copyright */
.realfactory-footer-wrapper {
  padding-top: 70px;
  padding-bottom: 50px;
}

.realfactory-copyright-text {
  text-align: center;
  padding-top: 38px;
  padding-bottom: 38px;
  font-size: 14px;
  letter-spacing: 1px;
}

/**
 * 4.0 - Blog
 */

/* single */
.realfactory-single-article-date-wrapper {
  float: left;
  width: 63px;
  border-right-width: 3px;
  border-right-style: solid;
  line-height: 1;
  margin-top: 5px;
  margin-right: 30px;
  padding-right: 25px;
  padding-bottom: 25px;
  text-align: center;
  white-space: nowrap;
}

.realfactory-single-article-date-wrapper .realfactory-single-article-date-day {
  font-size: 27px;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 0;
  letter-spacing: 3px;
}

.realfactory-single-article-date-wrapper .realfactory-single-article-date-month {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.realfactory-single-article {
  padding-bottom: 35px;
}

.realfactory-single-article .realfactory-single-article-content ul,
.realfactory-single-article .realfactory-single-article-content ol {
  line-height: 2;
}

.realfactory-single-article .realfactory-single-article-thumbnail {
  margin-bottom: 42px;
}

.realfactory-single-article .realfactory-single-article-head {
  margin-bottom: 32px;
}

.realfactory-single-article .realfactory-single-article-title {
  font-size: 37px;
  font-weight: 700;
  margin-bottom: 16px;
  letter-spacing: 0.5px;
}

.realfactory-single-article .realfactory-single-article-head-right {
  overflow: hidden;
}

.realfactory-blog-info-wrapper .realfactory-head {
  margin-right: 13px;
  vertical-align: middle;
}

.realfactory-blog-info-wrapper .realfactory-head i {
  font-size: 15px;
}

.realfactory-blog-info-wrapper .realfactory-blog-info {
  display: inline-block;
  margin-right: 25px;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.realfactory-blog-info-wrapper .realfactory-blog-info:last-child {
  margin-right: 0;
}

.realfactory-sticky-banner {
  line-height: 1;
  display: inline-block;
  letter-spacing: 0.5px;
  padding: 18px 20px 17px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 22px;
}

.realfactory-sticky-banner i {
  font-size: 12px;
  margin-right: 10px;
}

.realfactory-single-article-thumbnail .realfactory-sticky-banner {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 0;
}

body.single-post .realfactory-sidebar-style-none {
  margin-left: auto;
  margin-right: auto;
}

body.blog .realfactory-sidebar-style-none {
  margin-left: auto;
  margin-right: auto;
}

body.blog .realfactory-single-article {
  padding-bottom: 60px;
}

.realfactory-archive-taxonomy-description {
  margin-bottom: 60px;
}

/* single post format */
.realfactory-blog-aside-format .realfactory-single-article-content {
  font-size: 18px;
  font-style: italic;
  padding: 50px 60px 40px;
}

.realfactory-blog-aside-format .realfactory-excerpt-read-more {
  display: none;
}

.realfactory-blog-quote-format .realfactory-single-article-content {
  padding: 50px 45px 40px;
  position: relative;
}

.realfactory-blog-quote-format .realfactory-blog-quote-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  filter: alpha(opacity=30);
}

.realfactory-blog-quote-format .realfactory-blog-quote {
  float: left;
  font-size: 120px;
  margin-top: -10px;
  line-height: 1;
  margin-right: 35px;
  position: relative;
}

.realfactory-blog-quote-format .realfactory-blog-content-wrap {
  overflow: hidden;
  position: relative;
}

.realfactory-blog-quote-format .realfactory-blog-quote-content {
  font-size: 18px;
  font-style: italic;
}

.realfactory-blog-quote-format .realfactory-blog-quote-author {
  font-size: 16px;
  font-style: italic;
  margin-bottom: 20px;
  font-weight: bold;
}

.realfactory-blog-quote-format .realfactory-blog-quote-author:before {
  content: "-";
  margin-right: 2px;
}

.realfactory-blog-quote-format .realfactory-excerpt-read-more {
  display: none;
}

.realfactory-blog-quote-format blockquote,
.realfactory-blog-quote-format q {
  background: transparent;
  border: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
}

.realfactory-blog-link-format .realfactory-blog-icon-link {
  font-size: 23px;
  float: left;
  margin-right: 30px;
  margin-top: 5px;
}

.realfactory-blog-link-format .realfactory-blog-content-wrap {
  overflow: hidden;
}

.realfactory-blog-link-format .realfactory-blog-title {
  font-size: 20px;
  text-transform: none;
  letter-spacing: 0;
  margin-bottom: 9px;
}

.realfactory-blog-link-format .realfactory-blog-content {
  font-size: 14px;
  margin-bottom: 0;
}

.realfactory-blog-link-format .realfactory-excerpt-read-more {
  display: none;
}

/* single social share */
.realfactory-single-social-share {
  padding-top: 10px;
  padding-bottom: 48px;
}

/* single author */
.realfactory-single-author {
  margin-bottom: 55px;
  padding-top: 55px;
  border-top-width: 1px;
  border-top-style: solid;
}

.realfactory-single-author .realfactory-single-author-avartar {
  max-width: 80px;
  overflow: hidden;
  float: left;
  margin-right: 30px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  margin-bottom: 25px;
}

.realfactory-single-author .realfactory-single-author-head-wrap {
  overflow: hidden;
  padding-top: 5px;
}

.realfactory-single-author .realfactory-single-author-caption {
  font-size: 14px;
  font-style: italic;
  margin-bottom: 9px;
  letter-spacing: 0.5px;
}

.realfactory-single-author .realfactory-single-author-title {
  font-size: 26px;
  margin-bottom: 22px;
}

.realfactory-single-author .realfactory-single-author-description {
  clear: left;
}

/* single navigation */
.realfactory-single-nav-area {
  border-top: 1px;
  border-bottom: 1px;
  border-style: solid;
  padding: 33px 0;
  margin-bottom: 55px;
}

.realfactory-single-nav i {
  font-size: 25px;
}

.realfactory-single-nav .realfactory-text {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.5px;
}

.realfactory-single-nav-left {
  float: left;
}

.realfactory-single-nav-left i {
  margin-right: 15px;
  vertical-align: middle;
}

.realfactory-single-nav-right {
  float: right;
}

.realfactory-single-nav-right i {
  margin-left: 15px;
  vertical-align: middle;
}

/* comment area */
.realfactory-comments-area .realfactory-comments-title {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  margin-bottom: 55px;
}

.realfactory-comments-area ol.comment-list {
  list-style: none;
  margin-bottom: 55px;
  margin-left: 0;
}

.realfactory-comments-area ol.comment-list ol {
  margin-left: 50px;
}

.realfactory-comments-area .comment-article {
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.realfactory-comments-area .comment-avatar {
  max-width: 80px;
  margin-right: 35px;
  float: left;
  overflow: hidden;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.realfactory-comments-area .comment-avatar img {
  display: block;
}

.realfactory-comments-area .comment-meta {
  position: relative;
  margin-bottom: 20px;
}

.realfactory-comments-area .comment-author {
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 5px;
}

.realfactory-comments-area .comment-body {
  overflow: hidden;
}

.realfactory-comments-area .comment-time {
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.8px;
}

.realfactory-comments-area .comment-reply {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.5px;
  position: absolute;
  right: 0;
  top: 4px;
}

.realfactory-comments-area .comment-respond {
  margin-bottom: 85px;
}

.realfactory-comments-area ol.comment-list .comment-respond {
  margin-top: 55px;
}

.realfactory-comments-area .comment-reply-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
}

.realfactory-comments-area .comment-reply-title small {
  margin-left: 10px;
}

.realfactory-comments-area textarea {
  width: 100%;
  padding: 18px 22px;
  font-size: 14px;
  display: block;
  border-width: 1px 1px 2px 1px;
  border-style: solid;
  margin: 0;
  margin-bottom: 14px;
}

.realfactory-comments-area input[type="text"] {
  width: 100%;
  display: block;
  font-size: 14px;
  border-width: 1px 1px 2px 1px;
  border-style: solid;
  padding: 16px 22px;
  margin-bottom: 14px;
}

.realfactory-comments-area input[type="text"]:focus,
.realfactory-comments-area textarea:focus {
  outline: none;
}

.realfactory-comments-area .realfactory-comment-form-author {
  width: 50%;
  padding-right: 7px;
  float: left;
}

.realfactory-comments-area .realfactory-comment-form-email {
  width: 50%;
  padding-left: 7px;
  float: left;
}

.realfactory-comments-area .form-submit {
  padding-top: 18px;
}

/* blog title */
.realfactory-blog-title-wrap {
  background-image: url('../images/page-title-background.jpg');
  background-position: center;
  position: relative;
  background-size: cover;
}

.realfactory-blog-title-wrap .realfactory-blog-title-container {
  position: relative;
}

.realfactory-blog-title-wrap .realfactory-blog-title-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.realfactory-blog-title-wrap.realfactory-feature-image .realfactory-blog-title-overlay {
  opacity: 1;
  background: url("../images/blog-title-gradient.png") center bottom repeat-x;
}

.realfactory-blog-title-wrap.realfactory-style-small .realfactory-blog-title-content {
  padding-top: 93px;
  padding-bottom: 87px;
}

.realfactory-blog-title-wrap.realfactory-style-large .realfactory-blog-title-content {
  padding-top: 184px;
  padding-bottom: 168px;
}

.realfactory-blog-title-wrap .realfactory-single-article-title {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 11px;
  letter-spacing: 0.5px;
}

.realfactory-blog-title-wrap .realfactory-single-article-head-right {
  overflow: hidden;
}

.realfactory-blog-title-wrap .realfactory-single-article-date-wrapper {
  margin-top: 11px;
}

/* 404 */
.realfactory-not-found-wrap {
  text-align: center;
  position: relative;
}

.realfactory-not-found-wrap .realfactory-not-found-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.27;
  filter: alpha(opacity=27);
  background-position: center;
  background-size: cover;
  //background-image: url('../images/404-background.jpg');
}

.realfactory-not-found-wrap .realfactory-not-found-content {
  position: relative;
  padding: 60px 0 65px;
}

.realfactory-not-found-wrap .realfactory-not-found-head {
  font-size: 150px;
  line-height: 1;
  margin-bottom: 40px;
}

.realfactory-not-found-wrap .realfactory-not-found-title {
  font-size: 35px;
  line-height: 1;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 14px;
}

.realfactory-not-found-wrap .realfactory-not-found-caption {
  font-size: 18px;
}

.realfactory-not-found-wrap .realfactory-not-found-back-to-home a {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.realfactory-not-found-wrap form.search-form {
  max-width: 430px;
  margin: 58px auto 0;
  position: relative;
  margin-bottom: 13px;
}

.realfactory-not-found-wrap form.search-form input.search-field {
  font-size: 15px;
  width: 100%;
  border: none;
  padding: 22px 60px 22px 25px;
  height: 64px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.realfactory-not-found-wrap form.search-form input.search-submit {
  position: absolute;
  right: 0;
  top: 0;
  width: 64px;
  height: 64px;
  opacity: 0;
  filter: alpha(opacity=0);
}

.realfactory-not-found-wrap form.search-form .realfactory-top-search-submit {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -15px;
  font-size: 18px;
}

body.search .realfactory-not-found-wrap .realfactory-not-found-head {
  font-size: 100px;
  text-transform: uppercase;
  margin-bottom: 18px;
}

body.search .realfactory-not-found-wrap form.search-form {
  margin-top: 50px;
}

/* Not Found */
body.search .realfactory-not-found-wrap {
  background-color: #bd584e;
}

body.search .realfactory-not-found-wrap .realfactory-not-found-caption {
  color: #ffe6e4;
}

/**
 * 5.0 - Widget
 */

/* sidebar */
.realfactory-sidebar-area .realfactory-widget {
  margin-bottom: 55px;
}

.realfactory-sidebar-area .realfactory-widget-title {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  position: relative;
  margin-bottom: 28px;
  overflow: hidden;
}

.realfactory-sidebar-area .realfactory-widget-title .gdlr-core-flexslider-nav {
  float: right;
  margin-left: 20px;
}

.realfactory-sidebar-area .realfactory-widget-head-text {
  display: block;
  float: left;
  margin-right: 28px;
}

.realfactory-sidebar-left .realfactory-sidebar-area {
  overflow: hidden;
  border-right-width: 1px;
  border-right-style: solid;
}

.realfactory-sidebar-right .realfactory-sidebar-area {
  overflow: hidden;
  border-left-width: 1px;
  border-left-style: solid;
}

/* list */
.widget_rss .rss-widget-icon {
  margin-right: 10px;
  margin-top: -3px;
}

.widget_product_categories ul,
.widget_archive ul, .widget_categories ul, .widget_nav_menu ul,
.widget_meta ul, .widget_pages ul, .widget_recent_comments ul,
.widget_recent_entries ul, .widget_rss ul {
  position: relative;
  list-style: none;
  margin-left: 0;
}

.widget_product_categories ul li,
.widget_archive ul li, .widget_categories ul li, .widget_nav_menu ul li,
.widget_meta ul li, .widget_pages ul li, .widget_recent_comments ul li,
.widget_recent_entries ul li, .widget_rss ul li {
  font-size: 15px;
  margin-bottom: 15px;
  line-height: 18px;
}

.widget_product_categories ul ul,
.widget_archive ul ul, .widget_categories ul ul, .widget_nav_menu ul ul,
.widget_meta ul ul, .widget_pages ul ul, .widget_recent_comments ul ul,
.widget_recent_entries ul ul, .widget_rss ul ul {
  margin-left: 20px;
}

.widget_product_categories ul ul li,
.widget_archive ul ul li, .widget_categories ul ul li, .widget_nav_menu ul ul li,
.widget_meta ul ul li, .widget_pages ul ul li:last-child, .widget_recent_comments ul ul li,
.widget_recent_entries ul ul li, .widget_rss ul ul li {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
  margin-top: 15px;
}

/* search widget */
.widget_search form {
  display: block;
  position: relative;
}

.widget_search form:after {
  content: "\f002";
  font-family: "fontAwesome";
  font-size: 14px;
  line-height: 14px;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -7px;
}

.widget_search label .screen-reader-text {
  display: none;
}

.widget_search .search-form input.search-field {
  font-size: 13px;
  line-height: 17px;
  height: 51px;
  border-width: 1px;
  border-style: solid;
  width: 100%;
  padding: 16px 15px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.widget_search input.search-field:focus {
  outline: none;
}

.widget_search input.search-submit {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 51px;
  padding: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 1;
}

/* tag cloud */
.tagcloud {
  zoom: 1;
}

.tagcloud:after {
  content: " ";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.tagcloud a {
  font-size: 11px !important;
  text-transform: uppercase;
  font-weight: 600;
  float: left;
  padding: 8px 16px 7px;
  border-width: 1px;
  border-style: solid;
  margin-right: 10px;
  margin-bottom: 10px;
}

/* calendar */
.widget_calendar table {
  width: 100%;
  margin-bottom: 0;
}

.widget_calendar table thead th {
  font-size: 13px;
  font-weight: bold;
  padding: 10px 0;
}

.widget_calendar table tbody td {
  text-align: center;
  padding: 10px 0;
}

.widget_calendar table tfoot td {
  text-align: center;
  padding: 10px 0;
}

.widget_calendar caption {
  font-size: 14px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}

/* table */
table {
  width: 100%;
  text-align: center;
  border-spacing: 2px;
  border-collapse: separate;
  margin-bottom: 20px;
}

table tr td {
  padding: 15px;
}

table tr th {
  padding: 15px;
  font-size: 16px;
  font-weight: normal;
}

/* for goodlayer core plugin */
body.realfactory-boxed .gdlr-core-column-extend-left .gdlr-core-pbf-background-wrap {
  margin-left: -50px;
}

body.realfactory-boxed .gdlr-core-column-extend-right .gdlr-core-pbf-background-wrap {
  margin-right: -50px;
}

/**
 * 6.0 - Responsive
 */
.realfactory-mobile-header-wrap {
  display: none;
}

.realfactory-mobile-header {
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 9999;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.realfactory-mobile-header.realfactory-fixed-navigation {
  display: none;
}

.realfactory-mobile-header .realfactory-mobile-header-container {
  position: relative;
}

.realfactory-mobile-header .realfactory-logo {
  padding: 0 15px;
}

.realfactory-mobile-header .realfactory-logo-inner {
  line-height: 45px;
  margin-right: 110px;
}

.realfactory-mobile-header .realfactory-logo-inner img {
  vertical-align: middle;
  width: auto;
}

.realfactory-mobile-header .realfactory-mobile-menu {
  margin-left: 15px;
}

.realfactory-mobile-header .realfactory-main-menu-cart {
  margin-left: 0;
  padding-left: 15px;
}

/*--- tablet ---*/
@media only screen and (max-width: 1140px) {

  html .widget_product_search input[type="search"]#woocommerce-product-search-field {
    width: 100%;
    margin-bottom: 10px;
  }

}

@media only screen and (max-width: 999px) {

  body {
    width: 100%;
    overflow-x: hidden;
  }
  body.realfactory-boxed div.realfactory-body-wrapper {
    margin-top: 0;
    margin-bottom: 0;
  }

  .realfactory-bullet-anchor {
    display: none;
  }

  /* remove header */
  //.realfactory-top-bar {
  //  display: none;
  //}
  .realfactory-header-wrap.realfactory-header-style-plain,
  .realfactory-header-wrap.realfactory-header-style-bar, .realfactory-navigation-bar-wrap,
  .realfactory-header-background-transparent, .realfactory-header-boxed-wrap, .realfactory-header-side-nav,
  .realfactory-header-transparent-substitute, .realfactory-sticky-navigation {
    display: none !important;
  }

  .realfactory-mobile-header-wrap {
    display: block;
  }
  .realfactory-mobile-header-wrap .realfactory-top-bar {
    display: block;
  }
  .realfactory-mobile-header-wrap .realfactory-top-bar {
    font-size: 13px;
  }
  .realfactory-mobile-header-wrap .realfactory-top-bar-left,
  .realfactory-mobile-header-wrap .realfactory-top-bar-right {
    padding-top: 0;
    padding-bottom: 0;
  }
  .realfactory-mobile-header.realfactory-fixed-navigation {
    display: block;
  }
  .realfactory-sticky-menu-placeholder {
    display: none !important;
  }

  body .realfactory-header-side-content.realfactory-style-left {
    margin-left: 0;
  }
  body .realfactory-header-side-content.realfactory-style-right {
    margin-right: 0;
  }

  body.single-product.woocommerce div.product form.cart {
    float: none;
    margin-bottom: 30px;
    width: auto;
    margin-right: 0;
  }
  body.single-product.woocommerce div.product .product_meta {
    margin-top: 0;
  }
}

/*--- mobile landscape style ---*/
@media only screen and (max-width: 767px) {
  body.realfactory-body-front .realfactory-container {
    max-width: 500px;
    padding-left: 15px;
    padding-right: 15px;
  }
  body.realfactory-body-front .gdlr-core-container {
    max-width: 500px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .realfactory-column-10, .realfactory-column-12, .realfactory-column-15, .realfactory-column-20,
  .realfactory-column-24, .realfactory-column-30, .realfactory-column-36, .realfactory-column-40,
  .realfactory-column-45, .realfactory-column-48, .realfactory-column-50, .realfactory-column-60 {
    width: 100%;
  }

  /* header */
  .realfactory-mobile-header-wrap .realfactory-top-bar-left,
  .realfactory-mobile-header-wrap .realfactory-top-bar-right {
    float: none;
    text-align: center;
  }

  .realfactory-page-title-wrap .realfactory-page-title-content {
    padding-top: 60px !important;
    padding-bottom: 50px !important;
  }
  .realfactory-blog-title-wrap .realfactory-blog-title-content {
    padding-top: 60px !important;
    padding-bottom: 50px !important;
  }
  .realfactory-single-article-date-wrapper {
    display: none;
  }

  .realfactory-overlay-menu-content ul.menu > li {
    font-size: 18px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .realfactory-overlay-menu-content ul.menu {
    padding-top: 120px;
    padding-bottom: 100px;
    max-width: 80%;
  }
  .realfactory-overlay-menu-content .realfactory-overlay-menu-close {
    top: 35px;
  }
  body.admin-bar .realfactory-overlay-menu-content {
    margin-top: 0;
  }

  /* single*/
  .realfactory-comments-area .comment-respond {
    padding: 45px 30px;
  }
  .realfactory-comments-area .comment-respond {
    padding: 45px 30px;
  }
  .realfactory-comments-area .realfactory-comment-form-author {
    width: 100%;
    padding-right: 0;
  }
  .realfactory-comments-area .realfactory-comment-form-email {
    width: 100%;
    padding-left: 0;
  }

  /* footer */
  .realfactory-footer-wrapper .realfactory-footer-column {
    padding: 0 15px;
    margin-bottom: 60px;
    border: none;
  }
  .realfactory-footer-wrapper .realfactory-footer-column:last-child {
    margin-bottom: 20px;
  }

  .realfactory-fixed-footer-placeholder {
    display: none;
  }
  .realfactory-fixed-footer {
    position: static;
  }

  body .woocommerce .col2-set, .woocommerce-page .col2-set {
    width: 100%;
    float: none;
    margin-right: 0%;
  }
  body div#order_review {
    width: 100%;
  }
}

/*--- mobile portrait style ---*/
@media only screen and (max-width: 419px) {

  body {
    min-width: 320px;
  }
  .realfactory-top-search-wrap input.search-field {
    font-size: 30px;
    padding-right: 90px;
  }
  .realfactory-top-search-wrap .realfactory-top-search-submit {
    right: 48px;
    font-size: 24px;
    margin-top: -2px;
  }
  .realfactory-top-search-wrap input.search-submit {
    right: 48px;
  }
  .realfactory-top-search-wrap .realfactory-top-search-close {
    font-size: 40px;
  }
  .realfactory-top-search-wrap input.search-field {
    padding-bottom: 10px;
  }

}

/**
 * 7.0 - Plugins
 */

/*-------------------*/
/*--- wpml        ---*/
/*-------------------*/
ul.sf-menu > .menu-item-language img.iclflag {
  vertical-align: baseline;
  margin-right: 10px;
}

ul.sf-menu > .menu-item-language ul.submenu-languages {
  display: none;
  position: absolute;
  z-index: 99;
  list-style: none;
  margin: 0 0 0 -15px;
}

ul.sf-menu > .menu-item-language li {
  padding: 1px 11px;
}

ul.sf-menu > .menu-item-language li a {
  padding: 6px 12px;
  display: block;
}

ul.sf-menu > .menu-item-language li:first-child {
  padding-top: 12px;
}

ul.sf-menu > .menu-item-language li:last-child {
  padding-bottom: 12px;
}

.realfactory-custom-wpml-flag {
  margin-right: 26px;
}

.realfactory-custom-wpml-flag .realfactory-custom-wpml-flag-item {
  margin-left: 20px;
}

.realfactory-custom-wpml-flag .realfactory-custom-wpml-flag-item:first-child {
  margin-left: 0;
}

/*-------------------*/
/*--- wpcf7       ---*/
/*-------------------*/
.realfactory-body span.wpcf7-not-valid-tip {
  font-size: 13px;
  margin-bottom: 15px;
}

.realfactory-body div.wpcf7-response-output {
  font-size: 13px;
  padding: 6px 20px;
  clear: both;
  margin: 0;
}

.wpcf7 input {
  font-size: 15px;
  padding: 15px 22px;
  width: 100%;
  display: block;
}

.wpcf7 input[type="button"], .wpcf7 input[type="submit"], input[type="reset"] {
  width: auto;
}

//.wpcf7 input[type="checkbox"], .wpcf7 input[type="radio"] {
////  width: auto;
////  display: inline;
////}

.wpcf7 .wpcf7-form-control .wpcf7-list-item {
  display: block;
  margin-bottom: 5px;
}

.wpcf7 textarea {
  font-size: 15px;
  padding: 18px 22px;
  height: 120px;
  width: 100%;
}

/*-------------------*/
/*--- woocommerce ---*/
/*-------------------*/

/* style */
figure.woocommerce-product-gallery__wrapper {
  max-width: none;
}

body.woocommerce .woocommerce-error, body.woocommerce .woocommerce-info, body.woocommerce .woocommerce-message {
  padding: 0.7em 2em 1em 3.5em !important;
}

.woocommerce .woocommerce-error, .woocommerce .woocommerce-info, .woocommerce .woocommerce-message {
  padding: 0.7em 2em 1em 3.5em !important;
}

.woocommerce .woocommerce-error:before, .woocommerce .woocommerce-info:before, .woocommerce .woocommerce-message:before {
  top: 0.7em;
}

.woocommerce .woocommerce-error .button, .woocommerce .woocommerce-info .button, .woocommerce .woocommerce-message .button {
  margin-top: 5px;
  padding: 0;
  background-color: transparent;
}

.woocommerce .woocommerce-error .button:hover, .woocommerce .woocommerce-info .button:hover, .woocommerce .woocommerce-message .button:hover {
  background-color: transparent;
}

.woocommerce div.product form.cart .variations td.label {
  padding-right: 1.3em;
  padding-left: 1.3em;
}

.woocommerce div.product form.cart .variations td.value, .woocommerce div.product form.cart .variations th.value {
  min-width: 210px;
}

.woocommerce div.product form.cart .variations select {
  float: left;
  margin-top: 0.6em;
  margin-left: 2em;
}

a.reset_variations {
  margin-right: 15px;
  margin-left: 7px;
}

.woocommerce div.product form.cart .variations select {
  min-width: 59%;
}

.related.products h2 {
  font-size: 23px;
  margin-bottom: 42px;
}

.woocommerce table.shop_attributes th {
  background: transparent;
  padding-top: 16px;
  padding-bottom: 16px;
}

.woocommerce table.shop_attributes td, .woocommerce table.shop_attributes th {
  border-bottom: 0;
}

.woocommerce table.shop_attributes {
  border-top: 0;
}

.single-product.woocommerce #review_form #respond p.comment-form-comment {
  margin-bottom: 25px;
}

.woocommerce table.shop_table {
  border: 0;
}

.woocommerce table.shop_table.shop_table_responsive.cart {
  border-spacing: 0;
}

.woocommerce table.shop_table th {
  padding: 17px 12px;
  background: none;
}

.woocommerce table.shop_table.shop_table_responsive.cart tr:nth-child(odd), .woocommerce table.shop_table.shop_table_responsive.cart tr:nth-child(even) {
  background-color: transparent;
}

.woocommerce table.shop_table td {
  padding: 19px 20px;
}

.woocommerce-cart table.cart img {
  width: 70px;
}

.woocommerce td.product-name dl.variation dd, .woocommerce td.product-name dl.variation dt {
  margin-bottom: 0;
}

.woocommerce a.remove {
  width: auto;
}

.cart_item input.input-text.qty.text {
  padding: 15px 0 15px 10px;
  border-bottom-width: 1px;
}

.woocommerce-cart table.cart td.actions .coupon .input-text {
  padding: 15px 23px 15px;
  height: 49px;
}

.woocommerce .cart input.button[name="apply_coupon"] {
  height: 49px;
}

.woocommerce #content table.cart td.actions .input-text, .woocommerce table.cart td.actions .input-text, .woocommerce-page #content table.cart td.actions .input-text, .woocommerce-page table.cart td.actions .input-text {
  width: 180px;
}

.woocommerce .cart .button, .woocommerce .cart input.button {
  padding: 19px 20px 18px;
  border-radius: 0;
  font-size: 12px;
}

.woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button {
  padding: 15px 20px;
  border-radius: 0;
  font-size: 12px;
}

.woocommerce #respond input#submit.disabled, .woocommerce #respond input#submit:disabled, .woocommerce #respond input#submit:disabled[disabled], .woocommerce a.button.disabled, .woocommerce a.button:disabled, .woocommerce a.button:disabled[disabled], .woocommerce button.button.disabled, .woocommerce button.button:disabled, .woocommerce button.button:disabled[disabled], .woocommerce input.button.disabled, .woocommerce input.button:disabled, .woocommerce input.button:disabled[disabled] {
  padding: inherit;
  opacity: inherit;
  padding-left: 23px;
  padding-right: 23px;
}

.woocommerce .cart_totals h2 {
  font-size: 18px;
}

.woocommerce-cart .cart-collaterals .cart_totals tr td, .woocommerce-cart .cart-collaterals .cart_totals tr th {
  border-top: 0;
}

.woocommerce-cart .cart-collaterals .cart_totals table {
  border-spacing: 0;
  margin: 0;
}

.woocommerce-cart .wc-proceed-to-checkout {
  padding: 0;
}

.woocommerce table.shop_table tbody:first-child tr:first-child td, .woocommerce table.shop_table tbody:first-child tr:first-child th {
  padding-left: 20px;
  padding-right: 20px;
}

.woocommerce-cart .cart-collaterals .cart_totals tr td, .woocommerce-cart .cart-collaterals .cart_totals tr th {
  padding-left: 20px;
  padding-right: 20px;
}

.woocommerce a.showcoupon {
  margin-left: 20px;
  color: #baf2ff;
}

.woocommerce .woocommerce-billing-fields h3, .woocommerce .woocommerce-shipping-fields h3, #order_review_heading {
  font-size: 17px;
}

.woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea {
  padding: 13px 15px;
  border-bottom-width: 1px;
}

.woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-1 {
  margin-bottom: 35px;
}

.woocommerce .col2-set, .woocommerce-page .col2-set {
  width: 47%;
  float: left;
  margin-right: 3%;
}

.woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-1 {
  width: 100%;
}

.woocommerce .col2-set .col-2, .woocommerce-page .col2-set .col-2 {
  width: 100%;
}

div#order_review {
  float: left;
  width: 50%;
}

.woocommerce form .form-row {
  margin: 0 0 14px;
}

.woocommerce form p.form-row.form-row-first {
  margin: 0;
}

.select2-container .select2-choice {
  padding: 6px 1px 6px 13px;
}

.woocommerce form .form-row-first, .woocommerce form .form-row-last, .woocommerce-page form .form-row-first, .woocommerce-page form .form-row-last {
  width: 49%;
}

.shop_table.woocommerce-checkout-review-order-table {
  border-spacing: 0;
}

.woocommerce table.shop_table th {
  padding: 17px 20px 17px 20px;
}

.woocommerce .cart_item td.product-total {
  padding-right: 20px;
  padding-left: 20px;
}

.woocommerce .cart_item td.product-name {
  padding-left: 20px;
}

.woocommerce table.shop_table td {
  padding-right: 20px;
}

.checkout_coupon .form-row.form-row-last {
  margin-bottom: 0;
}

table tr.cart_item {
  background: transparent;
}

.woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea {
  font-size: 13px;
}

html .woocommerce a.remove {
  border-radius: 100%;
  color: #333 !important;
}

.woocommerce-checkout table tr.cart_item {
  background: transparent;
}

li.woocommerce-MyAccount-navigation-link {
  padding: 13px 0;
  padding-bottom: 13px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.woocommerce-account .woocommerce-MyAccount-navigation {
  width: 28%;
}

li.woocommerce-MyAccount-navigation-link.is-active {
  font-weight: 600;
}

header.woocommerce-Address-title.title h3 {
  font-size: 18px;
}

.woocommerce-Address address {
  padding: 25px;
}

.u-columns.woocommerce-Addresses.col2-set.addresses {
  width: 100%;
  margin-bottom: 40px;
}

.woocommerce-account .woocommerce-MyAccount-content {
  padding: 31px;
}

.woocommerce-Address address,
.woocommerce-account .woocommerce-MyAccount-content {
  background: #f5f5f5;
}

/*-- Widget --*/
.woocommerce ul.cart_list li dl dd, .woocommerce ul.cart_list li dl dt, .woocommerce ul.product_list_widget li dl dd, .woocommerce ul.product_list_widget li dl dt {
  margin-bottom: 0em;
}

.woocommerce ul.cart_list li dl, .woocommerce ul.product_list_widget li dl {
  padding-left: 0;
  border-left: 0;
}

.woocommerce .widget_shopping_cart .cart_list li, .woocommerce.widget_shopping_cart .cart_list li {
  margin-bottom: 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 15px;
}

.woocommerce .widget_shopping_cart .cart_list li:last-child, .woocommerce.widget_shopping_cart .cart_list li:last-child {
  margin-bottom: 15px;
}

.woocommerce .widget_shopping_cart .total, .woocommerce.widget_shopping_cart .total {
  border-top: 0;
  font-size: 17px;
}

.widget_shopping_cart_content .total span.woocommerce-Price-amount.amount {
  margin-left: 5px;
}

.woocommerce ul.cart_list li img, .woocommerce ul.product_list_widget li img {
  width: 47px;
}

.widget_shopping_cart a.button.wc-forward {
  padding: 19px 28px;
  font-size: 14px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.woocommerce .widget_price_filter .price_slider_amount .button {
  float: left;
  font-size: 14px;
  padding: 16px 26px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.price_slider_amount .price_label {
  margin-top: 24px;
  font-size: 17px;
  font-weight: 700;
}

.woocommerce ul.cart_list li, .woocommerce ul.product_list_widget li {
  padding: 15px 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.woocommerce ul.cart_list li, .woocommerce ul.product_list_widget li {
  padding: 15px 0;
  margin: 0;
  list-style: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

html ul.cart_list li a, html .woocommerce ul.product_list_widget li a {
  font-weight: 400;
}

html .woocommerce ul.cart_list li a, html .woocommerce ul.product_list_widget li a {
  font-weight: 400;
}

html .woocommerce ul.cart_list li .star-rating {
  float: left;
  margin-top: 10px;
}

html .woocommerce ul.product_list_widget li .star-rating {
  float: left;
  margin-top: 3px;
  margin-right: 10px;
}

html .woocommerce ul.product_list_widget li del {
  margin-right: 10px;
}

html .product_list_widget span.reviewer {
  margin-top: 4px;
  margin-left: 12px;
  float: left;
}

html .widget_product_search label.screen-reader-text {
  display: none;
}

html .widget_product_search input[type="submit"] {
  height: 47px;
}

html .widget_product_search input#woocommerce-product-search-field {
  height: 47px;
  padding: 14px 12px;
  border-bottom-width: 1px;
  width: 62%;
  font-size: 13px;
}

.widget.woocommerce ul.cart_list li:first-child, .widget.woocommerce ul.product_list_widget li:first-child {
  padding-top: 0;
}

/*-- Fixed Color --*/
.woocommerce .woocommerce-message:before {
  color: #fff;
}

.woocommerce .woocommerce-error {
  background-color: #bd584e;
  border-top: 3px solid #bd584e;
  color: #fff;
}

.woocommerce .woocommerce-info {
  background-color: #46a5ca;
  border-top: 3px solid #46a5ca;
  color: #fff;
}

.woocommerce .woocommerce-message {
  background-color: #46ca7b;
  border-top: 3px solid #46ca7b;
  color: #fff;
}

.woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button {
  color: #ffffff;
}

.single-product.woocommerce div.product .quantity .qty {
  background: #f3f3f3;
}

.woocommerce .woocommerce-info:before {
  color: #fff
}

.woocommerce .woocommerce-error:before, .woocommerce .woocommerce-info:before, .woocommerce .woocommerce-message:before {
  color: #fff !important;
  top: inherit;
}

html .woocommerce a.remove:hover {
  color: #afafaf !important;
  background: transparent !important;
}

html .woocommerce form .form-row .required {
  color: #c5c5c5;
}

html .woocommerce form .form-row.woocommerce-invalid .select2-container, html .woocommerce form .form-row.woocommerce-invalid input.input-text, html .woocommerce form .form-row.woocommerce-invalid select {
  border-color: #d4d4d4;
}

html .woocommerce form .form-row.woocommerce-invalid label {
  color: #9e9e9e;
}

html .woocommerce form .form-row.woocommerce-validated .select2-container, html .woocommerce form .form-row.woocommerce-validated input.input-text, html .woocommerce form .form-row.woocommerce-validated select {
  border-color: #d4d4d4;
}

/* single product */
html .woocommerce span.onsale {
  font-size: 13px;
  font-weight: 600;
  line-height: 48px;
  width: 48px;
  padding: 2px 0 0;
  height: 48px;
}

.single-product.woocommerce .realfactory-page-wrapper {
  border-top-width: 1px;
  border-top-style: solid;
}

.single-product.woocommerce .woocommerce-breadcrumb {
  font-size: 14px;
}

.single-product.woocommerce .product_title {
  font-size: 40px;
  margin-bottom: 2px;
}

.single-product.woocommerce div.product .woocommerce-product-rating {
  margin-bottom: 2px;
}

.single-product.woocommerce div.product .star-rating {
  font-size: 14px;
  padding-right: 15px;
  box-sizing: content-box;
  margin-right: 10px;
}

.single-product.woocommerce div.product .star-rating:before,
.single-product.woocommerce div.product .star-rating span:before {
  letter-spacing: 3px;
}

.single-product.woocommerce div.product .woocommerce-review-link {
  font-size: 13px;
}

.single-product.woocommerce div.product p.price {
  padding-top: 27px;
  margin-bottom: 28px;
}

.single-product.woocommerce div.product p.price ins {
  text-decoration: none;
}

.single-product.woocommerce div.product p.price .woocommerce-Price-amount {
  font-size: 26px;
  font-weight: bold;
}

.single-product.woocommerce div.product p.price del .woocommerce-Price-amount {
  font-size: 23px;
  margin-right: 9px;
}

.single-product.woocommerce div.product span.onsale {
  position: static;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
}

.single-product.woocommerce div.product .quantity .qty {
  height: 60px;
  font-size: 16px;
  width: 75px;
  padding-left: 15px;
  border: none;
}

.single-product.woocommerce div.product form.cart {
  margin: 25px 30px 55px 0;
  float: left;
}

.single-product.woocommerce div.product form.cart.variations_form {
  width: 300px;
}

.single-product.woocommerce div.product form.cart .button {
  font-size: 13px;
  height: 60px;
  text-transform: uppercase;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  padding: 10px 30px;
}

.single-product.woocommerce div.product .product_meta {
  margin-top: 50px;
}

.single-product.woocommerce div.product .product_meta {
  overflow: hidden;
  padding-top: 5px;
}

.single-product.woocommerce div.product .product_meta > span {
  display: block;
  font-size: 13px;
  margin-bottom: 5px;
}

.single-product.woocommerce div.product .product_meta > span .realfactory-head {
  font-weight: 600;
}

.single-product.woocommerce div.product .woocommerce-variation-add-to-cart-disabled {
  display: none;
}

.single-product.woocommerce div.product .realfactory-woocommerce-social-share {
  clear: both;
  padding-top: 15px;
  margin-top: 50px;
  border-top-width: 2px;
  border-top-style: solid;
}

.single-product.woocommerce div.product .gdlr-core-social-share-item a {
  font-size: 14px;
}

.single-product.woocommerce div.product .realfactory-woocommerce-tab {
  clear: both;
  padding-top: 60px;
  margin-bottom: 50px;
}

.single-product.woocommerce div.product .realfactory-woocommerce-tab .gdlr-core-tab-item-title {
  font-size: 16px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 600;
  padding-bottom: 18px;
}

.single-product.woocommerce div.product .realfactory-woocommerce-tab .gdlr-core-tab-item-title-line {
  border-bottom-width: 1px;
  bottom: -1px;
}

.single-product.woocommerce div.product .realfactory-woocommerce-tab .gdlr-core-tab-item-title-wrap {
  border-bottom-width: 1px;
}

.single-product.woocommerce div.product .realfactory-woocommerce-tab .gdlr-core-tab-item-content {
  max-width: 900px;
  margin: 0 auto;
}

.single-product.woocommerce .woocommerce-Reviews-title {
  display: none;
}

.single-product.woocommerce #reviews {
  padding-top: 43px;
}

.single-product.woocommerce #reviews .comment-respond {
  text-align: left;
}

.single-product.woocommerce #reviews #comments ol.commentlist li img.avatar {
  border: none;
  width: 85px;
  position: static;
  padding: 0;
  border-radius: 50%;
  margin-right: 30px;
}

.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text {
  overflow: hidden;
  margin-left: 0;
  padding: 0;
  border: none;
  text-align: left;
}

.single-product.woocommerce #reviews #comments ol.commentlist li {
  padding: 39px 49px;
  margin-bottom: 30px;
}

.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta {
  margin-bottom: 18px;
}

.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta strong[itemprop="author"] {
  display: block;
  font-size: 17px;
  text-transform: capitalize;
}

.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta time[itemprop="datePublished"] {
  display: block;
  font-size: 15px;
}

.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text .star-rating {
  float: none;
  margin-top: 24px;
}

.single-product.woocommerce #reviews #review_form_wrapper {
  padding-top: 35px;
}

.single-product.woocommerce #reviews .comment-reply-title {
  font-size: 22px;
  margin-bottom: 30px;
}

.single-product.woocommerce #review_form #respond label {
  font-size: 15px;
  font-weight: 600;
}

.single-product.woocommerce #review_form #respond p {
  margin-bottom: 30px;
}

.single-product.woocommerce #review_form #respond label[for="rating"] {
  display: inline-block;
  margin-right: 30px;
  vertical-align: middle;
}

.single-product.woocommerce #review_form #respond p.stars {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  font-size: 19px;
  line-height: 1;
}

.single-product.woocommerce #review_form #respond p.stars a {
  margin-right: 5px;
}

.single-product.woocommerce #review_form #respond input[type="text"],
.single-product.woocommerce #review_form #respond input[type="email"] {
  display: block;
  margin-top: 20px;
  border-bottom-width: 1px;
  width: 100%;
  height: 55px;
}

.single-product.woocommerce #review_form #respond textarea {
  display: block;
  margin-top: 20px;
  border-bottom-width: 1px;
  width: 100%;
  height: 150px;
}

.single-product.woocommerce #review_form #respond .form-submit input {
  font-size: 13px;
  height: 58px;
  text-transform: uppercase;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  padding: 10px 38px;
}

.woocommerce.single-product .related.products {
  clear: left;
}

/*-------------*/
/*--- mmenu ---*/
/*-------------*/
.mm-menu, .mm-panels, .mm-panels > .mm-panel {
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  box-sizing: border-box
}

.mm-btn, .mm-menu {
  box-sizing: border-box
}

.mm-listview a, .mm-listview a:hover, .mm-navbar a, .mm-navbar a:hover {
  text-decoration: none
}

.mm-hidden {
  display: none !important
}

.mm-wrapper {
  overflow-x: hidden;
  position: relative
}

.mm-menu {
  display: block;
  padding: 0;
  position: absolute
}

.mm-panels, .mm-panels > .mm-panel {
  background: inherit;
  border-color: inherit;
  position: absolute
}

.mm-btn, .mm-panel.mm-highest {
  z-index: 1
}

.mm-panels {
  overflow: hidden
}

.mm-panel {
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0)
}

.mm-panel.mm-opened {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.mm-panel.mm-subopened {
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0)
}

.mm-panels > .mm-panel {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px
}

.mm-listview .mm-divider, .mm-listview > li > a, .mm-listview > li > span, .mm-navbar .mm-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden
}

.mm-panels > .mm-panel.mm-hasnavbar {
  padding-top: 40px
}

.mm-vertical .mm-panel {
  -webkit-transform: none !important;
  transform: none !important
}

.mm-listview .mm-vertical .mm-panel, .mm-vertical .mm-listview .mm-panel {
  display: none;
  padding: 10px 0 10px 10px
}

.mm-listview .mm-vertical .mm-panel .mm-listview > li:last-child:after, .mm-vertical .mm-listview .mm-panel .mm-listview > li:last-child:after {
  border-color: transparent
}

.mm-vertical li.mm-opened > .mm-panel, li.mm-vertical.mm-opened > .mm-panel {
  display: block
}

.mm-listview > li.mm-vertical > .mm-next, .mm-vertical .mm-listview > li > .mm-next {
  height: 40px;
  bottom: auto
}

.mm-listview > li.mm-vertical > .mm-next:after, .mm-vertical .mm-listview > li > .mm-next:after {
  top: 16px;
  bottom: auto
}

.mm-listview > li.mm-vertical.mm-opened > .mm-next:after, .mm-vertical .mm-listview > li.mm-opened > .mm-next:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 19px
}

.mm-btn {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0
}

.mm-clear:after, .mm-clear:before, .mm-close:after, .mm-close:before {
  content: '';
  border: 2px solid transparent;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.mm-clear:before, .mm-close:before {
  border-right: none;
  border-bottom: none;
  right: 18px
}

.mm-clear:after, .mm-close:after {
  border-left: none;
  border-top: none;
  right: 25px
}

.mm-arrow:after, .mm-next:after {
  content: '';
  border: 1px solid transparent;
  display: block;
  width: 6px;
  height: 6px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.mm-prev:before {
  border-right: none;
  border-bottom: none;
  left: 0
}

.mm-arrow:after, .mm-next:after {
  border-top: none;
  border-left: none;
  right: 2px
}

.mm-navbar {
  text-align: center;
  line-height: 20px;
  height: 40px;
  margin: 0;
}

.mm-navbar .mm-btn:last-child {
  text-align: right;
  padding-right: 20px;
  right: 0
}

.mm-panel .mm-navbar {
  display: none
}

.mm-panel.mm-hasnavbar .mm-navbar {
  display: block;
  margin: 0 25px 30px;
  position: relative;
}

.mm-listview, .mm-listview > li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0
}

.mm-listview {
  font: inherit;
  font-size: 14px;
  line-height: 20px
}

.mm-listview > li {
  position: relative
}

.mm-listview > li, .mm-listview > li .mm-next, .mm-listview > li .mm-next:before, .mm-listview > li:after {
  border-color: inherit
}

.mm-listview > li > a, .mm-listview > li > span {
  color: inherit;
  display: block;
  margin: 0
}

.mm-listview > li > a.mm-arrow, .mm-listview > li > span.mm-arrow {
  padding-right: 50px
}

.mm-listview .mm-next {
  background: rgba(3, 2, 1, 0);
  width: 50px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2
}

.mm-listview .mm-next.mm-fullsubopen {
  width: 100%
}

.mm-listview .mm-next.mm-fullsubopen:before {
  border-left: none
}

.mm-listview .mm-next.mm-fullsubopen + a, .mm-listview .mm-next.mm-fullsubopen + span {
  padding-right: 50px;
  margin-right: 0
}

.mm-listview .mm-inset {
  list-style: disc inside;
  padding: 0 10px 15px 40px;
  margin: 0
}

.mm-listview .mm-inset > li {
  padding: 5px 0
}

.mm-listview .mm-divider {
  font-size: 10px;
  text-transform: uppercase;
  text-indent: 20px;
  line-height: 25px
}

.mm-listview .mm-spacer {
  padding-top: 40px
}

.mm-listview .mm-spacer > .mm-next {
  top: 40px
}

.mm-listview .mm-spacer.mm-divider {
  padding-top: 25px
}

.mm-page {
  box-sizing: border-box;
  position: relative
}

.mm-slideout {
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
  z-index: 2
}

html.mm-opened {
  overflow-x: hidden;
  position: static;
}

#mm-blocker {
  background: rgba(3, 2, 1, 0);
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100
}

html.mm-blocking #mm-blocker {
  display: block
}

.mm-menu.mm-offcanvas {
  z-index: 0;
  display: none;
  position: fixed
}

.mm-menu.mm-offcanvas.mm-current {
  display: block
}

.mm-menu {
  width: 350px
}

@media all and (max-width: 550px) {
  .mm-menu {
    width: 250px
  }
}

html.mm-opening .mm-slideout {
  -webkit-transform: translate3d(350px, 0, 0);
  transform: translate3d(350px, 0, 0)
}

@media all and (max-width: 550px) {
  html.mm-opening .mm-slideout {
    -webkit-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0)
  }
}

/* right effect */
html.mm-right.mm-opened .mm-menu.mm-effect-menu-zoom {
  -webkit-transform: scale(.7, .7) translate3d(30%, 0, 0);
  transform: scale(.7, .7) translate3d(30%, 0, 0);
  -webkit-transform-origin: right center;
  transform-origin: right center
}

html.mm-right.mm-opening .mm-menu.mm-effect-menu-zoom {
  -webkit-transform: scale(1, 1) translate3d(0, 0, 0);
  transform: scale(1, 1) translate3d(0, 0, 0)
}

html.mm-right.mm-opened .mm-menu.mm-effect-menu-slide {
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0)
}

html.mm-right.mm-opening .mm-menu.mm-effect-menu-slide {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

html.mm-right.mm-opening.mm-fullscreen .mm-slideout {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0)
}

.mm-menu.mm-pageshadow.mm-right:after {
  left: auto;
  right: 100%
}

.mm-menu.mm-right {
  left: auto;
  right: 0
}

html.mm-right.mm-opening .mm-slideout {
  -webkit-transform: translate3d(-350px, 0, 0);
  transform: translate3d(-350px, 0, 0)
}

@media all and (max-width: 550px) {
  html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate3d(-250px, 0, 0);
    transform: translate3d(-250px, 0, 0)
  }
}

.mm-menu.mm-front.mm-right, .mm-menu.mm-next.mm-right {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0)
}

/* page dim */
html.mm-pagedim #mm-blocker, html.mm-pagedim-black #mm-blocker, html.mm-pagedim-white #mm-blocker {
  opacity: 0
}

html.mm-pagedim-black.mm-opening #mm-blocker, html.mm-pagedim-white.mm-opening #mm-blocker, html.mm-pagedim.mm-opening #mm-blocker {
  opacity: .3;
  transition: ease .4s
}

html.mm-pagedim #mm-blocker {
  background: inherit
}

html.mm-pagedim-white #mm-blocker {
  background: #fff
}

html.mm-pagedim-black #mm-blocker {
  background: #000
}

/* custom */
ul.mm-listview li {
  margin: 0 25px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

ul.mm-listview li a, ul.mm-listview li span.realfactory-mm-menu-blank {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 17px 0;
}

.mm-navbar .mm-btn {
  line-height: 40px;
  font-size: 30px;
  display: block;
  text-align: left;
  left: -8px;
  padding: 0;
}

.mm-navbar .mm-btn:before {
  content: "\34";
  font-family: elegantIcons;
}

.mm-navbar .mmenu-custom-close {
  line-height: 40px;
  font-size: 30px;
  display: block;
  text-align: left;
  margin-left: -8px;
  cursor: pointer;
}

.mm-navbar .mmenu-custom-close:after {
  content: "\4d";
  font-family: elegantIcons;
}

.mm-navbar .mm-title {
  display: none;
}

.mm-navbar .mm-title:first-child {
  display: block;
}

html.realfactory-mmenu-left .realfactory-sticky-navigation.realfactory-fixed-navigation,
html.realfactory-mmenu-right .realfactory-sticky-navigation.realfactory-fixed-navigation {
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

html.mm-opening.realfactory-mmenu-left .realfactory-sticky-navigation.realfactory-fixed-navigation {
  -webkit-transform: translate3d(350px, 0, 0);
  transform: translate3d(350px, 0, 0);
}

html.mm-opening.realfactory-mmenu-right .realfactory-sticky-navigation.realfactory-fixed-navigation {
  -webkit-transform: translate3d(-350px, 0, 0);
  transform: translate3d(-350px, 0, 0);
}

html.realfactory-mmenu-left .realfactory-mobile-header-wrap .realfactory-top-bar,
html.realfactory-mmenu-left .realfactory-mobile-header,
html.realfactory-mmenu-right .realfactory-mobile-header-wrap .realfactory-top-bar,
html.realfactory-mmenu-right .realfactory-mobile-header {
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

html.mm-opening.realfactory-mmenu-left .realfactory-mobile-header-wrap .realfactory-top-bar,
html.mm-opening.realfactory-mmenu-left .realfactory-mobile-header {
  -webkit-transform: translate3d(350px, 0, 0);
  transform: translate3d(350px, 0, 0);
}

html.mm-opening.realfactory-mmenu-right .realfactory-mobile-header-wrap .realfactory-top-bar,
html.mm-opening.realfactory-mmenu-right .realfactory-mobile-header {
  -webkit-transform: translate3d(-350px, 0, 0);
  transform: translate3d(-350px, 0, 0);
}

@media all and (max-width: 550px) {
  html.mm-opening.realfactory-mmenu-left .realfactory-sticky-navigation.realfactory-fixed-navigation {
    -webkit-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0);
  }
  html.mm-opening.realfactory-mmenu-right .realfactory-sticky-navigation.realfactory-fixed-navigation {
    -webkit-transform: translate3d(-250px, 0, 0);
    transform: translate3d(-250px, 0, 0);
  }
  html.mm-opening.realfactory-mmenu-left .realfactory-mobile-header-wrap .realfactory-top-bar,
  html.mm-opening.realfactory-mmenu-left .realfactory-mobile-header {
    -webkit-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0);
  }
  html.mm-opening.realfactory-mmenu-right .realfactory-mobile-header-wrap .realfactory-top-bar,
  html.mm-opening.realfactory-mmenu-right .realfactory-mobile-header {
    -webkit-transform: translate3d(-250px, 0, 0);
    transform: translate3d(-250px, 0, 0);
  }
}

/*-----------------*/
/*--- superfish ---*/
/*-----------------*/

/* top level */
.sf-menu {
  list-style: none;
  margin-left: 0;
}

.sf-menu > li {
  float: left;
  cursor: pointer;
}

.sf-menu > li > a {
  display: block;
  text-decoration: none;
  position: relative;
  z-index: 9;
}

/* normal menu */
.sf-menu > .realfactory-normal-menu ul {
  list-style: none;
  position: absolute;
  display: none;
  z-index: 99;
  min-width: 220px;
}

.sf-menu > .realfactory-normal-menu ul {
  margin-left: -15px;
}

.sf-menu > .realfactory-normal-menu ul ul {
  margin-left: 0;
}

.sf-menu > .realfactory-normal-menu.sfHover > ul,
.sf-menu > .realfactory-normal-menu.sfHover > ul:hover,
.sf-menu > .realfactory-normal-menu li.sfHover > ul {
  display: block;
}

.sf-menu > .realfactory-normal-menu ul ul {
  top: 0;
  left: 100%;
}

.sf-menu > .realfactory-normal-menu ul ul.sub-menu-right {
  left: auto;
  right: 100%;
}

.sf-menu > .realfactory-normal-menu li {
  position: relative;
  white-space: nowrap;
}

.sf-menu > .realfactory-normal-menu li a {
  text-decoration: none;
}

.sf-menu > .realfactory-normal-menu li > a.sf-with-ul-pre {
}

.sf-menu > .realfactory-normal-menu li > a.sf-with-ul-pre:after {
  content: '>';
}

/* mega menu */
.sf-menu > .realfactory-mega-menu .sf-mega {
  position: absolute;
  display: none;
  z-index: 99;
}

.sf-menu > .realfactory-mega-menu.sfHover .sf-mega,
.sf-menu > .realfactory-mega-menu.sfHover .sf-mega:hover {
  display: block;
}

.sf-menu > .realfactory-mega-menu .sf-mega-section-inner a {
  text-decoration: none;
}

.sf-menu > .realfactory-mega-menu .sf-mega-section-inner ul {
  list-style: none;
  margin-left: 0;
  margin-bottom: 0;
}

/* vertical menu */
.sf-vertical {
  list-style: none;
  margin-left: 0;
  left: 100%;
  top: 0;
  margin-bottom: 0;
}

.sf-vertical li {
  position: relative;
}

.sf-vertical ul.sub-menu {
  list-style: none;
  position: absolute;
  display: none;
  left: 100%;
  top: 0;
  z-index: 9;
  min-width: 190px;
}

.sf-vertical ul.sub-menu li {
  white-space: nowrap;
}

#gdlr-core-button-id-38517 {
  color: #edbf2a;
  padding: 13px 30px 13px 30px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-width: 2px 2px 2px 2px;
  border-color: #edbf2a
}

#gdlr-core-button-id-38517:hover {
  border-color: #edbf2a
}

#gdlr-core-title-item-id-27983 .gdlr-core-title-item-title a {
  color: #141414
}

.submit-button {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  padding: 15px 33px;
  display: inline-block;
  -webkit-appearance: none;
  background-color: #333;
  border: 0;
  color: #fff;
  cursor: pointer;
  width: 100%;
}
@import "style";
@import "bootstrap";
@import "margins";


#back-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 9999;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 30px;
  background: orange;
  color: #444;
  cursor: pointer;
  border: 0;
  border-radius: 20px;
  text-decoration: none;
  transition: opacity 0.2s ease-out;
  opacity: 0;
  font-size: 20px;
}
#back-to-top:hover {
  background: #e9ebec;
}
#back-to-top.show {
  opacity: 1;
}



//Card
